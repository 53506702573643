import { Button, Grid, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';
import { FormikHelpers } from 'formik/dist/types';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { useAntiDoubleClick, useGeneralObjectContext, useStore, useYup } from '../../../../../../hooks';
import { ContentSettingsModel } from '../../../../../../models';
import { categoryMessages } from '../../../../../../resources';
import {
    CodeTitle,
    GeneralObject,
    GeneralObjectRouteParams,
    GeneralObjectWithDescendantObject,
    SettingsContent,
    SettingsContentFields,
} from '../../../../../../types';
import { disableSubmitOnEnterKeyPress } from '../../../../../../utils';
import { AutocompleteField as AutocompleteFieldInj } from '../../../../../fields';

const getFormLabel = (option: CodeTitle): string => {
    const { title, code } = option;
    return title && `${title} (${code})`;
};

export const GeneralObjectTabSettingsContentForm = observer(() => {
    const [AutocompleteField] = di([AutocompleteFieldInj], GeneralObjectTabSettingsContentForm);

    const { objectStore } = useGeneralObjectContext();
    const { tabId, id } = useParams<GeneralObjectRouteParams>();
    const rootStore = useStore();
    const { intlStore } = rootStore;
    const intl = useIntl();

    const settings = useMemo<ContentSettingsModel>(
        () => new ContentSettingsModel(id, tabId, rootStore, objectStore),
        [id, tabId, rootStore, objectStore],
    );

    useEffect(() => {
        settings.load();
    }, [intl.locale]);

    const {
        form,
        list,
        objectList,
        formList,
        presetList,
        serverErrors,
        categoriesList,
        generalObjectType,
        isFormListLoading,
        isObjectListLoading,
        isPresetsAndCategoriesListLoading,
    } = settings;
    const { serverFormErrors } = serverErrors;
    const { formData, isDownloadAllFilesButtonShown, entity, preset, categories } = SettingsContentFields;

    const { Yup } = useYup();
    const schema = Yup.object().shape({
        form: Yup.object().shape({
            formData: Yup.object().nullable(),
            isDownloadAllFilesButtonShown: Yup.boolean(),
        }),
        list: Yup.object().shape({
            entity: Yup.object().nullable(),
            preset: Yup.object()
                .when('entity', {
                    is: (entity: CodeTitle): boolean => entity && !!entity.code,
                    then: Yup.object().required().nullable(),
                })
                .nullable(),
            categories: Yup.array(),
        }),
    });

    const initialValues: SettingsContent = {
        form,
        list,
    };

    const handleSubmit = (values: SettingsContent): Promise<void> => {
        return settings.updateContent(values);
    };

    const getObjectLabel = (option: CodeTitle): string => {
        return objectList.find((object: CodeTitle) => object.code === option.code)?.title || '';
    };

    const [isSending, endIcon, createHandler] = useAntiDoubleClick(handleSubmit);
    const [isEntitySelected, setIsEntitySelected] = useState<boolean>(false);

    useEffect(() => {
        const isEntitySelected = list.entity !== null;
        setIsEntitySelected(isEntitySelected);

        if (isEntitySelected) {
            settings.loadTabSettingsContentInfo((list.entity as CodeTitle).code);
        }
    }, [list]);

    const onEntityChange =
        (setFieldValue: FormikHelpers<SettingsContent>['setFieldValue']): ((entity: CodeTitle) => void) =>
        (entity): void => {
            const isWithEntity = Boolean(entity && entity.code);
            setIsEntitySelected(isWithEntity);
            setTimeout(() => {
                setFieldValue(preset, null);
                setFieldValue(categories, []);
            }, 0);

            if (isWithEntity) {
                settings.loadTabSettingsContentInfo(entity.code);
            }
        };

    const isGeneralObjectTypePortfolio = generalObjectType === GeneralObject.portfolio;

    const getIsCategoriesSelectDisabled = (values: SettingsContent): boolean => {
        const isEntityChildObject = values.list.entity?.code === GeneralObjectWithDescendantObject.childObject;
        const isWithoutCategories = isGeneralObjectTypePortfolio && isEntityChildObject;

        return !isEntitySelected || isWithoutCategories;
    };

    const getIsDownloadAllFilesCheckboxDisabled = (values: SettingsContent): boolean => {
        return !values.form.formData;
    };

    const showDownloadAllFilesLabelProps = {
        label: intl.formatMessage({ id: 'objectSettings.contentFields.showDownloadAllFilesButton' }),
    };

    return (
        <Grid item container direction="column" spacing={5}>
            <Grid item>
                <Typography variant="h3" component="h2">
                    <FormattedMessage id="objectSettings.contentFields.form" />
                </Typography>
            </Grid>
            <Grid item>
                <Formik
                    initialValues={initialValues}
                    validationSchema={schema}
                    onSubmit={createHandler}
                    enableReinitialize={true}
                >
                    {({ values, setFieldValue }): JSX.Element => {
                        const isCategoriesSelectDisabled = getIsCategoriesSelectDisabled(values);
                        const isDownloadAllFilesCheckboxDisabled = getIsDownloadAllFilesCheckboxDisabled(values);

                        if (isDownloadAllFilesCheckboxDisabled && values.form.isDownloadAllFilesButtonShown === true) {
                            setFieldValue(isDownloadAllFilesButtonShown, false);
                        }

                        return (
                            <Form onKeyDown={disableSubmitOnEnterKeyPress}>
                                <Grid container item direction="column" spacing={10}>
                                    <Grid container item direction="column" spacing={4}>
                                        <Grid item>
                                            <AutocompleteField
                                                fieldName={formData}
                                                label={intl.formatMessage({ id: 'objectSettings.contentFields.form' })}
                                                options={formList}
                                                getLabel={getFormLabel}
                                                serverError={serverFormErrors[formData]}
                                                loading={isFormListLoading}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Field
                                                name={isDownloadAllFilesButtonShown}
                                                disabled={isDownloadAllFilesCheckboxDisabled}
                                                type="checkbox"
                                                component={CheckboxWithLabel}
                                                Label={showDownloadAllFilesLabelProps}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item direction="column" spacing={4}>
                                        <Grid item>
                                            <Typography variant="h3" component="h2">
                                                <FormattedMessage id="objectSettings.contentFields.list" />
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <AutocompleteField
                                                fieldName={entity}
                                                label={intl.formatMessage({
                                                    id: 'objectSettings.contentFields.entity',
                                                })}
                                                options={objectList}
                                                getLabel={getObjectLabel}
                                                onChange={onEntityChange(setFieldValue)}
                                                serverError={serverFormErrors[entity]}
                                                loading={isObjectListLoading}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <AutocompleteField
                                                disabled={!isEntitySelected}
                                                fieldName={preset}
                                                required={isEntitySelected}
                                                label={intl.formatMessage({
                                                    id: 'objectSettings.contentFields.preset',
                                                })}
                                                options={presetList}
                                                serverError={serverFormErrors[preset]}
                                                loading={isPresetsAndCategoriesListLoading}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <AutocompleteField
                                                disabled={isCategoriesSelectDisabled}
                                                fieldName={categories}
                                                multiple={true}
                                                label={intlStore.formatMessageFromDefineMessage(
                                                    categoryMessages.tabSettingsList,
                                                )}
                                                options={categoriesList}
                                                serverError={serverFormErrors[categories]}
                                                loading={isPresetsAndCategoriesListLoading}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            disabled={isSending}
                                            endIcon={endIcon}
                                            type="submit"
                                        >
                                            <FormattedMessage id="common.save" />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        );
                    }}
                </Formik>
            </Grid>
        </Grid>
    );
});
