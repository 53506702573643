import {
    ChartSettingField,
    GeneralObjectPermissionTrigger,
    GeneralObjectPFSettingSections,
    GeneralObjectSettingSections,
    LocalesTree,
    SettingsContentType,
} from '../../../types';

const settingsSections: Record<GeneralObjectSettingSections, string> = {
    access: 'Доступ',
    content: 'Контент',
};

const pfSettingsSections: Record<GeneralObjectPFSettingSections, string> = {
    settings: 'Настройки',
    access: 'Доступ',
};

const permissionTrigger: Record<GeneralObjectPermissionTrigger, string> = {
    role: 'Роль',
    condition: 'Условие',
};

const contentTypes: Record<SettingsContentType, string> = {
    FORM: 'Форма',
    LIST: 'Список',
    PF: 'Печатная форма',
    CHART: 'Визуализация',
};

const visualizationSettingsFields: Record<ChartSettingField, string> = {
    chartSettings: 'Параметры визуализации',
    typeChart: 'Тип диаграммы',
    objectTab: 'Вкладка',
    objectRegistry: 'Объект',
    valuesFieldCode: 'Код поля значения',
    dataLabelsFieldCode: 'Код поля подписей данных',
    element: 'Элемент',
    source: 'Источник данных',
    registryQuery: 'Запрос',
};

const objectSettings: LocalesTree = {
    title: 'Настройка вкладок',
    addTab: 'Добавить вкладку',
    hideTab: 'Скрыть вкладку',
    showTab: 'Показать вкладку',
    hideTabConfirmation: 'Вкладка будет скрыта на страницах объектов, а её атрибуты будут недоступны в реестрах',
    showTabConfirmation: 'Вкладка будет отображена на страницах объектов, а её атрибуты будут доступны в реестрах',
    tabFields: {
        title: 'Наименование',
        code: 'Код',
        type: 'Тип контента',
    },
    tabModalTitle: 'Новая вкладка',
    addRule: 'Добавить правило',
    addPermissionModalTitle: 'Новое правило',
    editPermissionModalTitle: 'Правило доступа',
    codeHelperText: 'Латинские буквы и цифры, без пробелов',
    accessFields: {
        roleCondition: 'Роль/ условие',
        lifecycle: 'Состояния ЖЦ',
        access: 'Доступ',
        generation: 'Генерация',
        filesDownload: 'Скачивание файлов',
        edit: 'Редактирование',
        editForm: 'Редактирование формы',
        filesManagement: 'Управление файлами',
        filesUpload: 'Загрузка файлов',
        filesEdit: 'Удаление файлов и изменение категорий файлов',
    },
    contentFields: {
        form: 'Форма',
        showDownloadAllFilesButton: 'Отображать кнопку “Скачать все файлы”',
        list: 'Список',
        entity: 'Объект',
        preset: 'Пресет',
    },
    printForms: 'Печатные формы',
    addPrintForm: 'Добавить шаблон ПФ',
    newPrintForm: 'Новый шаблон ПФ',
    editPrintForm: 'Редактировать шаблон ПФ',
    printFormFields: {
        titles: 'Название',
        code: 'Код',
        description: 'Описание',
        docType: 'Категория документа',
        file: 'Файл шаблона',
        fileFormat: 'Формат файла генерируемого документа',
        required: 'Является обязательным для генерации',
    },
    printFormList: {
        fileFormat: 'Формат файла',
        required: 'Является обязательным',
    },
    printFormDeleteText: 'Шаблон печатной формы будет удалён на вкладках объектов',
    visualizations: 'Визуализация данных',
    addVisualization: 'Добавить визуализацию',
    editVisualization: 'Редактировать визуализацию',
    newVisualization: 'Новая визуализация',
    previewVisualization: 'Предпросмотр диаграммы',
    notValidSettings: 'Настройки визуализации не валидны',
    visualizationIsNotSet: 'Настройте параметры визуализации слева',
    visualizationSetWithErrors: 'Визуализация настроена с ошибками',
    visualizationPreview: 'Данные',
    visualizationFields: {
        titles: 'Название',
        type: 'Тип',
        source: 'Источник данных',
    },
    visualizationDeleteText: 'Визуализация будет удалёна на вкладках объектов',
    noVisualizations: 'Нет настроенных визуализаций',
    view: 'Просмотр',
    allStatuses: 'Все статусы',
    contentTypes,
    settingsSections,
    pfSettingsSections,
    permissionTrigger,
    visualizationSettingsFields,
    notValidQuery: 'Запрос указан не верно',
};

export default objectSettings;
