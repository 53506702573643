import { Box, Button, Container, Grid, Link, Paper, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { EmailField as EmailFieldInj, TotBackground as TotBackgroundInj } from '../../components';
import { useStore, useYup } from '../../hooks';
import { RecoveryPasswordPageFields, RegistrationDTO } from '../../types';
import { disableSubmitOnEnterKeyPress } from '../../utils';

const initialValues: RegistrationDTO = {
    email: '',
    password: '',
    firstName: '',
    middleName: '',
    lastName: '',
    lang: '',
};

export const RecoveryPasswordPage = observer((): JSX.Element => {
    const [TotBackground] = di([TotBackgroundInj], RecoveryPasswordPage);
    const [EmailField] = di([EmailFieldInj], RecoveryPasswordPage);

    const { authenticationStore } = useStore();
    const { recoveryPasswordModel } = authenticationStore;
    const { serverErrorsModel, sentSucceed } = recoveryPasswordModel;
    const { serverFormErrors } = serverErrorsModel;
    const { email } = RecoveryPasswordPageFields;

    const { Yup } = useYup();

    const schema = Yup.object().shape({
        email: Yup.string().required().email(),
    });

    const handleConfirm = (values: RegistrationDTO): void => {
        recoveryPasswordModel.sendToken(values);
    };

    return (
        <TotBackground withBackdrop={true}>
            <Grid container item direction="column" alignItems="center" justifyContent="center">
                <Container className="t-recovery-password-page" maxWidth="xs">
                    <Paper elevation={24}>
                        <Box p={12}>
                            <Grid container justifyContent="center">
                                <Grid item>
                                    <Typography variant="h5" className="t-recovery-title">
                                        <Box fontWeight="fontWeightBold">
                                            <FormattedMessage id="authentication.recoveryTitle" />
                                        </Box>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Box pt={8}>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={schema}
                                    onSubmit={handleConfirm}
                                >
                                    <Form onKeyDown={disableSubmitOnEnterKeyPress}>
                                        <Grid container spacing={6} direction="column">
                                            <Grid item>
                                                <EmailField name={email} serverError={serverFormErrors[email]} />
                                            </Grid>
                                            <Grid item>
                                                <Typography className="t-recovery-info">
                                                    <FormattedMessage id="authentication.recoveryInfo" />
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    className="t-recovery"
                                                    fullWidth
                                                    size="large"
                                                    variant="contained"
                                                    type="submit"
                                                >
                                                    <FormattedMessage id="authentication.sendToken" />
                                                </Button>
                                            </Grid>
                                            <Grid item container justifyContent="center">
                                                <Grid item>
                                                    <Link
                                                        className="t-login"
                                                        component={RouterLink}
                                                        to={clientRoute.login}
                                                        underline="none"
                                                    >
                                                        <FormattedMessage id="authentication.login" />
                                                    </Link>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                </Formik>
                            </Box>
                        </Box>
                    </Paper>
                    {sentSucceed && <Redirect to={clientRoute.recoveryPasswordInfoSuccess} />}
                </Container>
            </Grid>
        </TotBackground>
    );
});
