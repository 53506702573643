import { Grid } from '@mui/material';
import { makeSxStyles } from '@platform/front-ui';
import React, { ReactNode } from 'react';
import { di } from 'react-magnetic-di';
import { redesignTotPalette } from '../theme';
import { CommonTypography as CommonTypographyInj } from './typography';

export type CommonInfoLineProps = {
    title?: ReactNode;
    value?: ReactNode;
    isColumnDirection?: boolean;
};

export const commonInfoLineSxStyles = makeSxStyles({
    title: {
        color: redesignTotPalette.typography.default.lighterColor,
    },
    value: {
        wordWrap: 'break-word',
        color: redesignTotPalette.typography.default.dark,
    },
    infoLineGridItemCommonStyles: {
        maxWidth: '100%',
    },
});

export const CommonInfoLine = (props: CommonInfoLineProps): JSX.Element => {
    const [CommonTypography] = di([CommonTypographyInj], CommonInfoLine);

    const { title, value = '-', isColumnDirection = false } = props;
    const sx = commonInfoLineSxStyles;

    return (
        <Grid
            item
            container
            flexDirection={isColumnDirection ? 'column' : 'row'}
            alignItems={isColumnDirection ? 'flex-start' : 'center'}
            spacing={1}
            wrap="nowrap"
        >
            {title && (
                <Grid item sx={sx.infoLineGridItemCommonStyles}>
                    <CommonTypography sx={sx.title} paddingRight={1}>
                        {title}
                    </CommonTypography>
                </Grid>
            )}
            <Grid item sx={sx.infoLineGridItemCommonStyles}>
                <CommonTypography sx={sx.value} component="span">
                    {value}
                </CommonTypography>
            </Grid>
        </Grid>
    );
};
