import { ThemeProvider, Typography } from '@mui/material';
import { ObjectHeaderLayout, ObjectHeaderLayoutProps } from '@platform/front-core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { useGeneralObjectChartSettingsPageContext, useStore } from '../../../../../../../../hooks';
import { AppTheme, BreadcrumbsEntities, GeneralObjectChartParams } from '../../../../../../../../types';
import { ServerBreadcrumbs } from '../../../../../../../breadcrumbs';
import { GeneralObjectEditTitleBtn as GeneralObjectEditTitleBtnInj } from '../../../../../general-object-edit-title';

export const GeneralObjectChartSettingsPageHeader = observer((): JSX.Element => {
    const [GeneralObjectEditTitleBtn] = di([GeneralObjectEditTitleBtnInj], GeneralObjectChartSettingsPageHeader);

    const { chartSettingId } = useParams<GeneralObjectChartParams>();
    const { appTheme } = useStore();
    const { model } = useGeneralObjectChartSettingsPageContext();
    const { title, saveChartSettingTitles, getChartSettingTitles } = model;
    const objectHeaderLayoutProps: ObjectHeaderLayoutProps = {
        breadcrumbs: (
            <ThemeProvider theme={appTheme as AppTheme}>
                <ServerBreadcrumbs entityType={BreadcrumbsEntities.chartContent} entityId={chartSettingId} />
            </ThemeProvider>
        ),
        titleBlockAndMenu: {
            titleBlock: (
                <Typography variant="subtitle2" pt={0.5}>
                    {title}
                </Typography>
            ),
            menu: {
                element: (
                    <ThemeProvider theme={appTheme as AppTheme}>
                        <GeneralObjectEditTitleBtn
                            loadTitles={getChartSettingTitles}
                            saveTitles={saveChartSettingTitles}
                        />
                    </ThemeProvider>
                ),
            },
        },
    };
    return <ObjectHeaderLayout {...objectHeaderLayoutProps} />;
});
