import { Grid } from '@mui/material';
import { SxStyle } from '@platform/front-ui';
import React from 'react';
import { di } from 'react-magnetic-di';
import { StyledCircularProgress as StyledCircularProgressInj } from './StyledCircularProgress';

const wrapperSx: SxStyle = { padding: 5 };

export const Loader = (): JSX.Element => {
    const [StyledCircularProgress] = di([StyledCircularProgressInj], Loader);
    return (
        <Grid container justifyContent="center" sx={wrapperSx}>
            <StyledCircularProgress />
        </Grid>
    );
};
