import { Tab, TabProps } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { di } from 'react-magnetic-di';
import { LinkProps } from 'react-router-dom';
import { AuthorizationCheck as AuthorizationCheckInj, AuthorizationCheckProps } from '../AuthorizationCheck';

export type AuthorizedTabProps = TabProps & LinkProps & AuthorizationCheckProps;

export const AuthorizedTab = observer((props: AuthorizedTabProps): JSX.Element => {
    const { permCode, entityCode, entityId, pendingElement, onAllowed, errorElement, ...restProps } = props;
    const [AuthorizationCheck] = di([AuthorizationCheckInj], AuthorizedTab);

    return (
        <AuthorizationCheck
            entityCode={entityCode}
            permCode={permCode}
            entityId={entityId}
            pendingElement={pendingElement}
            onAllowed={onAllowed}
            errorElement={errorElement}
        >
            <Tab {...restProps} />
        </AuthorizationCheck>
    );
});
