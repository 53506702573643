import { FormioSidebarStore } from '@platform/formiojs-react';
import { AsyncCheckStore } from '@platform/front-utils';
import { History } from 'history';
import { action, makeObservable, observable } from 'mobx';
import { AppTheme, Env, LocalizedMessages, RootStorePropsType, ThemeOverrides } from '../types';
import { ApiStore, getApiStore } from './ApiStore';
import { AuthenticationStore, getAuthenticationStore } from './AuthenticationStore';
import { AuthorizationStore, getAuthorizationStore } from './AuthorizationStore';
import { BreadcrumbsStore, getBreadcrumbsStore } from './BreadcrumbsStore';
import { CatalogStore, getCatalogStore } from './CatalogStore';
import { CategoryStore, getCategoryStore } from './CategoryStore';
import { ChildObjectStore, getChildObjectStore } from './ChildObjectStore';
import { ConsoleStore, getConsoleStore } from './ConsoleStore';
import { FormioStore, getFormioStore } from './FormioStore';
import { getHeaderLinksStore, HeaderLinksStore } from './HeaderLinksStore';
import { getIntlStore, IntlStore } from './IntlStore';
import { getLkNotificationStore, LkNotificationStore } from './LkNotificationStore';
import { getModulesStore, ModulesStore } from './ModulesStore';
import { getNotificationStore, NotificationStore } from './NotificationStore';
import { getPersonStore, PersonStore } from './PersonStore';
import { getPortfolioStore, PortfolioStore } from './PortfolioStore';
import { getProjectStore, ProjectStore } from './ProjectStore';
import { getStickyElementsStore, StickyElementsStore } from './StickyElementsStore';
import { getUserStore, UserStore } from './UserStore';

export const RootStoreProps = {
    history: observable,
    api: observable,
    authenticationStore: observable,
    authorizationStore: observable,
    intlStore: observable,
    modulesStore: observable,
    notificationStore: observable,
    personStore: observable,
    formioSidebarStore: observable,
    formioStore: observable,
    stickyElementsStore: observable,
    userStore: observable,
    catalogStore: observable,
    portfolioStore: observable,
    categoryStore: observable,
    lkNotificationStore: observable,
    breadcrumbsStore: observable,
    headerLinksStore: observable,
    projectStore: observable,
    childObjectStore: observable,
    asyncCheckStore: observable,
    consoleStore: observable,
    features: observable,
    env: observable,
    themeOverrides: observable,
    appTheme: observable,
    setObservables: action.bound,
    setAppTheme: action.bound,
};

export class RootStore {
    history: History;
    api: ApiStore;
    authenticationStore: AuthenticationStore;
    authorizationStore: AuthorizationStore;
    intlStore: IntlStore;
    modulesStore: ModulesStore;
    notificationStore: NotificationStore;
    personStore: PersonStore;
    formioSidebarStore: FormioSidebarStore;
    formioStore: FormioStore;
    stickyElementsStore: StickyElementsStore;
    userStore: UserStore;
    catalogStore: CatalogStore;
    portfolioStore: PortfolioStore;
    categoryStore: CategoryStore;
    lkNotificationStore: LkNotificationStore;
    breadcrumbsStore: BreadcrumbsStore;
    headerLinksStore: HeaderLinksStore;
    projectStore: ProjectStore;
    childObjectStore: ChildObjectStore;
    asyncCheckStore: AsyncCheckStore;
    consoleStore: ConsoleStore;

    features: RootStorePropsType['features'] = {};
    env: Env = {} as Env;
    themeOverrides: ThemeOverrides = {} as ThemeOverrides;
    appTheme?: AppTheme;

    constructor(props: RootStorePropsType) {
        makeObservable(this, RootStoreProps);
        const ApiStore = getApiStore();
        const AuthenticationStore = getAuthenticationStore();
        const AuthorizationStore = getAuthorizationStore();
        const IntlStore = getIntlStore();
        const ModulesStore = getModulesStore();
        const NotificationStore = getNotificationStore();
        const PersonStore = getPersonStore();
        const FormioStore = getFormioStore();
        const StickyElementsStore = getStickyElementsStore();
        const UserStore = getUserStore();
        const CatalogStore = getCatalogStore();
        const PortfolioStore = getPortfolioStore();
        const ProjectStore = getProjectStore();
        const CategoryStore = getCategoryStore();
        const LkNotificationStore = getLkNotificationStore();
        const BreadcrumbsStore = getBreadcrumbsStore();
        const HeaderLinksStore = getHeaderLinksStore();
        const ChildObjectStore = getChildObjectStore();
        const ConsoleStore = getConsoleStore();

        this.setObservables(props);

        const { locales, history } = props;
        this.history = history;
        const messages: LocalizedMessages = locales;

        this.api = new ApiStore(this);
        this.notificationStore = new NotificationStore(this);
        this.intlStore = new IntlStore(this, messages);
        this.asyncCheckStore = new AsyncCheckStore(
            this.api.client,
            this.api.userActionClientCatchHandler,
            this.env.asyncCheckDelay as number,
            this.env.asyncCheckTimeout as number,
        );
        this.authenticationStore = new AuthenticationStore(this);
        this.authorizationStore = new AuthorizationStore(this);
        this.modulesStore = new ModulesStore(this);
        this.personStore = new PersonStore(this);
        this.formioSidebarStore = new FormioSidebarStore();
        this.formioStore = new FormioStore(this);
        this.stickyElementsStore = new StickyElementsStore();
        this.userStore = new UserStore(this);
        this.catalogStore = new CatalogStore(this);
        this.categoryStore = new CategoryStore(this);
        this.portfolioStore = new PortfolioStore(this);
        this.projectStore = new ProjectStore(this);
        this.lkNotificationStore = new LkNotificationStore(this);
        this.breadcrumbsStore = new BreadcrumbsStore(this);
        this.headerLinksStore = new HeaderLinksStore(this);
        this.projectStore = new ProjectStore(this);
        this.childObjectStore = new ChildObjectStore(this);
        this.consoleStore = new ConsoleStore(this);
    }

    setObservables(props: RootStorePropsType): void {
        const { env, features, themeOverrides } = props;
        this.env = env;
        this.features = features;
        this.themeOverrides = themeOverrides;
    }

    setAppTheme(appTheme: AppTheme): void {
        this.appTheme = appTheme;
        this.formioStore.addComponentsWithMaterialTheme();
    }
}
