import { LocalesTree } from '../../../types';

const confirmLeavePage: LocalesTree = {
    title: 'Подтверждение закрытия режима редактирования',
    message:
        '<p>Вы действительно хотите покинуть текущую страницу без сохранения изменений?</p>' +
        '<p>Для сохранения внесенных изменений нажмите кнопку "Сохранить" внизу страницы.</p>',
    textMessage: 'Вы действительно хотите покинуть текущую страницу без сохранения изменений?',
};

export default confirmLeavePage;
