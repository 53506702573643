import { Warning } from '@mui/icons-material';
import { Box, Grid, TabsActions, Tooltip } from '@mui/material';
import { makeSxStyles, red, SxStyles } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import sx from 'mui-sx';
import React from 'react';
import { di } from 'react-magnetic-di';
import { generatePath, NavLink, useParams } from 'react-router-dom';
import {
    EllipsisBox as EllipsisBoxInj,
    OuterTabsProps,
    RoutedTabs as RoutedTabsInj,
    StyledTab as StyledTabInj,
} from '../../../../components';
import { printFormContainerAnchor } from '../../../../constants';
import { useGeneralObjectCardContext, useGeneralObjectContext, useStore } from '../../../../hooks';
import { ErrorEntityType, GeneralObjectRouteParams, IdTitle } from '../../../../types';

export const generalObjectTabsSxStyles = makeSxStyles({
    fullwidth: {
        maxWidth: '100%',
    },
    opened: {
        width: '100%',
    },
    closed: {
        width: (theme) => `calc(100% - ${theme.variables.infoSidebar.closeButtonWidth}px)`,
    },
    warningIcon: {
        color: red[600],
        marginLeft: (theme) => theme.spacing(2),
    },
});

export type GeneralObjectTabsProps = {
    isInfoSidebarOpen: boolean;
    tabsActions: React.Ref<TabsActions>;
};

export const generalTabsProps: OuterTabsProps = {
    TabIndicatorProps: {
        style: { display: 'none' },
    },
    variant: 'scrollable',
    scrollButtons: 'auto',
};

export const tabSxStyles = makeSxStyles({
    scroller: {
        display: 'flex',
        alignItems: 'center',
    },
});

export const GeneralObjectTabs = observer((props: GeneralObjectTabsProps): JSX.Element => {
    const [StyledTab] = di([StyledTabInj], GeneralObjectTabs);
    const [RoutedTabs] = di([RoutedTabsInj], GeneralObjectTabs);
    const [EllipsisBox] = di([EllipsisBoxInj], GeneralObjectTabs);

    const { isInfoSidebarOpen, tabsActions } = props;
    const { routes } = useGeneralObjectContext();
    const { objectModel } = useGeneralObjectCardContext();
    const { tabs, objectContentErrorsModel } = objectModel;
    const { errorsByTabs } = objectContentErrorsModel;
    const { id } = useParams<GeneralObjectRouteParams>();
    const { stickyElementsStore } = useStore();
    const { generalObjectHeadIsSticky } = stickyElementsStore;

    const settings = tabs.map((tab) => {
        const tabId = tab.id;

        return {
            tab: tabId,
            path: generatePath(routes.tab, { id, tabId }),
        };
    });

    const renderTab = (tab: IdTitle): JSX.Element => {
        const { title, id: tabId } = tab;
        const errors = errorsByTabs[tabId];
        const tabError = errors && errors.getGeneralMessage();
        const errorTitle = <Box whiteSpace="pre-line">{tabError}</Box>;
        const label = (
            <React.Fragment>
                <EllipsisBox text={title} correctLength={40} />
                {tabError && (
                    <Tooltip title={errorTitle}>
                        <Warning sx={generalObjectTabsSxStyles.warningIcon} />
                    </Tooltip>
                )}
            </React.Fragment>
        );

        const pathParams = { id, tabId };
        let tabPath = generatePath(routes.tab, pathParams);

        if (tabError) {
            if (errors.isWithError(ErrorEntityType.submission)) {
                tabPath = generatePath(routes.formEdit, pathParams);
            } else if (errors.isWithError(ErrorEntityType.printForm)) {
                tabPath = generatePath(`${routes.tab}${printFormContainerAnchor}`, pathParams);
            }
        }

        return (
            <StyledTab
                // @ts-ignore
                component={NavLink}
                label={label}
                value={tabId}
                key={tabId}
                to={tabPath}
            />
        );
    };

    const tabsProps = { ...generalTabsProps, sx: tabSxStyles.scroller };
    const tabsWrapperSxStyles = sx(
        {
            condition: generalObjectHeadIsSticky,
            sx: generalObjectTabsSxStyles.fullwidth as SxStyles,
        },
        {
            condition: isInfoSidebarOpen,
            sx: generalObjectTabsSxStyles.opened as SxStyles,
        },

        {
            condition: !isInfoSidebarOpen,
            sx: generalObjectTabsSxStyles.closed as SxStyles,
        },
    );

    return (
        <Grid container item sx={tabsWrapperSxStyles}>
            <RoutedTabs customTabsActions={tabsActions} tabsProps={tabsProps} settings={settings}>
                {tabs.map(renderTab)}
            </RoutedTabs>
        </Grid>
    );
});
