// setimmediate нужно для работы с react-scripts@5.0.0
import 'setimmediate';
import React from 'react';
import ReactDOM from 'react-dom';
import { env, features, history, themeOverrides } from './initRootProps';
import { Root } from './Root';

ReactDOM.render(
    <React.StrictMode>
        <Root env={env} features={features} themeOverrides={themeOverrides} history={history} />
    </React.StrictMode>,
    document.getElementById('root'),
);
