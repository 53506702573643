import { LocalesTree } from '../../../types';

const feature: LocalesTree = {
    phrases: {
        productTitle: 'TOT Record',
        logoAlt: 'TOT Record logo',
        footerLogoAlt: 'TOT Record logo',
        productName: 'Система управления проектами и контрактами',
        productDescription: 'Контролируйте проекты и контракты на всех стадиях их жизненного цикла',
    },
};

export default feature;
