import { observer } from 'mobx-react';
import React, { ReactNode, useCallback } from 'react';
import { di } from 'react-magnetic-di';
import { MenuButton as MenuButtonInj, renderMenuButton as renderMenuButtonInj } from '../../../components';
import { DotMenu } from '../../../resources';
import { UserTableRow } from '../../../types';
import { UserStatusButton as UserStatusButtonInj } from './UserStatusButton';

export type UserRegistryActionsBtnsProps = {
    userTableRow: UserTableRow;
    reloadData: () => void;
    toggleIsRowBlocked: () => void;
    rowErrorHandler: () => void;
};

export const UserRegistryActionsBtns = observer((props: UserRegistryActionsBtnsProps): JSX.Element => {
    const [MenuButton] = di([MenuButtonInj], UserRegistryActionsBtns);
    const [UserStatusButton] = di([UserStatusButtonInj], UserRegistryActionsBtns);
    const [renderMenuButton] = di([renderMenuButtonInj], UserRegistryActionsBtns);

    const { userTableRow, reloadData, toggleIsRowBlocked, rowErrorHandler } = props;

    const renderActionItems = useCallback((): ((hideMenu?: () => void) => ReactNode[]) => {
        const renderItems = (hideMenu?: () => void): ReactNode[] => {
            return [
                <React.Fragment key="actionBtns">
                    <UserStatusButton
                        userTableRow={userTableRow}
                        reloadData={reloadData}
                        hideMenu={hideMenu}
                        toggleIsRowBlocked={toggleIsRowBlocked}
                        rowErrorHandler={rowErrorHandler}
                    />
                </React.Fragment>,
            ];
        };
        return renderItems;
    }, [userTableRow, reloadData, toggleIsRowBlocked, rowErrorHandler]);

    return <MenuButton renderButton={renderMenuButton(<DotMenu />)} renderMenuItems={renderActionItems()} />;
});
