import { LocalesTree, ObjectLocales } from '../../../types';

const project: ObjectLocales & LocalesTree = {
    single: {
        nominative: 'проект',
        accusative: 'проект',
    },
    plural: {
        nominative: 'проекты',
        accusative: 'проекты',
    },
    createFields: {
        name: 'Наименование',
        number: 'Номер',
    },
    phrases: {
        create: 'Создать {project}',
    },
};

export default project;
