import { TitlesDTO } from '@platform/multi-lang-field';
import { CreationInfoModel, GeneralObjectModel } from '../models';
import { ViewUpdatePermissions } from './authorization';
import { ModalProps } from './modals';
import { PersonContact, PersonContactNull } from './personContact';
import { UserPersonDTO, UserRole } from './userPerson';
import { CodeTitle, IdTitle, IdTitleNull } from './withTitle';

export type DescendantCreateInfo = {
    identifier: Identifier;
    categories: IdTitle[];
};

export type DescendantObjectCreateDTO = {
    titles: TitlesDTO;
    identifier: Identifier;
    categoryId: string;
};

export enum CreateDescendantFields {
    titles = 'titles',
    number = 'number',
    category = 'category',
}

export type GeneralObjectRouteParams = {
    id: string;
    tabId: string;
};

export type GeneralObjectPFRouteParams = GeneralObjectRouteParams & {
    pfId: string;
};

export type GeneralObjectChartParams = GeneralObjectPFRouteParams & {
    chartSettingId: string;
};

export type GeneralObjectDTO = {
    id: string;
    title: string;
    metaInfo: GeneralObjectMetaInfoDTO;
};

export type GeneralObjectMetaInfoDTO = {
    author: UserPersonDTO;
    state?: string;
    created: string;
};

export type GeneralObjectServiceInfoDTO = {
    lifecycleDTO: LinkDTO;
    formInfoDTO: LinkDTO[];
    lastModified?: string;
};

export type GeneralObjectTab = IdTitle[];

export type LinkDTO = {
    label: string;
    url: string;
};

export type GeneralObjectRoutes = {
    root: string;
    card: string;
    tab: string;
    syslog: string;
    versions: string;
    formEdit: string;
    settings: string;
    settingsTab: string;
    settingsTabAccess: string;
    settingsTabContent: string;
    settingsPF: string;
    settingsPFMain: string;
    settingsPFAccess: string;
    chart: string;
};

export type RenderEditModal = Partial<Record<GeneralObjectFieldName, EditModalComponent<EditModalProps>>>;
export type EditModalProps = GeneralObjectEditFieldModalProps & {
    rawValue: RawValueType;
};
export type EditModalComponent<T extends GeneralObjectEditFieldModalProps> = (props: T) => JSX.Element;

export type RenderCreateDescendantModal = Partial<
    Record<GeneralObjectWithDescendantObject, CreateDescendantModalComponent>
>;

export type CreateDescendantModalProps = ModalProps & {
    creationInfoModel: CreationInfoModel;
};

export type CreateDescendantModalComponent = (props: CreateDescendantModalProps) => JSX.Element;

export type GeneralObjectEditFieldModalProps = ModalProps & {
    objectId: string;
};

export type GeneralObjectCardContextType = {
    objectModel: GeneralObjectModel;
    updateStickyElementsHeight: () => void;
};

export type RawValueType = Identifier | PersonContactNull | PersonContact[];

export type GeneralObjectField = {
    id: GeneralObjectFieldName;
    value?: GeneralObjectFieldValue | GeneralObjectFieldValue[];
    isDate?: boolean;
    isExternalUrl?: boolean;
    isObjectField?: boolean;
    title?: string;
    rawValue?: RawValueType;
    authorization?: Partial<ViewUpdatePermissions>;
    withBottomSeparator?: boolean;
    shouldSeparateArrayValuesWithComma?: boolean;
    withContactInformation?: boolean;
};

export type GeneralObjectFieldValue = string | LinkDTO;

export type Responsible = {
    responsible: IdTitleNull | IdTitle[];
};

export type ResponsibleUsersByRole = Record<UserRole, { isLoading: boolean; users: IdTitle[] }>;

export enum ResponsibleFields {
    responsible = 'responsible',
}

export enum GeneralObjectObjectFieldName {
    author = 'author',
    syslog = 'syslog',
    portfolio = 'portfolio',
    category = 'category',
    responsible = 'responsible',
    project = 'project',
    portfolioManager = 'portfolioManager',
    portfolioViewer = 'portfolioViewer',
    projectManager = 'projectManager',
    projectCurator = 'projectCurator',
    projectExecutor = 'projectExecutor',
    projectMonitor = 'projectMonitor',
    projectViewer = 'projectViewer',
    childObjectExecutor = 'childObjectExecutor',
    version = 'version',
    allVersions = 'allVersions',
}

export enum GeneralObjectCommonFieldName {
    title = 'title',
    number = 'number',
    created = 'created',
    lifecycle = 'lifecycle',
    form = 'form',
    modified = 'modified',
    parentObject = 'parentObject',
}

export const GeneralObjectFieldName = { ...GeneralObjectObjectFieldName, ...GeneralObjectCommonFieldName };
export type GeneralObjectFieldName = keyof typeof GeneralObjectFieldName;

export enum GeneralObjectWithDescendantObject {
    project = 'project',
    childObject = 'childObject',
}

export enum GeneralObjectWithoutDescendantObject {
    portfolio = 'portfolio',
    category = 'category',
}

export const GeneralObject = { ...GeneralObjectWithDescendantObject, ...GeneralObjectWithoutDescendantObject };

export type GeneralObject = keyof typeof GeneralObject;

export type GeneralObjectWithCreateCopy = GeneralObjectWithoutDescendantObject.category;

export enum GeneralObjectSettingSections {
    access = 'access',
    content = 'content',
}

export enum GeneralObjectPFSettingSections {
    settings = 'settings',
    access = 'access',
}

export enum GeneralObjectPermissionTrigger {
    role = 'role',
    condition = 'condition',
}

export type GeneralObjectCard = {
    id: string;
    title: string;
    state?: string;
    commonDescription: GeneralObjectField[];
};

export type RegistryDTO = {
    object: CodeTitle;
    preset: IdTitle;
    categories: IdTitle[];
};

export type GeneralObjectTabRegistryParams = {
    tabOwnerId: string;
    tabId: string;
    entityType: GeneralObject;
};

export type GeneralObjectSyslogRegistryParams = {
    entityId: string;
    entityType: GeneralObject;
};

export type Identifier = {
    prefix: string;
    number: string;
};
