import React from 'react';
import { defineMessages, FormattedMessage, MessageDescriptor } from 'react-intl';
import { GeneralObjectWithoutDescendantObject } from '../../../types';

export const objectSettingsDeleteTabMessages: Record<GeneralObjectWithoutDescendantObject, MessageDescriptor> =
    defineMessages({
        portfolio: {
            id: 'portfolio.phrases.tabDeleteText',
            values: {
                portfolio: <FormattedMessage id="portfolio.single.genitive" />,
            },
        },
        category: {
            id: 'category.phrases.tabDeleteText',
            values: {
                category: <FormattedMessage id="category.single.genitive" />,
            },
        },
    });
