import { TitlesDTO } from '@platform/multi-lang-field';
import { FieldValues as LangTitle } from '@platform/multi-lang-field/dist/MultiLangField/types';
import { CodeTitleGroupRC } from '../stores';
import { FileDTO } from './file';
import { CodeTitle, CodeTitleNull } from './withTitle';

export type PrintFormCreationInfo = {
    docTypes: CodeTitle[];
    fileFormats: CodeTitle[];
};

export type PrintFormSetting = {
    titles: LangTitle[];
    code: string;
    description?: string;
    docType?: CodeTitleNull;
    file?: File;
    fileName?: string;
    fileFormat: CodeTitleNull;
    required: boolean;
};

export type PrintFormSettingDTO = {
    titles: TitlesDTO;
    code: string;
    description?: string;
    docType?: CodeTitle;
    fileFormat: CodeTitle;
    file: FileDTO;
    required: boolean;
};

export enum PrintFormStatusCode {
    generated = 'GENERATED',
    failed = 'FAILED',
    new = 'NEW',
    processing = 'PROCESSING',
}

export type PrintFormStatus = {
    code: PrintFormStatusCode;
    title: '';
};

export type PrintFormDTO = {
    pfSettingId: string;
    filename: string;
    pfId?: string;
    pf?: FileDTO;
    docType?: string;
    status: PrintFormStatus;
    generated?: string; // Date
    required: string;
};

export type PrintFormItemDTO = {
    id: string;
    positionRank: number;
    title: string;
    code: string;
    fileFormat: string;
    file?: FileDTO;
    required: boolean;
};

export enum PrintFormFields {
    titles = 'titles',
    code = 'code',
    description = 'description',
    docType = 'docType',
    file = 'file',
    fileFormat = 'fileFormat',
    required = 'required',
}

export type PrintFormAccessPermissionDTO = {
    roleCondition: CodeTitleGroupRC;
    whenObjectStateIsOneOf: CodeTitle[];
    allowEdit: boolean;
};

export type PrintFormPermissionSettingsDTO = {
    processCode: string;
    permissions: PrintFormAccessPermissionDTO[];
};

export type PrintFormNewPermissionDTO = {
    role?: string;
    when?: string;
    whenObjectStateIsOneOf: string[];
    allowEdit: boolean;
};

export type PrintFormLoadPermissions = () => Promise<PrintFormPermissionSettingsDTO>;

export type PrintFormUpdatePermissions = (dto: PrintFormNewPermissionDTO[]) => Promise<void>;

export enum PrintFormSettingsPermissionsFields {
    roleCondition = 'roleCondition',
    whenObjectStateIsOneOf = 'whenObjectStateIsOneOf',
    allowEdit = 'allowEdit',
}
