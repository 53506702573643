import { observer } from 'mobx-react-lite';
import { fromPromise } from 'mobx-utils';
import React, { useCallback, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { clientRoute } from '../clientRoute';
import { useStore } from '../hooks';
import { AuthorizationCheckQuery } from '../types';

type RenderChildren = (allowed: boolean) => JSX.Element;

export type AuthorizationCheckProps = AuthorizationCheckQuery & {
    pendingElement?: JSX.Element;
    onAllowed?: () => void;
    errorElement?: JSX.Element;
    children?: RenderChildren | JSX.Element;
    isWithRedirect?: boolean;
};

// раньше компонент работал по-другому, теперь он реагирует на изменение entityCode, permCode, entityId - перезапрашивает права
export const AuthorizationCheck = observer((props: AuthorizationCheckProps): JSX.Element => {
    const { onAllowed, entityCode, permCode, entityId, children, pendingElement, errorElement, isWithRedirect } = props;
    const { authorizationStore } = useStore();

    const checkIsAllowed = useCallback(() => {
        return authorizationStore.check({ entityCode, permCode, entityId }).then((allowedRes) => {
            if (allowedRes && onAllowed) {
                onAllowed();
            }
            return allowedRes;
        });
    }, [entityCode, permCode, entityId, onAllowed]);

    const [allowed, setAllowed] = useState<Promise<boolean>>(Promise.resolve(false));

    useEffect(() => {
        setAllowed(checkIsAllowed());
    }, [checkIsAllowed]);

    const renderChildren = useCallback(
        (allowed: boolean): JSX.Element => {
            if (typeof children === 'function') {
                const render = children as RenderChildren;
                return render(allowed);
            }
            if (allowed) {
                return (children as JSX.Element) || <React.Fragment />;
            }
            return isWithRedirect ? <Redirect to={clientRoute.notAllowed} /> : <React.Fragment />;
        },
        [children, isWithRedirect],
    );

    return fromPromise(allowed).case({
        pending: () => pendingElement || <React.Fragment />,
        fulfilled: renderChildren,
        rejected: () => errorElement || <React.Fragment />,
    });
});
