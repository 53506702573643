import { Grid } from '@mui/material';
import React from 'react';
import { di } from 'react-magnetic-di';
import { GeneralObjectField } from '../../../../types';
import { GeneralObjectDescriptionTabValueField as GeneralObjectDescriptionTabValueFieldInj } from './GeneralObjectDescriptionTabValueField';

export type GeneralObjectDescriptionTabValueProps = {
    fields: GeneralObjectField[];
};

export const GeneralObjectDescriptionTabValue = (props: GeneralObjectDescriptionTabValueProps): JSX.Element => {
    const [GeneralObjectDescriptionTabValueField] = di(
        [GeneralObjectDescriptionTabValueFieldInj],
        GeneralObjectDescriptionTabValue,
    );
    const { fields } = props;

    return (
        <Grid container spacing={2} direction="column">
            {fields.map((field) => (
                <GeneralObjectDescriptionTabValueField field={field} />
            ))}
        </Grid>
    );
};
