import { AppFonts, AppIcons, AppPalette, ThemeOverrides } from '../../types';

export type GpngColorNames =
    | 'lightblue'
    | 'darkblue'
    | 'black'
    | 'blackLight'
    | 'white'
    | 'yellow'
    | 'yellowDark'
    | 'greyMain'
    | 'greyLight'
    | 'greyDark'
    | 'greyWhite'
    | 'greyLighter'
    | 'greyMiddleLight'
    | 'greyMiddleLighter'
    | 'red'
    | 'green'
    | 'blue'
    | 'lighterBlue'
    | 'lighterGrey'
    | 'disabled'
    | 'blackLighter'
    | 'whiteGrey';

const gpngColors: Record<GpngColorNames, string> = {
    lightblue: '#005eb8',
    darkblue: '#002855',
    black: '#000000',
    blackLight: '#1c1d1f',
    blackLighter: '#252629',
    white: '#ffffff',
    whiteGrey: '#ececec',
    yellow: '#ffff33',
    yellowDark: '#f5c722',
    greyMain: '#63666a',
    greyLight: '#85898f',
    greyDark: '#2c302e',
    greyWhite: '#f1f1f1',
    greyLighter: '#fafafa',
    greyMiddleLight: '#ccd3db',
    greyMiddleLighter: '#eff0f2',
    red: '#fb4d3d',
    green: '#008000',
    blue: '#1570EF',
    lighterBlue: 'rgba(53, 165, 255, 0.09)',
    lighterGrey: 'rgba(133, 147, 143, 0.09)',
    disabled: 'rgba(0, 0, 0, 0.12)',
};

const accentBackgroundGradient = gpngColors.lightblue;

export const gpngPalette: AppPalette = {
    main: gpngColors.darkblue,
    textMain: gpngColors.greyMain,
    textDark: gpngColors.black,
    linkMain: gpngColors.blue,
    mainMiddleLight: gpngColors.greyMiddleLight,
    transparentLight: gpngColors.greyMiddleLighter,
    mainLight: gpngColors.greyLight,
    mainDark: gpngColors.lightblue,
    mainContrast: gpngColors.white,
    mainContrastDarker: gpngColors.greyLighter,
    accent: gpngColors.yellow,
    accentDark: gpngColors.lightblue,
    accentContrast: gpngColors.black,
    accentBackgroundGradient,
    hover: gpngColors.lightblue,
    hoverInLists: gpngColors.greyWhite,
    red: gpngColors.red,
    green: gpngColors.green,
    campaignList: {
        backgroundCard: gpngColors.lighterBlue,
        selectedPaginationButton: gpngColors.lighterGrey,
    },
    table: {
        row: {
            even: gpngColors.white,
            odd: gpngColors.greyLighter,
        },
    },
    card: {
        header: {
            main: gpngColors.blackLighter,
        },
    },
    appBar: {
        mainContrast: gpngColors.lightblue,
    },
    tooltip: {
        backgroundColor: gpngColors.blackLight,
    },
    button: {
        accentContrast: gpngColors.white,
        hoverBackground: gpngColors.darkblue,
        disabled: gpngColors.disabled,
    },
    iconButton: {
        color: gpngColors.greyMain,
        hoverColor: gpngColors.white,
        hoverBackgroundColor: gpngColors.greyMain,
    },
    background: {
        textMain: gpngColors.black,
    },
    footer: {
        textMain: gpngColors.white,
        textHover: gpngColors.darkblue,
        padding: 3.75,
    },
    tab: {
        backgroundColor: gpngColors.whiteGrey,
        color: gpngColors.greyMain,
        hoverBackgroundColor: gpngColors.greyMain,
        hoverColor: gpngColors.white,
    },
    panel: {
        markerBackgroundColor: gpngColors.lightblue,
        markerColor: gpngColors.white,
    },
};

const gpngFonts: AppFonts = {
    mainFontAttrs: {
        fontSize: '13px',
        lineHeight: 1.69,
        letterSpacing: '0.2px',
    },
};

const gpngIcons: AppIcons = {
    fontFamily: 'glyphicons-theme',
    arrowIcon: {
        fontSize: 11,
        content: '"\\e90f"',
        width: 12,
        height: 7,
    },
};

export default {
    fonts: gpngFonts,
    palette: gpngPalette,
    icons: gpngIcons,
} as ThemeOverrides;
