import { useCallback, useEffect, useState } from 'react';
import { useStore } from '../../../../hooks';
import { AllowedLink, AppHeaderLinkData } from '../../../../stores';

export const useVerifyHeaderLinks = (): AppHeaderLinkData[] => {
    const { personStore, authorizationStore, headerLinksStore } = useStore();
    const { additionalLinks, mainLinks } = headerLinksStore;

    const [verifiedLinks, setVerifiedLinks] = useState<AppHeaderLinkData[]>([]);

    const getQueriesArray = useCallback((): Promise<AllowedLink>[] => {
        const { check } = authorizationStore;
        const allLinks = [...mainLinks];
        return allLinks.map((link: AppHeaderLinkData) => {
            if (link.query) {
                return check(link.query).then((allowed: boolean) => ({
                    link,
                    allowed,
                }));
            }
            return Promise.resolve({ link, allowed: true });
        });
    }, [authorizationStore, mainLinks, additionalLinks]);

    const checkLinksPermission = useCallback((): void => {
        Promise.all(getQueriesArray()).then((links: AllowedLink[]) => {
            const allowedLinks = links.filter(({ allowed }) => allowed);
            setVerifiedLinks(allowedLinks.map(({ link }) => link));
        });
    }, [getQueriesArray]);

    useEffect(() => {
        checkLinksPermission();
    }, [checkLinksPermission, personStore.user.id]);

    return verifiedLinks;
};
