import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { GeneralObjectEditResponsible as GeneralObjectEditResponsibleInj } from '../../../../components';
import { useStore } from '../../../../hooks';
import { EditModalProps } from '../../../../types';

export const EditPortfolioManager = observer((props: EditModalProps): JSX.Element => {
    const [GeneralObjectEditResponsible] = di([GeneralObjectEditResponsibleInj], EditPortfolioManager);

    const { portfolioStore } = useStore();
    const intl = useIntl();
    const title = intl.formatMessage({ id: 'portfolioManager.single.nominative' });

    return (
        <GeneralObjectEditResponsible {...props} editResponsible={portfolioStore.editPortfolioManager} title={title} />
    );
});
