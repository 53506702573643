import { Link } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

export type InlineLinkProps = {
    title: string;
    to: string;
};

export const InlineLink = observer((props: InlineLinkProps): JSX.Element => {
    const { title, to } = props;

    return (
        <Link sx={{ display: 'inline' }} component={RouterLink} to={to} underline="always">
            {title}
        </Link>
    );
});
