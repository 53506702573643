var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { defaultFormsLocale } from '@platform/front-utils';
import { makeAutoObservable, observable } from 'mobx';
var FormModel = /** @class */ (function () {
    function FormModel(ownerEntityId, dto) {
        this.form = {};
        this.submission = {};
        this.startSubmission = {};
        this.i18n = {};
        this.loaded = false;
        makeAutoObservable(this, { form: observable.ref, i18n: observable.ref }, { autoBind: true });
        this.ownerEntityId = ownerEntityId;
        this.load(dto);
    }
    FormModel.prototype.load = function (dto) {
        this.form = dto.form;
        this.i18n = dto.i18n;
        if (dto.submission) {
            this.setSubmission(dto.submission);
            this.startSubmission = this.clone(dto.submission);
        }
        this.loaded = true;
        return this;
    };
    FormModel.prototype.getFullSubmission = function () {
        return this.submission;
    };
    FormModel.prototype.setLangSubmission = function (lang, submission) {
        var _a;
        this.setSubmission(__assign(__assign({}, this.submission), (_a = {}, _a[lang] = submission, _a)));
    };
    FormModel.prototype.setStartSubmission = function (lang) {
        if (lang && this.startSubmission && this.submission) {
            this.startSubmission[lang] = this.clone(this.submission[lang]);
        }
        else {
            this.startSubmission = this.clone(this.submission);
        }
    };
    FormModel.prototype.setSubmissionFromStartSubmission = function () {
        this.setSubmission(this.startSubmission);
    };
    FormModel.prototype.getLangSubmission = function (lang) {
        var langSubmission = this.submission[lang];
        if (langSubmission) {
            var newLangSubmission = this.clone(langSubmission);
            Object.assign(newLangSubmission.data, { ownerEntityId: this.ownerEntityId });
            return newLangSubmission;
        }
        return this.emptySubmission();
    };
    FormModel.prototype.getSubmission = function (lang) {
        var submission;
        if (lang) {
            submission = this.getLangSubmission(lang) || this.getLangSubmission(defaultFormsLocale);
        }
        else {
            submission = this.getLangSubmission(defaultFormsLocale);
        }
        return submission || this.emptySubmission();
    };
    FormModel.prototype.setSubmission = function (submission) {
        this.submission = submission;
    };
    FormModel.prototype.emptySubmission = function () {
        return { data: { ownerEntityId: this.ownerEntityId }, meta: {} };
    };
    FormModel.prototype.clone = function (obj) {
        return JSON.parse(JSON.stringify(obj));
    };
    return FormModel;
}());
export { FormModel };
