import { Box, Button, Checkbox, Container, FormControlLabel, Grid, Link, Paper, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import {
    EmailField as EmailFieldInj,
    FieldWithServerError as FieldWithServerErrorInj,
    PasswordField as PasswordFieldInj,
} from '../../components/fields';
import { TotBackground as TotBackgroundInj } from '../../components/TotBackground';
import { useStore, useYup } from '../../hooks';
import { RegistrationModel } from '../../models';
import { RegistrationDTO, RegistrationPageFields } from '../../types';
import { disableSubmitOnEnterKeyPress } from '../../utils';

const initialValues: RegistrationDTO = {
    email: '',
    password: '',
    firstName: '',
    middleName: '',
    lastName: '',
    lang: '',
};

export const passwordMinLength = 8;

export const RegistrationPage = observer((): JSX.Element => {
    const [TotBackground] = di([TotBackgroundInj], RegistrationPage);
    const [PasswordField] = di([PasswordFieldInj], RegistrationPage);
    const [EmailField] = di([EmailFieldInj], RegistrationPage);
    const [FieldWithServerError] = di([FieldWithServerErrorInj], RegistrationPage);

    const { authenticationStore, env } = useStore();
    const intl = useIntl();
    const registrationModel = useMemo<RegistrationModel>(
        () => authenticationStore.registrationModel,
        [authenticationStore],
    );
    const { termsIsAccepted, registerSucceed, serverErrorsModel } = registrationModel;
    const { serverFormErrors } = serverErrorsModel;
    const { email, password, firstName, middleName, lastName } = RegistrationPageFields;

    const termsOfService = useMemo((): string | undefined => {
        return intl.locale === 'en' ? env.termsOfServiceLinkEn : env.termsOfServiceLinkRu;
    }, [intl.locale, env]);

    const { Yup } = useYup();

    const schema = Yup.object().shape({
        email: Yup.string().required().email(),
        password: Yup.string().required().min(passwordMinLength),
        firstName: Yup.string().required(),
        middleName: Yup.string(),
        lastName: Yup.string().required(),
    });

    const handleConfirm = (values: RegistrationDTO): void => {
        registrationModel.register(values);
    };

    return (
        <TotBackground withBackdrop={true}>
            <Grid container item direction="column" alignItems="center" justifyContent="center">
                <Container className="t-registration-page" maxWidth="sm">
                    <Paper elevation={24}>
                        <Box p={12}>
                            <Grid container justifyContent="center">
                                <Grid item>
                                    <Typography variant="h5" className="t-registration-title">
                                        <Box fontWeight="fontWeightBold">
                                            <FormattedMessage id="authentication.registrationTitle" />
                                        </Box>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Box pt={8}>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={schema}
                                    onSubmit={handleConfirm}
                                >
                                    <Form onKeyDown={disableSubmitOnEnterKeyPress}>
                                        <Grid container spacing={6} direction="column">
                                            <Grid item>
                                                <FieldWithServerError
                                                    component={TextField}
                                                    size="normal"
                                                    name={lastName}
                                                    serverError={serverFormErrors[lastName]}
                                                    disabled={false}
                                                    inputProps={{
                                                        className: 't-last-name-field',
                                                    }}
                                                    FormHelperTextProps={{
                                                        className: 't-last-name-field-help-text',
                                                    }}
                                                    fullWidth
                                                    label={intl.formatMessage({ id: 'authentication.lastName' })}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item container spacing={6} justifyContent="space-between">
                                                <Grid item xs={6}>
                                                    <FieldWithServerError
                                                        component={TextField}
                                                        size="normal"
                                                        name={firstName}
                                                        serverError={serverFormErrors[firstName]}
                                                        disabled={false}
                                                        inputProps={{
                                                            className: 't-first-name-field',
                                                        }}
                                                        FormHelperTextProps={{
                                                            className: 't-first-name-field-help-text',
                                                        }}
                                                        label={intl.formatMessage({ id: 'authentication.firstName' })}
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FieldWithServerError
                                                        component={TextField}
                                                        size="normal"
                                                        name={middleName}
                                                        serverError={serverFormErrors[middleName]}
                                                        disabled={false}
                                                        inputProps={{
                                                            className: 't-middle-name-field',
                                                        }}
                                                        FormHelperTextProps={{
                                                            className: 't-first-name-field-help-text',
                                                        }}
                                                        helperText={intl.formatMessage({
                                                            id: 'authentication.helperInfoMiddleName',
                                                        })}
                                                        label={intl.formatMessage({ id: 'authentication.middleName' })}
                                                        fullWidth
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <EmailField name={email} serverError={serverFormErrors[email]} />
                                            </Grid>
                                            <Grid item>
                                                <PasswordField
                                                    newPassword={false}
                                                    name={password}
                                                    serverError={serverFormErrors[password]}
                                                />
                                            </Grid>
                                            {termsOfService && (
                                                <Grid item>
                                                    <FormControlLabel
                                                        value="top"
                                                        control={
                                                            <Checkbox
                                                                className="t-terms-is-accepted"
                                                                color="primary"
                                                                onChange={registrationModel.onTermsIsAcceptedChange}
                                                            />
                                                        }
                                                        label={
                                                            <Link
                                                                target="_blank"
                                                                href={termsOfService}
                                                                underline="none"
                                                            >
                                                                <FormattedMessage id="authentication.termsIsAccepted" />
                                                            </Link>
                                                        }
                                                        labelPlacement="end"
                                                    />
                                                </Grid>
                                            )}
                                            <Grid item>
                                                <Button
                                                    className="t-register"
                                                    fullWidth
                                                    size="large"
                                                    variant="contained"
                                                    type="submit"
                                                    disabled={!!termsOfService && !termsIsAccepted}
                                                >
                                                    <FormattedMessage id="authentication.register" />
                                                </Button>
                                            </Grid>
                                            <Grid item container justifyContent="center">
                                                <Grid item>
                                                    <Link
                                                        className="t-login"
                                                        component={RouterLink}
                                                        to={clientRoute.login}
                                                        underline="none"
                                                    >
                                                        <FormattedMessage id="authentication.login" />
                                                    </Link>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                </Formik>
                            </Box>
                        </Box>
                    </Paper>
                    {registerSucceed && <Redirect to={clientRoute.registrationInfoSuccess} />}
                </Container>
            </Grid>
        </TotBackground>
    );
});
