import { Box, Container, Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { registryPageTableSxStyles } from '../../components';
import { useServerTitleBreadcrumbs } from '../../hooks';
import { BreadcrumbsLocation, UserTableRow } from '../../types';
import { UserRegistryTable as UserRegistryTableInj } from './components';
import { UserRegistryActionsBtns as UserRegistryActionsBtnsInj } from './components/UserRegistryActionsBtns';

export const UserRegistryPage = observer((): JSX.Element => {
    const [UserRegistryTable] = di([UserRegistryTableInj], UserRegistryPage);
    const [UserRegistryActionsBtns] = di([UserRegistryActionsBtnsInj], UserRegistryPage);

    useServerTitleBreadcrumbs({ location: BreadcrumbsLocation.account });

    const setRowActions = (
        row: UserTableRow,
        reloadData: () => void,
        toggleIsRowBlocked: () => void,
        rowErrorHandler: () => void,
    ): JSX.Element => {
        return (
            <UserRegistryActionsBtns
                userTableRow={row}
                reloadData={reloadData}
                toggleIsRowBlocked={toggleIsRowBlocked}
                rowErrorHandler={rowErrorHandler}
            />
        );
    };

    return (
        <Container maxWidth={false}>
            <Box pt={5.5} pb={15}>
                <Grid container spacing={5} flexDirection="column">
                    <Grid item>
                        <Typography variant="h1" component="h1">
                            <FormattedMessage id="user.plural.nominative" />
                        </Typography>
                    </Grid>
                    <Grid item sx={registryPageTableSxStyles.tableWrapper}>
                        <UserRegistryTable setRowActions={setRowActions} />
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
});
