import { LocalesTree, ObjectLocales } from '../../../types';

const console: ObjectLocales & LocalesTree = {
    single: {
        nominative: 'консоль',
    },
    script: 'Скрипт',
    runScript: 'Запустить скрипт',
};

export default console;
