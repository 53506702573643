import React, { ReactNode } from 'react';
import { IdLocale, LocaleFormatter } from '../types';

export const getIdLocale = (chunks: ReactNode[]): IdLocale => {
    const words = (chunks[0] as string).split(' ');
    const id = words.shift() || '';
    const phrase = words.join(' ');
    chunks[0] = phrase;

    return [id, chunks];
};

export const formatLocale: LocaleFormatter = (chunks) => {
    const [id, message] = getIdLocale(chunks);

    return <span id={id}>{message}</span>;
};
