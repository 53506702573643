import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { BreadcrumbsEntities, LinkDTO } from '../types';
import { setPageTitle } from '../utils';
import { useFlag } from './useFlag';
import { useLocale } from './useLocale';
import { useStore } from './useStore';

export type UseServerTitleBreadcrumbsProps = {
    entityType?: BreadcrumbsEntities;
    entityId?: string;
    entityTitle?: string;
    location?: string;
};

export type UseServerTitleBreadcrumbs = [LinkDTO[], boolean];

export const useServerTitleBreadcrumbs = (props: UseServerTitleBreadcrumbsProps): UseServerTitleBreadcrumbs => {
    const { entityTitle, entityType = BreadcrumbsEntities.system, entityId, location } = props;
    const { breadcrumbsStore } = useStore();
    const intl = useIntl();
    const [productTitle] = useLocale('feature.phrases.productTitle', true);
    const [isLoading, startLoading, stopLoading] = useFlag(true);
    const [routes, setRoutes] = useState<LinkDTO[]>([]);
    const routesCount = routes.length;

    useEffect(() => {
        if (entityTitle || entityTitle === undefined) {
            breadcrumbsStore
                .getServerRoutes(entityType, entityId, location)
                .then(setRoutes)
                .catch(() => {
                    setPageTitle(productTitle);
                })
                .finally(() => {
                    isLoading && stopLoading();
                });
        }
    }, [intl.locale, entityId, entityType, entityTitle, location]);

    useEffect(() => {
        if (routesCount) {
            const lastRoureLabel = routes[routesCount - 1].label;
            const capitalizedLocation = lastRoureLabel[0].toUpperCase() + lastRoureLabel.slice(1);
            const title = `${capitalizedLocation} | ${productTitle}`;

            setPageTitle(title);
        }
    }, [routes]);

    return [routes, isLoading];
};
