import { FormDTO, FullSubmissionWithAdditionalInfo } from '@platform/formiojs-react';
import { action, makeObservable, observable } from 'mobx';
import { di } from 'react-magnetic-di';
import { generatePath } from 'react-router-dom';
import { apiConfigs } from '../apiConfigs';
import { clientRoute } from '../clientRoute';
import { isCategoryWithTabs } from '../constants';
import { UserNameModel } from '../models';
import {
    CategoryCreateDTO,
    CategoryCreateInfo,
    CategoryDTO,
    CreateCategoryCopyDTO,
    GeneralObject,
    GeneralObjectCard,
    GeneralObjectContent,
    GeneralObjectFieldName,
    SettingsContentDTO,
} from '../types';
import { getGeneralObjectLinkValue } from '../utils';
import { GeneralObjectExtendStore, GeneralObjectExtendStoreProps } from './GeneralObjectExtendsStore';
import { RootStore } from './RootStore';

export const CategoryStoreProps = {
    ...GeneralObjectExtendStoreProps,
    isFormInfoLoaded: observable,
    formInfo: observable,
    loadCard: action.bound,
    saveFormInfo: action.bound,
    mapObjectDTOToCard: action.bound,
    mapServiceInfoToDescription: action.bound,
    loadCurrentTabContent: action.bound,
    saveForm: action.bound,
    setIsFormInfoLoaded: action.bound,
    createCategoryCopy: action.bound,
};

export class CategoryStore extends GeneralObjectExtendStore<CategoryDTO, CategoryCreateDTO, CategoryCreateInfo> {
    objectType = GeneralObject.category;
    isWithTabs = isCategoryWithTabs;
    isWithCreateCopy = true;
    isWithChartContent = false;

    isFormInfoLoaded = false;
    formInfo?: FormDTO;

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this, CategoryStoreProps);
    }

    async loadCard(id: string): Promise<GeneralObjectCard> {
        this.setIsFormInfoLoaded(false);
        return this.api
            .mainInfoClient(apiConfigs.getObject(id, this.objectType))
            .then((r) => r.data)
            .then((data: CategoryDTO) => {
                this.saveFormInfo(data);
                if (!data.formInfo) {
                    const { notificationStore, intlStore } = this.rootStore;
                    const message = intlStore.intl.formatMessage({ id: 'generalObject.formNotFound' });
                    notificationStore.onError(message);
                }
                return this.mapObjectDTOToCard(data);
            });
    }

    saveFormInfo(data: CategoryDTO): void {
        this.formInfo = data.formInfo;
        this.setIsFormInfoLoaded(true);
    }

    mapObjectDTOToCard(data: CategoryDTO): GeneralObjectCard {
        const { id, title, metaInfo } = data;
        const { author, created, state } = metaInfo;
        return {
            id,
            title,
            state,
            commonDescription: [
                {
                    id: GeneralObjectFieldName.created,
                    value: created,
                    isDate: true,
                },
                {
                    id: GeneralObjectFieldName.author,
                    isObjectField: true,
                    value: getGeneralObjectLinkValue(
                        new UserNameModel().load(author).name,
                        generatePath(clientRoute.user, { id: author.userId }),
                    ),
                    withBottomSeparator: true,
                },
                this.getSyslogDescription(id),
            ],
        };
    }

    loadCurrentTabContent(objectId: string, tabId: string): Promise<GeneralObjectContent> {
        return Promise.resolve({
            content: {} as SettingsContentDTO,
            formInfo: this.formInfo,
        });
    }

    saveForm(id: string, tabId = '', submissionData: FullSubmissionWithAdditionalInfo): Promise<void> {
        return this.api.userActionClient(apiConfigs.editCategoryForm(id, submissionData)).then((r) => r.data);
    }

    createCategoryCopy(dto: CreateCategoryCopyDTO, prototypeCategoryId: string): Promise<string> {
        return this.api.userActionClient(apiConfigs.createCategoryCopy(dto, prototypeCategoryId)).then((r) => r.data);
    }

    setIsFormInfoLoaded(isFormInfoLoaded: boolean): void {
        this.isFormInfoLoaded = isFormInfoLoaded;
    }
}

export const getCategoryStore = (): any => {
    const [_CategoryStore] = di([CategoryStore], getCategoryStore);
    return _CategoryStore;
};
