import { action, makeObservable, observable } from 'mobx';
import { di } from 'react-magnetic-di';
import { apiConfigs } from '../apiConfigs';
import { fileContentType } from '../constants';
import { ConsoleResult } from '../types';
import { ApiStore } from './ApiStore';
import { RootStore } from './RootStore';

export const ConsoleStoreProps = {
    rootStore: observable,
    api: observable,
    runScript: action.bound,
};

const filenameRegex = /filename[^;=\n]*=['"].*?(\2|[^;\n'"]*)/;

const getFileName = (contentDisposition: string): string => {
    const filenameMatches = filenameRegex.exec(contentDisposition);
    return filenameMatches ? filenameMatches[1] : '';
};

export class ConsoleStore {
    private rootStore: RootStore;
    private api: ApiStore;

    constructor(rootStore: RootStore) {
        makeObservable(this, ConsoleStoreProps);
        this.rootStore = rootStore;
        this.api = rootStore.api;
    }

    runScript(script: string): Promise<ConsoleResult> {
        return this.api.client(apiConfigs.runScript(script)).then((r) => {
            const isFile = r.headers['content-type'] === fileContentType;
            const fileName = isFile ? getFileName(r.headers['content-disposition']) : '';

            return {
                blob: r.data,
                isFile,
                fileName,
            };
        });
    }
}

export const getConsoleStore = (): any => {
    const [_ConsoleStore] = di([ConsoleStore], getConsoleStore);
    return _ConsoleStore;
};
