import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { clientRoute } from '../clientRoute';
import { TotRouterLink as TotRouterLinkInj } from './TotRouterLink';

export const RouterLinkToMainPage = (): JSX.Element => {
    const [TotRouterLink] = di([TotRouterLinkInj], RouterLinkToMainPage);

    return (
        <TotRouterLink to={clientRoute.root}>
            <FormattedMessage id="errorPage.main" />
        </TotRouterLink>
    );
};
