import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { GeneralObjectEditResponsible as GeneralObjectEditResponsibleInj } from '../../../../components';
import { useStore } from '../../../../hooks';
import { EditModalProps, UserRole } from '../../../../types';

export const EditProjectCurator = observer((props: EditModalProps) => {
    const [GeneralObjectEditResponsible] = di([GeneralObjectEditResponsibleInj], EditProjectCurator);

    const { projectStore } = useStore();
    const intl = useIntl();
    const title = intl.formatMessage({ id: 'projectCurator.single.nominative' });

    return (
        <GeneralObjectEditResponsible
            {...props}
            editResponsible={projectStore.editProjectCurator}
            roleToSelectBy={UserRole.curator}
            title={title}
        />
    );
});
