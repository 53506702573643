import { FormatXMLElementFn } from 'intl-messageformat';
import { ReactNode } from 'react';

export enum TotLocale {
    ru = 'ru',
    en = 'en',
}

export type LocalizedMessages = Record<TotLocale, Record<string, string>>;

export type LocaleFormatter = FormatXMLElementFn<ReactNode, ReactNode | ReactNode[]>;

export type MessageCase = {
    nominative: string;
    genitive?: string;
    dative?: string;
    accusative?: string;
    instrumental?: string;
    prepositional?: string;
};

export type ObjectLocales = {
    single: MessageCase;
    plural?: MessageCase;
};

export type LocalesTree = Record<string, Record<string, string> | string>;

export type IdLocale = [string, ReactNode[]];
