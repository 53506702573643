import { Grid, TabsActions } from '@mui/material';
import { makeSxStylesWithProps } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useRef } from 'react';
import { di } from 'react-magnetic-di';
import { Route, useParams } from 'react-router-dom';
import {
    PageContentContainer as PageContentContainerInj,
    TotObjectContentInner as TotObjectContentInnerInj,
    TotObjectLightContainer as TotObjectLightContainerInj,
} from '../../components';
import { tabContentContainerPadding } from '../../constants';
import { GeneralObjectCardContext } from '../../contexts';
import { useGeneralObjectContext, useStore } from '../../hooks';
import { GeneralObjectModel, getDefaultResponsibleUsersByRole } from '../../models';
import { GeneralObjectRouteParams } from '../../types';
import { fakeScroll } from '../../utils';
import {
    GeneralObjectDescription as GeneralObjectDescriptionInj,
    GeneralObjectHeader as GeneralObjectHeaderInj,
    GeneralObjectTabContent as GeneralObjectTabContentInj,
    GeneralObjectTabsPanel as GeneralObjectTabsPanelInj,
    GeneralObjectWithoutAvailableContent as GeneralObjectWithoutAvailableContentInj,
    TotObjectContentWrapper as TotObjectContentWrapperInj,
} from './components';

export type UseGeneralObjectPageStylesSxProps = {
    descriptionPanelWidth: number;
};

export const useGeneralObjectPageStylesSx = makeSxStylesWithProps((props: UseGeneralObjectPageStylesSxProps) => ({
    fullSpace: {
        flexGrow: 1,
    },
    contentWrapper: {
        width: `calc(100% - ${props.descriptionPanelWidth}px)`,
    },
}));

export const GeneralObjectPage = observer((): JSX.Element => {
    const [GeneralObjectHeader] = di([GeneralObjectHeaderInj], GeneralObjectPage);
    const [PageContentContainer] = di([PageContentContainerInj], GeneralObjectPage);
    const [TotObjectContentInner] = di([TotObjectContentInnerInj], GeneralObjectPage);
    const [TotObjectLightContainer] = di([TotObjectLightContainerInj], GeneralObjectPage);
    const [GeneralObjectTabContent] = di([GeneralObjectTabContentInj], GeneralObjectPage);
    const [GeneralObjectTabsPanel] = di([GeneralObjectTabsPanelInj], GeneralObjectPage);
    const [TotObjectContentWrapper] = di([TotObjectContentWrapperInj], GeneralObjectPage);
    const [GeneralObjectDescription] = di([GeneralObjectDescriptionInj], GeneralObjectPage);
    const [GeneralObjectWithoutAvailableContent] = di([GeneralObjectWithoutAvailableContentInj], GeneralObjectPage);

    const { routes, objectStore } = useGeneralObjectContext();
    const { isWithTabs } = objectStore;
    const { id, tabId } = useParams<GeneralObjectRouteParams>();
    const rootStore = useStore();
    const { intlStore, stickyElementsStore } = rootStore;
    const { setGeneralObjectElementsHeight, descriptionPanelWidth, toggleDescriptionPanel } = stickyElementsStore;
    const sx = useGeneralObjectPageStylesSx({ descriptionPanelWidth });

    const isDescriptionPanelOpen = !!descriptionPanelWidth;
    const isDescriptionPanelClosed = !isDescriptionPanelOpen;

    const objectModel = useMemo<GeneralObjectModel>(
        () => new GeneralObjectModel(id, routes, rootStore, objectStore),
        [id, routes, rootStore, objectStore],
    );

    const { isWithServiceDescription, tabs } = objectModel;

    useEffect(() => {
        objectModel.loadMainInfo(tabId);
    }, [objectModel, intlStore.locale]);

    useEffect(() => {
        objectModel.loadServiceDescription();
    }, [isWithServiceDescription]);

    useEffect(() => {
        return () => objectModel.setResponsibleUsersByRole(getDefaultResponsibleUsersByRole());
    }, []);

    const toggleIsDescriptionPanelOpen = async (): Promise<void> => {
        toggleDescriptionPanel();
        fakeScroll();
        setTimeout(updateStickyElementsHeight, 0);
    };

    const tabsContainerRef = useRef<HTMLDivElement>(null);
    const headerContainerRef = useRef<HTMLDivElement>(null);

    // используется для пересчета высоты:
    // 1) при смене состояния sticky
    // 2) при переключении между режимами просмотра и редактирования
    // 3) при скрытии/раскрытии описания
    const updateStickyElementsHeight = (): void => {
        setGeneralObjectElementsHeight(
            headerContainerRef.current?.offsetHeight,
            tabsContainerRef.current?.offsetHeight,
        );
    };

    const tabsActions = useRef<TabsActions>(null);

    return (
        <GeneralObjectCardContext.Provider
            value={{
                objectModel,
                updateStickyElementsHeight,
            }}
        >
            <PageContentContainer container direction="column" wrap="nowrap">
                <GeneralObjectHeader headerContainerRef={headerContainerRef} />
                <Grid container direction="column" sx={sx.fullSpace}>
                    <Grid container item justifyContent="space-between" wrap="nowrap" sx={sx.fullSpace}>
                        <TotObjectContentWrapper container item direction="column" wrap="nowrap" sx={sx.contentWrapper}>
                            {(isWithTabs || isDescriptionPanelClosed) && (
                                <GeneralObjectTabsPanel
                                    tabsActions={tabsActions}
                                    isDescriptionPanelOpen={isDescriptionPanelOpen}
                                    openDescription={toggleIsDescriptionPanelOpen}
                                    tabsContainerRef={tabsContainerRef}
                                />
                            )}
                            <Grid container item wrap="nowrap" sx={sx.fullSpace}>
                                <TotObjectContentInner item>
                                    <TotObjectLightContainer
                                        container
                                        direction="column"
                                        style={{ padding: tabContentContainerPadding }}
                                    >
                                        {tabs.length || !isWithTabs ? (
                                            <Route path={isWithTabs ? routes.tab : routes.card}>
                                                <GeneralObjectTabContent />
                                            </Route>
                                        ) : (
                                            <GeneralObjectWithoutAvailableContent />
                                        )}
                                    </TotObjectLightContainer>
                                </TotObjectContentInner>
                            </Grid>
                        </TotObjectContentWrapper>
                        {isDescriptionPanelOpen && (
                            <GeneralObjectDescription closeDescription={toggleIsDescriptionPanelOpen} />
                        )}
                    </Grid>
                </Grid>
            </PageContentContainer>
        </GeneralObjectCardContext.Provider>
    );
});
