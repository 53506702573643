import { action, makeObservable, observable } from 'mobx';
import { di } from 'react-magnetic-di';
import { generatePath } from 'react-router-dom';
import { apiConfigs } from '../apiConfigs';
import { clientRoute } from '../clientRoute';
import { UserNameModel } from '../models';
import {
    DescendantCreateInfo,
    GeneralObject,
    GeneralObjectCard,
    GeneralObjectFieldName,
    NumberField,
    ProjectCreateDTO,
    ProjectCreateInfo,
    ProjectDTO,
    ResponsibleCheck,
    ResponsibleFields,
} from '../types';

import {
    getGeneralObjectLinkValue,
    getMultipleResponsibleDescription,
    getNumberDescription,
    getResponsibleDescription,
} from '../utils';
import { GeneralObjectExtendStore, GeneralObjectExtendStoreProps } from './GeneralObjectExtendsStore';
import { PortfolioStore } from './PortfolioStore';
import { RootStore } from './RootStore';

export const ProjectStoreProps = {
    ...GeneralObjectExtendStoreProps,
    portfolioStore: observable,

    editProjectCurator: action.bound,
    editProjectMonitor: action.bound,
    editProjectViewers: action.bound,
    editProjectNumber: action.bound,
    editProjectManager: action.bound,
    editProjectExecutors: action.bound,
    getProjectCreateInfo: action.bound,
    getDescendantCreateInfo: action.bound,
};

export class ProjectStore extends GeneralObjectExtendStore<ProjectDTO, ProjectCreateDTO, ProjectCreateInfo> {
    private portfolioStore: PortfolioStore;
    objectType = GeneralObject.project;
    isWithTabs = true;
    isWithCreateCopy = false;
    isWithChartContent = false;

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this, ProjectStoreProps);
        this.portfolioStore = rootStore.portfolioStore;
    }

    mapObjectDTOToCard(data: ProjectDTO): GeneralObjectCard {
        const { id, title, metaInfo, portfolio, identifier, category, manager, curator, monitor, viewers, executors } =
            data;
        const { author, created, state } = metaInfo;

        const { intlStore } = this.rootStore;

        const getResponsibleCheckData = (fieldName: GeneralObjectFieldName): ResponsibleCheck => ({
            object: this.objectType,
            entityId: id,
            fieldName,
        });

        return {
            id,
            title,
            state,
            commonDescription: [
                getNumberDescription(identifier),
                getResponsibleDescription(getResponsibleCheckData(GeneralObjectFieldName.projectCurator), curator),
                getResponsibleDescription(getResponsibleCheckData(GeneralObjectFieldName.projectManager), manager),
                getMultipleResponsibleDescription(
                    getResponsibleCheckData(GeneralObjectFieldName.projectExecutor),
                    executors,
                    intlStore.intl.formatMessage({ id: 'projectExecutor.plural.nominative' }),
                ),
                getResponsibleDescription(getResponsibleCheckData(GeneralObjectFieldName.projectMonitor), monitor),
                getMultipleResponsibleDescription(
                    getResponsibleCheckData(GeneralObjectFieldName.projectViewer),
                    viewers,
                    intlStore.intl.formatMessage({ id: 'viewer.plural.nominative' }),
                    true,
                ),
                {
                    id: GeneralObjectFieldName.portfolio,
                    isObjectField: true,
                    value: getGeneralObjectLinkValue(
                        portfolio.title,
                        generatePath(clientRoute.portfolio.card, { id: portfolio.id }),
                    ),
                },
                {
                    id: GeneralObjectFieldName.category,
                    isObjectField: true,
                    value: getGeneralObjectLinkValue(
                        category.title,
                        generatePath(clientRoute.category.card, { id: category.id }),
                    ),
                    withBottomSeparator: true,
                },
                {
                    id: GeneralObjectFieldName.created,
                    value: created,
                    isDate: true,
                },
                {
                    id: GeneralObjectFieldName.author,
                    isObjectField: true,
                    value: getGeneralObjectLinkValue(
                        new UserNameModel().load(author).name,
                        generatePath(clientRoute.user, { id: author.userId }),
                    ),
                    withBottomSeparator: true,
                },
                this.getSyslogDescription(id),
            ],
        };
    }

    getProjectCreateInfo(id: string): Promise<ProjectCreateInfo> {
        return this.api.client(apiConfigs.getProjectCreateInfo(this.objectType, id)).then((r) => r.data);
    }

    getDescendantCreateInfo(portfolioId: string, tabId: string): Promise<DescendantCreateInfo> {
        return this.api
            .client(
                apiConfigs.getObjectCreateInfo(this.objectType, {
                    tabId,
                    portfolioId,
                }),
            )
            .then((r) => r.data);
    }

    editProjectNumber(id: string, number: string, onSuccess: () => void): Promise<void> {
        return this.api
            .userActionClient(apiConfigs.editProjectNumber(id, number), Object.values(NumberField))
            .then(onSuccess);
    }

    editProjectManager(id: string, managerId: string, onSuccess: () => void): Promise<void> {
        return this.api
            .userActionClient(apiConfigs.editProjectManager(id, managerId), Object.values(ResponsibleFields))
            .then(onSuccess);
    }

    editProjectCurator(id: string, curatorId: string, onSuccess: () => void): Promise<void> {
        return this.api
            .userActionClient(apiConfigs.editProjectCurator(id, curatorId), Object.values(ResponsibleFields))
            .then(onSuccess);
    }

    editProjectMonitor(id: string, monitorId: string, onSuccess: () => void): Promise<void> {
        return this.api
            .userActionClient(apiConfigs.editProjectMonitor(id, monitorId), Object.values(ResponsibleFields))
            .then(onSuccess);
    }

    editProjectViewers(id: string, viewerIds: string[], onSuccess: () => void): Promise<void> {
        return this.api
            .userActionClient(apiConfigs.editProjectViewers(id, viewerIds), Object.values(ResponsibleFields))
            .then(onSuccess);
    }

    editProjectExecutors(id: string, executorIds: string[], onSuccess: () => void): Promise<void> {
        return this.api
            .userActionClient(apiConfigs.editProjectExecutors(id, executorIds), Object.values(ResponsibleFields))
            .then(onSuccess);
    }
}

export const getProjectStore = (): any => {
    const [_ProjectStore] = di([ProjectStore], getProjectStore);
    return _ProjectStore;
};
