var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from 'styled-components';
import { sassVars } from '../../constants/sassVars';
export default function () {
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        width: ", ";\n        float: ", ";\n        margin-bottom: ", ";\n\n        > dt {\n            font-size: ", ";\n            font-weight: ", ";\n            line-height: ", ";\n            color: ", ";\n            margin-bottom: ", ";\n        }\n\n        > dd {\n            font-size: ", ";\n            line-height: ", ";\n            font-weight: ", ";\n            color: ", ";\n        }\n    "], ["\n        width: ", ";\n        float: ", ";\n        margin-bottom: ", ";\n\n        > dt {\n            font-size: ", ";\n            font-weight: ", ";\n            line-height: ", ";\n            color: ", ";\n            margin-bottom: ", ";\n        }\n\n        > dd {\n            font-size: ", ";\n            line-height: ", ";\n            font-weight: ", ";\n            color: ", ";\n        }\n    "])), sassVars.viewOnlyDlWidth, sassVars.viewOnlyDlFloat, sassVars.viewOnlyComponentFieldsetMarginBottom, sassVars.viewOnlyFieldLabelFontSize, sassVars.viewOnlyFieldLabelFontWeight, sassVars.viewOnlyFieldLabelLineHeight, sassVars.viewOnlyFieldLabelColor, sassVars.viewOnlyFieldLabelMarginBottom, sassVars.viewOnlyFieldValueFontSize, sassVars.viewOnlyFieldValueLineHeight, sassVars.viewOnlyFieldValueFontWeight, sassVars.viewOnlyFieldValueColor).join('');
}
var templateObject_1;
