import CloseIcon from '@mui/icons-material/Close';
import { FormHelperText, Grid } from '@mui/material';
import React from 'react';

export type ServerErrorHelperProps = {
    serverError?: string;
    withoutMargin?: boolean;
};

export const ServerErrorHelper = (props: ServerErrorHelperProps): JSX.Element => {
    const { serverError, withoutMargin } = props;

    return (
        <React.Fragment>
            {serverError && (
                <FormHelperText
                    error={true}
                    sx={{
                        marginTop: withoutMargin ? 0 : 1,
                        marginRight: withoutMargin ? 0 : 3.5,
                        marginLeft: withoutMargin ? 0 : 3.5,
                    }}
                >
                    <Grid container alignItems="center">
                        <CloseIcon fontSize="small" />
                        {serverError}
                    </Grid>
                </FormHelperText>
            )}
        </React.Fragment>
    );
};
