import { makeSxStyles } from '@platform/front-ui';
import { CSSProperties } from 'react';
import { grey } from '../../theme';

export const consoleSxStyles = makeSxStyles({
    wrapper: {
        height: '100%',
        paddingTop: (theme) => theme.spacing(5.5),
        paddingBottom: (theme) => theme.spacing(15),
    },
    inner: {
        height: '100%',
    },
    mainBlock: {
        marginBottom: (theme) => theme.spacing(5),
        maxWidth: '100% !important',
    },
    editorWrapper: {
        maxHeight: '65vh',
        overflowY: 'auto',
        border: `1px solid ${grey[300]}`,
        maxWidth: '100% !important',
        '& *': {
            fontFamily: '"Fira code", "Fira Mono", monospace !important',
            textShadow: 'none !important',
        },
    },
    columnTitle: {
        marginBottom: (theme) => theme.spacing(2),
    },
    resultWrapper: {
        position: 'relative',
    },
    codeWrapperLeft: {
        marginRight: '8px',
    },
    codeWrapperRight: {
        marginLeft: '8px',
    },
    resultControlsWrapper: {
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: '10',
    },
});

export const consoleEditorStyles: CSSProperties = {
    fontFamily: '"Fira code", "Fira Mono", monospace !important',
    fontSize: 12,
    minHeight: '100%',
};
