export default {
    startPage: false,
    startPageDefaultText: false,
    startPageWithLogin: true,
    startPageLogoImg: {
        isEnabled: true,
        locales: {
            ru: {
                src: require('./gpn-logo.png'),
                alt: 'ГПН-ННГ',
            },
            en: {
                src: require('./gpn-logo.png'),
                alt: 'GPN-NNG',
            },
        },
        style: { width: '150px' },
    },
    headerLogoImg: {
        isEnabled: true,
        locales: {
            ru: {
                src: require('./gpng-logo.png'),
                alt: 'ГПН-ННГ',
            },
            en: {
                src: require('./gpng-logo.png'),
                alt: 'GPN-NNG',
            },
        },
        style: { width: '120px' },
    },
    logo: {
        isEnabled: true,
        locales: {
            ru: {
                src: require('./gpng-logo.png'),
                alt: 'ГПН-ННГ',
            },
            en: {
                src: require('./gpng-logo.png'),
                alt: 'GPN-NNG',
            },
        },
        style: { width: '100%', maxWidth: '140px', height: '80px' },
        withText: false,
        mdLogo: false,
    },
    customBackground: true,
    phoneNumber: false,
    instructions: false,
    esiaLogin: false,
    helpIcon: true,
    yaMetrika: true,

    userAdding: true,
    userAssignPassword: true,
    notificationIcon: true,
    modularNavigation: true,

    withSubjectFilesTab: false,
};
