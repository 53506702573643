import { Button, Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { NavLink } from 'react-router-dom';
import { CommonTypography as CommonTypographyInj } from '../../../typography';
import { HeaderLinkStyle } from './HeaderLinkStyle';
import { useVerifyHeaderLinks as useVerifyHeaderLinksInj } from './useVerifyHeaderLinks';

export const HeaderLinksList = observer((): JSX.Element => {
    const [useVerifyHeaderLinks] = di([useVerifyHeaderLinksInj], HeaderLinksList);
    const [CommonTypography] = di([CommonTypographyInj], HeaderLinksList);
    const verifiedLinks = useVerifyHeaderLinks();
    return (
        <React.Fragment>
            {verifiedLinks.map(({ key, to, messageID }) => (
                <Grid item key={key}>
                    <Button component={NavLink} to={to} variant="text" color="secondary" sx={HeaderLinkStyle}>
                        <CommonTypography>
                            <FormattedMessage id={messageID} />
                        </CommonTypography>
                    </Button>
                </Grid>
            ))}
        </React.Fragment>
    );
});
