import { Box, Container, Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { registryPageTableSxStyles } from '../../components';
import { useServerTitleBreadcrumbs } from '../../hooks';
import { BreadcrumbsLocation, ChildTableRow } from '../../types';
import {
    ChildObjectRegistryActionsBtns as ChildObjectRegistryActionsBtnsInj,
    ChildObjectRegistryTable as ChildObjectRegistryTableInj,
} from './components';

export const ChildObjectRegistryPage = observer((): JSX.Element => {
    const [ChildObjectRegistryActionsBtns] = di([ChildObjectRegistryActionsBtnsInj], ChildObjectRegistryPage);
    const [ChildObjectRegistryTable] = di([ChildObjectRegistryTableInj], ChildObjectRegistryPage);

    useServerTitleBreadcrumbs({ location: BreadcrumbsLocation.childObject });

    const setRowActions = (
        row: ChildTableRow,
        reloadData: () => void,
        toggleIsRowBlocked: () => void,
        rowErrorHandler: () => void,
    ): JSX.Element => {
        return (
            <ChildObjectRegistryActionsBtns
                childObjectRow={row}
                reloadData={reloadData}
                toggleIsRowBlocked={toggleIsRowBlocked}
                rowErrorHandler={rowErrorHandler}
            />
        );
    };

    return (
        <Container maxWidth={false}>
            <Box pt={5.5} pb={15}>
                <Grid container spacing={5}>
                    <Grid item container justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h1" component="h1">
                                <FormattedMessage id="childObject.plural.nominative" />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container direction="column">
                        <Grid item sx={registryPageTableSxStyles.tableWrapper}>
                            <ChildObjectRegistryTable setRowActions={setRowActions} />
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
});
