import { DropResult } from 'react-beautiful-dnd';

export const reorderList = <T>(list: T[], startIndex: number, endIndex: number): T[] => {
    const reorderedList = Array.from(list);
    const [removed] = reorderedList.splice(startIndex, 1);
    reorderedList.splice(endIndex, 0, removed);

    return reorderedList;
};

export const onDragEndCreator =
    (
        changePosition: (id: string, newPosition: number) => void,
        reorderList: (sourceIndex: number, destinationIndex: number) => void,
    ): ((result: DropResult) => void) =>
    (result) => {
        if (!result.destination) {
            return;
        }

        const startIndex = result.source.index;
        const endIndex = result.destination.index;

        changePosition(result.draggableId, endIndex + 1);
        reorderList(startIndex, endIndex);
    };
