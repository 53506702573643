import { ThemeProvider } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { Route, Switch } from 'react-router-dom';
import { useGeneralObjectContext } from '../../hooks';
import { ChildObjectVersionsPage as ChildObjectVersionsPageInj } from '../../pages';
import { redesignTheme } from '../../theme';
import { GeneralObject } from '../../types';
import {
    GeneralObjectChartSettingsPage as GeneralObjectTabSettingsChartSettingsPageInj,
    GeneralObjectPFSettings as GeneralObjectPFSettingsInj,
    GeneralObjectSettings as GeneralObjectSettingsInj,
    GeneralObjectTabSettings as GeneralObjectTabSettingsInj,
} from './components';
import { GeneralObjectPage as GeneralObjectPageInj } from './GeneralObjectPage';
import { GeneralObjectSyslogPage as GeneralObjectSyslogPageInj } from './GeneralObjectSyslogPage';

export const GeneralObjectMainComponent = observer((): JSX.Element => {
    const [GeneralObjectPage] = di([GeneralObjectPageInj], GeneralObjectMainComponent);
    const [GeneralObjectSyslogPage] = di([GeneralObjectSyslogPageInj], GeneralObjectMainComponent);
    const [GeneralObjectSettings] = di([GeneralObjectSettingsInj], GeneralObjectMainComponent);
    const [GeneralObjectPFSettings] = di([GeneralObjectPFSettingsInj], GeneralObjectMainComponent);
    const [GeneralObjectTabSettings] = di([GeneralObjectTabSettingsInj], GeneralObjectMainComponent);
    const [ChildObjectVersionsPage] = di([ChildObjectVersionsPageInj], GeneralObjectMainComponent);
    const [GeneralObjectTabSettingsChartSettingsPage] = di(
        [GeneralObjectTabSettingsChartSettingsPageInj],
        GeneralObjectMainComponent,
    );

    const { routes, isWithSettings = true, objectStore } = useGeneralObjectContext();
    const { objectType } = objectStore;

    return (
        <Switch>
            {isWithSettings && (
                <Route path={routes.settings}>
                    <Switch>
                        <Route path={routes.chart}>
                            <ThemeProvider theme={redesignTheme}>
                                <GeneralObjectTabSettingsChartSettingsPage />
                            </ThemeProvider>
                        </Route>
                        <Route path={routes.settingsPF}>
                            <GeneralObjectPFSettings />
                        </Route>
                        <Route path={routes.settingsTab}>
                            <GeneralObjectTabSettings />
                        </Route>
                        <Route path={routes.settings} exact>
                            <GeneralObjectSettings />
                        </Route>
                    </Switch>
                </Route>
            )}
            <Route path={routes.syslog}>
                <GeneralObjectSyslogPage />
            </Route>
            {objectType === GeneralObject.childObject && (
                <Route path={routes.versions}>
                    <ChildObjectVersionsPage />
                </Route>
            )}
            <Route path={[routes.tab, routes.card]}>
                <GeneralObjectPage />
            </Route>
        </Switch>
    );
});
