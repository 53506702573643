import { Container, Grid, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeSxStyles } from '@platform/front-ui';
import React from 'react';
import { di } from 'react-magnetic-di';
import { AppTheme } from '../../types';
import { HomePageLeftSide as HomePageLeftSideInj, HomePageRightSide as HomePageRightSideInj } from './components';

export const homePageSxStyles = makeSxStyles({
    fullHeight: {
        height: '100%',
    },
});

export const HomePageMainContainerInj = styled('div')(
    ({ theme }) => `
            width: 100%;
            height: 100%;
            padding: ${(theme as AppTheme).spacing(3.75)};
            box-sizing: border-box;
            background: linear-gradient(90deg, ${
                (theme as AppTheme).variables.palette.appBar.mainContrast
            } 50%, transparent 50%);
    `,
);

export const HomePageColoredContainerInj = styled(Grid)(
    ({ theme }) => `
            color: ${(theme as AppTheme).variables.palette.mainContrast};
    `,
);

export const HomePage = (): JSX.Element => {
    const [HomePageMainContainer] = di([HomePageMainContainerInj], HomePage);
    const [HomePageColoredContainer] = di([HomePageColoredContainerInj], HomePage);
    const [HomePageLeftSide] = di([HomePageLeftSideInj], HomePage);
    const [HomePageRightSide] = di([HomePageRightSideInj], HomePage);

    return (
        <HomePageMainContainer>
            <Container maxWidth="lg" sx={homePageSxStyles.fullHeight}>
                <Grid container item spacing={10} sx={homePageSxStyles.fullHeight}>
                    <HomePageColoredContainer item xs={6}>
                        <HomePageLeftSide />
                    </HomePageColoredContainer>
                    <Grid item xs={6}>
                        <HomePageRightSide />
                    </Grid>
                </Grid>
            </Container>
        </HomePageMainContainer>
    );
};
