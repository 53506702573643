import React from 'react';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { GeneralObjectChildObjectModal as GeneralObjectChildObjectModalInj } from '../../../../components';
import { CreateDescendantModalProps, GeneralObjectRouteParams } from '../../../../types';

export const ProjectDescendantChildObjectModal = (props: CreateDescendantModalProps): JSX.Element => {
    const [GeneralObjectChildObjectModal] = di([GeneralObjectChildObjectModalInj], ProjectDescendantChildObjectModal);
    const { id } = useParams<GeneralObjectRouteParams>();

    return <GeneralObjectChildObjectModal {...props} projectId={id} />;
};
