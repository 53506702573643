import SearchIcon from '@mui/icons-material/Search';
import { useFeature } from 'feature-toggle-jsx';
import { Field, Form, Formik } from 'formik';
import { InputBase } from 'formik-mui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { disableSubmitOnEnterKeyPress } from '../../../../utils';
import { SearchIconStyle, SearchStyle } from './SearchStyle';

export const Search = observer((): JSX.Element => {
    const intl = useIntl();
    const [search] = useFeature('search');
    return search ? (
        <Formik initialValues={{ search: '' }} onSubmit={() => {}}>
            <Form>
                <Field
                    placeholder={intl.formatMessage({ id: 'common.search' })}
                    component={InputBase}
                    name="search"
                    disabled={false}
                    fullWidth
                    endAdornment={<SearchIcon sx={SearchIconStyle} />}
                    sx={SearchStyle}
                />
            </Form>
        </Formik>
    ) : (
        <React.Fragment />
    );
});
