import { AxiosResponse } from 'axios';
import { Env, LocalizedMessages, MessageCase, ObjectLocales, TotLocale } from '../types';
const axios = require('axios');

const phrasePath = '.single.nominative';

const getLocaleKeyPath = (locale: string): [string, string] => {
    let key = locale;
    let path = locale;

    if (locale.indexOf('phrases') !== -1) {
        if (locale.indexOf(phrasePath) !== -1) {
            key = locale.replace(phrasePath, '');
        }

        path = key + phrasePath;
    }

    return [key, path];
};

function localeObjectToDotNotation(
    obj: { [key: string]: any },
    result: Record<string, string>,
    isWithTextWrapper: boolean,
    currentKey?: string,
) {
    const keys = Object.keys(obj);
    keys.forEach((key) => {
        const value = obj[key];
        const newKey = currentKey ? `${currentKey}.${key}` : key; // joined key with dot
        if (value && typeof value === 'object') {
            localeObjectToDotNotation(value, result, isWithTextWrapper, newKey); // it's a nested object, so do it again
        } else {
            const [localeKey, localePath] = getLocaleKeyPath(newKey);
            result[localeKey] = isWithTextWrapper ? `<t>${localePath} ${value}</t>` : value; // it's not an object, so set the property
        }
    });
}

export function recordToLang(record: Record<string, any>, isWithTextWrapper = true): Record<string, string> {
    const result: Record<string, string> = {};
    localeObjectToDotNotation(record, result, isWithTextWrapper);
    return result;
}

const localStorageKey = 'TotLocales';
const localClearDateStorageKey = 'TotLocalesClearDate';

const getLocales = (env: Env): Promise<LocalizedMessages> => {
    return axios
        .get(`${env.apiUrl}/locales`)
        .then((r: AxiosResponse<LocalizedMessages>) => r.data)
        .then((locales: LocalizedMessages) => {
            setStorageLocales(locales);
            return locales;
        });
};

const checkStorageLocalesClear = (): void => {
    const savedLocalesClearDate = localStorage.getItem(localClearDateStorageKey);

    if (savedLocalesClearDate && new Date() >= new Date(JSON.parse(savedLocalesClearDate))) {
        clearStorageLocales();
    }
};

const setStorageLocales = (locales: LocalizedMessages): void => {
    localStorage.setItem(localStorageKey, JSON.stringify(locales));

    const date = new Date();
    date.setDate(date.getDate() + 1);

    localStorage.setItem(localClearDateStorageKey, JSON.stringify(date));
};

export const clearStorageLocales = (): void => {
    localStorage.removeItem(localStorageKey);
    localStorage.removeItem(localClearDateStorageKey);
};

export const getFullLocales = async (defaultLocales: LocalizedMessages, env: Env): Promise<LocalizedMessages> => {
    checkStorageLocalesClear();

    const savedLocales = localStorage.getItem(localStorageKey);

    const locales = savedLocales ? JSON.parse(savedLocales) : await getLocales(env).catch(() => {});

    const messagesCache: LocalizedMessages = { ...defaultLocales };

    if (locales) {
        Object.keys(locales).forEach((locale) => {
            const messages = locales[locale as TotLocale];
            const lang = recordToLang(messages);

            messagesCache[locale as TotLocale] = {
                ...messagesCache[locale as TotLocale],
                ...lang,
            };
        });
    }

    return messagesCache;
};

export const getMessageCase = (word: string): MessageCase => ({
    nominative: word,
    accusative: word,
    dative: word,
    genitive: word,
    instrumental: word,
    prepositional: word,
});

export const createLocalesWithoutCases = (singleWord: string, pluralWord?: string): ObjectLocales => {
    const locales: ObjectLocales = {
        single: getMessageCase(singleWord),
    };

    if (pluralWord) {
        locales.plural = getMessageCase(pluralWord);
    }

    return locales;
};
