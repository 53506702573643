import {
    Box,
    Button,
    Container,
    Grid,
    Paper,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, DraggableProvided, Droppable } from 'react-beautiful-dnd';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { useFlag, useGeneralObjectContext, useStore } from '../../../../hooks';
import { ObjectSettingsModel } from '../../../../models';
import { objectSettingsDeleteTabMessages } from '../../../../resources';
import {
    BreadcrumbsEntities,
    BreadcrumbsLocation,
    ComputedConfirmationDialogProps,
    GeneralObjectRouteParams,
    GeneralObjectWithoutDescendantObject,
    ObjectTabSettingsDTO,
    TabSettingConfirmModalType,
} from '../../../../types';
import { onDragEndCreator } from '../../../../utils';
import { ServerBreadcrumbs as ServerBreadcrumbsInj, ServerBreadcrumbsProps } from '../../../breadcrumbs';
import { ConfirmationDialog as ConfirmationDialogInj } from '../../../ConfirmationDialog';
import { DroppableTableProvide as DroppableTableProvideInj } from '../../../DroppableTableProvide';
import { GeneralObjectSettingsTabItem } from './GeneralObjectSettingsTabItem';
import { GeneralObjectTabFormModal as GeneralObjectTabFormModalInj } from './GeneralObjectTabFormModal';

const getTabItemInj =
    (
        tab: ObjectTabSettingsDTO,
        openDeleteModal: () => void,
        openEditArchivedModal: () => void,
    ): ((provided: DraggableProvided) => JSX.Element) =>
    (provided) =>
        (
            <GeneralObjectSettingsTabItem
                tabModel={tab}
                provided={provided}
                openDeleteModal={openDeleteModal}
                openEditArchivedModal={openEditArchivedModal}
            />
        );

export const GeneralObjectSettings = observer((): JSX.Element => {
    const [getTabItem] = di([getTabItemInj], GeneralObjectSettings);
    const [ServerBreadcrumbs] = di([ServerBreadcrumbsInj], GeneralObjectSettings);
    const [GeneralObjectTabFormModal] = di([GeneralObjectTabFormModalInj], GeneralObjectSettings);
    const [ConfirmationDialog] = di([ConfirmationDialogInj], GeneralObjectSettings);
    const [DroppableTableProvide] = di([DroppableTableProvideInj], GeneralObjectSettings);

    const { id, tabId } = useParams<GeneralObjectRouteParams>();
    const rootStore = useStore();
    const { intlStore } = rootStore;
    const intl = useIntl();
    const { objectStore } = useGeneralObjectContext();
    const { objectType } = objectStore;
    const [createModalIsOpen, setCreateModalIsOpen, setCreateModalIsClosed] = useFlag();
    const [tabSettingOpenedModalType, setTabSettingOpenedModalType] = useState<TabSettingConfirmModalType>();
    const [tabIdForActions, setTabIdForActions] = useState<string>('');

    const settings = useMemo<ObjectSettingsModel>(
        () => new ObjectSettingsModel(id, rootStore, objectStore),
        [id, rootStore, objectStore],
    );

    const { changeTabPosition, reorderTabList, tabs, onDeleteTabConfirm, onArchiveTabConfirm, onUnarchiveTabConfirm } =
        settings;

    useEffect(() => {
        settings.load();
    }, [id, objectStore, intl.locale]);

    const closeTabSettingModal = (): void => {
        setTabIdForActions('');
        setTabSettingOpenedModalType(undefined);
    };

    const onDragEnd = onDragEndCreator(changeTabPosition, reorderTabList);

    const draggableTabs = useMemo((): JSX.Element[] => {
        return tabs.map((tab, index) => {
            const id = tab.id;

            const openDeleteModal = (): void => {
                setTabIdForActions(id);
                setTabSettingOpenedModalType(TabSettingConfirmModalType.deleteTab);
            };

            const openEditArchivedModal = (): void => {
                setTabIdForActions(id);
                const editArchivedModalType = tab.archived
                    ? TabSettingConfirmModalType.unarchiveTab
                    : TabSettingConfirmModalType.archiveTab;
                setTabSettingOpenedModalType(editArchivedModalType);
            };

            return (
                <Draggable draggableId={id} index={index} key={id}>
                    {getTabItem(tab, openDeleteModal, openEditArchivedModal)}
                </Draggable>
            );
        });
    }, [tabs, tabs.length]);

    //На будущее: если будут проблемы с производительностью, то превратить объект в функцию с switch
    const computedConfirmationDialogProps: ComputedConfirmationDialogProps = {
        [TabSettingConfirmModalType.deleteTab]: {
            id: TabSettingConfirmModalType.deleteTab,
            title: intl.formatMessage({ id: 'common.confirmDeletion' }),
            message: intlStore.formatMessageFromDefineMessage(
                objectSettingsDeleteTabMessages[objectType as GeneralObjectWithoutDescendantObject],
            ),
            onConfirm: onDeleteTabConfirm(tabIdForActions, closeTabSettingModal),
        },
        [TabSettingConfirmModalType.archiveTab]: {
            id: TabSettingConfirmModalType.archiveTab,
            title: intl.formatMessage({ id: 'objectSettings.hideTab' }),
            message: intl.formatMessage({ id: 'objectSettings.hideTabConfirmation' }),
            onConfirm: onArchiveTabConfirm(tabIdForActions, closeTabSettingModal),
        },
        [TabSettingConfirmModalType.unarchiveTab]: {
            id: TabSettingConfirmModalType.unarchiveTab,
            title: intl.formatMessage({ id: 'objectSettings.showTab' }),
            message: intl.formatMessage({ id: 'objectSettings.showTabConfirmation' }),
            onConfirm: onUnarchiveTabConfirm(tabIdForActions, closeTabSettingModal),
        },
    };

    const isTabSettingModalOpened = !!tabSettingOpenedModalType;

    const serverBreadcrumbsProps: ServerBreadcrumbsProps = tabId
        ? {
              entityId: tabId,
              entityType: BreadcrumbsEntities.tabSetting,
          }
        : {
              entityId: id,
              entityType: objectType,
              location: BreadcrumbsLocation.tabSettings,
          };

    return (
        <Container maxWidth="lg">
            <GeneralObjectTabFormModal
                setIsClosed={setCreateModalIsClosed}
                isOpen={createModalIsOpen}
                settingsModel={settings}
            />
            {isTabSettingModalOpened && (
                <ConfirmationDialog
                    isOpen={isTabSettingModalOpened}
                    setIsClosed={closeTabSettingModal}
                    keepMounted
                    {...computedConfirmationDialogProps[tabSettingOpenedModalType]}
                />
            )}
            <Box pt={5.5} pb={15}>
                <Box pb={5}>
                    <ServerBreadcrumbs {...serverBreadcrumbsProps} />
                </Box>
                <Box pb={5} pt={2}>
                    <Grid container direction="column" spacing={8}>
                        <Grid item>
                            <Typography variant="h4" component="h1">
                                <FormattedMessage id="objectSettings.title" />
                            </Typography>
                        </Grid>
                        <Grid container item justifyContent="flex-end" alignItems="center">
                            <Button variant="contained" onClick={setCreateModalIsOpen}>
                                <FormattedMessage id="objectSettings.addTab" />
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography>
                                        <FormattedMessage id="objectSettings.tabFields.title" />
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        <FormattedMessage id="objectSettings.tabFields.code" />
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        <FormattedMessage id="objectSettings.tabFields.type" />
                                    </Typography>
                                </TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable">{DroppableTableProvide(draggableTabs)}</Droppable>
                        </DragDropContext>
                    </Table>
                </TableContainer>
            </Box>
        </Container>
    );
});
