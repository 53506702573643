import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { fontStylesMain, redesignTotPalette } from '../../../../theme';

export const HeaderLinkStyle: SxProps<Theme> = {
    ...fontStylesMain,
    height: '32px',
    color: redesignTotPalette.menuButton.default.text,
    '&.MuiButton-root.active': {
        color: redesignTotPalette.menuButton.active.color,
        backgroundColor: redesignTotPalette.menuButton.active.background,
    },
    '&:hover': {
        color: redesignTotPalette.menuButton.hover.color,
    },
};
