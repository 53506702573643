import InfoIcon from '@mui/icons-material/Info';
import { Grid } from '@mui/material';
import { useFeature } from 'feature-toggle-jsx';
import React from 'react';
import { di } from 'react-magnetic-di';
import { IconStyle } from '../../../../theme';
import { TotIconButton as TotIconButtonInj } from '../../../TotIconButton';

export type HelpButtonProps = {
    isDarkBackground?: boolean;
};

export const HelpButton = (props: HelpButtonProps): JSX.Element => {
    const [TotIconButton] = di([TotIconButtonInj], HelpButton);

    const { isDarkBackground } = props;
    const [helpIcon] = useFeature('helpIcon');

    return (
        <React.Fragment>
            {helpIcon && (
                <Grid item>
                    <TotIconButton color="secondary" isDarkBackground={isDarkBackground}>
                        <InfoIcon sx={IconStyle} />
                    </TotIconButton>
                </Grid>
            )}
        </React.Fragment>
    );
};
