import { NotificationDTO, NotificationsQueryData } from '@platform/notification-widget';
import { action, makeObservable, observable } from 'mobx';
import { di } from 'react-magnetic-di';
import { apiConfigs } from '../apiConfigs';
import { ApiStore } from './ApiStore';
import { RootStore } from './RootStore';

export const LkNotificationStoreProps = {
    rootStore: observable,
    api: observable,
    loadNotifications: action.bound,
    loadCountNotViewedMessages: action.bound,
    changeViewed: action.bound,
    deleteNotification: action.bound,
};

export class LkNotificationStore {
    private rootStore: RootStore;
    private api: ApiStore;

    constructor(rootStore: RootStore) {
        makeObservable(this, LkNotificationStoreProps);
        this.rootStore = rootStore;
        this.api = rootStore.api;
    }

    loadNotifications(queryData: NotificationsQueryData): Promise<NotificationDTO[]> {
        return this.api.client(apiConfigs.loadNotifications(queryData)).then((res) => res.data);
    }

    loadCountNotViewedMessages(): Promise<number> {
        return this.api.client(apiConfigs.loadCountNotViewedMessages()).then((res) => res.data);
    }

    changeViewed(id: string, viewed: boolean): Promise<void> {
        return this.api.client(apiConfigs.changeViewed(id, viewed)).then((res) => res.data);
    }

    deleteNotification(id: string): Promise<void> {
        return this.api.client(apiConfigs.deleteNotification(id)).then((res) => res.data);
    }
}

export const getLkNotificationStore = (): any => {
    const [_LkNotificationStore] = di([LkNotificationStore], getLkNotificationStore);
    return _LkNotificationStore;
};
