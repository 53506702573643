import { Button, Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, RefObject, useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import Sticky from 'react-sticky-el';
import { entities, permissions, permissionsConfig } from '../../../../authSchemeConfig';
import {
    AuthorizationCheck as AuthorizationCheckInj,
    AuthorizationCheckAll as AuthorizationCheckAllInj,
    CommonInfoLine as CommonInfoLineInj,
    CreateCategoryCopyBtn as CreateCategoryCopyBtnInj,
    EllipsisBox as EllipsisBoxInj,
    GeneralObjectEditTitleBtn as GeneralObjectEditTitleBtnInj,
    MenuButton as MenuButtonInj,
    renderMenuButton as renderMenuButtonInj,
    ServerBreadcrumbs as ServerBreadcrumbsInj,
} from '../../../../components';
import { rootId, stickyZIndex } from '../../../../constants';
import { useGeneralObjectCardContext, useGeneralObjectContext, useStore } from '../../../../hooks';
import { DotMenu } from '../../../../resources';
import {
    AuthorizationCheckQuery,
    BreadcrumbsEntities,
    GeneralObject,
    GeneralObjectRouteParams,
} from '../../../../types';
import { fakeScroll } from '../../../../utils';
import { GeneralObjectDeleteBtn as GeneralObjectDeleteBtnInj } from '../general-object-delete';
import { TotObjectHeader as TotObjectHeaderInj } from '../styled/TotObjectHeader';
import { GeneralObjectControlPanel as GeneralObjectControlPanelInj } from './general-object-control-panel/GeneralObjectControlPanel';

export type GeneralObjectHeaderProps = {
    headerContainerRef: RefObject<HTMLDivElement>;
};

export const GeneralObjectHeader = observer((props: GeneralObjectHeaderProps): JSX.Element => {
    const [AuthorizationCheck] = di([AuthorizationCheckInj], GeneralObjectHeader);
    const [AuthorizationCheckAll] = di([AuthorizationCheckAllInj], GeneralObjectHeader);
    const [ServerBreadcrumbs] = di([ServerBreadcrumbsInj], GeneralObjectHeader);
    const [TotObjectHeader] = di([TotObjectHeaderInj], GeneralObjectHeader);
    const [GeneralObjectControlPanel] = di([GeneralObjectControlPanelInj], GeneralObjectHeader);
    const [EllipsisBox] = di([EllipsisBoxInj], GeneralObjectHeader);
    const [GeneralObjectDeleteBtn] = di([GeneralObjectDeleteBtnInj], GeneralObjectHeader);
    const [CreateCategoryCopyBtn] = di([CreateCategoryCopyBtnInj], GeneralObjectHeader);
    const [CommonInfoLine] = di([CommonInfoLineInj], GeneralObjectHeader);
    const [MenuButton] = di([MenuButtonInj], GeneralObjectHeader);
    const [GeneralObjectEditTitleBtn] = di([GeneralObjectEditTitleBtnInj], GeneralObjectHeader);
    const [renderMenuButton] = di([renderMenuButtonInj], GeneralObjectHeader);

    const { headerContainerRef } = props;
    const { routes, objectStore, isWithSettings = true } = useGeneralObjectContext();
    const { objectType, isWithTabs, isWithCreateCopy } = objectStore;
    const { objectModel, updateStickyElementsHeight } = useGeneralObjectCardContext();
    const { title = '', loadTitles, saveTitles, state } = objectModel;
    const { id, tabId } = useParams<GeneralObjectRouteParams>();
    const { stickyElementsStore } = useStore();
    const intl = useIntl();
    const location = useLocation();
    const { pathname } = location;
    const { generalObjectHeadIsSticky, setGeneralObjectHeadIsSticky } = stickyElementsStore;

    useEffect(() => {
        triggerHeaderHeightRerender();
    }, [pathname]);

    const triggerHeaderHeightRerender = (): void => {
        fakeScroll();
        setTimeout(onFixedToggle, 0);
    };

    const renderActionItems = useCallback(
        (permissions: boolean[]): ((hideMenu?: () => void) => ReactNode[]) => {
            const renderItems = (hideMenu?: () => void): ReactNode[] => {
                const onDeleteClick = (): Promise<void> => {
                    hideMenu && hideMenu();
                    return objectModel.deleteObject();
                };

                const [isDeleteGeneralObjectAllowed, isCreateCategoryCopyAllowed] = permissions;

                return [
                    <React.Fragment key="actionBtns">
                        {isWithCreateCopy && isCreateCategoryCopyAllowed && (
                            <CreateCategoryCopyBtn prototypeCategoryId={id} />
                        )}
                        {isDeleteGeneralObjectAllowed && (
                            <GeneralObjectDeleteBtn
                                object={intl.formatMessage({ id: `${objectType}.single.accusative` })}
                                onDeleteClick={onDeleteClick}
                                title={title}
                            />
                        )}
                    </React.Fragment>,
                ];
            };
            return renderItems;
        },
        [objectModel, title],
    );

    const onFixedToggle = (fixed: boolean): void => {
        setGeneralObjectHeadIsSticky(fixed);
        updateStickyElementsHeight();
    };

    const viewPermissionsProps = permissionsConfig.viewGeneralObject(objectType, id);
    const updatePermissionsProps = permissionsConfig.updateGeneralObject(objectType, id);

    const menuButtonQueries = useMemo<AuthorizationCheckQuery[]>(() => {
        return [permissionsConfig.deleteGeneralObject(objectType, id), permissionsConfig.createCategoryCopy];
    }, [id]);

    return (
        <Sticky
            scrollElement={rootId}
            stickyStyle={{ zIndex: stickyZIndex }}
            onFixedToggle={onFixedToggle}
            dontUpdateHolderHeightWhenSticky={true}
        >
            <TotObjectHeader ref={headerContainerRef}>
                <Grid container direction="column" spacing={3} sx={{ marginBottom: 2 }}>
                    <Grid item>
                        {(!isWithTabs || (isWithTabs && tabId)) && (
                            <ServerBreadcrumbs
                                entityTitle={title}
                                entityId={tabId || id}
                                entityType={tabId ? BreadcrumbsEntities.tab : objectType}
                                isVisible={!generalObjectHeadIsSticky}
                            />
                        )}
                    </Grid>
                    <Grid container item justifyContent="space-between" wrap="nowrap">
                        <Grid container item direction="column" spacing={3}>
                            <Grid container item wrap="nowrap">
                                <Grid item sx={{ marginRight: 1 }}>
                                    <Typography variant={generalObjectHeadIsSticky ? 'h6' : 'h1'} component="h1">
                                        {generalObjectHeadIsSticky ? (
                                            <EllipsisBox text={title} correctLength={30} />
                                        ) : (
                                            title
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <AuthorizationCheck {...updatePermissionsProps}>
                                        <GeneralObjectEditTitleBtn loadTitles={loadTitles} saveTitles={saveTitles} />
                                    </AuthorizationCheck>
                                </Grid>
                                <Grid item>
                                    <AuthorizationCheckAll queries={menuButtonQueries} or={false}>
                                        {(permissions: boolean[]) => {
                                            const isAtLeastOnePermissionGranted = permissions.some((perm) => perm);

                                            const renderButton = renderMenuButton(<DotMenu />);
                                            const renderMenuItems = renderActionItems(permissions);

                                            return (
                                                <React.Fragment>
                                                    {isAtLeastOnePermissionGranted && (
                                                        <MenuButton
                                                            renderButton={renderButton}
                                                            renderMenuItems={renderMenuItems}
                                                        />
                                                    )}
                                                </React.Fragment>
                                            );
                                        }}
                                    </AuthorizationCheckAll>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <AuthorizationCheck {...viewPermissionsProps}>
                                    <GeneralObjectControlPanel />
                                </AuthorizationCheck>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            direction="column"
                            spacing={3}
                            sx={{ flexShrink: 5 }}
                            alignItems="flex-end"
                        >
                            {isWithSettings && (
                                <AuthorizationCheck
                                    entityCode={entities[objectType]}
                                    permCode={permissions[objectType].TabSettingManagement}
                                    entityId={id}
                                >
                                    <Grid item>
                                        <Button
                                            component={RouterLink}
                                            to={generatePath(routes.settings, { id })}
                                            variant="contained"
                                            color="secondary"
                                        >
                                            <FormattedMessage id="generalObject.settingsBtn" />
                                        </Button>
                                    </Grid>
                                </AuthorizationCheck>
                            )}
                            <Grid item>
                                <CommonInfoLine title={intl.formatMessage({ id: 'common.state' })} value={state} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </TotObjectHeader>
        </Sticky>
    );
});
