import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { di } from 'react-magnetic-di';
import { useStore } from '../hooks';
import { usersConfirmStatusMessages, usersConfirmStatusTextMessages } from '../resources';
import { ModalProps, UserStatus } from '../types';
import { actionCodeByUserStatus } from '../utils';
import { ConfirmationDialog as ConfirmationDialogInj, ConfirmationDialog } from './ConfirmationDialog';

export type UserStatusModalProps = ModalProps & {
    id: string;
    login: string;
    name: string;
    userStatusCode: UserStatus;
    reloadData: () => void;
    onFinally?: () => void;
    toggleIsRowBlocked?: () => void; //временно оставил потому что и для реестра, и для карточки используется один и тот же диалог
    rowErrorHandler?: () => void;
};

export const UserStatusModal = observer((props: UserStatusModalProps): JSX.Element => {
    const [ConfirmationDialog] = di([ConfirmationDialogInj], UserStatusModal);

    const {
        id,
        login,
        name,
        userStatusCode,
        isOpen,
        reloadData,
        setIsClosed,
        onFinally,
        toggleIsRowBlocked,
        rowErrorHandler,
    } = props;
    const { userStore, intlStore } = useStore();
    const userStatus = actionCodeByUserStatus[userStatusCode];

    const onChangeStatusConfirm = useCallback(async () => {
        setIsClosed();
        toggleIsRowBlocked && toggleIsRowBlocked();
        userStore
            .editUserStatus(
                id,
                {
                    action: userStatus,
                },
                true,
            )
            .then(reloadData)
            .catch(rowErrorHandler)
            .finally(() => {
                onFinally && onFinally();
            });
    }, [id, userStatusCode, userStore, reloadData]);

    const confirmationDialogMessage = intlStore.formatMessageFromDefineMessage({
        ...usersConfirmStatusTextMessages[userStatus],
        values: {
            ...usersConfirmStatusTextMessages[userStatus].values,
            login,
            name,
        },
    });

    return (
        <ConfirmationDialog
            id="confirm-action"
            keepMounted
            isOpen={isOpen}
            onConfirm={onChangeStatusConfirm}
            setIsClosed={setIsClosed}
            title={intlStore.formatMessageFromDefineMessage(usersConfirmStatusMessages[userStatus])}
            message={confirmationDialogMessage}
        />
    );
});
