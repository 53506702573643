import { AppFonts, AppIcons, AppPalette, ThemeOverrides } from '../../types';

type ColorName =
    | 'black'
    | 'blackLight'
    | 'white'
    | 'yellow'
    | 'yellowDark'
    | 'greyMain'
    | 'greyLight'
    | 'greyDark'
    | 'greyWhite'
    | 'greyLighter'
    | 'greyMiddleLight'
    | 'greyMiddleLighter'
    | 'red'
    | 'green'
    | 'blue'
    | 'lighterBlue'
    | 'lighterGrey'
    | 'disabled';

const totXColors: Record<ColorName, string> = {
    black: '#000000',
    blackLight: '#1c1d1f',
    white: '#ffffff',
    yellow: '#ffff33',
    yellowDark: '#f5c722',
    greyMain: '#63666a',
    greyLight: '#85898f',
    greyDark: '#2c302e',
    greyWhite: '#f1f1f1',
    greyLighter: '#fafafa',
    greyMiddleLight: '#ccd3db',
    greyMiddleLighter: '#eff0f2',
    red: '#fb4d3d',
    green: '#008000',
    blue: '#1570EF',
    lighterBlue: 'rgba(53, 165, 255, 0.09)',
    lighterGrey: 'rgba(133, 147, 143, 0.09)',
    disabled: 'rgba(0, 0, 0, 0.12)',
};

const totXFonts: AppFonts = {
    mainFontAttrs: {
        fontSize: '13px',
        lineHeight: 1.69,
        letterSpacing: '0.2px',
    },
};

// перенести в formiojs-react
const totXIcons: AppIcons = {
    fontFamily: 'glyphicons-theme',
    arrowIcon: {
        fontSize: 11,
        content: '"\\e90f"',
        width: 12,
        height: 7,
    },
};

const accentBackgroundGradient = `linear-gradient(-30deg, ${totXColors.yellow} 0%, ${totXColors.yellowDark} 100%)`;

export const totXPalette: AppPalette = {
    main: totXColors.greyMain,
    textMain: totXColors.greyMain,
    textDark: totXColors.black,
    linkMain: totXColors.blue,
    mainMiddleLight: totXColors.greyMiddleLight,
    transparentLight: totXColors.greyMiddleLighter,
    mainLight: totXColors.greyLight,
    mainDark: totXColors.greyDark,
    mainContrast: totXColors.white,
    mainContrastDarker: totXColors.greyLighter,
    accent: totXColors.yellow,
    accentDark: totXColors.yellowDark,
    accentContrast: totXColors.black,
    accentBackgroundGradient,
    hover: totXColors.yellow,
    hoverInLists: totXColors.greyWhite,
    red: totXColors.red,
    green: totXColors.green,
    campaignList: {
        backgroundCard: totXColors.lighterBlue,
        selectedPaginationButton: totXColors.lighterGrey,
    },
    table: {
        row: {
            even: totXColors.white,
            odd: totXColors.greyLighter,
        },
    },
    card: {
        header: {
            main: '#252629',
        },
    },
    appBar: {
        mainContrast: totXColors.blackLight,
    },
    tooltip: {
        backgroundColor: totXColors.blackLight,
    },
    button: {
        accentContrast: totXColors.black,
        hoverBackground: totXColors.greyMain,
        disabled: totXColors.disabled,
    },
    iconButton: {
        color: totXColors.greyMain,
        hoverColor: totXColors.white,
        hoverBackgroundColor: totXColors.greyMain,
    },
    background: {
        textMain: totXColors.black,
    },
    footer: {
        textMain: totXColors.greyMain,
        textHover: totXColors.yellow,
        padding: 3.75,
    },
    tab: {
        backgroundColor: '#ececec',
        color: totXColors.greyMain,
        hoverBackgroundColor: totXColors.greyMain,
        hoverColor: totXColors.white,
    },
    panel: {
        markerBackgroundColor: accentBackgroundGradient,
        markerColor: totXColors.black,
    },
};

export default {
    fonts: totXFonts,
    palette: totXPalette,
    icons: totXIcons,
} as ThemeOverrides;
