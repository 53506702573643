import { CodeTitle } from '@platform/front-utils';
import { TitlesDTO } from '@platform/multi-lang-field';
import { action, computed, makeObservable, observable } from 'mobx';
import { IntlStore, NotificationStore, RootStore } from '../../stores';
import {
    ChartData,
    ChartFormDataDTO,
    ChartSettingDTO,
    ChartSettingField,
    ChartSettingFormValues,
    ChartSettingFormValuesDTO,
    ChartSourceType,
    DataSourceOptionsDTO,
    GeneralObjectChartContentStore,
} from '../../types';
import { getChartTypes, getPieChartDefaultSettings, parsingSubmissionEditGrid } from '../../utils';
import { ServerErrorsModel } from '../ServerErrorsModel';

export const chartSettingsModelObservables = {
    id: observable,
    dataOptions: observable,
    title: observable,
    objectStore: observable,
    intlStore: observable,
    notificationStore: observable,
    chartVisualSettings: observable,
    objectTabs: observable,
    sourceCatalog: observable,
    registries: observable,
    chartTypes: observable,
    chartData: observable,
    previewDataForChart: observable,
    registryQuery: observable,
    serverErrors: observable,
    changeSettings: action.bound,
    changeRegistryQuery: action.bound,
    loadTabs: action.bound,
    submitChartSetting: action.bound,
    load: action.bound,
    getPreviewData: action.bound,
    calculateChartData: action.bound,
    loadChartSources: action.bound,
    loadRegistries: action.bound,
    getChartSettingTitles: action.bound,
    saveChartSettingTitles: action.bound,
    setChartData: action.bound,
    setChartVisualSettings: action.bound,
    setRegistryQuery: action.bound,
    setObjectTabs: action.bound,
    setSourceCatalog: action.bound,
    setRegistries: action.bound,
    setPreviewDataForChart: action.bound,

    stringifySettings: computed,
    stringifyRegistryQuery: computed,
};

export class ChartSettingsModel {
    protected objectStore: GeneralObjectChartContentStore;
    protected notificationStore: NotificationStore;
    protected intlStore: IntlStore;

    id = '';
    title = '';

    dataOptions?: ChartSettingFormValuesDTO;

    chartVisualSettings: any = getPieChartDefaultSettings(); // рандомный json
    registryQuery: any; // рандомный json

    objectTabs: CodeTitle[] = [];
    chartTypes: CodeTitle[] = getChartTypes();
    sourceCatalog: CodeTitle[] = [];
    registries: CodeTitle[] = [];
    serverErrors = new ServerErrorsModel<ChartSettingField>();
    chartData: ChartData[] = [];
    previewDataForChart = '';

    constructor(id: string, rootStore: RootStore, objectStore: GeneralObjectChartContentStore) {
        makeObservable(this, chartSettingsModelObservables);
        this.id = id;
        this.objectStore = objectStore;
        this.notificationStore = rootStore.notificationStore;
        this.intlStore = rootStore.intlStore;
    }

    get stringifySettings(): string {
        return JSON.stringify(this.chartVisualSettings, null, 2);
    }

    get stringifyRegistryQuery(): string {
        return JSON.stringify(this.registryQuery, null, 2);
    }

    load(): Promise<void> {
        return this.objectStore.getChartSettingInfo(this.id).then((data) => {
            const { title, settings } = data;
            this.title = title;
            if (settings) {
                const { chartOptions, dataSourceOptions, chartType } = settings;
                const { visualizationOptions, dataOptions } = chartOptions;
                const { element, dataLabelsFieldCode, valuesFieldCode } = dataOptions;
                this.chartVisualSettings = visualizationOptions;
                this.registryQuery = dataSourceOptions.registry?.query;

                const sourceValue = this.sourceCatalog.find((item) => item.code === dataOptions.source);
                const tabValue = this.objectTabs.find((item) => item.code === dataSourceOptions.tabCode);
                const registryValue = this.registries.find(
                    (item) => item.code === dataSourceOptions.registry?.entityType,
                );
                const chartTypeValue = this.chartTypes.find((item) => item.code === chartType);
                this.dataOptions = {
                    element,
                    dataLabelsFieldCode,
                    valuesFieldCode,
                    source: sourceValue,
                    tab: tabValue,
                    typeChart: chartTypeValue,
                    registry: registryValue,
                };
            }
        });
    }

    loadTabs(objectId: string): Promise<void> {
        return this.objectStore.getTabsWithForm(objectId).then(this.setObjectTabs);
    }

    loadChartSources(): Promise<void> {
        return this.objectStore.getChartSources().then(this.setSourceCatalog);
    }

    loadRegistries(objectId: string): Promise<void> {
        return this.objectStore.getRegistries(objectId).then(this.setRegistries);
    }

    changeSettings(settings?: string): void {
        try {
            if (settings) {
                this.setChartVisualSettings(JSON.parse(settings));
            }
        } catch {
            this.notificationStore.onError(
                this.intlStore.intl.formatMessage({ id: 'objectSettings.notValidSettings' }),
            );
        }
    }

    changeRegistryQuery(query?: string): void {
        try {
            if (query) {
                this.setRegistryQuery(JSON.parse(query));
            }
        } catch {
            this.notificationStore.onError(this.intlStore.intl.formatMessage({ id: 'objectSettings.notValidQuery' }));
        }
    }

    submitChartSetting(values: ChartSettingFormValues): Promise<void> {
        const { typeChart, element, dataLabelsFieldCode, valuesFieldCode, objectTab, source, objectRegistry } = values;
        const registryQuery = this.registryQuery;
        const dataSourceOptions: DataSourceOptionsDTO = {
            tabCode: source?.code === ChartSourceType.form ? objectTab?.code : undefined,
            registry:
                source?.code === ChartSourceType.registry && objectRegistry && registryQuery
                    ? { entityType: objectRegistry?.code, query: registryQuery }
                    : undefined,
        };
        const dto: ChartSettingDTO = {
            chartOptions: {
                visualizationOptions: this.chartVisualSettings,
                dataOptions: {
                    source: source?.code as string,
                    element,
                    dataLabelsFieldCode,
                    valuesFieldCode,
                },
            },
            chartType: typeChart?.code as string,
            dataSourceOptions,
        };
        return this.objectStore.submitChartSetting(this.id, dto).then(() => {
            this.notificationStore.onSuccess(this.intlStore.intl.formatMessage({ id: 'common.changesSaved' }));
        });
    }

    getPreviewData(dto: DataSourceOptionsDTO): Promise<ChartFormDataDTO> {
        return this.objectStore.getPreviewData(this.id, dto);
    }

    calculateChartData(values: ChartSettingFormValues): () => void {
        return (): void => {
            const { dataLabelsFieldCode, valuesFieldCode, element, objectTab, objectRegistry, source } = values;
            const registryQuery = this.registryQuery;

            if (dataLabelsFieldCode && element && valuesFieldCode && (objectTab || (objectRegistry && registryQuery))) {
                const previewDTO: DataSourceOptionsDTO = {
                    tabCode: source?.code === ChartSourceType.form ? objectTab?.code : undefined,
                    registry:
                        source?.code === ChartSourceType.registry && objectRegistry && registryQuery
                            ? { entityType: objectRegistry.code, query: registryQuery }
                            : undefined,
                };

                this.getPreviewData(previewDTO).then((data) => {
                    const parseData = parsingSubmissionEditGrid(data, dataLabelsFieldCode, valuesFieldCode, element);
                    this.setChartData(parseData || []);
                    this.setPreviewDataForChart(JSON.stringify(data, null, 2));
                });
            }
        };
    }

    saveChartSettingTitles(dto: TitlesDTO): Promise<void> {
        return this.objectStore.saveChartSettingTitles(this.id, dto).then(this.load);
    }

    getChartSettingTitles(): Promise<TitlesDTO> {
        return this.objectStore.getChartSettingTitles(this.id);
    }

    setChartData(data: ChartData[]): void {
        this.chartData = data;
    }

    setChartVisualSettings(chartVisualSettings: any): void {
        this.chartVisualSettings = chartVisualSettings;
    }

    setRegistryQuery(registryQuery: any): void {
        this.registryQuery = registryQuery;
    }

    setObjectTabs(setObjectTabs: CodeTitle[]): void {
        this.objectTabs = setObjectTabs;
    }

    setSourceCatalog(sourceCatalog: CodeTitle[]): void {
        this.sourceCatalog = sourceCatalog;
    }

    setRegistries(registries: CodeTitle[]): void {
        this.registries = registries;
    }

    setPreviewDataForChart(previewDataForChart: string): void {
        this.previewDataForChart = previewDataForChart;
    }
}
