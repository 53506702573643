import { LocalesTree, ObjectLocales } from '../../../types';
import { createLocalesWithoutCases } from '../../../utils';

const console: ObjectLocales & LocalesTree = {
    ...createLocalesWithoutCases('console'),
    script: 'Script',
    runScript: 'Run script',
};

export default console;
