import { useIntl } from 'react-intl';
import { getIdLocale } from '../formatters';

type UseLocale = [string, string];

export function useLocale(localeId: string, isTitle = true): UseLocale {
    const intl = useIntl();
    // useState вызывает бесконечный рендер
    let messageId = '';

    const message = intl.formatMessage(
        { id: localeId },
        {
            t: (chunks) => {
                const [id, messageText] = getIdLocale(chunks);
                messageId = id;

                return messageText;
            },
        },
    ) as string;

    const finalMessage = isTitle ? message[0].toUpperCase() + message.slice(1) : message;

    return [finalMessage, messageId];
}
