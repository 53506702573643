import { Box, Button, Grid, Link, Typography } from '@mui/material';
import { makeSxStyles } from '@platform/front-ui';
import { Field, Form, Formik } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { EmailField as EmailFieldInj, PasswordField as PasswordFieldInj } from '../../../components';
import { useLoginRedirect, useStore, useYup } from '../../../hooks';
import { LoginDTO } from '../../../types';
import { disableSubmitOnEnterKeyPress } from '../../../utils';

export const useHomePageRightSideSxStyles = makeSxStyles({
    mainContainer: {
        height: '100%',
    },
});

const initialValues: LoginDTO = {
    email: '',
    password: '',
    rememberMe: false,
};

export const HomePageRightSide = observer((): JSX.Element => {
    const [EmailField] = di([EmailFieldInj], HomePageRightSide);
    const [PasswordField] = di([PasswordFieldInj], HomePageRightSide);

    const { personStore, authenticationStore } = useStore();
    const intl = useIntl();
    const { loginModel } = authenticationStore;
    const { loginFailedMessage } = loginModel;
    const { Yup } = useYup();

    const schema = Yup.object().shape({
        email: Yup.string().required().email(),
        password: Yup.string().required(),
    });

    const handleConfirm = (values: LoginDTO): void => {
        loginModel.login(values);
    };

    const [isRedirect, backUrl] = useLoginRedirect();

    return (
        <Grid container justifyContent="center" alignItems="center" sx={useHomePageRightSideSxStyles.mainContainer}>
            <Grid item xs={8}>
                <Grid container justifyContent="center">
                    <Grid item>
                        <Typography variant="h5" className="t-login-title">
                            <Box fontWeight="fontWeightBold">
                                <FormattedMessage id="authentication.loginTitle" />
                            </Box>
                        </Typography>
                    </Grid>
                </Grid>
                <Box pt={8}>
                    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleConfirm}>
                        <Form onKeyDown={disableSubmitOnEnterKeyPress}>
                            <Grid item container spacing={6} direction="column">
                                {loginFailedMessage && (
                                    <Grid item>
                                        <Typography className="t-login-failed-message" color="error">
                                            {loginFailedMessage}
                                        </Typography>
                                    </Grid>
                                )}
                                <Grid item>
                                    <EmailField />
                                </Grid>
                                <Grid item>
                                    <PasswordField newPassword={false} />
                                </Grid>
                                <Grid item>
                                    <Field
                                        component={CheckboxWithLabel}
                                        disabled={false}
                                        name="rememberMe"
                                        Label={{ label: intl.formatMessage({ id: 'authentication.rememberMe' }) }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        className="t-login"
                                        fullWidth
                                        size="large"
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                    >
                                        <FormattedMessage id="authentication.login" />
                                    </Button>
                                </Grid>
                                <Grid item container justifyContent="space-between">
                                    <Grid item>
                                        <Link
                                            className="t-forgot-password"
                                            component={RouterLink}
                                            to={clientRoute.recoveryPassword}
                                            underline="none"
                                        >
                                            <FormattedMessage id="authentication.forgotPassword" />
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <Link
                                            className="t-registration"
                                            component={RouterLink}
                                            to={clientRoute.registration}
                                            underline="none"
                                        >
                                            <FormattedMessage id="authentication.register" />
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    </Formik>
                </Box>
                {isRedirect && <Redirect push to={backUrl || personStore.redirectPath} />}
            </Grid>
        </Grid>
    );
});
