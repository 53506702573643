import { BoxProps, SnackbarProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { useStore } from '../../hooks';
import { Notifications as NotificationsInj } from './Notifications';

export const notificationsOffset = 8;

export const NotificationsWrapper = observer((): JSX.Element => {
    const [Notifications] = di([NotificationsInj], NotificationsWrapper);

    const { notificationStore, stickyElementsStore } = useStore();
    const { model } = notificationStore;
    const { visibleAppBarHeight } = stickyElementsStore;

    const calculatedSnackbarTopOffset = notificationsOffset + visibleAppBarHeight;
    const calculatedSnackbarTopBottomOffset = 2 * notificationsOffset + visibleAppBarHeight;

    const snackbarProps: SnackbarProps = {
        style: { top: `${calculatedSnackbarTopOffset}px`, right: '16px' },
    };
    const snackbarBoxProps: BoxProps = {
        style: { maxHeight: `calc(100vh - ${calculatedSnackbarTopBottomOffset}px)` },
    };

    return <Notifications model={model} snackbarProps={snackbarProps} snackbarBoxProps={snackbarBoxProps} />;
});
