import Editor, { EditorProps } from '@monaco-editor/react';
import { Grid, Typography } from '@mui/material';
import { ResponsivePie } from '@nivo/pie';
import { sxHeight100 } from '@platform/front-core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { monacoDefaultOptions } from '../../../../../../../../constants/monacoDefaultOptions';
import { useGeneralObjectChartSettingsPageContext } from '../../../../../../../../hooks';
import { ErrorBoundary as ErrorBoundaryInj } from '../../../../../../../ErrorBoundary';
import { GeneralObjectChartSettingsPageEmptyChart as GeneralObjectChartSettingsPageEmptyChartInj } from './GeneralObjectChartSettingsPageEmptyChart';

const monacoOptions: EditorProps['options'] = {
    ...monacoDefaultOptions,
    readOnly: true,
};

export const GeneralObjectChartSettingsPageChart = observer((): JSX.Element => {
    const [GeneralObjectChartSettingsPageEmptyChart] = di(
        [GeneralObjectChartSettingsPageEmptyChartInj],
        GeneralObjectChartSettingsPageChart,
    );
    const [ErrorBoundary] = di([ErrorBoundaryInj], GeneralObjectChartSettingsPageChart);

    const { formatMessage } = useIntl();
    const { model } = useGeneralObjectChartSettingsPageContext();
    const { chartVisualSettings, chartData, previewDataForChart, stringifySettings } = model;

    const errorComponent = (
        <GeneralObjectChartSettingsPageEmptyChart
            title={formatMessage({ id: 'objectSettings.visualizationSetWithErrors' })}
        />
    );

    return (
        <Grid container direction="column" sx={sxHeight100} paddingX={3}>
            <Grid item height="50vh">
                {chartData.length ? (
                    <ErrorBoundary key={stringifySettings} replacementComponent={errorComponent}>
                        <ResponsivePie data={chartData} {...chartVisualSettings} />
                    </ErrorBoundary>
                ) : (
                    <GeneralObjectChartSettingsPageEmptyChart />
                )}
            </Grid>
            <Grid item>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Typography variant="subtitle2" fontSize="16px">
                            <FormattedMessage id="objectSettings.visualizationPreview" />
                        </Typography>
                    </Grid>
                    <Grid item height="38vh">
                        <Editor defaultLanguage="json" value={previewDataForChart} options={monacoOptions} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
});
