import { Box, Chip, FormControl, FormHelperText, InputLabel, SnackbarProps } from '@mui/material';
import { makeSxStyles } from '@platform/front-ui';
import { FormikContextType, useFormikContext } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { HTMLProps, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { DropzoneArea } from 'react-mui-dropzone';
import { useFlag, useFormikErrors as useFormikErrorsInj } from '../../../../../hooks';
import { PrintFormCreateModel } from '../../../../../models';
import { FormValues, PrintFormFields, PrintFormSetting } from '../../../../../types';
import { RequiredLabel } from '../../../../RequiredLabel';

const sx = makeSxStyles({
    dropzone: {
        '& [class*="MuiDropzoneArea-root"]': {
            minHeight: 'unset',
        },
    },
});

export type GeneralObjectPFDropzoneProps = {
    model: PrintFormCreateModel;
    acceptedFiles: string[];
    serverError?: string;
};

const alertSnackbarProps: SnackbarProps = {
    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
};

export const GeneralObjectPFDropzone = observer((props: GeneralObjectPFDropzoneProps): JSX.Element => {
    const [useFormikErrors] = di([useFormikErrorsInj], GeneralObjectPFDropzone);

    const { model, acceptedFiles, serverError } = props;
    const intl = useIntl();
    const { fileName: initialFileName, dropFileName } = model;

    const formikContext = useFormikContext<PrintFormSetting>();
    const { values, errors, isSubmitting, setFieldValue, validateField } = formikContext;
    const fieldName = PrintFormFields.file;
    const currentValues = values[fieldName];
    const currentError = errors[fieldName];
    const fileName = initialFileName || currentValues?.name;

    const [isTouched, setIsTouched] = useState<boolean>(false);
    const [isRejected, setIsRejectedTrue, setIsRejectedFalse] = useFlag();

    useEffect(() => {
        if (isSubmitting && !isTouched) {
            setIsTouched(true);
        }
    }, [isSubmitting]);

    const rejectedError = isRejected && intl.formatMessage({ id: 'validation.dropzoneError' });
    const [formError, helperText] = useFormikErrors(
        formikContext as unknown as FormikContextType<FormValues>,
        fieldName,
        serverError,
    );
    const error = rejectedError || (isTouched && currentError) || helperText;

    const onFileChange = ([file]: File[]): void => {
        if (!file) {
            return;
        }
        dropFileName();
        setFieldValue(fieldName, file);
        setIsRejectedFalse();
    };

    const dropInitialFileName = (): void => {
        dropFileName();
        setTimeout(() => {
            validateField(fieldName);
        }, 0);
    };

    const onFileDelete = (): void => {
        initialFileName ? dropInitialFileName() : setFieldValue(fieldName, undefined);

        if (!isTouched) {
            setIsTouched(true);
        }
    };

    const inputProps: HTMLProps<HTMLInputElement> = {
        name: fieldName,
    };

    return (
        <FormControl fullWidth required error={!!error} variant="outlined" sx={sx.dropzone}>
            <InputLabel shrink={true} required={false}>
                <RequiredLabel text={intl.formatMessage({ id: 'objectSettings.printFormFields.file' })} />
            </InputLabel>
            <DropzoneArea
                inputProps={inputProps}
                showAlerts={false}
                useChipsForPreview={true}
                showPreviewsInDropzone={false}
                filesLimit={1}
                dropzoneText={intl.formatMessage({ id: 'common.dropzoneText' })}
                acceptedFiles={acceptedFiles}
                onDrop={onFileChange}
                onChange={onFileChange}
                onDropRejected={setIsRejectedTrue}
                alertSnackbarProps={alertSnackbarProps}
            />
            {fileName && (
                <Box pt={1}>
                    <Chip label={fileName} onDelete={onFileDelete} />
                </Box>
            )}
            <FormHelperText>{error}</FormHelperText>
        </FormControl>
    );
});
