import { TTable } from '@platform/ttable';
import { observer } from 'mobx-react';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { entities, permissions } from '../../../authSchemeConfig';
import { AuthorizationCheck as AuthorizationCheckInj } from '../../../components';
import { useLocale, useStore } from '../../../hooks';
import { ChildTableRow, RegistryCode } from '../../../types';

export type ChildObjectRegistryTableProps = {
    setRowActions: (
        row: ChildTableRow,
        reloadData: () => void,
        toggleIsRowBlocked: () => void,
        rowErrorHandler: () => void,
    ) => JSX.Element;
};

export const ChildObjectRegistryTable = observer((props: ChildObjectRegistryTableProps): JSX.Element => {
    const [AuthorizationCheck] = di([AuthorizationCheckInj], ChildObjectRegistryTable);

    const { setRowActions } = props;
    const { catalogStore, userStore, api, notificationStore } = useStore();
    const { handleMainInfoClientError } = api;
    const handleMainInfoError = handleMainInfoClientError();
    const intl = useIntl();
    const [tableTitle] = useLocale('childObject.plural.accusative');

    return (
        <AuthorizationCheck entityCode={entities.system} permCode={permissions.system.Administration}>
            {(allowed: boolean): JSX.Element => (
                <TTable<ChildTableRow>
                    tableTitle={tableTitle}
                    client={api.client}
                    registryCode={RegistryCode.childObject}
                    fetchCatalog={catalogStore.getCatalog}
                    fetchSelectDataByUrl={catalogStore.getSelectDataByUrl}
                    lang={intl.locale}
                    fetchUserRoleList={userStore.userRoleList}
                    rowActions={setRowActions}
                    isAdmin={allowed}
                    handleMainInfoError={handleMainInfoError}
                    handleCustomError={notificationStore.onError}
                />
            )}
        </AuthorizationCheck>
    );
});
