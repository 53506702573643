import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { getRuTitleInitialValue, MultiLangField, titlesYupSchema } from '@platform/multi-lang-field';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import {
    AutocompleteField as AutocompleteFieldInj,
    IdentifierField as IdentifierFieldInj,
    RequiredLabel as RequiredLabelInj,
    ServerErrorHelper as ServerErrorHelperInj,
} from '../../../components';
import { useAntiDoubleClick, useStore, useYup } from '../../../hooks';
import { ProjectCreateDialogValues, ProjectCreateModel } from '../../../models';
import { projectMessages } from '../../../resources';
import { CreateProjectFields, IdTitle, ModalProps } from '../../../types';
import { disableSubmitOnEnterKeyPress } from '../../../utils';

const initialValues: ProjectCreateDialogValues = {
    titles: getRuTitleInitialValue(),
    number: '',
    category: null,
    portfolio: null,
};

export const ProjectRegistryCreateDialog = observer((props: ModalProps): JSX.Element => {
    const [AutocompleteField] = di([AutocompleteFieldInj], ProjectRegistryCreateDialog);
    const [RequiredLabel] = di([RequiredLabelInj], ProjectRegistryCreateDialog);
    const [ServerErrorHelper] = di([ServerErrorHelperInj], ProjectRegistryCreateDialog);
    const [IdentifierField] = di([IdentifierFieldInj], ProjectRegistryCreateDialog);

    const { isOpen, setIsClosed } = props;
    const rootStore = useStore();
    const intl = useIntl();

    const project = useMemo(() => new ProjectCreateModel(rootStore), [rootStore]);
    const { portfoliosList, identifier, categoriesList, serverErrors } = project;
    const [isPortfolioSelected, setIsPortfolioSelected] = useState<boolean>(false);
    const { dropServerFormErrors, serverFormErrors } = serverErrors;
    const { titles, portfolio, category, number } = CreateProjectFields;

    useEffect(() => {
        project.getPortfolioList();
    }, [project]);

    const { Yup } = useYup();

    const validationSchema = Yup.object({
        titles: titlesYupSchema(Yup, true).min(1),
        number: Yup.string().required(),
        portfolio: Yup.object().nullable().required(),
        category: Yup.object().nullable().required(),
    });

    const onPortfolioChange =
        (setFieldValue: (field: string, value: any) => void): ((portfolio: IdTitle) => void) =>
        (portfolio): void => {
            const isWithObject = Boolean(portfolio && portfolio.id);
            setIsPortfolioSelected(isWithObject);
            setTimeout(() => setFieldValue('category', null), 0);

            if (isWithObject) {
                project.getProjectCreateInfo(portfolio.id).then((number) => {
                    setFieldValue('number', number);
                });
            }
        };

    const onClose = (): void => {
        setIsClosed();
        dropServerFormErrors();
    };

    const onSubmit = (values: ProjectCreateDialogValues): Promise<void> => {
        return project.createProject(values);
    };

    const [isSending, endIcon, handleSubmit] = useAntiDoubleClick(onSubmit);

    const portfolioLabel = <RequiredLabel text={intl.formatMessage({ id: 'portfolio.single.nominative' })} />;
    const categoryLabel = <RequiredLabel text={intl.formatMessage({ id: 'category.single.nominative' })} />;

    return (
        <Dialog fullWidth={true} maxWidth="sm" open={isOpen}>
            <DialogTitle>
                <FormattedMessage {...projectMessages.create} />
            </DialogTitle>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                {({ setFieldValue }): JSX.Element => {
                    return (
                        <Form onKeyDown={disableSubmitOnEnterKeyPress}>
                            <DialogContent>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item>
                                        <MultiLangField
                                            systemLanguage={intl.locale}
                                            fieldName={titles}
                                            ruEngLangs={true}
                                        />
                                        <ServerErrorHelper serverError={serverFormErrors[titles]} />
                                    </Grid>
                                    <Grid item>
                                        <AutocompleteField
                                            fieldName={portfolio}
                                            label={portfolioLabel}
                                            options={portfoliosList}
                                            onChange={onPortfolioChange(setFieldValue)}
                                            serverError={serverFormErrors[portfolio]}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <AutocompleteField
                                            disabled={!isPortfolioSelected}
                                            fieldName={category}
                                            label={categoryLabel}
                                            options={categoriesList}
                                            serverError={serverFormErrors[category]}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <IdentifierField
                                            name={number}
                                            prefix={identifier.prefix}
                                            serverError={serverFormErrors[number]}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button color="primary" onClick={onClose}>
                                    <FormattedMessage id="common.cancel" />
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    disabled={isSending}
                                    endIcon={endIcon}
                                >
                                    <FormattedMessage id="common.save" />
                                </Button>
                            </DialogActions>
                        </Form>
                    );
                }}
            </Formik>
        </Dialog>
    );
});
