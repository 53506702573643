import { ObjectLocales } from '../../../types';

const version: ObjectLocales = {
    single: {
        nominative: 'version',
    },
    plural: {
        nominative: 'versions',
    },
};

export default version;
