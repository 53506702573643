import { Grid } from '@mui/material';
import { Field } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PrintFormSettingsPermissionsFields } from '../../../../../types';

export const GeneralObjectPFAccessModalAdditionalFields = observer((): JSX.Element => {
    const allowEditLabelProps: { label: JSX.Element } = {
        label: <FormattedMessage id="objectSettings.accessFields.generation" />,
    };

    const { allowEdit } = PrintFormSettingsPermissionsFields;

    return (
        <Grid item>
            <Field
                name={allowEdit}
                disabled={false}
                type="checkbox"
                component={CheckboxWithLabel}
                Label={allowEditLabelProps}
            />
        </Grid>
    );
});
