import { FormDTO, FullSubmissionWithAdditionalInfo } from '@platform/formiojs-react';
import { action, makeObservable, observable } from 'mobx';
import { UserStore } from '../../stores';
import { CodeTitle, RolesSettings, RolesSettingsDTO, TotLocale, UserDTO, UserState } from '../../types';

export const UserCardModelProps = {
    userStore: observable,
    id: observable,
    login: observable,
    fullName: observable,
    roles: observable,
    lang: observable,
    status: observable,
    formInfo: observable,
    isLoading: observable,
    load: action.bound,
    loginAsUser: action.bound,
    setRoles: action.bound,
    setUserLang: action.bound,
    setMainFields: action.bound,
    setIsLoading: action.bound,
    saveForm: action.bound,
};

export class UserCardModel {
    private userStore: UserStore;

    id: string;
    login = '';
    fullName = '';
    roles: CodeTitle[] = [];
    lang?: TotLocale;
    status?: UserState;
    formInfo?: FormDTO;
    isLoading = true;

    constructor(userStore: UserStore, id: string) {
        makeObservable(this, UserCardModelProps);
        this.userStore = userStore;
        this.id = id;
    }

    load(): void {
        this.setIsLoading(true);
        this.userStore.loadUser(this.id).then((dto) => {
            this.setMainFields(dto);
            this.setIsLoading(false);
        });
    }

    loginAsUser(): void {
        this.userStore.loginAsUser(this.id);
    }

    setRoles(formValues: RolesSettings): Promise<void> {
        const roles = formValues.roles.map((role) => role.code);
        const dto: RolesSettingsDTO = {
            roles,
        };

        return this.userStore.setRoles(this.id, dto).then(this.load);
    }

    setUserLang(lang: TotLocale): void {
        this.userStore.setUserLang(this.id, lang).then(this.load);
    }

    setMainFields(dto: UserDTO): void {
        this.login = dto.login;
        this.fullName = dto.fullName;
        this.roles = dto.roles;
        this.status = dto.status;
        this.lang = dto.lang;
        this.formInfo = dto.formInfo;
    }

    setIsLoading(isLoading: boolean): void {
        this.isLoading = isLoading;
    }

    saveForm(submissionData: FullSubmissionWithAdditionalInfo): Promise<void> {
        return this.userStore.saveUser(this.id, submissionData).then(this.load);
    }
}
