import { TableBody } from '@mui/material';
import React from 'react';
import { DroppableProvided } from 'react-beautiful-dnd';

export const DroppableTableProvide = (
    tableItems: JSX.Element[],
): ((droppableProvided: DroppableProvided) => JSX.Element) => {
    return (droppableProvided) => (
        <TableBody {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
            {tableItems}
            {droppableProvided.placeholder}
        </TableBody>
    );
};
