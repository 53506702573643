import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useAntiDoubleClick, useGeneralObjectCardContext, useYup } from '../../../../hooks';
import { ServerErrorsModel } from '../../../../models';
import { EditModalProps, Identifier, NumberField } from '../../../../types';
import { disableSubmitOnEnterKeyPress } from '../../../../utils';
import { IdentifierField as IdentifierFieldInj } from '../../../fields';

export type EditGeneralObjectNumberInitialValues = {
    number: Identifier['number'];
};

export type EditGeneralObjectNumberProps = EditModalProps & {
    dialogTitle: string;
    generalObjectEditNumber: (objectId: string, number: string, onSuccess: () => void) => Promise<void>;
};

export const EditGeneralObjectNumber = observer((props: EditGeneralObjectNumberProps): JSX.Element => {
    const [IdentifierField] = di([IdentifierFieldInj], EditGeneralObjectNumber);
    const { dialogTitle, generalObjectEditNumber, isOpen, setIsClosed, objectId, rawValue } = props;
    const { objectModel } = useGeneralObjectCardContext();
    const serverErrors = useMemo(() => new ServerErrorsModel<NumberField>(), []);
    const { dropServerFormErrors, setServerFormErrors, serverFormErrors } = serverErrors;
    const { number } = NumberField;
    const editingValue = rawValue as Identifier;

    const onClose = (): void => {
        setIsClosed();
        dropServerFormErrors();
    };

    const { Yup } = useYup();
    const validationSchema = Yup.object({
        number: Yup.string().required(),
    });

    const initialValues: EditGeneralObjectNumberInitialValues = {
        number: editingValue.number,
    };

    const onSubmit = (values: EditGeneralObjectNumberInitialValues): Promise<void> => {
        dropServerFormErrors();
        return generalObjectEditNumber(objectId, values.number, objectModel.load)
            .then(onClose)
            .catch(setServerFormErrors);
    };

    const [isSending, endIcon, handleSubmit] = useAntiDoubleClick(onSubmit);

    return (
        <Dialog fullWidth={true} maxWidth="sm" open={isOpen}>
            <DialogTitle>{dialogTitle}</DialogTitle>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                enableReinitialize={true}
            >
                <Form onKeyDown={disableSubmitOnEnterKeyPress}>
                    <DialogContent>
                        <IdentifierField
                            name={number}
                            prefix={editingValue.prefix}
                            serverError={serverFormErrors[number]}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={onClose}>
                            <FormattedMessage id="common.cancel" />
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={isSending}
                            endIcon={endIcon}
                        >
                            <FormattedMessage id="common.save" />
                        </Button>
                    </DialogActions>
                </Form>
            </Formik>
        </Dialog>
    );
});
