import { Button, Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useUserPageContext } from '../../../../hooks';
import { usersMessages } from '../../../../resources';

export const UserPageLoginAsButton = observer((): JSX.Element => {
    const { userModel } = useUserPageContext();
    const { loginAsUser } = userModel;

    return (
        <Grid item>
            <Button onClick={loginAsUser} sx={{ whiteSpace: 'nowrap' }} size="medium" variant="contained">
                <FormattedMessage {...usersMessages.loginAsUser} />
            </Button>
        </Grid>
    );
});
