import { AuthorizationCheckQuery, GeneralObject, GeneralObjectObjectFieldName, ResponsiblePermissions } from './types';

export enum SystemEntities {
    system = 'System',
    tab = 'Tab',
    tabSetting = 'TabSetting',
    account = 'Account',
    pfSetting = 'PfSetting',
    preset = 'Preset',
}

export const Entities = { ...GeneralObject, ...SystemEntities };
export type Entities = keyof typeof Entities;

export const entities: Record<Entities, string> = {
    system: 'System',
    account: 'Account',
    category: 'Category',
    portfolio: 'Portfolio',
    project: 'Project',
    childObject: 'ChildObject',
    tab: 'Tab',
    tabSetting: 'TabSetting',
    pfSetting: 'PfSetting',
    preset: 'Preset',
};

const generalObjectPermissions = {
    View: 'View',
    Update: 'Update',
    Delete: 'Delete',
    Syslog: 'Syslog',
    TabSettingManagement: 'TabSettingManagement',
};

const getResponsiblePermissions = (fieldName: GeneralObjectObjectFieldName): ResponsiblePermissions => {
    const upperFieldName = fieldName[0].toUpperCase() + fieldName.slice(1);
    return {
        [fieldName]: {
            view: `${upperFieldName}View`,
            update: `${upperFieldName}Update`,
        },
    };
};

export const responsiblePermissions: Record<GeneralObject, ResponsiblePermissions> = {
    project: {
        ...getResponsiblePermissions(GeneralObjectObjectFieldName.projectCurator),
        ...getResponsiblePermissions(GeneralObjectObjectFieldName.projectManager),
        ...getResponsiblePermissions(GeneralObjectObjectFieldName.projectExecutor),
        ...getResponsiblePermissions(GeneralObjectObjectFieldName.projectMonitor),
        ...getResponsiblePermissions(GeneralObjectObjectFieldName.projectViewer),
    },
    portfolio: {
        ...getResponsiblePermissions(GeneralObjectObjectFieldName.portfolioManager),
        ...getResponsiblePermissions(GeneralObjectObjectFieldName.portfolioViewer),
    },
    childObject: {
        ...getResponsiblePermissions(GeneralObjectObjectFieldName.childObjectExecutor),
    },
    category: {},
};

export const permissions: Record<keyof typeof entities, Record<string, string>> = {
    system: {
        Administration: 'Administration',
        AccountList: 'AccountList',
        PortfolioList: 'PortfolioList',
        ProjectList: 'ProjectList',
        ChildObjectList: 'ChildObjectList',
        CategoryList: 'CategoryList',
        CreateCategory: 'CreateCategory',
        CreatePortfolio: 'CreatePortfolio',
        CreateProject: 'CreateProject',
    },
    account: {
        LoginAs: 'LoginAs',
        Update: 'Update',
        UpdateForm: 'UpdateForm',
        View: 'View',
    },
    category: generalObjectPermissions,
    portfolio: generalObjectPermissions,
    project: generalObjectPermissions,
    childObject: generalObjectPermissions,
    tab: {
        Edit: 'Edit',
        BatchDownloadFiles: 'BatchDownloadFiles',
    },
    tabSetting: {},
    pfSetting: {
        GeneratePf: 'GeneratePf',
    },
    preset: {
        View: 'View',
    },
};

export const permissionsConfig = {
    viewGeneralObject: (objectType: GeneralObject, id: string): AuthorizationCheckQuery => ({
        permCode: permissions[objectType].View,
        entityCode: entities[objectType],
        entityId: id,
    }),
    updateGeneralObject: (objectType: GeneralObject, id: string): AuthorizationCheckQuery => ({
        permCode: permissions[objectType].Update,
        entityCode: entities[objectType],
        entityId: id,
    }),
    deleteGeneralObject: (objectType: GeneralObject, id: string): AuthorizationCheckQuery => ({
        permCode: permissions[objectType].Delete,
        entityCode: entities[objectType],
        entityId: id,
    }),
    createCategoryCopy: {
        entityCode: entities.system,
        permCode: permissions.system.CreateCategory,
    } as AuthorizationCheckQuery,
};

export const authSchemeConfig = { entities, permissions, permissionsConfig };
