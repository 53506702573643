import { TabContext, TabPanel } from '@mui/lab';
import { Grid, Link, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { makeSxStylesWithProps, SxStyles } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import sx from 'mui-sx';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { descriptionPanelWidth, longestAnimationDuration, rootElement, stickyZIndex } from '../../../../constants';
import { useGeneralObjectCardContext, useStore } from '../../../../hooks';
import { InfoTab as InfoTabInj } from '../../../InfoTab';
import { InfoTabs as InfoTabsInj } from '../../../InfoTabs';
import { TotObjectLightContainer as TotObjectLightContainerInj } from '../styled';
import { GeneralObjectDescriptionTabValue as GeneralObjectDescriptionTabValueInj } from './GeneralObjectDescriptionTabValue';

export type UseGeneralObjectDescriptionStylesProps = {
    generalObjectHeaderHeight: number;
    scrollBarWidth: number;
    visibleFooterHeight: number;
};

export const useGeneralObjectDescriptionSxStyles = makeSxStylesWithProps(
    (props: UseGeneralObjectDescriptionStylesProps) => ({
        '@keyframes fadeAnimation': {
            '0%': {
                opacity: 0,
            },
            '30%': {
                opacity: 0,
            },
            '100%': {
                opacity: 1,
            },
        },
        wrapper: {
            padding: '10px 15px',
            width: descriptionPanelWidth,
        },
        fullHeight: {
            height: '100%',
        },
        content: {
            height: '100%',
            animation: '$fadeAnimation',
            animationDuration: `${longestAnimationDuration}ms`,
            animationTimingFunction: (theme) => theme.transitions.easing.sharp,
        },
        infoWrapper: {
            position: 'relative',
        },
        infoInner: {
            position: 'absolute',
            overflowY: 'auto',
            height: '100%',
            top: 0,
            right: 0,
            left: 0,
        },
        minify: {
            position: 'fixed',
            right: `${props.scrollBarWidth}px`,
            borderLeft: (theme) => `1px solid ${theme.variables.palette.hoverInLists}`,
            top: `${props.generalObjectHeaderHeight}px`,
            height: `calc(100% - ${props.generalObjectHeaderHeight}px - ${props.visibleFooterHeight}px)`,
            zIndex: stickyZIndex,
        },
    }),
);

export type GeneralObjectDescriptionProps = {
    closeDescription: () => void;
};

const gridTabsWrapperSx: SxProps<Theme> = { marginBottom: '20px' };

export const GeneralObjectDescription = observer((props: GeneralObjectDescriptionProps): JSX.Element => {
    const [TotObjectLightContainer] = di([TotObjectLightContainerInj], GeneralObjectDescription);
    const [InfoTabs] = di([InfoTabsInj], GeneralObjectDescription);
    const [InfoTab] = di([InfoTabInj], GeneralObjectDescription);
    const [GeneralObjectDescriptionTabValue] = di([GeneralObjectDescriptionTabValueInj], GeneralObjectDescription);

    const { closeDescription } = props;
    const { objectModel } = useGeneralObjectCardContext();
    const { commonDescription, serviceDescription, isWithServiceDescription } = objectModel;
    const { stickyElementsStore } = useStore();
    const { visibleFooterHeight, handleVisibleFooterHeight } = stickyElementsStore;
    const { generalObjectHeaderHeight, generalObjectHeadIsSticky, scrollBarWidth } = stickyElementsStore;
    const infoWrapperRef = useRef<HTMLDivElement>(null);
    const intl = useIntl();

    useEffect(() => {
        rootElement && rootElement.addEventListener('scroll', handleVisibleFooterHeight);
        return () => {
            rootElement && rootElement.removeEventListener('scroll', handleVisibleFooterHeight);
        };
    }, []);

    const sxStyles = useGeneralObjectDescriptionSxStyles({
        generalObjectHeaderHeight,
        scrollBarWidth,
        visibleFooterHeight,
    });

    const [selectedTab, setSelectedTab] = useState<string>('common');

    const handleTabChange = (event: React.SyntheticEvent, newTab: string): void => {
        setSelectedTab(newTab);
    };

    const commonInfoOrLoader =
        isWithServiceDescription !== undefined ? <GeneralObjectDescriptionTabValue fields={commonDescription} /> : null;

    const commonLabel = intl.formatMessage({ id: 'generalObject.descriptionPanel.commonTabLabel' });
    const serviceLabel = intl.formatMessage({ id: 'generalObject.descriptionPanel.serviceTabLabel' });

    const objectLightContainerSxStyles = sx(sxStyles.wrapper as SxStyles, {
        condition: generalObjectHeadIsSticky,
        sx: sxStyles.minify as SxStyles,
    });

    return (
        <TotObjectLightContainer item sx={objectLightContainerSxStyles} ref={infoWrapperRef}>
            <Grid container direction="column" sx={sxStyles.content} wrap="nowrap">
                <Grid container item justifyContent="space-between" wrap="nowrap" sx={gridTabsWrapperSx}>
                    <Grid item>
                        <Typography variant="h3">
                            <FormattedMessage id="generalObject.descriptionPanel.title" />
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Link component="button" underline="none" onClick={closeDescription}>
                            <FormattedMessage id="generalObject.descriptionPanel.close" />
                        </Link>
                    </Grid>
                </Grid>

                <Grid item flexGrow={1}>
                    {isWithServiceDescription ? (
                        <TabContext value={selectedTab}>
                            <Grid container direction="column" sx={sxStyles.fullHeight}>
                                <Grid item>
                                    <InfoTabs onChange={handleTabChange}>
                                        <InfoTab label={commonLabel} value="common" />
                                        <InfoTab label={serviceLabel} value="service" />
                                    </InfoTabs>
                                </Grid>

                                <Grid container item direction="column" flexGrow={1} sx={sxStyles.infoWrapper}>
                                    <Grid item sx={sxStyles.infoInner}>
                                        <TabPanel value="common">
                                            <GeneralObjectDescriptionTabValue fields={commonDescription} />
                                        </TabPanel>
                                        {serviceDescription && (
                                            <TabPanel value="service">
                                                <GeneralObjectDescriptionTabValue fields={serviceDescription} />
                                            </TabPanel>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </TabContext>
                    ) : (
                        commonInfoOrLoader
                    )}
                </Grid>
            </Grid>
        </TotObjectLightContainer>
    );
});
