import { getObjValueByDotProp } from '@platform/multi-lang-field';
import { FormikContextType } from 'formik';
import React, { ReactNode } from 'react';
import { ServerErrorHelper } from '../components';
import { FormValues } from '../types';

export type UseFormikErrorsType = [boolean, JSX.Element];

export function useFormikErrors(
    FormikContext: FormikContextType<FormValues>,
    name: string,
    serverError = '',
    defaultHelperText?: ReactNode,
): UseFormikErrorsType {
    const { touched, errors } = FormikContext;
    const fieldTouched = getObjValueByDotProp(touched, name);
    let fieldErrors = getObjValueByDotProp(errors, name);

    if (typeof fieldErrors === 'object') {
        const firstFieldKey = Object.keys(fieldErrors)[0];
        fieldErrors = fieldErrors[firstFieldKey];
    }

    const helperText =
        fieldTouched && fieldErrors ? (
            fieldErrors
        ) : serverError ? (
            <ServerErrorHelper serverError={serverError} withoutMargin={true} />
        ) : (
            defaultHelperText
        );

    const isWithError = fieldTouched && !!fieldErrors;

    return [isWithError, helperText];
}
