import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { fontStylesMain, redesignTotPalette } from '../../../../theme';

export const SearchStyle: SxProps<Theme> = {
    border: `1px solid ${redesignTotPalette.search.default.border}`,
    borderRadius: '5px',
    padding: '0 10px',

    ...fontStylesMain,

    '& > .MuiInputBase-input::placeholder': {
        color: redesignTotPalette.search.default.placeholder,
        opacity: 1,
    },

    '&:hover': {
        borderColor: redesignTotPalette.search.hover.border,
    },

    '&.Mui-focused': {
        color: redesignTotPalette.search.active.color,
        borderColor: redesignTotPalette.search.active.border,
    },

    '&.Mui-focused > .MuiInputBase-input::placeholder': {
        color: redesignTotPalette.search.active.placeholder,
    },
};

export const SearchIconStyle: SxProps<Theme> = {
    color: redesignTotPalette.search.default.endIcon,
};
