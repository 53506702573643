import { DescendantCreateInfo, Identifier } from '../types';

export const getEmptyIdentifier = (): Identifier => ({
    prefix: '',
    number: '',
});

export const getEmptyCreationInfo = (): DescendantCreateInfo => ({
    identifier: getEmptyIdentifier(),
    categories: [],
});
