import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

export const childObjectMessages = defineMessages({
    allVersions: {
        id: 'childObject.phrases.allVersions',
        values: {
            versions: <FormattedMessage id="version.plural.nominative" />,
        },
    },
});
