import { action, computed, makeObservable, observable } from 'mobx';
import { IdTitle, PersonContact, UserPersonDTO } from '../../types';
import { PersonModel } from './PersonModel';

export const UserNameModelProps = {
    userId: observable,
    name: observable,
    asIdTitle: computed,
    load: action.bound,
};

export class UserNameModel {
    userId = '';
    name = '';

    constructor() {
        makeObservable(this, UserNameModelProps);
        return this;
    }

    get asIdTitle(): IdTitle {
        return {
            id: this.userId,
            title: this.name,
        };
    }

    load(dto: UserPersonDTO | PersonContact, isWithFullName?: boolean): UserNameModel {
        this.userId = dto.userId;
        const person: PersonModel = new PersonModel().load(dto.person);
        this.name = isWithFullName ? person.fullName : person.lastNameWithInitials;
        return this;
    }
}
