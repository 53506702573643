import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { clientRoute } from '../../clientRoute';
import { GeneralObjectMainComponent as GeneralObjectMainComponentInj } from '../../components';
import { GeneralObjectContext } from '../../contexts';
import { useStore } from '../../hooks';
import { RenderCreateDescendantModal, RenderEditModal } from '../../types';
import {
    ChildObjectDescendantChildObjectModal as ChildObjectDescendantChildObjectModalInj,
    EditChildObjectExecutor as EditChildObjectExecutorInj,
    EditChildObjectNumber as EditChildObjectNumberInj,
} from './components';

export const ChildObjectPage = observer((): JSX.Element => {
    const [ChildObjectDescendantChildObjectModal] = di([ChildObjectDescendantChildObjectModalInj], ChildObjectPage);
    const [GeneralObjectMainComponent] = di([GeneralObjectMainComponentInj], ChildObjectPage);
    const [EditChildObjectNumber] = di([EditChildObjectNumberInj], ChildObjectPage);
    const [EditChildObjectExecutor] = di([EditChildObjectExecutorInj], ChildObjectPage);

    const { childObjectStore } = useStore();

    const renderRegistryModals: RenderCreateDescendantModal = {
        childObject: ChildObjectDescendantChildObjectModal,
    };

    const renderEditModals: RenderEditModal = {
        number: EditChildObjectNumber,
        childObjectExecutor: EditChildObjectExecutor,
    };

    const routes = clientRoute[childObjectStore.objectType];
    return (
        <GeneralObjectContext.Provider
            value={{
                isWithSettings: false,
                objectStore: childObjectStore,
                renderRegistryModals,
                renderEditModals,
                routes,
            }}
        >
            <GeneralObjectMainComponent />
        </GeneralObjectContext.Provider>
    );
});
