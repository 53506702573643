import { LocalesTree, ObjectLocales } from '../../../types';
import { createLocalesWithoutCases } from '../../../utils';

const project: ObjectLocales & LocalesTree = {
    ...createLocalesWithoutCases('project', 'projects'),
    createFields: {
        name: 'Name',
        number: 'Number',
    },
    phrases: {
        create: 'Create {project}',
    },
};

export default project;
