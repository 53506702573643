import { AppBar, Box, Grid } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { ModularNavigationWidget } from '@platform/modular-navigation-widget';
import { useFeature } from 'feature-toggle-jsx';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { di } from 'react-magnetic-di';
import { rootElement } from '../../constants';
import { useStore } from '../../hooks';
import { backgroundColorAppBar, borderAppBar } from '../../theme';
import { LogoConfig } from '../../types';
import {
    HeaderLinksList as HeaderLinksListInj,
    HeaderLogo as HeaderLogoInj,
    HelpButton as HelpButtonInj,
    LanguageButton as LanguageButtonInj,
    NotificationButton as NotificationButtonInj,
    UserButton as UserButtonInj,
} from './components';
import { Search } from './components/search/Search';

const StyledAppBar = styled(AppBar)`
    background-color: ${backgroundColorAppBar};
    box-shadow: none;
`;

const logoWrapperSx: SxProps<Theme> = { paddingRight: 12.5, width: 'auto' };
const logoInnerSx: SxProps<Theme> = { paddingRight: 2 };

export const Header = observer((): JSX.Element => {
    const [HeaderLinksList] = di([HeaderLinksListInj], Header);
    const [HeaderLogo] = di([HeaderLogoInj], Header);
    const [HelpButton] = di([HelpButtonInj], Header);
    const [LanguageButton] = di([LanguageButtonInj], Header);
    const [NotificationButton] = di([NotificationButtonInj], Header);
    const [UserButton] = di([UserButtonInj], Header);

    const { personStore, intlStore, modulesStore, stickyElementsStore } = useStore();
    const { loadModulesList } = modulesStore;
    const { handleVisibleAppBarHeightCalculation } = stickyElementsStore;
    const userId = personStore.user.id;

    const [modularNavigation] = useFeature('modularNavigation');
    const [logo, logoConfig] = useFeature('headerLogoImg');

    useEffect(() => {
        rootElement && rootElement.addEventListener('scroll', handleVisibleAppBarHeightCalculation);
        return () => {
            rootElement && rootElement.removeEventListener('scroll', handleVisibleAppBarHeightCalculation);
        };
    }, []);

    useEffect(() => {
        personStore.getInfo();
    }, [personStore, intlStore.locale]);

    return (
        <StyledAppBar color="default" position="static">
            <Box borderBottom={borderAppBar} pr={4} pl={4} pt={1.5} pb={1.5}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Grid container alignItems="center">
                            <Grid container item alignItems="center" sx={logoWrapperSx}>
                                <Grid item sx={logoInnerSx}>
                                    <HeaderLogo logoConfig={logoConfig as LogoConfig} />
                                </Grid>

                                {modularNavigation && userId && (
                                    <Grid item>
                                        <ModularNavigationWidget
                                            loadModulesList={loadModulesList}
                                            locale={intlStore.locale}
                                        />
                                    </Grid>
                                )}
                            </Grid>

                            <Grid item>
                                <Grid container spacing={2.5}>
                                    <HeaderLinksList />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Grid container alignItems="center">
                            <Grid item>
                                <Grid container alignItems="center" spacing={1.5}>
                                    <Grid item>
                                        <Box pr={7.5}>
                                            <Search />
                                        </Box>
                                    </Grid>

                                    <Grid item>
                                        <HelpButton />
                                    </Grid>

                                    <Grid item>
                                        <NotificationButton />
                                    </Grid>

                                    <Grid item>
                                        <UserButton />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Box pl={4}>
                                    <LanguageButton />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </StyledAppBar>
    );
});
