import { Button, Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useFlag, useStore } from '../../../../../hooks';
import { PrintFormSettingsModel } from '../../../../../models';
import { getAnswer } from '../../../../../utils';
import { CommonInfoLine as CommonInfoLineInj } from '../../../../CommonInfoLine';
import { FileDownload as FileDownloadInj } from '../../../../FileDownload';
import { Loader as LoaderInj } from '../../../../Loader';
import { GeneralObjectPFModal as GeneralObjectPFModalInj } from '../general-object-pf-modal';

export type GeneralObjectPFSettingsMainProps = {
    model: PrintFormSettingsModel;
};

export const GeneralObjectPFSettingsMain = observer((props: GeneralObjectPFSettingsMainProps): JSX.Element => {
    const [Loader] = di([LoaderInj], GeneralObjectPFSettingsMain);
    const [FileDownload] = di([FileDownloadInj], GeneralObjectPFSettingsMain);
    const [CommonInfoLine] = di([CommonInfoLineInj], GeneralObjectPFSettingsMain);
    const [GeneralObjectPFModal] = di([GeneralObjectPFModalInj], GeneralObjectPFSettingsMain);

    const { model } = props;
    const { intlStore } = useStore();
    const { titles, code, description, docType, fileFormat, file, required, isLoading, load, printForm, downloadFile } =
        model;
    const title = titles[intlStore.locale];
    const requiredText = getAnswer(required);

    const [isModalOpen, setModalOpen, setModalClosed] = useFlag();
    const fileName = file.filename;
    const downloadFileComponent = fileName && <FileDownload fileName={fileName} downloadFile={downloadFile} />;

    return (
        <Grid container>
            {isLoading ? (
                <Loader />
            ) : (
                <React.Fragment>
                    <Grid container item justifyContent="flex-end">
                        <Button variant="contained" color="primary" onClick={setModalOpen}>
                            <FormattedMessage id="common.edit" />
                        </Button>
                    </Grid>
                    <Grid container item spacing={4}>
                        <CommonInfoLine
                            title={<FormattedMessage id="objectSettings.printFormFields.titles" />}
                            value={title}
                            isColumnDirection={true}
                        />
                        <CommonInfoLine
                            title={<FormattedMessage id="objectSettings.printFormFields.code" />}
                            value={code}
                            isColumnDirection={true}
                        />
                        <CommonInfoLine
                            title={<FormattedMessage id="objectSettings.printFormFields.description" />}
                            value={description}
                            isColumnDirection={true}
                        />
                        <CommonInfoLine
                            title={<FormattedMessage id="objectSettings.printFormFields.docType" />}
                            value={docType?.title}
                            isColumnDirection={true}
                        />
                        <CommonInfoLine
                            title={<FormattedMessage id="objectSettings.printFormFields.fileFormat" />}
                            value={fileFormat.title}
                            isColumnDirection={true}
                        />
                        <CommonInfoLine
                            title={<FormattedMessage id="objectSettings.printFormFields.file" />}
                            value={downloadFileComponent}
                            isColumnDirection={true}
                        />
                        <CommonInfoLine
                            title={<FormattedMessage id="objectSettings.printFormFields.required" />}
                            value={requiredText}
                            isColumnDirection={true}
                        />
                    </Grid>
                    <GeneralObjectPFModal
                        isOpen={isModalOpen}
                        setIsClosed={setModalClosed}
                        onSuccess={load}
                        printForm={printForm}
                    />
                </React.Fragment>
            )}
        </Grid>
    );
});
