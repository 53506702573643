import { GeneralObjectCommonFieldName, LocalesTree } from '../../../types';

const fields: Record<GeneralObjectCommonFieldName, string> = {
    title: 'Наименование',
    number: 'Номер',
    created: 'Дата создания',
    parentObject: 'Родительский объект',
    lifecycle: 'Жизненный цикл',
    form: 'Форма',
    modified: 'Последнее изменение',
};

const generalObject: LocalesTree = {
    confirmDeletionInfoText: 'Вы действительно хотите удалить {object} "{title}"?',
    newObject: 'Новый {descendantObject}',
    settingsBtn: 'Настройка',
    descriptionPanel: {
        title: 'Описание',
        close: 'Свернуть',
        open: 'Развернуть описание',
        commonTabLabel: 'Общее',
        serviceTabLabel: 'Служебное',
    },
    printFormFields: {
        file: 'Файл',
        docType: 'Категория документа',
        status: 'Статус генерации',
        date: 'Дата',
        required: 'Обязательно',
    },
    printFormItemActions: {
        generate: 'Сгенерировать',
        update: 'Обновить',
    },
    printFormsList: 'Печатные формы документов',
    editTitle: 'Изменить наименование',
    editViewer: 'Изменить наблюдателя',
    emptyTabs: 'Объект пустой: контент не настроен',
    withoutAvailableTabs: 'У вас нет доступа к содержимому объекта',
    registryIsNotAvailable: 'У вас нет доступа к данным реестра',
    registryWithError: 'Ошибка в настройке реестра',
    changeLifecycleInfo: 'После замены жизненного цикла проверьте правила доступа ко вкладкам',
    registryIsNotAvailableAdministrationContact: '<a>Обратитесь</a> к администратору системы',
    administrationContact: 'Вы можете <a>Обратиться</a> к администратору системы',
    changeNumber: 'Изменить номер',
    downloadAllFiles: 'Скачать все файлы',
    createCopy: 'Создать копию',
    formNotFound: 'Не найдена форма, используемая в настройках контента',
    fields,
};

export default generalObject;
