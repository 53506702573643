import { Button, Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { useFlag, useGeneralObjectContext, useStore } from '../../../../../../../hooks';
import { PrintFromSettingsListModel } from '../../../../../../../models';
import { GeneralObjectRouteParams } from '../../../../../../../types';
import { GeneralObjectPFModal as GeneralObjectPFModalInj } from '../../../general-object-pf-modal';
import { GeneralObjectTabSettingsPFList as GeneralObjectTabSettingsPFListInj } from './general-object-tab-settings-pf-list';

export const GeneralObjectTabSettingsPF = observer((): JSX.Element => {
    const [GeneralObjectPFModal] = di([GeneralObjectPFModalInj], GeneralObjectTabSettingsPF);
    const [GeneralObjectTabSettingsPFList] = di([GeneralObjectTabSettingsPFListInj], GeneralObjectTabSettingsPF);

    const { intlStore } = useStore();
    const { objectStore } = useGeneralObjectContext();
    const { id, tabId } = useParams<GeneralObjectRouteParams>();

    const model = useMemo(() => new PrintFromSettingsListModel(id, tabId, objectStore), [id, tabId, objectStore]);
    const { load } = model;

    useEffect(() => {
        load();
    }, [intlStore.locale]);

    const [isModalOpen, setModalOpen, setModalClosed] = useFlag();

    return (
        <Grid container item direction="column" spacing={5}>
            <Grid item>
                <Grid container item justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h3" component="h2">
                            <FormattedMessage id="objectSettings.printForms" />
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={setModalOpen}>
                            <FormattedMessage id="objectSettings.addPrintForm" />
                        </Button>
                    </Grid>
                    <GeneralObjectPFModal isOpen={isModalOpen} setIsClosed={setModalClosed} onSuccess={load} />
                </Grid>
            </Grid>
            <Grid item>
                <GeneralObjectTabSettingsPFList model={model} />
            </Grid>
        </Grid>
    );
});
