import { Tab } from '@mui/material';
import { styled } from '@mui/material/styles';
import { transitionDefault } from '../theme';

export const StyledTab = styled(Tab)(
    ({ theme }) => `
    background-color: ${theme.variables.palette.tab.backgroundColor} !important;
    color: ${theme.variables.palette.tab.color} !important;
    margin-right: 12px !important;
    min-height: 36px !important;
    border-radius: 4px !important;
    font-size: 13px !important;
    font-weight: 700 !important;
    opacity: 1 !important;
    padding: 7px 12px 7px !important;
    transition: ${transitionDefault};
    min-width: 150px !important;
    flex-direction: row;

    &:hover,
    &.Mui-selected {
        background-color: ${theme.variables.palette.tab.hoverBackgroundColor} !important;
        color: ${theme.variables.palette.tab.hoverColor} !important;
        transition: ${transitionDefault};
    }

    & > .MuiTab-wrapper {
        display: block;

        & > svg {
            margin-bottom: 0 !important;
            margin-right: 10px !important;
        }
    }
`,
);
