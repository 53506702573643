import Editor from '@monaco-editor/react';
import { Button, Grid, Typography } from '@mui/material';
import { CodeTitle } from '@platform/front-utils';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { monacoDefaultOptions } from '../../../../../../../../constants/monacoDefaultOptions';
import { useAntiDoubleClick, useGeneralObjectChartSettingsPageContext, useYup } from '../../../../../../../../hooks';
import { ChartSettingField, ChartSettingFormValues, ChartSourceType } from '../../../../../../../../types';
import {
    AutocompleteField as AutocompleteFieldInj,
    FieldWithServerError as FieldWithServerErrorInj,
} from '../../../../../../../fields';

const isFormSource = (value: CodeTitle): boolean => {
    return value && value.code === ChartSourceType.form;
};

const isRegistrySource = (value: CodeTitle): boolean => {
    return value && value.code === ChartSourceType.registry;
};

export const GeneralObjectChartSettingsPageForm = observer((): JSX.Element => {
    const [AutocompleteField] = di([AutocompleteFieldInj], GeneralObjectChartSettingsPageForm);
    const [FieldWithServerError] = di([FieldWithServerErrorInj], GeneralObjectChartSettingsPageForm);

    const {
        source: sourceField,
        objectTab: objectTabField,
        objectRegistry: objectRegistryField,
        typeChart: typeChartField,
        dataLabelsFieldCode: dataLabelsFieldCodeField,
        valuesFieldCode: valuesFieldCodeField,
        element: elementField,
    } = ChartSettingField;
    const { Yup } = useYup();

    const { model } = useGeneralObjectChartSettingsPageContext();
    const {
        objectTabs,
        chartTypes,
        sourceCatalog,
        registries,
        changeSettings,
        stringifySettings,
        serverErrors,
        submitChartSetting,
        calculateChartData,
        dataOptions,
        stringifyRegistryQuery,
        changeRegistryQuery,
    } = model;

    const { serverFormErrors } = serverErrors;
    const initialValues: ChartSettingFormValues = {
        source: dataOptions?.source || null,
        element: dataOptions?.element || '',
        dataLabelsFieldCode: dataOptions?.dataLabelsFieldCode || '',
        typeChart: dataOptions?.typeChart || null,
        objectTab: dataOptions?.tab || null,
        valuesFieldCode: dataOptions?.valuesFieldCode || '',
        objectRegistry: dataOptions?.registry || null,
    };

    const validationSchema = Yup.object({
        source: Yup.object().nullable().required(),
        element: Yup.string().required(),
        objectTab: Yup.object().nullable().when('source', {
            is: isFormSource,
            then: Yup.object().nullable().required(),
        }),
        objectRegistry: Yup.object().nullable().when('source', {
            is: isRegistrySource,
            then: Yup.object().nullable().required(),
        }),
        dataLabelsFieldCode: Yup.string().required(),
        typeChart: Yup.object().nullable().required(),
        valuesFieldCode: Yup.string().required(),
    });

    const { formatMessage } = useIntl();

    const [isSending, endIcon, actionHandler] = useAntiDoubleClick<ChartSettingFormValues>(submitChartSetting);

    const sourceDataLabel = formatMessage({
        id: 'objectSettings.visualizationSettingsFields.source',
    });
    const objectTabLabel = formatMessage({
        id: 'objectSettings.visualizationSettingsFields.objectTab',
    });
    const registryLabel = formatMessage({
        id: 'objectSettings.visualizationSettingsFields.objectRegistry',
    });
    const elementLabel = formatMessage({
        id: 'objectSettings.visualizationSettingsFields.element',
    });
    const typeChartLabel = formatMessage({
        id: 'objectSettings.visualizationSettingsFields.typeChart',
    });
    const dataLabelsFieldCodeLabel = formatMessage({
        id: 'objectSettings.visualizationSettingsFields.dataLabelsFieldCode',
    });
    const valuesFieldCodeLabel = formatMessage({
        id: 'objectSettings.visualizationSettingsFields.valuesFieldCode',
    });

    return (
        <Formik
            enableReinitialize={true}
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={actionHandler}
        >
            {({ values }) => {
                const { source } = values;
                const sourceCode = source?.code;
                const isSourceForm = sourceCode === ChartSourceType.form;
                const isSourceRegistry = sourceCode === ChartSourceType.registry;
                const handlePreview = calculateChartData(values);
                return (
                    <Form>
                        <Grid container flexDirection="column" spacing={3} padding={5}>
                            <Grid item>
                                <AutocompleteField
                                    fieldName={sourceField}
                                    options={sourceCatalog}
                                    disableClearable={true}
                                    required
                                    label={sourceDataLabel}
                                    serverError={serverFormErrors[sourceField]}
                                />
                            </Grid>
                            {isSourceForm && (
                                <Grid item>
                                    <AutocompleteField
                                        fieldName={objectTabField}
                                        options={objectTabs}
                                        disableClearable={true}
                                        label={objectTabLabel}
                                        required
                                        serverError={serverFormErrors[objectTabField]}
                                    />
                                </Grid>
                            )}
                            {isSourceRegistry && (
                                <Grid item>
                                    <AutocompleteField
                                        fieldName={objectRegistryField}
                                        options={registries}
                                        disableClearable={true}
                                        label={registryLabel}
                                        required
                                        serverError={serverFormErrors[objectRegistryField]}
                                    />
                                </Grid>
                            )}
                            <Grid item>
                                <FieldWithServerError
                                    name={elementField}
                                    fullWidth
                                    label={elementLabel}
                                    variant="outlined"
                                    required
                                    serverError={serverFormErrors[elementField]}
                                />
                            </Grid>
                            <Grid item>
                                <AutocompleteField
                                    fieldName={typeChartField}
                                    options={chartTypes}
                                    disableClearable={true}
                                    label={typeChartLabel}
                                    required
                                    serverError={serverFormErrors[typeChartField]}
                                />
                            </Grid>
                            <Grid item>
                                <FieldWithServerError
                                    name={dataLabelsFieldCodeField}
                                    fullWidth
                                    label={dataLabelsFieldCodeLabel}
                                    variant="outlined"
                                    required
                                    serverError={serverFormErrors[dataLabelsFieldCodeField]}
                                />
                            </Grid>
                            <Grid item>
                                <FieldWithServerError
                                    name={valuesFieldCodeField}
                                    fullWidth
                                    label={valuesFieldCodeLabel}
                                    variant="outlined"
                                    required
                                    serverError={serverFormErrors[valuesFieldCodeField]}
                                />
                            </Grid>
                            <Grid item>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item>
                                        <Typography variant="subtitle2" fontSize="16px">
                                            <FormattedMessage id="objectSettings.visualizationSettingsFields.chartSettings" />
                                        </Typography>
                                    </Grid>
                                    <Grid item height="250px">
                                        <Editor
                                            defaultLanguage="json"
                                            value={stringifySettings}
                                            onChange={changeSettings}
                                            saveViewState={true}
                                            options={monacoDefaultOptions}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {isSourceRegistry && (
                                <Grid item>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item>
                                            <Typography variant="subtitle2" fontSize="16px">
                                                <FormattedMessage id="objectSettings.visualizationSettingsFields.registryQuery" />
                                            </Typography>
                                        </Grid>
                                        <Grid item height="250px">
                                            <Editor
                                                defaultLanguage="json"
                                                value={stringifyRegistryQuery}
                                                onChange={changeRegistryQuery}
                                                saveViewState={true}
                                                options={monacoDefaultOptions}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item>
                                <Grid container spacing={2} justifyContent="flex-end">
                                    <Grid item>
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            type="button"
                                            onClick={handlePreview}
                                        >
                                            <FormattedMessage id="objectSettings.previewVisualization" />
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            disabled={isSending}
                                            endIcon={endIcon}
                                        >
                                            <FormattedMessage id="common.save" />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
});
