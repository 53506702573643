import { LocalesTree, ObjectLocales } from '../../../types';
import { createLocalesWithoutCases } from '../../../utils';

const childObject: ObjectLocales & LocalesTree = {
    ...createLocalesWithoutCases('child object', 'child objects'),
    phrases: {
        allVersions: 'All {versions}',
    },
};

export default childObject;
