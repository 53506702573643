import { Container, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { grey, makeSxStyles } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { di } from 'react-magnetic-di';
import { useStore } from '../../hooks';
import { AppTheme } from '../../types';
import {
    FooterContactInformation as FooterContactInformationInj,
    FooterLogo as FooterLogoInj,
    FooterTypography as FooterTypographyInj,
} from './components';

export const FooterWrapperInj = styled('footer')(({ theme }) => {
    const appTheme = theme as AppTheme;
    return `
        position: relative;
        padding: ${appTheme.spacing(appTheme.variables.palette.footer.padding)} 0;
        background-color: ${grey[100]};
        bottom: 0;
        z-index: 1000;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
        }
    `;
});

const sx = makeSxStyles({
    footerTypography: {
        color: grey[700],
    },
});

export const Footer = observer((): JSX.Element => {
    const [FooterWrapper] = di([FooterWrapperInj], Footer);
    const [FooterTypography] = di([FooterTypographyInj], Footer);
    const [FooterLogo] = di([FooterLogoInj], Footer);
    const [FooterContactInformation] = di([FooterContactInformationInj], Footer);

    const { env, intlStore } = useStore();
    const { totTel, totMail, totCopyrightRu, totCopyrightEn } = env;

    const totCopyright = useMemo((): string | undefined => {
        return intlStore.locale === 'en' ? totCopyrightEn : totCopyrightRu;
    }, [intlStore.locale, totCopyrightEn, totCopyrightRu]);

    return (
        <FooterWrapper>
            <Container maxWidth="lg">
                <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={3}>
                    <Grid item>
                        {/* @ts-ignore */}
                        <FooterTypography component="small" color="primary" sx={sx.footerTypography}>
                            {totCopyright}
                        </FooterTypography>
                    </Grid>
                    <FooterContactInformation phoneNumberText={totTel} emailText={totMail} />
                    <FooterLogo />
                </Grid>
            </Container>
        </FooterWrapper>
    );
});
