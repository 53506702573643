export const orderObjectByKey = <T = object>(objectToOrder: T, compareFn?: (a: string, b: string) => number): T =>
    Object.keys(objectToOrder)
        .sort(compareFn)
        .reduce((obj, key) => {
            obj[key as keyof T] = objectToOrder[key as keyof T];
            return obj;
        }, {} as T);

export const stringifyObjectOrderedByKey = (obj: object): string => {
    return JSON.stringify(orderObjectByKey(obj));
};
