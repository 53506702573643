import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { GeneralObjectField } from '../../../../types';
import { AuthorizationCheck } from '../../../AuthorizationCheck';
import { InfoLine as InfoLineInj } from '../../../InfoLine';
import { GeneralObjectDescriptionFormattedField as GeneralObjectDescriptionFormattedFieldInj } from './GeneralObjectDescriptionFormattedField';

export type GeneralObjectDescriptionTabValueFieldProps = {
    field: GeneralObjectField;
};

export const GeneralObjectDescriptionTabValueField = observer(
    (props: GeneralObjectDescriptionTabValueFieldProps): JSX.Element => {
        const [InfoLine] = di([InfoLineInj], GeneralObjectDescriptionTabValueField);
        const [GeneralObjectDescriptionFormattedField] = di(
            [GeneralObjectDescriptionFormattedFieldInj],
            GeneralObjectDescriptionTabValueField,
        );

        const { field } = props;
        const { id, isObjectField, title: fieldTitle, authorization } = field;
        const intl = useIntl();
        const formattedField = <GeneralObjectDescriptionFormattedField field={field} />;

        let title = fieldTitle;
        if (fieldTitle === undefined) {
            const formattedMessageId = isObjectField ? `${id}.single.nominative` : `generalObject.fields.${id}`;
            title = intl.formatMessage({ id: formattedMessageId });
        }

        const infoLine = <InfoLine key={id} title={title} value={formattedField} field={field} />;

        if (authorization?.view) {
            return <AuthorizationCheck {...authorization.view}>{infoLine}</AuthorizationCheck>;
        }

        return infoLine;
    },
);
