import { titlesToTitlesDTO } from '@platform/multi-lang-field';
import { action, makeObservable, observable } from 'mobx';
import { RootStore } from '../stores';
import {
    GeneralObjectStore,
    NewObjectTabSettings,
    NewObjectTabSettingsDTO,
    ObjectTabSettingsDTO,
    SettingsTabFields,
} from '../types';
import { reorderList } from '../utils';
import { ServerErrorsModel } from './ServerErrorsModel';

export const ObjectSettingsModelProps = {
    rootStore: observable,
    objectStore: observable,
    serverErrors: observable,
    id: observable,
    tabs: observable,
    codeList: observable,
    load: action.bound,
    addNewTab: action.bound,
    deleteTab: action.bound,
    archiveTab: action.bound,
    unarchiveTab: action.bound,
    editTabArchived: action.bound,
    onDeleteTabConfirm: action.bound,
    onArchiveTabConfirm: action.bound,
    onUnarchiveTabConfirm: action.bound,
    changeTabPosition: action.bound,
    reorderTabList: action.bound,
    setTabs: action.bound,
    setCodeList: action.bound,
};

export class ObjectSettingsModel {
    private rootStore: RootStore;
    private objectStore: GeneralObjectStore;

    serverErrors = new ServerErrorsModel<SettingsTabFields>();

    id = '';
    tabs: ObjectTabSettingsDTO[] = [];
    codeList: string[] = [];

    constructor(id: string, rootStore: RootStore, objectStore: GeneralObjectStore) {
        makeObservable(this, ObjectSettingsModelProps);
        this.id = id;
        this.rootStore = rootStore;
        this.objectStore = objectStore;
    }

    load(): void {
        this.objectStore.getTabsSettings(this.id).then((dto: ObjectTabSettingsDTO[]) => {
            this.setTabs(dto);
            this.setCodeList(dto);
        });
    }

    addNewTab(formValues: NewObjectTabSettings, onSuccess?: () => void): Promise<void> {
        this.serverErrors.dropServerFormErrors();

        const newTabDTO: NewObjectTabSettingsDTO = {
            ...formValues,
            titles: titlesToTitlesDTO(formValues.titles),
        };

        return this.objectStore
            .addNewTab(this.id, newTabDTO)
            .then((tab) => {
                this.tabs.push(tab);
                this.codeList.push(tab.code);
                onSuccess && onSuccess();
            })
            .catch(this.serverErrors.setServerFormErrors);
    }

    deleteTab(tabId: string): Promise<void> {
        return this.objectStore.deleteTab(this.id, tabId).then(this.load);
    }

    archiveTab(tabId: string): Promise<void> {
        return this.editTabArchived(tabId, true).then(this.load);
    }

    unarchiveTab(tabId: string): Promise<void> {
        return this.editTabArchived(tabId, false).then(this.load);
    }

    editTabArchived(tabId: string, archive: boolean): Promise<void> {
        return this.objectStore.editTabArchived(this.id, tabId, archive).then(this.load);
    }

    onDeleteTabConfirm(tabId: string, closeTabSettingModal: () => void): () => Promise<void> {
        return () => {
            return this.deleteTab(tabId).finally(closeTabSettingModal);
        };
    }

    onArchiveTabConfirm(tabId: string, closeTabSettingModal: () => void): () => Promise<void> {
        return () => {
            return this.archiveTab(tabId).finally(closeTabSettingModal);
        };
    }

    onUnarchiveTabConfirm(tabId: string, closeTabSettingModal: () => void): () => Promise<void> {
        return () => {
            return this.unarchiveTab(tabId).finally(closeTabSettingModal);
        };
    }

    changeTabPosition(id: string, newPosition: number): void {
        this.objectStore.changeTabPosition(this.id, id, newPosition);
    }

    reorderTabList(sourceIndex: number, destinationIndex: number): void {
        const reordered = reorderList<ObjectTabSettingsDTO>(this.tabs, sourceIndex, destinationIndex);
        this.setTabs(reordered);
    }

    setTabs(tabs: ObjectTabSettingsDTO[]): void {
        this.tabs = tabs;
    }

    setCodeList(tabs: ObjectTabSettingsDTO[]): void {
        this.codeList = tabs.map((tab) => tab.code);
    }
}
