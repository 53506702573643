import { LocalesTree } from '../../../types';

const startPage: LocalesTree = {
    headerLinks: {
        about: 'About product',
        news: 'News',
        documents: 'Documents',
        resources: 'Other resources',
        contacts: 'Contacts',
        radar: 'Radar',
        login: 'Sign in',
        loginAnonymously: 'Login without registration',
        loginAccount: 'Login to your account',
    },
};

export default startPage;
