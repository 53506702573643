import { CircularProgress, Grid, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { SxStyle } from '@platform/front-ui';
import React from 'react';
import { grey } from '../theme';

const wrapperSx: SxStyle = { height: '100%' };
const progressSx: SxStyle = { color: grey[500] };

export const AppLoader = (): JSX.Element => (
    <Grid container alignItems="center" justifyContent="center" sx={wrapperSx}>
        <CircularProgress sx={progressSx} />
    </Grid>
);
