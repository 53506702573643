import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Link, Paper, Popper, PopperProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { UserNameModel } from '../../../../../models';
import { PersonContact } from '../../../../../types';
import { GeneralObjectUserContactInformationInfoLine as GeneralObjectUserContactInformationInfoLineInj } from './GeneralObjectUserContactInformationInfoLine';

export type GeneralObjectUserContactInformationProps = {
    responsibleInfo: PersonContact;
};

const infoPaperSx: SxProps<Theme> = {
    display: 'grid',
    gridTemplateRows: 'repeat(2, auto)',
    gridTemplateColumns: 'repeat(2, auto)',
    rowGap: 2,
    columnGap: 2,
    p: 3,
    maxWidth: '376px',
    wordBreak: 'break-word',
};
const popperSx: SxProps<Theme> = { zIndex: 1000 };
const infoIconSx: SxProps<Theme> = { cursor: 'pointer', height: '0.8em', width: '0.8em' };
const popperModifiers: PopperProps['modifiers'] = [
    {
        name: 'offset',
        enabled: true,
        options: {
            offset: [-64, 0], // фикс налезания поппера на скролл
        },
    },
];

export const GeneralObjectUserContactInformation = observer(
    (props: GeneralObjectUserContactInformationProps): JSX.Element => {
        const [GeneralObjectUserContactInformationInfoLine] = di(
            [GeneralObjectUserContactInformationInfoLineInj],
            GeneralObjectUserContactInformation,
        );

        const { responsibleInfo } = props;
        const intl = useIntl();
        const [popperAnchorEl, setPopperAnchorEl] = useState<null | HTMLElement>(null);

        const showPopper = (event: React.MouseEvent<HTMLElement>): void => {
            setPopperAnchorEl(event.currentTarget);
        };

        const hidePopper = (event: React.MouseEvent<HTMLElement>): void => {
            setPopperAnchorEl(null);
        };

        const { fullName, phoneNumbersValue, emailsValue } = useMemo((): {
            fullName: string;
            phoneNumbersValue?: JSX.Element;
            emailsValue?: JSX.Element;
        } => {
            const { contacts } = responsibleInfo;
            const { phones, emails } = contacts;
            const fullName = new UserNameModel().load(responsibleInfo, true).name;
            const phoneNumbersValue = phones.length ? (
                <React.Fragment>
                    {phones.map((phone, index) => {
                        const href = `tel:${phone}`;
                        const isLast = index === phones.length - 1;
                        return (
                            <React.Fragment key={href}>
                                <Link href={href}>{phone}</Link>
                                {!isLast && ', '}
                            </React.Fragment>
                        );
                    })}
                </React.Fragment>
            ) : undefined;

            const emailsValue = emails.length ? (
                <React.Fragment>
                    {emails.map((email, index) => {
                        const href = `mailto:${email}`;
                        const isLast = index === emails.length - 1;
                        return (
                            <React.Fragment key={email}>
                                <Link href={href}>{email}</Link>
                                {!isLast && ', '}
                            </React.Fragment>
                        );
                    })}
                </React.Fragment>
            ) : undefined;

            return { fullName, phoneNumbersValue, emailsValue };
        }, [responsibleInfo]);

        return (
            <Box display="inline-flex" pt="6px" onMouseEnter={showPopper} onMouseLeave={hidePopper}>
                <InfoOutlinedIcon sx={infoIconSx} />
                <Popper
                    id="person-contact-information"
                    open={!!popperAnchorEl}
                    anchorEl={popperAnchorEl}
                    sx={popperSx}
                    modifiers={popperModifiers}
                >
                    <Paper sx={infoPaperSx}>
                        <GeneralObjectUserContactInformationInfoLine
                            title={intl.formatMessage({ id: 'userInfo.name' })}
                            value={fullName}
                        />
                        <GeneralObjectUserContactInformationInfoLine
                            title={intl.formatMessage({ id: 'userInfo.phoneNumber' })}
                            value={phoneNumbersValue}
                        />
                        <GeneralObjectUserContactInformationInfoLine
                            title={intl.formatMessage({ id: 'userInfo.email' })}
                            value={emailsValue}
                        />
                    </Paper>
                </Popper>
            </Box>
        );
    },
);
