import { TitlesDTO } from '@platform/multi-lang-field';
import { action, makeObservable, observable } from 'mobx';
import { RootStore } from '../stores';
import { GeneralObjectStore, TabSettingsDTO } from '../types';

export const TabSettingsModelProps = {
    rootStore: observable,
    objectStore: observable,
    tabId: observable,
    title: observable,
    objectId: observable,
    load: action.bound,
    loadTitles: action.bound,
    saveTitles: action.bound,
    setMainFields: action.bound,
};

export class TabSettingsModel {
    private rootStore: RootStore;
    private objectStore: GeneralObjectStore;

    tabId = '';
    title = '';
    objectId = '';

    constructor(objectId: string, tabId: string, rootStore: RootStore, objectStore: GeneralObjectStore) {
        makeObservable(this, TabSettingsModelProps);
        this.tabId = tabId;
        this.objectStore = objectStore;
        this.objectId = objectId;
        this.rootStore = rootStore;
    }

    load(): void {
        this.objectStore.getTabSettings(this.objectId, this.tabId).then(this.setMainFields);
    }

    loadTitles(): Promise<TitlesDTO> {
        return this.objectStore.loadTabTitles(this.objectId, this.tabId);
    }

    saveTitles(dto: TitlesDTO): Promise<void> {
        return this.objectStore.saveTabTitles(this.objectId, this.tabId, dto).then(this.load);
    }

    setMainFields(dto: TabSettingsDTO): void {
        this.title = dto.title;
    }
}
