import CloseIcon from '@mui/icons-material/Close';
import { Alert, AlertColor, AlertProps } from '@mui/lab';
import { Box, BoxProps, Fade, IconButton } from '@mui/material';
import { makeSxStylesWithProps } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { NotificationModel } from '../../models';

export type NotificationProps = {
    model: NotificationModel;
    closeNotification: () => void;
    alertProps?: AlertProps;
    alertBoxProps?: BoxProps;
};

export type UseNotificationStylesProps = {
    severity: AlertColor;
};

export const useNotificationSxStyles = makeSxStylesWithProps((props: UseNotificationStylesProps) => ({
    alert: {
        marginBottom: (theme) => theme.spacing(1),
    },
    textWrapper: {
        wordBreak: 'break-word',
        maxWidth: '400px',
    },
    closeButton: {
        marginTop: '3px',
        height: '22px',
        width: '22px',
        color: (theme) => theme.variables.palette.mainContrast,

        '&:hover': {
            backgroundColor: (theme) => theme.variables.palette.mainContrast,
            color: (theme) => theme.palette[props.severity].main,
        },
    },
}));

export const Notification = observer((props: NotificationProps): JSX.Element => {
    const { model, closeNotification, alertProps = {}, alertBoxProps = {} } = props;
    const { severity, id, title, isOpen } = model;

    const sx = useNotificationSxStyles({ severity });

    const alertClassName = alertProps.className;
    const alertBoxClassName = alertBoxProps.className;

    const alertAction = (
        <IconButton size="small" sx={sx.closeButton} onClick={closeNotification}>
            <CloseIcon fontSize="inherit" />
        </IconButton>
    );

    return (
        <Fade in={isOpen}>
            <Alert
                key={id}
                variant="filled"
                severity={severity}
                action={alertAction}
                {...alertProps}
                sx={sx.alert}
                className={alertClassName}
            >
                <Box {...alertBoxProps} className={alertBoxClassName} sx={sx.textWrapper}>
                    {title}
                </Box>
            </Alert>
        </Fade>
    );
});
