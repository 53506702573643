import { Chip, Grid } from '@mui/material';
import { makeSxStyles } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { entities, permissions } from '../../../../authSchemeConfig';
import {
    AuthorizationCheck as AuthorizationCheckInj,
    CommonInfoLine as CommonInfoLineInj,
    ServerBreadcrumbs as ServerBreadcrumbsInj,
    TitleTypography as TitleTypographyInj,
    TotObjectHeader as TotObjectHeaderInj,
} from '../../../../components';
import { useUserPageContext } from '../../../../hooks';
import { BreadcrumbsEntities } from '../../../../types';
import { UserPageHeaderActions as UserPageHeaderActionsInj } from './user-page-header-actions/UserPageHeaderActions';
import { UserPageLoginAsButton as UserPageLoginAsButtonInj } from './UserPageLoginAsButton';
import { UserPageStatusButton as UserPageStatusButtonInj } from './UserPageStatusButton';

const sx = makeSxStyles({
    firstLine: {
        height: '48px',
    },
    userPageLoginButton: {
        flexShrink: 10,
    },
});

export const UserPageHeader = observer((): JSX.Element => {
    const [TotObjectHeader] = di([TotObjectHeaderInj], UserPageHeader);
    const [ServerBreadcrumbs] = di([ServerBreadcrumbsInj], UserPageHeader);
    const [TitleTypography] = di([TitleTypographyInj], UserPageHeader);
    const [CommonInfoLine] = di([CommonInfoLineInj], UserPageHeader);
    const [UserPageStatusButton] = di([UserPageStatusButtonInj], UserPageHeader);
    const [UserPageLoginAsButton] = di([UserPageLoginAsButtonInj], UserPageHeader);
    const [UserPageHeaderActions] = di([UserPageHeaderActionsInj], UserPageHeader);
    const [AuthorizationCheck] = di([AuthorizationCheckInj], UserPageHeader);

    const { userModel } = useUserPageContext();
    const intl = useIntl();
    const { id, roles, login, status, fullName } = userModel;

    const commonInfoLineTittle = intl.formatMessage({ id: 'user.fields.status' });

    return (
        <TotObjectHeader>
            <Grid container direction="column" spacing={3} sx={{ marginBottom: 2 }}>
                <Grid item>
                    <ServerBreadcrumbs entityId={id} entityType={BreadcrumbsEntities.account} />
                </Grid>

                <Grid container item justifyContent="space-between" wrap="nowrap" spacing={2}>
                    <Grid container item>
                        <Grid
                            container
                            item
                            sx={sx.firstLine}
                            justifyContent="space-between"
                            wrap="nowrap"
                            alignItems="center"
                        >
                            <Grid container item wrap="nowrap" alignItems="center">
                                <Grid item sx={{ marginRight: 10 }}>
                                    <TitleTypography component="h1">{fullName}</TitleTypography>
                                </Grid>

                                <Grid item>
                                    <TitleTypography color="primary">{login}</TitleTypography>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <UserPageHeaderActions />
                            </Grid>
                        </Grid>

                        <Grid container item spacing={4} wrap="nowrap" alignItems="center">
                            <Grid item>
                                <TitleTypography>
                                    <FormattedMessage id="user.fields.roles" />
                                </TitleTypography>
                            </Grid>

                            <Grid container item spacing={2}>
                                {roles.map((role) => (
                                    <Grid item key={role.code}>
                                        <Chip label={role.title} variant="outlined" />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container item sx={sx.userPageLoginButton}>
                        <Grid container item sx={sx.firstLine} alignItems="center">
                            <CommonInfoLine title={commonInfoLineTittle} value={status && status.title} />
                        </Grid>

                        <Grid container item spacing={1.5} wrap="nowrap">
                            <AuthorizationCheck
                                entityCode={entities.account}
                                permCode={permissions.account.LoginAs}
                                entityId={id}
                            >
                                <UserPageLoginAsButton />
                            </AuthorizationCheck>
                            {status && <UserPageStatusButton userStatusCode={status.code} />}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </TotObjectHeader>
    );
});
