import axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';
import { History } from 'history';
import { action, computed, makeObservable, observable } from 'mobx';
import { di } from 'react-magnetic-di';
import { apiConfigs } from '../apiConfigs';
import { AuthStatus, AxiosErrorHandler, ResponseErrorDTO } from '../types';
import { createMainInfoErrorHandler } from '../utils';
import { NotificationStore } from './NotificationStore';
import { RootStore } from './RootStore';

export const ApiStoreProps = {
    rootStore: observable,
    history: observable,
    authStatus: observable,
    client: observable,
    notificationStore: computed,
    userActionClient: action.bound,
    userActionClientCatchHandler: action.bound,
    mainInfoClient: action.bound,
    handleMainInfoClientError: action.bound,
    authInterceptor: action.bound,
    authVerify: action.bound,
    setAuthStatus: action.bound,
};

export class ApiStore {
    private rootStore: RootStore;
    private history: History;
    authStatus: AuthStatus = AuthStatus.pending;
    client: AxiosInstance;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.history = rootStore.history;
        this.client = axios.create({
            baseURL: this.rootStore.env.apiUrl,
            withCredentials: true,
        });
        this.client.interceptors.response.use((_) => _, this.authInterceptor.bind(this));
        makeObservable(this, ApiStoreProps);
    }

    private get notificationStore(): NotificationStore {
        return this.rootStore.notificationStore;
    }

    userActionClient(
        config: AxiosRequestConfig,
        fieldsList: string[] = [],
        statusesToIgnore: number[] = [],
    ): AxiosPromise {
        return this.client(config).catch((error) =>
            this.userActionClientCatchHandler(error, fieldsList, statusesToIgnore),
        );
    }

    userActionClientCatchHandler(error: any, fieldsList: string[] = [], statusesToIgnore: number[] = []): AxiosPromise {
        const responseError: string | ResponseErrorDTO[] | undefined = error?.response && error.response.data;
        const responseStatus: number | undefined = error?.response && error.response.status;
        const errorsList: Record<string, string> = {};

        if (!statusesToIgnore.includes(responseStatus as number)) {
            if (Array.isArray(responseError)) {
                responseError.map((errorDTO) => {
                    const errorField = errorDTO.field;

                    if (errorField && fieldsList.indexOf(errorField) !== -1) {
                        errorsList[errorField] = errorDTO.message;
                    } else {
                        this.notificationStore.onError(errorDTO);
                    }
                });
            } else {
                this.notificationStore.onError(responseError || error);
            }
        }

        const shouldRejectOriginalError = !Object.keys(errorsList).length;
        return Promise.reject(shouldRejectOriginalError ? error : errorsList);
    }

    mainInfoClient(config: AxiosRequestConfig, isShouldUseCustomHandler = false): AxiosPromise {
        return this.client(config).catch(this.handleMainInfoClientError(isShouldUseCustomHandler)) as AxiosPromise;
    }

    handleMainInfoClientError(isShouldUseCustomHandler = false): AxiosErrorHandler {
        const createdHandler = createMainInfoErrorHandler(this.history, this.userActionClientCatchHandler);
        return createdHandler(isShouldUseCustomHandler);
    }

    authInterceptor(error: any): void {
        const response = error.response || {};
        if (response.status === 401) {
            this.setAuthStatus(AuthStatus.unauthorized);
        }
        throw error;
    }

    async authVerify(): Promise<void> {
        this.setAuthStatus(AuthStatus.pending);
        await this.client(apiConfigs.authorizationCheck([])).then((r) => r);
        this.setAuthStatus(AuthStatus.ok);
    }

    setAuthStatus(status: AuthStatus): void {
        this.authStatus = status;
    }
}

export const getApiStore = (): any => {
    const [_Api] = di([ApiStore], getApiStore);
    return _Api;
};
