import { action, computed, makeObservable, observable } from 'mobx';
import { PersonDTO } from '../../types';

export const PersonModelProps = {
    uuid: observable,
    userId: observable,
    lastName: observable,
    firstName: observable,
    middleName: observable,
    lastNameWithInitials: computed,
    fullName: computed,
    load: action.bound,
};

export class PersonModel {
    uuid = '';
    userId = '';
    lastName = '';
    firstName = '';
    middleName?: string;

    constructor() {
        makeObservable(this, PersonModelProps);
    }

    get lastNameWithInitials(): string {
        const { firstName, lastName, middleName } = this;
        return `${lastName} ${firstName ? firstName[0] + '. ' : ''}${middleName ? middleName[0] + '.' : ''}`;
    }

    get fullName(): string {
        const { firstName, lastName, middleName } = this;
        let fullName = `${lastName} ${firstName}`;

        if (middleName) {
            fullName += ` ${middleName}`;
        }

        return fullName;
    }

    load(dto: PersonDTO): PersonModel {
        this.uuid = dto.uuid;
        this.userId = dto.userId;
        this.lastName = dto.lastName;
        this.firstName = dto.firstName;
        this.middleName = dto.middleName;
        return this;
    }
}
