import { action, makeObservable, observable } from 'mobx';
import { GeneralObjectStore, TransitionDTO } from '../types';

export const ObjectTransitionsModelProps = {
    objectStore: observable,
    id: observable,
    transitions: observable,
    selectedTransition: observable,
    isLoaded: observable,
    load: action.bound,
    lifeCycleTransition: action.bound,
    setTransitions: action.bound,
    setSelectedTransition: action.bound,
};

export class ObjectTransitionsModel {
    private objectStore: GeneralObjectStore;

    id = '';
    transitions: TransitionDTO[] = [];
    selectedTransition?: TransitionDTO;
    isLoaded = false;

    constructor(id: string, objectStore: GeneralObjectStore) {
        makeObservable(this, ObjectTransitionsModelProps);
        this.id = id;
        this.objectStore = objectStore;
    }

    load(): void {
        this.objectStore.getTransitions(this.id).then(this.setTransitions);
    }

    lifeCycleTransition(onSuccess?: () => void): Promise<void> {
        return this.objectStore.transitionToNextLifeCycleStep(this.selectedTransition?.id || '', this.id).then(() => {
            this.load();
            onSuccess && onSuccess();
        });
    }

    setTransitions(transitions: TransitionDTO[]): void {
        this.transitions = transitions;
        this.isLoaded = true;
    }

    setSelectedTransition(transition: TransitionDTO) {
        this.selectedTransition = transition;
    }
}
