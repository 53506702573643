import { Location } from 'history';
import { computed, makeObservable } from 'mobx';
import { di } from 'react-magnetic-di';
import { match as Match } from 'react-router-dom';
import { entities, permissions } from '../authSchemeConfig';
import { clientRoute } from '../clientRoute';
import { AuthorizationCheckQuery } from '../types';

export type AppHeaderLinkData = {
    key: string;
    to: string;
    messageID: string;
    query?: AuthorizationCheckQuery;
    isActiveCallback?: (match: Match, location: Location) => boolean;
};

export type AllowedLink = {
    link: AppHeaderLinkData;
    allowed: boolean;
};

const mainLinks: AppHeaderLinkData[] = [
    {
        key: clientRoute.categories,
        to: clientRoute.categories,
        messageID: 'category.plural.nominative',
        query: {
            entityCode: entities.system,
            permCode: permissions.system.CategoryList,
        },
    },
    {
        key: clientRoute.portfolios,
        to: clientRoute.portfolios,
        messageID: 'portfolio.plural.nominative',
        query: {
            entityCode: entities.system,
            permCode: permissions.system.PortfolioList,
        },
    },
    {
        key: clientRoute.projects,
        to: clientRoute.projects,
        messageID: 'project.plural.nominative',
        query: {
            entityCode: entities.system,
            permCode: permissions.system.ProjectList,
        },
    },
    {
        key: clientRoute.childObjects,
        to: clientRoute.childObjects,
        messageID: 'childObject.plural.nominative',
        query: {
            entityCode: entities.system,
            permCode: permissions.system.ChildObjectList,
        },
    },
    {
        key: clientRoute.users,
        to: clientRoute.users,
        messageID: 'user.plural.nominative',
        query: {
            entityCode: entities.system,
            permCode: permissions.system.AccountList,
        },
    },
    {
        key: clientRoute.console,
        to: clientRoute.console,
        messageID: 'console.single.nominative',
        query: {
            entityCode: entities.system,
            permCode: permissions.system.Administration,
        },
    },
];

export const HeaderLinksStoreProps = {
    mainLinks: computed,
    additionalLinks: computed,
    avgLinkWidth: computed,
};

export class HeaderLinksStore {
    constructor() {
        makeObservable(this, HeaderLinksStoreProps);
    }

    get mainLinks(): AppHeaderLinkData[] {
        return mainLinks;
    }

    get additionalLinks(): AppHeaderLinkData[] {
        return [];
    }

    // На случай если будут особенно длинные ссылки
    // добавляем возможность переопределить для того, чтобы отображение не ломалось
    get avgLinkWidth(): number {
        return 155;
    }
}

export const getHeaderLinksStore = (): any => {
    const [_HeaderLinksStore] = di([HeaderLinksStore], getHeaderLinksStore);
    return _HeaderLinksStore;
};
