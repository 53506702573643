import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { Form, FormApi, FormDTO } from '@platform/formiojs-react';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { di } from 'react-magnetic-di';
import { generatePath, useRouteMatch } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import {
    FormStickyButtons as FormStickyButtonsInj,
    RouteLeavingGuard as RouteLeavingGuardInj,
    StickyFormSaveButtons as StickyFormSaveButtonsInj,
    TotObjectContentInner as TotObjectContentInnerInj,
} from '../../../components';
import { contentInnerPadding } from '../../../constants';
import { useFlag, useId, useStore, useUserPageContext } from '../../../hooks';

export type UserPageMainContentProps = {
    formDTO: FormDTO;
};

const stickyEditButtonSx: SxProps<Theme> = {
    my: 2,
};

export const UserPageMainContent = observer((props: UserPageMainContentProps): JSX.Element => {
    const [FormStickyButtons] = di([FormStickyButtonsInj], UserPageMainContent);
    const [StickyFormSaveButtons] = di([StickyFormSaveButtonsInj], UserPageMainContent);
    const [TotObjectContentInner] = di([TotObjectContentInnerInj], UserPageMainContent);
    const [RouteLeavingGuard] = di([RouteLeavingGuardInj], UserPageMainContent);

    const { formDTO } = props;
    const { intlStore, stickyElementsStore } = useStore();
    const { userModel } = useUserPageContext();
    const { id, saveForm } = userModel;

    const [formApi, setFormApi] = useState<FormApi>();
    const [isReadOnlyForm, setIsReadOnlyFormTrue, setIsReadOnlyFormFalse] = useFlag(true);
    const [formIsChanged, setFormIsChanged] = useState<boolean>(false);
    const isFormEdit = !!useRouteMatch(clientRoute.userEdit);

    useEffect(() => {
        isFormEdit && setIsReadOnlyFormFalse();
    }, []);

    const onCloseForm = (): void => {
        setIsReadOnlyFormTrue();
        setFormIsChanged(false);
    };

    const { summaryStickyOffsetRight } = stickyElementsStore;
    const stickyOffsetRight = summaryStickyOffsetRight + contentInnerPadding;
    const containerId = useId('userPageForm');
    const containerSelector = `#${containerId}`;

    const onSubmit = async (): Promise<void> => {
        if (formApi) {
            return saveForm(formApi.getSubmissionWithAdditionalInfo());
        }
        return Promise.reject();
    };

    return (
        <TotObjectContentInner container item direction="column" id={containerId}>
            {isReadOnlyForm && (
                <FormStickyButtons
                    stickyOffsetRight={stickyOffsetRight}
                    containerSelector={containerSelector}
                    onEditForm={setIsReadOnlyFormFalse}
                    formEditPath={generatePath(clientRoute.userEdit, { id })}
                    editButtonStyles={stickyEditButtonSx}
                />
            )}
            <Form
                ownerEntityId={id}
                multiLang={true}
                readOnly={isReadOnlyForm}
                onFormReady={setFormApi}
                formDTO={formDTO}
                intlStore={intlStore}
                setFormIsChanged={setFormIsChanged}
            />
            {!isReadOnlyForm && (
                <StickyFormSaveButtons
                    stickyOffsetRight={stickyOffsetRight}
                    containerSelector={containerSelector}
                    formApi={formApi}
                    isWithValidate={true}
                    onCloseForm={onCloseForm}
                    returnPath={generatePath(clientRoute.user, { id })}
                    onSubmit={onSubmit}
                />
            )}
            <RouteLeavingGuard when={formIsChanged} />
        </TotObjectContentInner>
    );
});
