import { action, computed, observable } from 'mobx';
import { AnySchema } from 'yup';
import { useYup } from '../../hooks';
import { RootStore } from '../../stores';
import { SelectedIndex, SystemEntityPermissionFormValues, SystemEntityPermissionSettingsModelType } from '../../types';

export const GeneralObjectSystemEntityAccessModelProps = {
    rootStore: observable,
    validationSchema: observable,
    permissionSettingsModel: observable,
    selectedPermissionIndex: observable,
    initialValues: computed,
    onSubmit: action.bound,
    setSelectedPermissionIndex: action.bound,
};

export abstract class GeneralObjectSystemEntityAccessModel<
    PermissionSettingsModel extends SystemEntityPermissionSettingsModelType,
    FormValues extends SystemEntityPermissionFormValues,
> {
    private rootStore: RootStore;
    validationSchema: AnySchema;
    permissionSettingsModel: PermissionSettingsModel;
    selectedPermissionIndex: SelectedIndex = null;

    protected constructor(
        rootStore: RootStore,
        settingsModel: PermissionSettingsModel,
        additionalFieldsValidationSchema: Record<string, AnySchema>,
    ) {
        this.rootStore = rootStore;
        this.permissionSettingsModel = settingsModel;

        const { Yup } = useYup();
        const validationSchema = Yup.object().shape({
            roleCondition: Yup.object().shape({
                title: Yup.string().required(),
            }),
            ...additionalFieldsValidationSchema,
        });
        this.validationSchema = validationSchema;
    }

    abstract get initialValues(): FormValues;
    abstract onSubmit(values: FormValues): Promise<void>;

    setSelectedPermissionIndex(index: SelectedIndex): void {
        this.selectedPermissionIndex = index;
    }
}
