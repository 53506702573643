import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

export const grey = {
    0: '#FFFFFF',
    25: '#FCFCFD',
    50: '#F9FAFB',
    100: '#F2F4F7',
    200: '#E4E7EC',
    300: '#D0D5DD',
    400: '#98A2B3',
    500: '#667085',
    600: '#475467',
    700: '#344054',
    800: '#1D2939',
    900: '#101828',
};

export const red = {
    50: '#FEF3F2',
    100: '#FEE4E2',
    200: '#FECDCA',
    300: '#FDA29B',
    400: '#F97066',
    500: '#F04438',
    600: '#D92D20',
    700: '#B32318',
    800: '#912018',
    900: '#7A271A',
};

export const blue = {
    25: '#F3FAFF',
    50: '#EFF8FF',
    100: '#D1E9FF',
    200: '#B2DDFF',
    300: '#84CAFF',
    400: '#53B1FD',
    500: '#2E90FA',
    600: '#1570EF',
    700: '#175CD3',
    800: '#1849A9',
    900: '#194185',
};

const greyValues = Object.values(grey);
export type Grey = typeof greyValues[number];

export const fontFamily = "'Inter', sans-serif";
export const black = '#000000';
export const backgroundColorAppBar = '#ffffff';
export const borderAppBar = `1px solid ${grey[300]}`;
export type PaletteType = Grey | typeof black;

export type RedesignTotPalette =
    | 'typography'
    | 'menuButton'
    | 'search'
    | 'iconButton'
    | 'logo'
    | 'userButton'
    | 'actionButton'
    | 'languageButton';

export const redesignTotPalette: Record<RedesignTotPalette, Record<string, Record<string, PaletteType>>> = {
    typography: {
        default: {
            lighterColor: grey[400],
            lightColor: grey[500],
            dark: grey[900],
        },
    },
    menuButton: {
        default: {
            color: grey[600],
        },
        hover: {
            color: grey[900],
        },
        active: {
            color: grey[900],
            background: grey[100],
        },
    },
    search: {
        default: {
            placeholder: grey[400],
            border: grey[200],
            endIcon: grey[600],
        },
        hover: {
            border: grey[400],
        },
        active: {
            placeholder: grey[600],
            border: grey[600],
            color: grey[600],
        },
    },
    iconButton: {
        default: {
            color: grey[400],
        },
        hover: {
            color: grey[600],
        },
        active: {
            color: grey[800],
        },
    },
    logo: {
        default: {
            color: black,
        },
    },
    userButton: {
        default: {
            color: grey[700],
            background: grey[200],
        },
    },
    actionButton: {
        active: {
            background: grey[200],
        },
    },
    languageButton: {
        default: {
            color: grey[700],
        },
        hover: {
            color: grey[900],
        },
    },
};

export const mainFontSize = '14px';

export const fontStylesMain: SxProps<Theme> = {
    fontSize: mainFontSize,
    fontWeight: 500,
    lineHeight: '20px',
    fontFamily,
};

export const fontStylesTitle = {
    ...fontStylesMain,
    fontSize: '16px',
};

export const fontStylesTitleBold = {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '24px',
    fontFamily,
};

export const fontStylesCommon = {
    ...fontStylesMain,
    fontWeight: 400,
};

export const IconStyle: SxProps<Theme> = { fontSize: '24px' };
