import Utils from '@platform/formio/utils';
import { defaultFormsLocale } from '@platform/front-utils';
export function flkValidateComponentIfVisible(component, data, errors) {
    var isComponentVisible = component.checkConditions();
    return !isComponentVisible ? [] : flkValidateComponent(component, data, errors);
}
function flkValidateComponent(component, data, errors) {
    var result = [];
    flkValidate(errors, component, result);
    if (typeof component.getComponents === 'function') {
        return component
            .getComponents()
            .reduce(function (arr, comp) { return arr.concat(flkValidateComponentIfVisible(comp, data, errors)); }, result);
    }
    else {
        return result;
    }
}
export var flkValidateReadonlyPage = function (props) {
    var formApi = props.formApi, errors = props.errors;
    if (formApi) {
        var formData = formApi.getSubmission();
        flkValidateComponentIfVisible(formApi.form(), formData[defaultFormsLocale].data, errors);
    }
};
function flkValidate(errors, component, result) {
    flkValidateErrors(errors, component, result);
    showSuccessResult(component);
}
export function flkValidateErrors(errors, component, result) {
    if (!errors) {
        return;
    }
    var errorMessage = errors[component.component.key];
    if (!errorMessage) {
        return;
    }
    // для обычных полей
    // errorMessage = string
    if (errorMessage && typeof errorMessage !== 'object') {
        var errorObj = {
            component: component.component,
            message: errorMessage,
        };
        component.error = errorObj;
        result.push(errorObj);
        component.addFlkMessage(errorMessage, component.options.name);
    }
    // для полей в эдитгриде и похожих структурах
    // errorMessage = [{ textfield: "message", }, {}]
    if (errorMessage && typeof errorMessage === 'object' && typeof component.getComponents === 'function') {
        var queries = generateQueries(errorMessage, component);
        queries.forEach(function (query) {
            var comp = findComponent(component.components, { key: query.key, 'options.name': query.name });
            var errorObj = {
                component: comp.component,
                message: query.message,
            };
            comp.error = errorObj;
            result.push(errorObj);
            comp.addFlkMessage(query.message, query.id);
        });
    }
    // для полей с multiple value
    // errorMessage = ["message1", "message2"]
    if (errorMessage && Array.isArray(errorMessage) && typeof component.getComponents !== 'function') {
        errorMessage.forEach(function (error, index) {
            if (error) {
                var errorObj = {
                    component: component.component,
                    message: error,
                };
                component.error = errorObj;
                result.push(errorObj);
                component.addFlkMessage(error, component.options.name + "[" + index + "]");
            }
        });
    }
}
function showSuccessResult(component) {
    var _a = component.component, objectVerification = _a.objectVerification, flkChecks = _a.flkChecks, jsonProgram = _a.jsonProgram;
    if (((objectVerification && objectVerification.length) || (flkChecks && flkChecks.length) || !!jsonProgram) &&
        !component.error) {
        if (component.component.multiple) {
            return component.data[component.component.key].forEach(function (value, index) {
                if (!value) {
                    return;
                }
                component.addFlkMessage(null, component.options.name + "[" + index + "]");
            });
        }
        component.addFlkMessage(null, component.options.name);
    }
}
// метод для генерации запроса поиска компонента
// из сабмишена генерируем name, id, key, message компонента
// key и name нужны для различия компонентов в разных строках editGrid и тд
// id для различия в texfield с multiple values
function generateQueries(errorsSubmission, component, name) {
    var componentName = name || "data[" + component.component.key + "]"; // имя из options.name для поиска компонента
    var id = componentName; // id для поиска в DOM, нужно для обнаружения элементов при multiple values
    var lastKey = component.component.key;
    var errorMessage = '';
    var queries = [];
    errorsSubmission.forEach(function (error, index) {
        if (Object.keys(error).length && error) {
            Object.keys(error).forEach(function (key) {
                componentName += "[" + index + "][" + key + "]";
                id = componentName;
                lastKey = key;
                errorMessage = error[lastKey];
                if (Array.isArray(errorMessage)) {
                    errorMessage.forEach(function (value, index) {
                        if (!value) {
                            return;
                        }
                        id += "[" + index + "]";
                        errorMessage = value;
                        queries.push({
                            key: lastKey,
                            name: componentName,
                            id: id,
                            message: errorMessage,
                        });
                        id = componentName;
                    });
                    componentName = "data[" + component.component.key + "]";
                    id = componentName;
                    return;
                }
                queries.push({
                    key: lastKey,
                    name: componentName,
                    id: id,
                    message: errorMessage,
                });
                componentName = "data[" + component.component.key + "]";
                id = componentName;
            });
        }
    });
    return queries;
}
function findComponent(components, query) {
    return Utils.searchComponents(components, query)[0];
}
