import { GeneralObjectFieldValue } from '../types';

export const getGeneralObjectLinkValue = (linkTitle: string, path: string): GeneralObjectFieldValue => {
    return linkTitle
        ? {
              label: linkTitle,
              url: path,
          }
        : '-';
};
