import { Box, Tooltip } from '@mui/material';
import React from 'react';

export type EllipsisBoxProps = {
    text: string;
    correctLength?: number;
};

export const EllipsisBox = (props: EllipsisBoxProps): JSX.Element => {
    const { text, correctLength = 100 } = props;
    if (text.length > correctLength) {
        return (
            <Tooltip title={text}>
                <Box>{text.substring(0, correctLength - 3) + '...'}</Box>
            </Tooltip>
        );
    } else {
        return <React.Fragment>{text}</React.Fragment>;
    }
};
