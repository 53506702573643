import { Grid, Link, Typography } from '@mui/material';
import { useFeature } from 'feature-toggle-jsx';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { Link as RouterLink } from 'react-router-dom';
import { clientRoute } from '../../../../clientRoute';
import { fontFamily, redesignTotPalette } from '../../../../theme';
import { CustomLogoFeature, LogoConfig } from '../../../../types';
import { CustomLogoImg as CustomLogoImgInj } from '../../../CustomLogoImg';

export type HeaderLogoProps = {
    logoConfig: LogoConfig;
};

export const HeaderLogo = (props: HeaderLogoProps): JSX.Element => {
    const { logoConfig } = props;

    const [CustomLogoImg] = di([CustomLogoImgInj], HeaderLogo);
    const { headerLogoImg } = CustomLogoFeature;
    const [isWithHeaderLogoText] = useFeature('headerLogoText');

    const productTitle = (
        <Typography
            fontFamily={fontFamily}
            fontSize="16px"
            lineHeight="22px"
            color={redesignTotPalette.logo.default.color}
            fontWeight="bold"
        >
            <FormattedMessage id="feature.phrases.productTitle" />
        </Typography>
    );

    return (
        <Link component={RouterLink} to={clientRoute.root} underline="none">
            {logoConfig.isEnabled ? (
                <Grid container alignItems="center" wrap="nowrap" spacing={2}>
                    <Grid item>
                        <CustomLogoImg logoFeatureName={headerLogoImg} />
                    </Grid>
                    {isWithHeaderLogoText && <Grid item>{productTitle}</Grid>}
                </Grid>
            ) : (
                productTitle
            )}
        </Link>
    );
};
