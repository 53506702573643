import { IconButton, SvgIcon } from '@mui/material';
import React from 'react';

export const renderMenuButton =
    (moreIcon: JSX.Element): ((onClick: (event: React.MouseEvent<HTMLButtonElement>) => void) => JSX.Element) =>
    (onClick) => {
        return (
            <IconButton className="t-menu-button" onClick={onClick}>
                <SvgIcon>{moreIcon}</SvgIcon>
            </IconButton>
        );
    };
