import { MessageDescriptor } from '@formatjs/intl';
import { action, computed, makeObservable, observable } from 'mobx';
import { createIntl, createIntlCache, IntlCache, IntlShape } from 'react-intl';
import { di } from 'react-magnetic-di';
import { apiConfigs } from '../apiConfigs';
import { defaultLocale, supportedLocales } from '../constants';
import { formatLocale, formatParagraph } from '../formatters';
import { LocaleFormatter, LocalizedMessages, TotLocale } from '../types';
import { ApiStore } from './ApiStore';
import { RootStore } from './RootStore';

const localStorageKey = 'TotAccountingSystemLangLocalization';

export const IntlStoreProps = {
    savedLocale: observable,
    messagesCache: observable,
    intlCache: observable,
    intl: observable,
    rootStore: observable,
    api: observable,
    locale: observable,
    locales: observable,
    defaultRichTextElements: computed,
    messages: computed,
    createIntl: action.bound,
    formatMessageFromDefineMessage: action.bound,
    changeLocale: action.bound,
    setLocalLocalization: action.bound,
};

export class IntlStore {
    private savedLocale: TotLocale | null = localStorage.getItem(localStorageKey) as TotLocale;
    private messagesCache: LocalizedMessages;
    private readonly intlCache: IntlCache;
    intl: IntlShape = {} as IntlShape;

    private rootStore: RootStore;
    private api: ApiStore;
    locale: TotLocale;
    locales: TotLocale[] = supportedLocales; // нужно для MultilangFormEdit

    constructor(rootStore: RootStore, locales: LocalizedMessages) {
        makeObservable(this, IntlStoreProps);
        this.messagesCache = locales;
        this.rootStore = rootStore;
        this.locale = this.savedLocale || defaultLocale;
        document.documentElement.lang = this.locale;
        this.intlCache = createIntlCache();
        this.createIntl();
        this.api = rootStore.api;
    }

    get defaultRichTextElements(): Record<string, LocaleFormatter> {
        return {
            t: formatLocale,
            p: formatParagraph,
        };
    }

    get messages(): Record<string, string> {
        return this.messagesCache[this.locale];
    }

    createIntl(): void {
        this.intl = createIntl(
            {
                locale: this.locale,
                messages: this.messages,
                defaultLocale,
                defaultRichTextElements: this.defaultRichTextElements,
            },
            this.intlCache,
        );
    }

    formatMessageFromDefineMessage(message: MessageDescriptor): string {
        return this.intl.formatMessage(message, message.values as Record<string, string>);
    }

    changeLocale(locale: string): void {
        if (this.api.authStatus === 'ok') {
            this.api.client(apiConfigs.setLang(locale)).then(() => {
                this.setLocalLocalization(locale);
            });
        } else {
            this.setLocalLocalization(locale);
        }
    }

    setLocalLocalization(locale: string): void {
        localStorage.setItem(localStorageKey, locale);
        this.locale = locale as TotLocale;
        this.createIntl();
        document.documentElement.lang = locale;
    }
}

export const getIntlStore = (): any => {
    const [_IntlStore] = di([IntlStore], getIntlStore);
    return _IntlStore;
};
