import { IconButton, IconButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { fontFamily, redesignTotPalette } from '../theme';
import { AppTheme } from '../types';

export type TotIconButtonProps = IconButtonProps & {
    isDarkBackground?: boolean;
};

export const TotIconButton = styled(IconButton)<TotIconButtonProps>(
    ({ theme, isDarkBackground }) => `
       font-family: ${fontFamily};
       width: 32px;
       height: 32px;
       color: ${
           isDarkBackground
               ? (theme as AppTheme)?.variables.palette.footer.textMain
               : redesignTotPalette.iconButton.default.color
       } !important;
    
       &.MuiIconButton-colorSecondary:hover {
           color: ${
               isDarkBackground
                   ? (theme as AppTheme)?.variables.palette.footer.textHover
                   : redesignTotPalette.iconButton.hover.color
           } !important;
       },
    
       &.MuiIconButton-colorSecondary:active {
           color: ${
               isDarkBackground
                   ? (theme as AppTheme)?.variables.palette.footer.textHover
                   : redesignTotPalette.iconButton.active.color
           } !important;
       },
       
       .MuiTouchRipple-root {
           color: ${
               isDarkBackground
                   ? (theme as AppTheme)?.variables.palette.footer.textMain
                   : redesignTotPalette.iconButton.default.color
           };  
       }
`,
);
