import { LocalesTree } from '../../../types';

const authentication: LocalesTree = {
    loginTitle: 'Sign in',
    email: 'Email',
    password: 'Password',
    rememberMe: 'Remember me',
    login: 'Sign in',
    forgotPassword: 'Forgot password?',
    register: 'Sign up',
    loginFailed: 'Wrong email or password',
    registrationTitle: 'Registration',
    firstName: 'First name',
    middleName: 'Middle name',
    lastName: 'Last name',
    termsOfService: 'Terms of service',
    termsIsAccepted: 'I accept the terms and conditions',
    emailAlreadyExists: 'Email is already registered',
    emailNotConfirmed: 'Email not verified',
    userBlocked: 'Your account is unavailable',
    confirmingEmailReminder:
        'We already sent confirm link to your email. Please check your email (link expires in 15 minutes) or try to register again later',
    confirmingEmailSent: 'A confirmation email was sent',
    confirmingEmailExpired: 'Sorry, your link has expired. We have already sent you a new one.',
    confirmingEmailNotFound: 'The link is incorrect or has already been used to navigate earlier.',
    recoveryTitle: 'Recovery password',
    goBackToSignIn: 'Go back to sign in',
    alreadyHaveAccount: 'Already have an account? Sign in',
    sendToken: 'Send',
    recoveryInfo: 'You will receive an email with the link',
    recoveryLinkSent: 'We sent you an email with the link',
    recoveryPasswordFailed: 'This email is not registered or recovery attempts were too frequent',
    newPasswordTitle: 'Change password',
    newPassword: 'New password',
    repeatPassword: 'Repeat password',
    confirm: 'Confirm',
    passwordsMustMatch: 'Passwords must match',
    linkInvalidOrExpired: 'This link is invalid or expired',
    logout: 'Sign out',
    loginEsia: 'Log in with Gosuslugi',
    samlAuth: 'Log in with {title}',
    error: 'An error has occurred',
    alertInfo:
        'For the correct operation of the portal functions, it is recommended to use the following browsers: Mozilla Firefox version 72 and higher, Google Chrome version 78 and higher, Opera version 63 and higher',
    helperInfoMiddleName: 'Optional',
    incorrectLink: 'The link is incorrect or has already been used to navigate earlier.',
    doStepsAgain: 'and try the steps again or contact your Administrator.',
};

export default authentication;
