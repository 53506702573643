import { Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { Route, useParams } from 'react-router-dom';
import { useGeneralObjectCardContext, useGeneralObjectContext } from '../../../../hooks';
import { GeneralObjectRouteParams } from '../../../../types';
import { Loader as LoaderInj } from '../../../Loader';
import {
    GeneralObjectContentChart as GeneralObjectContentChartInj,
    GeneralObjectContentForm as GeneralObjectContentFormInj,
    GeneralObjectContentPF as GeneralObjectContentPFInj,
    GeneralObjectContentRegistry as GeneralObjectRegistryContentInj,
} from './components';
import { generalObjectTabContentSxStyles as sx } from './useGeneralObjectTabContentStyles';

export const GeneralObjectTabContent = observer((): JSX.Element => {
    const [Loader] = di([LoaderInj], GeneralObjectTabContent);
    const [GeneralObjectContentForm] = di([GeneralObjectContentFormInj], GeneralObjectTabContent);
    const [GeneralObjectRegistryContent] = di([GeneralObjectRegistryContentInj], GeneralObjectTabContent);
    const [GeneralObjectContentPF] = di([GeneralObjectContentPFInj], GeneralObjectTabContent);
    const [GeneralObjectContentChart] = di([GeneralObjectContentChartInj], GeneralObjectTabContent);

    const { locale } = useIntl();
    const { tabId } = useParams<GeneralObjectRouteParams>();
    const { objectStore, routes } = useGeneralObjectContext();
    const { isWithTabs, isFormInfoLoaded } = objectStore;
    const { objectModel } = useGeneralObjectCardContext();
    const { loadContent, formDTO, registry, printFormListModel, isContentLoading, charts, loadTabChartSettings } =
        objectModel;

    useEffect(() => {
        if (isWithTabs || isFormInfoLoaded) {
            loadContent(tabId);
        }
    }, [tabId, isFormInfoLoaded, isWithTabs]);

    useEffect((): void => {
        loadTabChartSettings(tabId);
    }, [locale]);

    const renderCharts = charts.map((item) => {
        return <GeneralObjectContentChart key={item.id} chart={item} />;
    });

    const tabOrCard = isWithTabs ? routes.tab : routes.card;

    return (
        <React.Fragment>
            {isContentLoading ? (
                <Loader />
            ) : (
                <Grid container direction="column" spacing={3}>
                    <Route path={tabOrCard} exact>
                        {!!renderCharts.length && (
                            <Grid item sx={sx.fullwidth}>
                                {renderCharts}
                            </Grid>
                        )}
                    </Route>
                    {formDTO && (
                        <Grid item sx={sx.fullwidth}>
                            <GeneralObjectContentForm formDTO={formDTO} />
                        </Grid>
                    )}
                    <Route path={tabOrCard} exact>
                        {registry && (
                            <Grid item sx={sx.fullwidth}>
                                <GeneralObjectRegistryContent registry={registry} />
                            </Grid>
                        )}
                        {tabId && <GeneralObjectContentPF printFormListModel={printFormListModel} />}
                    </Route>
                </Grid>
            )}
        </React.Fragment>
    );
});
