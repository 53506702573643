import { Box, Container, Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { registryPageTableSxStyles } from '../../components';
import { useServerTitleBreadcrumbs } from '../../hooks';
import { BreadcrumbsLocation, PortfolioTableRow } from '../../types';
import {
    PortfolioRegistryActionsBtns as PortfolioRegistryActionsBtnsInj,
    PortfolioRegistryCreateButton as PortfolioRegistryCreateButtonInj,
    PortfolioRegistryTable as PortfolioRegistryTableInj,
} from './components';

export const PortfolioRegistryPage = observer((): JSX.Element => {
    const [PortfolioRegistryActionsBtns] = di([PortfolioRegistryActionsBtnsInj], PortfolioRegistryPage);
    const [PortfolioRegistryTable] = di([PortfolioRegistryTableInj], PortfolioRegistryPage);
    const [PortfolioRegistryCreateButton] = di([PortfolioRegistryCreateButtonInj], PortfolioRegistryPage);

    useServerTitleBreadcrumbs({ location: BreadcrumbsLocation.portfolio });

    const setRowActions = (
        row: PortfolioTableRow,
        reloadData: () => void,
        toggleIsRowBlocked: () => void,
        rowErrorHandler: () => void,
    ): JSX.Element => {
        return (
            <PortfolioRegistryActionsBtns
                portfolioRow={row}
                reloadData={reloadData}
                toggleIsRowBlocked={toggleIsRowBlocked}
                rowErrorHandler={rowErrorHandler}
            />
        );
    };

    return (
        <Container maxWidth={false}>
            <Box pt={5.5} pb={15}>
                <Grid container spacing={5}>
                    <Grid item container justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h1" component="h1">
                                <FormattedMessage id="portfolio.plural.nominative" />
                            </Typography>
                        </Grid>
                        <Grid item>
                            <PortfolioRegistryCreateButton />
                        </Grid>
                    </Grid>
                    <Grid item container direction="column">
                        <Grid item sx={registryPageTableSxStyles.tableWrapper}>
                            <PortfolioRegistryTable setRowActions={setRowActions} />
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
});
