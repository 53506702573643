import { Location } from 'history';
import React, { ReactNode, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { Prompt } from 'react-router-dom';
import { useFlag, useStore } from '../hooks';
import { ConfirmationDialog as ConfirmationDialogInj } from './ConfirmationDialog';

export type RouteLeavingGuardProps = {
    when?: boolean;
    title?: ReactNode;
    message?: ReactNode;
    textMessage?: string;
};

// Компонент для подтверждения изменения роута
export const RouteLeavingGuard = (props: RouteLeavingGuardProps): JSX.Element => {
    const [ConfirmationDialog] = di([ConfirmationDialogInj], RouteLeavingGuard);

    const intl = useIntl();
    const {
        when,
        title = intl.formatMessage({ id: 'confirmLeavePage.title' }),
        message = intl.formatMessage({ id: 'confirmLeavePage.message' }),
        textMessage = intl.formatMessage({ id: 'confirmLeavePage.textMessage' }),
    } = props;

    const [isModalOpen, setModalIsOpen, setModalIsClosed] = useFlag();
    const [lastLocation, setLastLocation] = useState<Location | null>(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState<boolean>(false);

    const { history } = useStore();

    const navigate = (path: string): void => {
        history.push(path);
    };

    const shouldBlockNavigation = (location: Location): boolean => {
        return !!when && !location.search.includes('prompt=false');
    };

    const handleBlockedNavigation = (nextLocation: Location): boolean => {
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
            setModalIsOpen();
            setLastLocation(nextLocation);
            return false;
        }
        return true;
    };

    const handleConfirmNavigationClick = (): Promise<void> => {
        setModalIsClosed();
        setConfirmedNavigation(true);
        return Promise.resolve();
    };

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            navigate(lastLocation.pathname);
        }
    }, [confirmedNavigation, lastLocation, navigate]);

    useEffect(() => {
        const beforeUnloadHandler = (e: BeforeUnloadEvent): string => {
            e.preventDefault();
            e.returnValue = textMessage;
            return textMessage;
        };

        if (when) {
            window.addEventListener('beforeunload', beforeUnloadHandler);
        }

        return (): void => {
            if (when) {
                window.removeEventListener('beforeunload', beforeUnloadHandler);
            }
        };
    }, [when, textMessage]);

    return (
        <React.Fragment>
            <Prompt when={when} message={handleBlockedNavigation} />
            <ConfirmationDialog
                id="prompt-dialog"
                isOpen={isModalOpen}
                onConfirm={handleConfirmNavigationClick}
                setIsClosed={setModalIsClosed}
                title={title}
                message={message}
                keepMounted
            />
        </React.Fragment>
    );
};
