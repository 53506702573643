import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { IconButton, IconButtonProps, Theme, ThemeProvider } from '@mui/material';
import React from 'react';
import { redesignTheme } from '../../../../../../../../theme';

export type GeneralObjectChartListDeleteIconButtonProps = Pick<IconButtonProps, 'onClick' | 'color'>;

export const GeneralObjectChartListDeleteIconButton = (
    props: GeneralObjectChartListDeleteIconButtonProps,
): JSX.Element => {
    const { onClick, color } = props;
    return (
        <ThemeProvider theme={redesignTheme as Theme}>
            <IconButton onClick={onClick} color={color}>
                <DeleteOutlineOutlinedIcon />
            </IconButton>
        </ThemeProvider>
    );
};
