import React from 'react';

export const SearchIcon = (): JSX.Element => (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="64" height="64" rx="8" fill="#FFF3C8" />
        <circle cx="12" cy="11" r="4" fill="#FFDB66" />
        <circle cx="12" cy="25" r="4" fill="#FFDB66" />
        <circle cx="12" cy="39" r="4" fill="#FFDB66" />
        <circle cx="12" cy="53" r="4" fill="#FFDB66" />
        <rect x="19" y="10" width="34" height="3" rx="1" fill="#FFDB66" />
        <rect x="19" y="24" width="38" height="3" rx="1" fill="#FFDB66" />
        <rect x="19" y="38" width="30" height="3" rx="1" fill="#FFDB66" />
        <rect x="19" y="52" width="34" height="3" rx="1" fill="#FFDB66" />
        <circle cx="29.5" cy="31.5" r="14.5" stroke="#4D4D4D" stroke-width="2" />
        <path d="M49 51L40 42" stroke="#4D4D4D" stroke-width="2" stroke-linecap="round" />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M26.4997 20.9718C26.4997 25.5897 22.7561 29.3333 18.1382 29.3333C17.6436 29.3333 17.159 29.2904 16.688 29.208C17.5557 24.1714 21.3401 20.1327 26.2394 18.8936C26.4094 19.558 26.4997 20.2544 26.4997 20.9718ZM26.4988 44.0023C26.4322 39.4418 22.7145 35.7653 18.1381 35.7653C17.8437 35.7653 17.5529 35.7805 17.2664 35.8102C18.7416 39.8888 22.2119 43.0124 26.4988 44.0023Z"
            fill="#FFC400"
        />
    </svg>
);
