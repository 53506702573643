import { Link, Typography } from '@mui/material';
import { makeSxStyles, mergeSxStyles } from '@platform/front-ui';
import React from 'react';
import { grey } from '../../theme';
import { EllipsisBox } from '../EllipsisBox';

export const breadcrumbsLinkSxStyles = makeSxStyles({
    breadcrumbsItem: {
        '&::first-letter': {
            textTransform: 'capitalize',
        },
    },
    link: {
        fontSize: '12px', // 0.75rem
        lineHeight: '16px', // 7rem
        display: 'block',
        maxWidth: 280,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        color: grey[600],

        '&:hover': {
            outline: 'none',
            textDecoration: 'underline',
            color: grey[700],
        },

        '&[class*="Mui-focusVisible"], &:active, &[class*="active"], &.focus': {
            outline: 'none',
            textDecoration: 'underline',
            color: grey[700],
        },

        '&:active, &[class*="active"]': {
            color: grey[800],
            textDecoration: 'none',
        },
    },
});
const sx = breadcrumbsLinkSxStyles;

export type BreadcrumbsLinkProps = {
    to: string;
    isLast: boolean;
    linkText: string;
};

export const BreadcrumbsLink = (props: BreadcrumbsLinkProps): JSX.Element => {
    const { to, isLast, linkText } = props;
    const linkLabel = <EllipsisBox text={linkText} correctLength={35} />;

    const breadcrumbLastLinkSx = mergeSxStyles(sx.link, sx.breadcrumbsItem);

    return (
        <React.Fragment>
            {isLast ? (
                <Typography color="textPrimary" key={to} sx={breadcrumbLastLinkSx}>
                    {linkLabel}
                </Typography>
            ) : (
                linkText && (
                    <Link href={to} key={to} sx={sx.link}>
                        <Typography sx={sx.breadcrumbsItem}>{linkLabel}</Typography>
                    </Link>
                )
            )}
        </React.Fragment>
    );
};
