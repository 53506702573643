import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { clientRoute } from '../../clientRoute';
import { GeneralObjectMainComponent as GeneralObjectMainComponentInj } from '../../components';
import { GeneralObjectContext } from '../../contexts';
import { useStore } from '../../hooks';
import { RenderCreateDescendantModal, RenderEditModal } from '../../types';
import {
    EditProjectCurator as EditProjectCuratorInj,
    EditProjectExecutor as EditProjectExecutorInj,
    EditProjectManager as EditProjectManagerInj,
    EditProjectMonitor as EditProjectMonitorInj,
    EditProjectNumber as EditProjectNumberInj,
    EditProjectViewers as EditProjectViewerInj,
    ProjectDescendantChildObjectModal as ProjectDescendantChildObjectModalInj,
} from './components';

export const ProjectPage = observer((): JSX.Element => {
    const [ProjectDescendantChildObjectModal] = di([ProjectDescendantChildObjectModalInj], ProjectPage);
    const [GeneralObjectMainComponent] = di([GeneralObjectMainComponentInj], ProjectPage);
    const [EditProjectManager] = di([EditProjectManagerInj], ProjectPage);
    const [EditProjectCurator] = di([EditProjectCuratorInj], ProjectPage);
    const [EditProjectMonitor] = di([EditProjectMonitorInj], ProjectPage);
    const [EditProjectViewer] = di([EditProjectViewerInj], ProjectPage);
    const [EditProjectNumber] = di([EditProjectNumberInj], ProjectPage);
    const [EditProjectExecutor] = di([EditProjectExecutorInj], ProjectPage);

    const { projectStore } = useStore();

    const renderEditModals: RenderEditModal = {
        number: EditProjectNumber,
        projectManager: EditProjectManager,
        projectCurator: EditProjectCurator,
        projectExecutor: EditProjectExecutor,
        projectMonitor: EditProjectMonitor,
        projectViewer: EditProjectViewer,
    };

    const routes = clientRoute[projectStore.objectType];

    const renderRegistryModals: RenderCreateDescendantModal = {
        childObject: ProjectDescendantChildObjectModal,
    };

    return (
        <GeneralObjectContext.Provider
            value={{
                isWithSettings: false,
                objectStore: projectStore,
                renderRegistryModals,
                routes,
                renderEditModals,
            }}
        >
            <GeneralObjectMainComponent />
        </GeneralObjectContext.Provider>
    );
});
