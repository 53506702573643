import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useLocation } from 'react-router-dom';
import { entities, permissions } from '../../../../../../authSchemeConfig';
import { printFormContainerAnchor } from '../../../../../../constants';
import { useStore } from '../../../../../../hooks';
import { PrintFormListModel, PrintFormModel } from '../../../../../../models';
import { AuthorizationCheck } from '../../../../../AuthorizationCheck';
import {
    generalObjectTabContentSxStyles,
    generalObjectTabContextInnerSx,
    generalObjectTabContextWrapperSx,
} from '../../useGeneralObjectTabContentStyles';
import { GeneralObjectPFItem as GeneralObjectPFItemInj } from './GeneralObjectPFItem';

export type GeneralObjectContentPFProps = {
    printFormListModel: PrintFormListModel;
};

const sx = generalObjectTabContentSxStyles;

export const GeneralObjectContentPF = observer((props: GeneralObjectContentPFProps): JSX.Element => {
    const [GeneralObjectPFItem] = di([GeneralObjectPFItemInj], GeneralObjectContentPF);

    const { printFormListModel } = props;
    const { printForms, isSomePrintFormGenerating, updateIfSomePrintFormIsGenerating, clearUpdatePfListInterval } =
        printFormListModel;

    const { intlStore: intl, env } = useStore();
    const { pfDelay } = env;
    const location = useLocation();
    const { hash } = location;
    const pfRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (hash === printFormContainerAnchor && printForms.length) {
            setTimeout(() => {
                pfRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'end',
                    inline: 'nearest',
                });
            }, 100);
        }
    }, [hash, printForms]);

    useEffect(() => {
        printFormListModel.load();
    }, [printFormListModel, intl.locale]);

    useEffect(() => {
        updateIfSomePrintFormIsGenerating(pfDelay as number);
        return clearUpdatePfListInterval;
    }, [printFormListModel, isSomePrintFormGenerating, pfDelay]);

    const renderGeneralObjectPFItem =
        (printFormModel: PrintFormModel): ((isGeneratingPfAllowed: boolean) => JSX.Element) =>
        (isGeneratingPfAllowed) => {
            return (
                <GeneralObjectPFItem printFormModel={printFormModel} isGeneratingPfAllowed={isGeneratingPfAllowed} />
            );
        };

    const isPrintFormListEmpty = printFormListModel.printForms.length === 0;

    return (
        <React.Fragment>
            {!isPrintFormListEmpty && (
                <Grid item sx={sx.fullwidth}>
                    <Grid container direction="column" sx={generalObjectTabContextWrapperSx} ref={pfRef}>
                        <Grid item sx={generalObjectTabContextInnerSx}>
                            <Typography variant="h5" component="h3">
                                <FormattedMessage id="generalObject.printFormsList" />
                            </Typography>
                        </Grid>

                        <Grid item sx={sx.fullwidth}>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <Typography>
                                                    <FormattedMessage id="generalObject.printFormFields.file" />
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>
                                                    <FormattedMessage id="generalObject.printFormFields.docType" />
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>
                                                    <FormattedMessage id="generalObject.printFormFields.required" />
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>
                                                    <FormattedMessage id="generalObject.printFormFields.status" />
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>
                                                    <FormattedMessage id="generalObject.printFormFields.date" />
                                                </Typography>
                                            </TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {printForms.map((printFormModel) => (
                                            <AuthorizationCheck
                                                key={printFormModel.pfSettingId}
                                                entityCode={entities.pfSetting}
                                                permCode={permissions.pfSetting.GeneratePf}
                                                entityId={printFormModel.pfSettingId}
                                            >
                                                {renderGeneralObjectPFItem(printFormModel)}
                                            </AuthorizationCheck>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </React.Fragment>
    );
});
