import { GeneralObjectCommonFieldName, LocalesTree } from '../../../types';

const fields: Record<GeneralObjectCommonFieldName, string> = {
    title: 'Title',
    number: 'Number',
    created: 'Created',
    parentObject: 'Parent object',
    lifecycle: 'Lifecycle',
    form: 'Form',
    modified: 'Last modified',
};

const generalObject: LocalesTree = {
    confirmDeletionInfoText: 'Do you actually want to delete the {object} "{title}"?',
    newObject: 'New {descendantObject}',
    settingsBtn: 'Settings',
    descriptionPanel: {
        title: 'Description',
        close: 'Minimize',
        open: 'Expand description',
        commonTabLabel: 'Common data',
        serviceTabLabel: 'Service data',
    },
    printFormFields: {
        file: 'File',
        docType: 'Document category',
        status: 'Generation status',
        date: 'Date',
        required: 'Required',
    },
    printFormItemActions: {
        generate: 'Generate',
        update: 'Update',
    },
    printFormsList: 'Printed forms',
    editTitle: 'Edit title',
    editViewer: 'Edit viewer',
    emptyTabs: 'The object is empty: the content is not configured',
    withoutAvailableTabs: 'You don’t have access to the content',
    registryIsNotAvailable: 'You don’t have permission to access the registry data',
    registryWithError: 'Registry settings failure',
    changeLifecycleInfo: 'After replacing the lifecycle, check the tab access rules',
    registryIsNotAvailableAdministrationContact: 'Please <a>contact</a> the system administrator',
    administrationContact: 'You can <a>Contact</a> the system administrator',
    changeNumber: 'Change number',
    downloadAllFiles: 'Download all files',
    createCopy: 'Create a copy',
    formNotFound: 'The form used in the content settings is missing.',
    fields,
};

export default generalObject;
