import CopyAllIcon from '@mui/icons-material/CopyAll';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { makeSxStyles } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { ConsoleModel } from '../../../models';
import { transitionDefault } from '../../../theme';

export type ResultConsoleControlsProps = {
    model: ConsoleModel;
};

const sx = makeSxStyles({
    buttonWrapper: {
        transition: transitionDefault,
        opacity: 0.5,

        '&:hover ': {
            opacity: 1,
        },
    },
});

export const ResultConsoleControls = observer((props: ResultConsoleControlsProps): JSX.Element => {
    const { model } = props;
    const { clearResult, copyResult } = model;
    const intl = useIntl();

    return (
        <Grid container>
            <Grid item sx={sx.buttonWrapper}>
                <Tooltip title={intl.formatMessage({ id: 'common.clear' })}>
                    <IconButton onClick={clearResult}>
                        <DeleteOutlineIcon />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid item sx={sx.buttonWrapper}>
                <Tooltip title={intl.formatMessage({ id: 'common.copy' })}>
                    <IconButton onClick={copyResult}>
                        <CopyAllIcon />
                    </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    );
});
