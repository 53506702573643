import { CellFormatters, TTable } from '@platform/ttable';
import { observer } from 'mobx-react';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath } from 'react-router-dom';
import { entities, permissions } from '../../../authSchemeConfig';
import { clientRoute } from '../../../clientRoute';
import { AuthorizationCheck as AuthorizationCheckInj, InlineLink as InlineLinkInj } from '../../../components';
import { useLocale, useStore } from '../../../hooks';
import { PortfolioTableRow, RegistryCode } from '../../../types';

export type PortfolioRegistryTableProps = {
    setRowActions: (
        row: PortfolioTableRow,
        reloadData: () => void,
        toggleIsRowBlocked: () => void,
        rowErrorHandler: () => void,
    ) => JSX.Element;
};

export const PortfolioRegistryTable = observer((props: PortfolioRegistryTableProps): JSX.Element => {
    const [AuthorizationCheck] = di([AuthorizationCheckInj], PortfolioRegistryTable);
    const [InlineLink] = di([InlineLinkInj], PortfolioRegistryTable);

    const { setRowActions } = props;
    const { catalogStore, userStore, api, notificationStore } = useStore();
    const { handleMainInfoClientError } = api;
    const handleMainInfoError = handleMainInfoClientError();
    const intl = useIntl();
    const [tableTitle] = useLocale('portfolio.plural.accusative');

    const portfolioRegistryCellFormatters: CellFormatters<PortfolioTableRow> = {
        'customData.categories': function title(p): JSX.Element {
            const categories = p.row.original.customData.categories;
            const categoriesLastIndex = categories.length - 1;
            return (
                <React.Fragment>
                    {categories.map((category, i) => {
                        const { id, title } = category;
                        return (
                            <React.Fragment key={id}>
                                <InlineLink title={title} to={generatePath(clientRoute.category.card, { id })} />
                                {i !== categoriesLastIndex && '; '}
                            </React.Fragment>
                        );
                    })}
                </React.Fragment>
            );
        },
    };

    return (
        <AuthorizationCheck entityCode={entities.system} permCode={permissions.system.Administration}>
            {(allowed: boolean): JSX.Element => (
                <TTable<PortfolioTableRow>
                    tableTitle={tableTitle}
                    client={api.client}
                    registryCode={RegistryCode.portfolio}
                    fetchCatalog={catalogStore.getCatalog}
                    fetchSelectDataByUrl={catalogStore.getSelectDataByUrl}
                    lang={intl.locale}
                    cellFormatters={portfolioRegistryCellFormatters}
                    fetchUserRoleList={userStore.userRoleList}
                    rowActions={setRowActions}
                    isAdmin={allowed}
                    handleMainInfoError={handleMainInfoError}
                    handleCustomError={notificationStore.onError}
                />
            )}
        </AuthorizationCheck>
    );
});
