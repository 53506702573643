import { Avatar, Box, Divider, IconButton, MenuItem, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, useHistory } from 'react-router-dom';
import { entities, permissions } from '../../../../authSchemeConfig';
import { clientRoute } from '../../../../clientRoute';
import { useStore } from '../../../../hooks';
import { usersMessages } from '../../../../resources';
import { AuthorizationCheck as AuthorizationCheckInj } from '../../../AuthorizationCheck';
import { MenuButton as MenuButtonInj } from '../../../buttons/MenuButton';
import { UserButtonStyle } from './UserButtonStyle';

export const UserButton = observer((): JSX.Element => {
    const [MenuButton] = di([MenuButtonInj], UserButton);
    const [AuthorizationCheck] = di([AuthorizationCheckInj], UserButton);
    const { authenticationStore, personStore, api } = useStore();
    const history = useHistory();

    const handleLogout = (hideMenu: () => void) => async (): Promise<void> => {
        await authenticationStore.logout().then(personStore.clearPerson).catch(hideMenu);
        await api.authVerify();
        await personStore.getInfo();
    };

    const renderMenuItems = (withProfile: boolean) => {
        const renderItems = (hideMenu: () => void): ReactNode[] => {
            const onProfileClick = (): void => {
                hideMenu();
                history.push(
                    generatePath(clientRoute.user, {
                        id: personStore.person.userId,
                    }),
                );
            };

            return [
                <Box mb={2} key="person">
                    <MenuItem dense tabIndex={-1} disabled={true} style={{ opacity: 1 }}>
                        <Box pb={2} fontWeight="fontWeightBold">
                            {personStore.person.lastNameWithInitials}
                        </Box>
                    </MenuItem>
                    {withProfile && (
                        <MenuItem dense tabIndex={0} onClick={onProfileClick}>
                            <Typography>
                                <FormattedMessage {...usersMessages.profile} />
                            </Typography>
                        </MenuItem>
                    )}
                </Box>,
                <Box key="divider" mb={2}>
                    <Divider />
                </Box>,
                <MenuItem
                    dense
                    key="logout"
                    component="button"
                    onClick={handleLogout(hideMenu)}
                    tabIndex={0}
                    sx={{ width: '100%' }}
                >
                    <FormattedMessage id="authentication.logout" />
                </MenuItem>,
            ];
        };
        return renderItems;
    };

    const renderButton = (onClick: (event: React.MouseEvent<HTMLElement>) => void): JSX.Element => {
        return (
            <IconButton onClick={onClick}>
                <Avatar sx={UserButtonStyle}>
                    {`${personStore.person.lastName[0]}${personStore.person.firstName[0]}`}
                </Avatar>
            </IconButton>
        );
    };

    return (
        <React.Fragment>
            {personStore.person.userId && (
                <AuthorizationCheck
                    entityCode={entities.account}
                    permCode={permissions.account.View}
                    entityId={personStore.person.userId}
                >
                    {(allowed) => (
                        <MenuButton
                            reloadKey={personStore.person.lastNameWithInitials}
                            renderButton={renderButton}
                            renderMenuItems={renderMenuItems(allowed)}
                        />
                    )}
                </AuthorizationCheck>
            )}
        </React.Fragment>
    );
});
