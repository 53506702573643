import { CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledCircularProgress = styled(CircularProgress)(
    ({ theme }) => `
    width: ${theme.spacing(5)}px !important;
    height: ${theme.spacing(5)}px !important;
`,
);

export const ButtonCircularProgress = styled(CircularProgress)(
    ({ theme }) => `
    width: ${theme.variables.requestPrintForms.actionIconSize}px !important;
    height: ${theme.variables.requestPrintForms.actionIconSize}px !important;
`,
);
