import { Button, Dialog, DialogActions, DialogContent, Portal } from '@mui/material';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { ModalProps } from '../types';
import { TitleTypography } from './typography';

export type InfoDialogProps = ModalProps & {
    message: ReactNode;
};

export const InfoDialog = (props: InfoDialogProps): JSX.Element => {
    const { isOpen, message, setIsClosed } = props;

    return (
        <Portal>
            <Dialog fullWidth={true} maxWidth="xs" open={isOpen}>
                <DialogContent>
                    <TitleTypography>{message}</TitleTypography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={setIsClosed} color="secondary" variant="contained">
                        <FormattedMessage id="common.ok" />
                    </Button>
                </DialogActions>
            </Dialog>
        </Portal>
    );
};
