import { Backdrop, CircularProgress } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { clientRoute } from '../clientRoute';
import { useStore } from '../hooks';
import { AuthStatus } from '../types';

export type AuthenticationProps = {
    children: ReactNode;
    redirectUrl?: string;
    backUrl?: string;
};

export const Authentication = observer((props: AuthenticationProps): JSX.Element => {
    const { children, redirectUrl = clientRoute.login, backUrl } = props;
    const { api } = useStore();
    const location = useLocation();

    const backUrlResult = backUrl
        ? `?backUrl=${backUrl}`
        : location.search.length
        ? location.search
        : `?backUrl=${location.pathname}`;

    useEffect(() => {
        api.authVerify();
    }, [api]);

    const renderComponent = (status: AuthStatus): JSX.Element => {
        switch (status) {
            case AuthStatus.ok:
                return children as JSX.Element;
            case AuthStatus.unauthorized:
                return <Redirect to={{ pathname: redirectUrl, search: backUrlResult }} />;
            default:
                return (
                    <Backdrop open>
                        <CircularProgress />
                    </Backdrop>
                );
        }
    };

    return renderComponent(api.authStatus);
});
