import { action, makeObservable, observable } from 'mobx';
import { apiConfigs } from '../apiConfigs';
import { ApiStore, IntlStore, PersonStore, RootStore } from '../stores';
import { AuthStatus } from '../types';

export enum RegistrationStatus {
    pending = 'pending',
    success = 'success',
    expired = 'expired',
    notFound = 'not-found',
}

export const RegistrationConfirmModelProps = {
    api: observable,
    intl: observable,
    status: observable,
    confirmEmail: action.bound,
};

export class RegistrationConfirmModel {
    api: ApiStore;
    intl: IntlStore;
    personStore: PersonStore;
    status?: RegistrationStatus;

    constructor(rootStore: RootStore) {
        makeObservable(this, RegistrationConfirmModelProps);
        this.api = rootStore.api;
        this.intl = rootStore.intlStore;
        this.personStore = rootStore.personStore;
    }

    async confirmEmail(token?: string): Promise<void> {
        try {
            this.status = RegistrationStatus.pending;
            const confirmEmailDTO = {
                token,
                lang: this.intl.locale,
            };
            await this.api.client(apiConfigs.confirmEmail(confirmEmailDTO));
            await this.personStore.getInfo();
            this.api.setAuthStatus(AuthStatus.ok);
            this.status = RegistrationStatus.success;
        } catch (e: any) {
            if (e.response) {
                if (e.response.status === 410) {
                    this.status = RegistrationStatus.expired;
                }
                if (e.response.status === 404) {
                    this.status = RegistrationStatus.notFound;
                }
            }
        }
    }
}
