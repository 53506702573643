import { ModuleDTO } from '@platform/modular-navigation-widget';
import { action, makeObservable, observable } from 'mobx';
import { di } from 'react-magnetic-di';
import { apiConfigs } from '../apiConfigs';
import { ApiStore } from './ApiStore';
import { RootStore } from './RootStore';

export const ModulesStoreProps = {
    rootStore: observable,
    api: observable,
    loadModulesList: action.bound,
};

export class ModulesStore {
    private rootStore: RootStore;
    private api: ApiStore;

    constructor(rootStore: RootStore) {
        makeObservable(this, ModulesStoreProps);
        this.rootStore = rootStore;
        this.api = rootStore.api;
    }

    loadModulesList(): Promise<ModuleDTO[]> {
        return this.api.client(apiConfigs.loadModulesList).then((r) => r.data);
    }
}

export const getModulesStore = (): any => {
    const [_ModulesStore] = di([ModulesStore], getModulesStore);
    return _ModulesStore;
};
