import { LocalesTree } from '../../../types';

const startPage: LocalesTree = {
    headerLinks: {
        about: 'O продукте',
        news: 'Новости',
        documents: 'Документы',
        resources: 'Другие ресурсы',
        contacts: 'Контакты',
        radar: 'Радар',
        login: 'Войти в систему',
        loginAnonymously: 'Войти без регистрации',
        loginAccount: 'Войти в личный кабинет',
    },
};

export default startPage;
