import { Grid, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { Form, FormApi, FormDTO } from '@platform/formiojs-react';
import downloadFile from 'js-file-download';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { entities, permissions } from '../../../../../../authSchemeConfig';
import { clientRoute } from '../../../../../../clientRoute';
import { contentInnerPadding, tabContentContainerPadding } from '../../../../../../constants';
import {
    useFlag,
    useGeneralObjectCardContext,
    useGeneralObjectContext,
    useId,
    useStore,
} from '../../../../../../hooks';
import { ErrorEntityType, GeneralObjectRouteParams } from '../../../../../../types';
import { fakeScroll, getDownloadAllFilesArchiveName } from '../../../../../../utils';
import { FormStickyButtons as FormStickyButtonsInj } from '../../../../../FormStickyButtons';
import { InfoDialog as InfoDialogInj } from '../../../../../InfoDialog';
import { RouteLeavingGuard as RouteLeavingGuardInj } from '../../../../../RouteLeavingGuard';
import { StickyFormSaveButtons as StickyFormSaveButtonsInj } from '../../../../../StickyFormSaveButtons';

export type GeneralObjectContentFormProps = {
    formDTO: FormDTO;
};

const readonlyStickyButtonsSx: SxProps<Theme> = {
    mb: 2,
    mx: 1,
};

export const GeneralObjectContentForm = observer((props: GeneralObjectContentFormProps): JSX.Element => {
    const [StickyFormSaveButtons] = di([StickyFormSaveButtonsInj], GeneralObjectContentForm);
    const [FormStickyButtons] = di([FormStickyButtonsInj], GeneralObjectContentForm);
    const [InfoDialog] = di([InfoDialogInj], GeneralObjectContentForm);
    const [RouteLeavingGuard] = di([RouteLeavingGuardInj], GeneralObjectContentForm);

    const { formDTO } = props;
    const { id, tabId } = useParams<GeneralObjectRouteParams>();
    const { objectStore, routes } = useGeneralObjectContext();
    const { intlStore, stickyElementsStore, authorizationStore } = useStore();
    const intl = useIntl();
    const history = useHistory();
    const { isWithTabs, downloadAllFiles } = objectStore;
    const { updateStickyElementsHeight, objectModel } = useGeneralObjectCardContext();
    const { saveForm, objectContentErrorsModel, isInfoModalOpen, closeInfoModal, commonDescription, tabs } =
        objectModel;
    const { errorsByTabs, shouldValidateFormAfterOpening } = objectContentErrorsModel;

    const formEffectOnMount = (): void => {
        fakeScroll();
        updateStickyElementsHeight();
    };
    useEffect(formEffectOnMount, []);

    const [formApi, setFormApi] = useState<FormApi>();
    const [isReadOnlyForm, setIsReadOnlyFormTrue, setIsReadOnlyFormFalse] = useFlag(true);
    const [formIsChanged, setFormIsChanged] = useState<boolean>(false);

    const isFormEdit = !!useRouteMatch(routes.formEdit);

    useEffect(() => {
        isFormEdit ? setIsReadOnlyFormFalse() : setIsReadOnlyFormTrue();
    }, [isFormEdit]);

    useEffect(() => {
        if (shouldValidateFormAfterOpening(isReadOnlyForm, isWithTabs, tabId)) {
            formApi?.formioFormApi?.validate();
        }
    }, [formApi]);

    useEffect(() => {
        if (!isReadOnlyForm) {
            authorizationStore
                .check({ entityCode: entities.tab, entityId: tabId, permCode: permissions.tab.Edit })
                .then((allowed) => {
                    if (!allowed) {
                        history.replace(clientRoute.notAllowed);
                    }
                });
        }
    }, [isReadOnlyForm]);

    const onCloseForm = (): void => {
        const tabErrors = errorsByTabs[tabId];
        if (tabErrors && formApi?.formioFormApi?.validate()) {
            tabErrors.removeError(ErrorEntityType.submission);
        }
        setIsReadOnlyFormTrue();
        setFormIsChanged(false);
    };

    // мне не нравится, что нет связи с высчитанными в GeneralObjectPage паддингами (contentInnerPadding + tabContentContainerPadding)
    // а используются константы, которые не связывают нам руки в изменении верстки, но решения лучше пока не придумала
    // можно оттуда же прокидывать так же высчитанный паддинг, но это ничего не даст
    const { summaryStickyOffsetRight } = stickyElementsStore;
    const stickyOffsetRight = summaryStickyOffsetRight + contentInnerPadding + tabContentContainerPadding;
    const containerId = useId('generalObjectForm');
    const containerSelector = `#${containerId}`;

    const formEditPath = generatePath(routes.formEdit, { id, ...(isWithTabs && { tabId }) });

    const returnPath = useMemo(() => {
        return isWithTabs ? generatePath(routes.tab, { id, tabId }) : generatePath(routes.card, { id });
    }, [isWithTabs, routes, id, tabId]);

    const onSubmit = async (): Promise<void> => {
        if (formApi) {
            return saveForm(tabId, formApi.getSubmissionWithAdditionalInfo());
        }
        return Promise.reject();
    };

    const allFilesArchiveName = getDownloadAllFilesArchiveName(commonDescription, tabs, tabId);

    const onDownloadAllFilesButtonClick = (): Promise<void> => {
        return downloadAllFiles(tabId).then((file) => downloadFile(file, allFilesArchiveName));
    };

    return (
        <React.Fragment>
            <Grid container direction="column" id={containerId}>
                <InfoDialog
                    isOpen={isInfoModalOpen}
                    setIsClosed={closeInfoModal}
                    message={intl.formatMessage({ id: 'generalObject.changeLifecycleInfo' })}
                />
                {isReadOnlyForm && (
                    <FormStickyButtons
                        stickyOffsetRight={stickyOffsetRight}
                        containerSelector={containerSelector}
                        onEditForm={setIsReadOnlyFormFalse}
                        formEditPath={formEditPath}
                        isDownloadAllFilesButtonShown={true}
                        editButtonStyles={readonlyStickyButtonsSx}
                        downloadAllFileButtonStyles={readonlyStickyButtonsSx}
                        tabId={tabId}
                        onDownloadAllFiles={onDownloadAllFilesButtonClick}
                    />
                )}
                <Form
                    ownerEntityId={isWithTabs ? tabId : id}
                    multiLang={true}
                    readOnly={isReadOnlyForm}
                    onFormReady={setFormApi}
                    formDTO={formDTO}
                    intlStore={intlStore}
                    setFormIsChanged={setFormIsChanged}
                />
                {!isReadOnlyForm && (
                    <StickyFormSaveButtons
                        stickyOffsetRight={stickyOffsetRight}
                        containerSelector={containerSelector}
                        formApi={formApi}
                        onCloseForm={onCloseForm}
                        returnPath={returnPath}
                        onSubmit={onSubmit}
                        horizontalMargin={5}
                    />
                )}
            </Grid>
            <RouteLeavingGuard when={formIsChanged} />
        </React.Fragment>
    );
});
