import { Typography, TypographyProps } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { TypographyTypeMap } from '@mui/material/Typography/Typography';
import React from 'react';
import { fontStylesCommon, fontStylesTitle, fontStylesTitleBold } from '../theme';

// TODO перенести в theme
export const TitleTypography: OverridableComponent<TypographyTypeMap> = (props: TypographyProps): JSX.Element => {
    const { children, sx, ...otherProps } = props;
    const typographySx = {
        ...fontStylesTitle,
        ...sx,
    };
    return (
        <Typography sx={typographySx} {...otherProps}>
            {children}
        </Typography>
    );
};

export const TitleBoldTypography: OverridableComponent<TypographyTypeMap> = (props: TypographyProps): JSX.Element => {
    const { children, sx, ...otherProps } = props;
    const typographySx = {
        ...fontStylesTitleBold,
        ...sx,
    };
    return (
        <Typography sx={typographySx} {...otherProps}>
            {children}
        </Typography>
    );
};

export const CommonTypography: OverridableComponent<TypographyTypeMap> = (props: TypographyProps): JSX.Element => {
    const { children, sx, ...otherProps } = props;
    const typographySx = {
        ...fontStylesCommon,
        ...sx,
    };
    return (
        <Typography sx={typographySx} {...otherProps}>
            {children}
        </Typography>
    );
};
