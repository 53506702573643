import { LocalesTree } from '../../../types';

const validation: LocalesTree = {
    required: 'Required field',
    minLength: 'Minimal length {length, number} {length, plural, one {symbol} other {symbols}}',
    maxLength: 'Maximum length {length, number} {length, plural, one {symbol} other {symbols}}',
    dropzoneError: 'File does not match type "doc" or "docx"',
    isNumber: 'Enter correct number',
    bannedSymbols: 'Symbols cannot be used in the field: « {symbols} »',
    validEmail: 'Enter a valid email',
    uniqTabCode: 'Code must be unique within this tab structure',
    pattern: 'Only {symbols} allowed',
    patternEnDigital: 'latin letters and numbers',
};

export default validation;
