import { Delete, Edit } from '@mui/icons-material';
import { IconButton, SvgIcon, TableCell, TableRow, Tooltip } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import downloadFile from 'js-file-download';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, Link as RouterLink, useParams } from 'react-router-dom';
import { fileDownloadTableSx, sxWidth25, sxWidth40 } from '../../../../../../../../constants';
import { useGeneralObjectContext } from '../../../../../../../../hooks';
import { MoveIcon } from '../../../../../../../../resources';
import { GeneralObjectRouteParams, PrintFormItemDTO } from '../../../../../../../../types';
import { getAnswer } from '../../../../../../../../utils';
import { FileDownload as FileDownloadInj } from '../../../../../../../FileDownload';

export type GeneralObjectTabSettingsPFItemProps = {
    printForm: PrintFormItemDTO;
    provided: DraggableProvided;
    openDeleteModal: () => void;
};

const actionsRowSx: SxProps<Theme> = { width: '10%', minWidth: '96px' };

export const GeneralObjectTabSettingsPFItem = observer((props: GeneralObjectTabSettingsPFItemProps): JSX.Element => {
    const [FileDownload] = di([FileDownloadInj], GeneralObjectTabSettingsPFItem);

    const { printForm, provided, openDeleteModal } = props;
    const { id: pfId, title, fileFormat, file, required } = printForm;
    const requiredText = getAnswer(required);
    const { routes, objectStore } = useGeneralObjectContext();
    const { id, tabId } = useParams<GeneralObjectRouteParams>();
    const intl = useIntl();

    const downloadPFFile = (): void => {
        if (file) {
            const { filename, mimeType } = file;
            objectStore.downloadTemplatePrintForm(tabId, pfId).then((file) => downloadFile(file, filename, mimeType));
        }
    };

    return (
        <TableRow key={pfId} hover ref={provided.innerRef} {...provided.draggableProps}>
            <TableCell sx={sxWidth40}>
                <FileDownload
                    fileName={file?.filename}
                    title={title}
                    sx={fileDownloadTableSx}
                    downloadFile={downloadPFFile}
                />
            </TableCell>
            <TableCell sx={sxWidth25}>{fileFormat}</TableCell>
            <TableCell sx={sxWidth25}>{requiredText}</TableCell>
            <TableCell align="right" sx={actionsRowSx}>
                <Tooltip title={intl.formatMessage({ id: 'common.move' })}>
                    <IconButton {...provided.dragHandleProps}>
                        <SvgIcon viewBox="0 0 20 20">
                            <MoveIcon />
                        </SvgIcon>
                    </IconButton>
                </Tooltip>
                <Tooltip title={intl.formatMessage({ id: 'common.edit' })}>
                    <IconButton
                        component={RouterLink}
                        to={generatePath(routes.settingsPFMain, { id, tabId, pfId })}
                        size="small"
                    >
                        <Edit />
                    </IconButton>
                </Tooltip>
                <Tooltip title={<FormattedMessage id="common.delete" />}>
                    <IconButton size="small" onClick={openDeleteModal}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
});
