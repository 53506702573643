import { FormDTO } from '@platform/formiojs-react';
import { TitlesDTO } from '@platform/multi-lang-field';
import { CodeTitle } from '../types';
import { GeneralObjectDTO } from './generalObject';

export type CategoryDTO = GeneralObjectDTO & {
    formInfo?: FormDTO;
};

export type CategoryCreateInfo = {
    forms: CodeTitle[];
    processes: CodeTitle[];
};

export type CategoryCreateDTO = {
    titles: TitlesDTO;
    formCode?: string;
    processCode?: string;
};

export type CategoryFormDTO = {
    childObjectProcessCode: string;
    projectProcessCode: string;
};

export type CreateCategoryCopyDTO = {
    titles: TitlesDTO;
};

export enum CreateCategoryCopyFields {
    titles = 'titles',
}
