import { ruRU } from '@mui/material/locale';
import createTheme from '@mui/material/styles/createTheme';
import { RootStore } from '../stores';
import { AppTheme, AppThemeOptions, AppThemeVariables, ThemeOverrides } from '../types';
import createAppThemeOptions from './appTheme';

// legacy scss переменные
import { sassVars } from './sass';

export const createAppTheme = (
    store: RootStore,
    createOptions: (overrides: ThemeOverrides) => AppThemeOptions = createAppThemeOptions,
): AppTheme => {
    const themeOptions = createOptions(store.themeOverrides);
    const themeVariables = themeOptions.variables as AppThemeVariables;
    return createTheme(
        {
            ...themeOptions,
            variables: {
                ...themeVariables,
                sass: {
                    ...sassVars,
                    ...themeVariables.sass,
                },
            },
        },
        ruRU,
    );
};
