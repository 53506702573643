import { InputAdornment } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { RequiredLabel, RequiredLabel as RequiredLabelInj } from '../RequiredLabel';
import { FieldWithServerError as FieldWithServerErrorInj } from './FieldWithServerError';

export type IdentifierFieldProps = {
    name: string;
    prefix: string;
    serverError?: string;
    disabled?: boolean;
};

export const IdentifierField = observer((props: IdentifierFieldProps) => {
    const [RequiredLabel] = di([RequiredLabelInj], IdentifierField);
    const [FieldWithServerError] = di([FieldWithServerErrorInj], IdentifierField);

    const { name, prefix, serverError, disabled = false } = props;
    const intl = useIntl();

    const label = <RequiredLabel text={intl.formatMessage({ id: 'generalObject.fields.number' })} />;

    return (
        <FieldWithServerError
            disabled={disabled}
            fullWidth
            name={name}
            InputProps={{
                className: 't-number-field',
                startAdornment: prefix && <InputAdornment position="start">{prefix}</InputAdornment>,
            }}
            FormHelperTextProps={{
                className: 't-number-field-help-text',
            }}
            label={label}
            serverError={serverError}
        />
    );
});
