import { action, makeObservable, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { SeverityType } from '../../stores';

export type MessageType = {
    severity: SeverityType;
    title: string;
};

export const NotificationModelProps = {
    id: observable,
    title: observable,
    severity: observable,
    isOpen: observable,
    closeNotification: action.bound,
    load: action.bound,
};

export class NotificationModel {
    id: string = uuidv4();
    title = '';
    severity: SeverityType = SeverityType.success;
    isOpen = true;

    constructor() {
        makeObservable(this, NotificationModelProps);
    }

    closeNotification(): void {
        this.isOpen = false;
    }

    load(dto: MessageType): void {
        this.severity = dto.severity;
        this.title = dto.title;
    }
}
