import { action, computed, makeObservable, observable } from 'mobx';
import { IntlShape } from 'react-intl';
import { apiConfigs } from '../apiConfigs';
import { ApiStore, PersonStore, RootStore } from '../stores';
import { AuthStatus, ContentErrorDTO, NewPasswordDTO, Status } from '../types';

export const NewPasswordModelProps = {
    api: observable,
    intl: observable,
    token: observable,
    tokenError: observable,
    isCheckingToken: observable,
    status: observable,
    changedSucceed: computed,
    updatePassword: action.bound,
    validateToken: action.bound,
    setTokenError: action.bound,
    setIsCheckingToken: action.bound,
};

export class NewPasswordModel {
    api: ApiStore;
    intl: IntlShape;
    personStore: PersonStore;

    token?: string;
    tokenError = '';
    isCheckingToken = true;
    status?: Status;

    constructor(rootStore: RootStore, token: string) {
        makeObservable(this, NewPasswordModelProps);
        this.api = rootStore.api;
        this.intl = rootStore.intlStore.intl;
        this.personStore = rootStore.personStore;
        this.token = token;
    }

    get changedSucceed(): boolean {
        return this.status === Status.success;
    }

    async updatePassword(newPasswordData: NewPasswordDTO): Promise<void> {
        newPasswordData.token = this.token;

        try {
            await this.api.userActionClient(apiConfigs.resetPassword(newPasswordData));
            this.api.setAuthStatus(AuthStatus.ok);
            await this.personStore.getInfo();
            this.status = Status.success;
        } catch (e) {
            this.status = Status.failed;
        }
    }

    async validateToken(): Promise<void> {
        if (this.token) {
            try {
                await this.api.client(apiConfigs.checkIsPasswordRecoveryTokenValid(this.token));
            } catch (e: any) {
                const responseErrorData: string | ContentErrorDTO[] | undefined = e?.response && e.response.data;
                if (Array.isArray(responseErrorData) && responseErrorData[0]) {
                    this.setTokenError(responseErrorData[0].message);
                } else {
                    this.setTokenError(this.intl.formatMessage({ id: 'authentication.linkInvalidOrExpired' }));
                }
            } finally {
                this.setIsCheckingToken(false);
            }
        }
    }

    setTokenError(error: string): void {
        this.tokenError = error;
    }

    setIsCheckingToken(isChecking: boolean): void {
        this.isCheckingToken = isChecking;
    }
}
