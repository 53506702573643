import { SelectData } from '@platform/ttable';
import { AxiosRequestConfig } from 'axios';
import { action, makeObservable, observable } from 'mobx';
import { di } from 'react-magnetic-di';
import { apiConfigs } from '../apiConfigs';
import { CodeTitle, CodeTitleGroup, GeneralObjectPermissionTrigger, IdTitle, UserRole } from '../types';
import { ApiStore } from './ApiStore';
import { IntlStore } from './IntlStore';
import { RootStore } from './RootStore';

type CatalogDTO = {
    code: string;
    catalogCode: string;
    title: string;
};

export type CodeTitleGroupRC = CodeTitleGroup<GeneralObjectPermissionTrigger>;

export const CatalogStoreProps = {
    rootStore: observable,
    api: observable,
    intlStore: observable,
    getSelectData: action.bound,
    getCatalog: action.bound,
    getRoleConditionsList: action.bound,
    getLifecyclesList: action.bound,
    getTabFormsList: action.bound,
    getSelectDataByUrl: action.bound,
    getSelectActiveUsersByRole: action.bound,
    getPortfoliosList: action.bound,
};

export class CatalogStore {
    rootStore: RootStore;
    api: ApiStore;
    intlStore: IntlStore;

    constructor(rootStore: RootStore) {
        makeObservable(this, CatalogStoreProps);
        this.rootStore = rootStore;
        this.api = rootStore.api;
        this.intlStore = rootStore.intlStore;
    }

    getSelectData(requestConfig: AxiosRequestConfig): Promise<IdTitle[]> {
        return this.api
            .client(requestConfig)
            .then((r) => r.data)
            .then((data: CodeTitle[]) => {
                return data.map(({ code, title }) => ({
                    id: code,
                    title,
                }));
            });
    }

    getCatalog(catalogCode: string, lang: string): Promise<CodeTitle[]> {
        return this.api
            .client(apiConfigs.catalogItems(catalogCode, lang))
            .then((r) => r.data)
            .then((arr) =>
                arr.map((item: CatalogDTO) => ({ code: `${item.code}@${item.catalogCode}`, title: item.title })),
            );
    }

    getRoleConditionsList(): Promise<CodeTitleGroupRC[]> {
        return this.api.client(apiConfigs.roleConditionsList).then((r) => r.data);
    }

    getLifecyclesList(processCode: string): Promise<CodeTitle[]> {
        return this.api.client(apiConfigs.getLifecycleList(processCode)).then((r) => r.data);
    }

    getTabFormsList(): Promise<CodeTitle[]> {
        return this.api.client(apiConfigs.tabFormsList).then((r) => r.data);
    }

    getSelectDataByUrl(url: string): Promise<SelectData> {
        return this.api
            .client({
                url: url,
                method: 'GET',
            })
            .then((r) => r.data);
    }

    getSelectActiveUsersByRole(roleCode: UserRole): Promise<IdTitle[]> {
        return this.api.client(apiConfigs.getActiveUsersByRole(roleCode)).then((r) => r.data);
    }

    getPortfoliosList(): Promise<IdTitle[]> {
        return this.api.client(apiConfigs.getPortfoliosList()).then((r) => r.data);
    }
}

export const getCatalogStore = (): any => {
    const [_CatalogStore] = di([CatalogStore], getCatalogStore);
    return _CatalogStore;
};
