import { observer } from 'mobx-react';
import React, { ReactNode, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { entities, permissions } from '../../../authSchemeConfig';
import {
    AuthorizationCheck,
    GeneralObjectDeleteBtn as GeneralObjectDeleteBtnInj,
    MenuButton as MenuButtonInj,
    renderMenuButton as renderMenuButtonInj,
} from '../../../components';
import { useStore } from '../../../hooks';
import { DotMenu } from '../../../resources';
import { ProjectTableRow } from '../../../types';

export type ProjectRegistryActionsBtnsProps = {
    projectTableRow: ProjectTableRow;
    reloadData: () => void;
    toggleIsRowBlocked: () => void;
    rowErrorHandler: () => void;
};

export const ProjectRegistryActionsBtns = observer((props: ProjectRegistryActionsBtnsProps): JSX.Element => {
    const [MenuButton] = di([MenuButtonInj], ProjectRegistryActionsBtns);
    const [GeneralObjectDeleteBtn] = di([GeneralObjectDeleteBtnInj], ProjectRegistryActionsBtns);
    const [renderMenuButton] = di([renderMenuButtonInj], ProjectRegistryActionsBtns);

    const { projectTableRow, reloadData, toggleIsRowBlocked, rowErrorHandler } = props;
    const { id } = projectTableRow;

    const { notificationStore, projectStore } = useStore();
    const intl = useIntl();

    const handleDeleteProject = useCallback((): Promise<void> => {
        return projectStore.deleteObject(id, true).then(reloadData).catch(rowErrorHandler);
    }, [reloadData, rowErrorHandler, id, projectStore, notificationStore]);

    const renderActionItems = useCallback((): ((hideMenu?: () => void) => ReactNode[]) => {
        const renderItems = (hideMenu?: () => void): ReactNode[] => {
            const onDeleteClick = (): Promise<void> => {
                hideMenu && hideMenu();
                toggleIsRowBlocked();
                return handleDeleteProject();
            };

            return [
                <React.Fragment key="actionBtns">
                    <GeneralObjectDeleteBtn
                        object={intl.formatMessage({ id: 'project.single.accusative' })}
                        title={projectTableRow.customData.title.title}
                        onDeleteClick={onDeleteClick}
                    />
                </React.Fragment>,
            ];
        };
        return renderItems;
    }, [projectTableRow, toggleIsRowBlocked]);

    return (
        <AuthorizationCheck entityCode={entities.project} permCode={permissions.project.Delete} entityId={id}>
            <MenuButton renderButton={renderMenuButton(<DotMenu />)} renderMenuItems={renderActionItems()} />
        </AuthorizationCheck>
    );
});
