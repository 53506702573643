import { TTable } from '@platform/ttable';
import { observer } from 'mobx-react';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { entities, permissions } from '../../../authSchemeConfig';
import { AuthorizationCheck as AuthorizationCheckInj } from '../../../components';
import { useLocale, useStore } from '../../../hooks';
import { RegistryCode, UserTableRow } from '../../../types';

export type UserRegistryTableProps = {
    setRowActions: (
        row: UserTableRow,
        reloadData: () => void,
        toggleIsRowBlocked: () => void,
        rowErrorHandler: () => void,
    ) => JSX.Element;
};

export const UserRegistryTable = observer((props: UserRegistryTableProps): JSX.Element => {
    const [AuthorizationCheck] = di([AuthorizationCheckInj], UserRegistryTable);

    const { setRowActions } = props;
    const { catalogStore, userStore, api, notificationStore } = useStore();
    const { handleMainInfoClientError } = api;
    const handleMainInfoError = handleMainInfoClientError();
    const intl = useIntl();
    const [tableTitle] = useLocale('user.plural.nominative');

    return (
        <AuthorizationCheck entityCode={entities.system} permCode={permissions.system.Administration}>
            {(allowed: boolean): JSX.Element => (
                <TTable<UserTableRow>
                    tableTitle={tableTitle}
                    client={api.client}
                    registryCode={RegistryCode.account}
                    fetchCatalog={catalogStore.getCatalog}
                    fetchSelectDataByUrl={catalogStore.getSelectDataByUrl}
                    lang={intl.locale}
                    fetchUserRoleList={userStore.userRoleList}
                    isAdmin={allowed}
                    handleMainInfoError={handleMainInfoError}
                    rowActions={setRowActions}
                    handleCustomError={notificationStore.onError}
                />
            )}
        </AuthorizationCheck>
    );
});
