import { Button, Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { entities, permissions } from '../../../../authSchemeConfig';
import {
    AuthorizationCheck as AuthorizationCheckInj,
    UserStatusModal as UserStatusModalInj,
} from '../../../../components';
import { useFlag, useUserPageContext } from '../../../../hooks';
import { UserStatus } from '../../../../types';
import { actionCodeByUserStatus } from '../../../../utils';

export type UserPageStatusButtonProps = {
    userStatusCode: UserStatus;
};

export const UserPageStatusButton = observer((props: UserPageStatusButtonProps): JSX.Element => {
    const [AuthorizationCheck] = di([AuthorizationCheckInj], UserPageStatusButton);
    const [UserStatusModal] = di([UserStatusModalInj], UserPageStatusButton);

    const { userStatusCode } = props;
    const { userModel } = useUserPageContext();
    const { id, fullName, login, load } = userModel;
    const [isDialogOpen, setIsDialogOpen, setIsDialogClosed] = useFlag();

    return (
        <React.Fragment>
            <Grid item>
                <AuthorizationCheck entityCode={entities.account} permCode={permissions.account.Update} entityId={id}>
                    <Button onClick={setIsDialogOpen} size="medium" variant="contained" color="secondary">
                        <FormattedMessage id={`user.action.${actionCodeByUserStatus[userStatusCode]}`} />
                    </Button>
                </AuthorizationCheck>
            </Grid>
            <UserStatusModal
                id={id}
                name={fullName}
                login={login}
                userStatusCode={userStatusCode}
                isOpen={isDialogOpen}
                reloadData={load}
                setIsClosed={setIsDialogClosed}
            />
        </React.Fragment>
    );
});
