import { observer } from 'mobx-react-lite';
import React, { PropsWithChildren } from 'react';
import { RawIntlProvider } from 'react-intl';
import { useStore } from '../hooks';

export type TotIntlProviderProps = PropsWithChildren<unknown>;

export const TotIntlProvider = observer((props: TotIntlProviderProps): JSX.Element => {
    const { children } = props;
    const { intlStore } = useStore();
    return <RawIntlProvider value={intlStore.intl}>{children}</RawIntlProvider>;
});
