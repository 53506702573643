import { FullSubmissionWithAdditionalInfo } from '@platform/formiojs-react';
import { AsyncCheckActionType, AsyncCheckStore } from '@platform/front-utils';
import { History } from 'history';
import { action, computed, makeAutoObservable, observable } from 'mobx';
import { di } from 'react-magnetic-di';
import { generatePath } from 'react-router-dom';
import { apiConfigs } from '../apiConfigs';
import {
    CodeTitle,
    EditStatusDTO,
    FullNameFields,
    FullNameSettingsDTO,
    RolesFields,
    RolesSettingsDTO,
    TotLocale,
    UserDTO,
} from '../types';
import { ApiStore } from './ApiStore';
import { PersonStore } from './PersonStore';
import { RootStore } from './RootStore';

export const UserStoreProps = {
    rootStore: observable,
    personStore: observable,
    api: observable,
    asyncCheckStore: observable,
    history: computed,
    loginAsUser: action.bound,
    loadUser: action.bound,
    saveUser: action.bound,
    userRoleList: action.bound,
    deleteUser: action.bound,
    getUserFullName: action.bound,
    editUserStatus: action.bound,
    setRoles: action.bound,
    setUserLang: action.bound,
    setFullName: action.bound,
};

export class UserStore {
    private rootStore: RootStore;
    private personStore: PersonStore;
    private api: ApiStore;
    private asyncCheckStore: AsyncCheckStore;

    constructor(rootStore: RootStore) {
        makeAutoObservable(this, UserStoreProps);
        this.rootStore = rootStore;
        this.personStore = rootStore.personStore;
        this.api = rootStore.api;
        this.asyncCheckStore = rootStore.asyncCheckStore;
    }

    get history(): History {
        return this.rootStore.history;
    }

    loginAsUser(id: string): Promise<void> {
        return this.api.client(apiConfigs.loginAsUser(id)).then(() => {
            this.personStore.getInfo().then(() => {
                this.history.push(generatePath(this.personStore.redirectPath));
            });
        });
    }

    loadUser(id: string): Promise<UserDTO> {
        return this.api.mainInfoClient(apiConfigs.loadUser(id)).then((r) => {
            return r.data;
        });
    }

    saveUser(id: string, submissionData: FullSubmissionWithAdditionalInfo): Promise<void> {
        return this.api.userActionClient(apiConfigs.saveUser(id, submissionData)).then((r) => r.data);
    }

    userRoleList(): Promise<CodeTitle[]> {
        return this.api.client(apiConfigs.userRoleList).then((r) => r.data);
    }

    deleteUser(id: string): Promise<void> {
        return this.api.client(apiConfigs.deleteUser(id)).then((r) => r.data);
    }

    getUserFullName(id: string): Promise<FullNameSettingsDTO> {
        return this.api.client(apiConfigs.userFullName(id)).then((r) => r.data);
    }

    editUserStatus(id: string, data: EditStatusDTO, isAsyncCheckRequired = false): Promise<void> {
        return this.api
            .userActionClient(apiConfigs.editUserStatus(id, data))
            .then(this.asyncCheckStore.asyncCheck(AsyncCheckActionType.registry, isAsyncCheckRequired))
            .then((r) => r.data);
    }

    setRoles(id: string, dto: RolesSettingsDTO): Promise<void> {
        return this.api.userActionClient(apiConfigs.setRoles(id, dto), Object.values(RolesFields)).then((r) => r.data);
    }

    setUserLang(id: string, lang: TotLocale): Promise<void> {
        return this.api.userActionClient(apiConfigs.setUserLang(id, lang)).then((r) => r.data);
    }

    setFullName(id: string, dto: FullNameSettingsDTO): Promise<void> {
        return this.api
            .userActionClient(apiConfigs.setFullName(id, dto), Object.values(FullNameFields))
            .then((r) => r.data);
    }
}

export const getUserStore = (): any => {
    const [_UserStore] = di([UserStore], getUserStore);
    return _UserStore;
};
