import { createContext } from 'react';
import { ChartSettingsModel } from '../models';

export type GeneralObjectChartSettingsPageContextType = {
    model: ChartSettingsModel;
};

export const GeneralObjectChartSettingsPageContext = createContext<GeneralObjectChartSettingsPageContextType>({
    model: {} as ChartSettingsModel,
});
