import { LocalesTree } from '../../../types';

const confirmLeavePage: LocalesTree = {
    title: 'Confirmation of closing edit mode',
    message:
        '<p>Are you sure you want to leave the current ticket without saving your changes?</p>' +
        '<p>To save the changes made, click the "Save" button on the page below.</p>',
    textMessage: 'Are you sure you want to leave the current ticket without saving your changes?',
};

export default confirmLeavePage;
