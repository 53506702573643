(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@mui/material"), require("@mui/system"), require("@platform/front-utils"), require("mobx"), require("react"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define(["@mui/material", "@mui/system", "@platform/front-utils", "mobx", "react", "react-dom"], factory);
	else if(typeof exports === 'object')
		exports["@platform/formiojs-react"] = factory(require("@mui/material"), require("@mui/system"), require("@platform/front-utils"), require("mobx"), require("react"), require("react-dom"));
	else
		root["@platform/formiojs-react"] = factory(root["@mui/material"], root["@mui/system"], root["@platform/front-utils"], root["mobx"], root["react"], root["react-dom"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__80901__, __WEBPACK_EXTERNAL_MODULE__68719__, __WEBPACK_EXTERNAL_MODULE__79785__, __WEBPACK_EXTERNAL_MODULE__70259__, __WEBPACK_EXTERNAL_MODULE__8156__, __WEBPACK_EXTERNAL_MODULE__47111__) {
return 