import { LocalesTree, ObjectLocales } from '../../../types';
import { createLocalesWithoutCases } from '../../../utils';

const portfolio: ObjectLocales & LocalesTree = {
    ...createLocalesWithoutCases('portfolio', 'portfolios'),
    createFields: {
        number: 'Number',
        process: 'Lifecycle',
    },
    phrases: {
        create: 'Create {portfolio}',
        tabDeleteText: 'The tab will be removed on the portfolio page',
    },
};

export default portfolio;
