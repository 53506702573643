import { LocalesTree } from '../../../types';

const errorPage: LocalesTree = {
    notExist: 'Страница не существует',
    actionNotAllowed: 'У Вас недостаточно прав для этого действия',
    goTo: 'Перейдите на ',
    main: 'Главную ',
    orReturn: 'или вернитесь на ',
    back: 'Предыдущую страницу ',
};

export default errorPage;
