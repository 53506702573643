import { Paper, Table, TableContainer } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';

export type GeneralObjectSystemEntityPermissionsTableProps = {
    renderPermissionTableHeader: () => React.ReactNode;
    renderPermissionTableBody: () => React.ReactNode;
};

export const GeneralObjectSystemEntityAccessSettingsPermissionsTable = observer(
    (props: GeneralObjectSystemEntityPermissionsTableProps): JSX.Element => {
        const { renderPermissionTableHeader, renderPermissionTableBody } = props;
        return (
            <TableContainer component={Paper}>
                <Table>
                    {renderPermissionTableHeader()}
                    {renderPermissionTableBody()}
                </Table>
            </TableContainer>
        );
    },
);
