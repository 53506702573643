import { GeneralObject } from './generalObject';

export enum SystemObject {
    account = 'account',
}

export enum BreadcrumbsCommonLocation {
    tabSettings = 'tabSettings',
    syslog = 'syslog',
    versions = 'versions',
    console = 'console',
}

export const BreadcrumbsLocation = {
    ...SystemObject,
    ...GeneralObject,
    ...BreadcrumbsCommonLocation,
};

export type BreadcrumbsLocation = keyof typeof BreadcrumbsCommonLocation;

export enum BreadcrumbsCommonEntitie {
    // Если у объеката нет id, то это system
    system = 'system',
    tab = 'tab',
    pfSetting = 'pfSetting',
    tabSetting = 'tabSetting',
    chartContent = 'chartContent',
}

export const BreadcrumbsEntities = {
    ...SystemObject,
    ...GeneralObject,
    ...BreadcrumbsCommonEntitie,
};
export type BreadcrumbsEntities = keyof typeof BreadcrumbsEntities;
