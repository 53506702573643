import { Grid } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import React from 'react';
import { di } from 'react-magnetic-di';
import { GeneralObjectFieldValue, PersonContact } from '../../../../../types';
import { GeneralObjectUserContactInformation as GeneralObjectUserContactInformationInj } from './GeneralObjectUserContactInformation';

export type GeneralObjectUserContactInformationFieldValueProps = {
    value: GeneralObjectFieldValue;
    responsibleInfo: PersonContact;
    isLast?: boolean;
    isShouldSeparateArrayValuesWithComma?: boolean;
};

const contactValueWrapperSx: SxProps<Theme> = { mr: 1 };

export const GeneralObjectUserContactInformationFieldValue = (
    props: GeneralObjectUserContactInformationFieldValueProps,
): JSX.Element => {
    const [GeneralObjectUserContactInformation] = di(
        [GeneralObjectUserContactInformationInj],
        GeneralObjectUserContactInformationFieldValue,
    );

    const { value, responsibleInfo, isLast = true, isShouldSeparateArrayValuesWithComma } = props;

    return (
        <Grid container alignItems="center">
            <Grid item sx={contactValueWrapperSx}>
                {value}
            </Grid>
            <Grid item>
                <GeneralObjectUserContactInformation responsibleInfo={responsibleInfo} />
            </Grid>
            {!isLast && isShouldSeparateArrayValuesWithComma && <Grid item>{', '}</Grid>}
        </Grid>
    );
};
