var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Grid, Paper, Tab, Tabs } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormRaw } from './FormRaw';
export var MultiLangFormEdit = observer(function (props) {
    var intlStore = props.intlStore, formApi = props.formApi, formRawProps = __rest(props, ["intlStore", "formApi"]);
    // locale прокидывается в FormRaw, но связанный с ней хук не вызывается, т.к readOnly === false (издержки)
    var locale = intlStore.locale, locales = intlStore.locales;
    var lang = formApi.lang;
    var handleLangChange = function (event, value) {
        formApi.onChangeLang(value);
    };
    return (React.createElement(Grid, { container: true, spacing: 10, direction: "column" },
        React.createElement(Grid, { item: true },
            React.createElement(Paper, { square: true, elevation: 0 },
                React.createElement(Tabs, { indicatorColor: "primary", textColor: "primary", style: { borderBottom: '1px solid #f1f1f1' }, value: lang, onChange: handleLangChange }, locales.map(function (locale) {
                    return React.createElement(Tab, { key: locale, value: locale, label: locale });
                })))),
        React.createElement(Grid, { item: true, style: { maxWidth: '100%', paddingTop: 0 } },
            React.createElement(FormRaw, __assign({}, formRawProps, { formApi: formApi, locale: locale })))));
});
