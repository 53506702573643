import { TabList } from '@mui/lab';
import { styled } from '@mui/material/styles';

export const InfoTabs = styled(TabList)(
    ({ theme }) => `
    min-height: auto;
    height: 38px;
    
    & .MuiTabs-indicator {
        height: 1px;
    }
`,
);
