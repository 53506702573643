import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

export const portfolioMessages = defineMessages({
    create: {
        id: 'portfolio.phrases.create',
        values: {
            portfolio: <FormattedMessage id="portfolio.single.accusative" />,
        },
    },
});
