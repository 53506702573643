import { Box } from '@mui/material';
import { makeSxStyles } from '@platform/front-ui';
import React from 'react';
import { grey } from '../theme';

export const infoLineSeparatorSxStyles = makeSxStyles({
    separator: {
        backgroundColor: grey[200],
        height: '1px',
        width: '100%',
    },
});

export const InfoLineSeparator = (): JSX.Element => {
    return <Box sx={infoLineSeparatorSxStyles.separator}></Box>;
};
