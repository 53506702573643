import { Edit as EditIcon } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { TitlesDTO } from '@platform/multi-lang-field';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useFlag } from '../../../../hooks';
import { GeneralObjectEditTitleModal as GeneralObjectEditTitleModalInj } from '../../index';

export type GeneralObjectEditTitleBtnProps = {
    loadTitles: () => Promise<TitlesDTO>;
    saveTitles: (dto: TitlesDTO) => Promise<void>;
};

export const GeneralObjectEditTitleBtn = observer((props: GeneralObjectEditTitleBtnProps): JSX.Element => {
    const [GeneralObjectEditTitleModal] = di([GeneralObjectEditTitleModalInj], GeneralObjectEditTitleBtn);

    const { loadTitles, saveTitles } = props;
    const [isEditTitleOpen, setEditTitleOpen, setEditTitleClosed] = useFlag();

    return (
        <React.Fragment>
            <GeneralObjectEditTitleModal
                isOpen={isEditTitleOpen}
                setIsClosed={setEditTitleClosed}
                loadTitles={loadTitles}
                saveTitles={saveTitles}
            />
            <Tooltip title={<FormattedMessage id="generalObject.editTitle" />}>
                <IconButton className="t-edit-button" onClick={setEditTitleOpen}>
                    <EditIcon />
                </IconButton>
            </Tooltip>
        </React.Fragment>
    );
});
