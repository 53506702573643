import { Box, Container, Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, NavLink, Route, Switch, useParams } from 'react-router-dom';
import { useGeneralObjectContext, useStore } from '../../../../../hooks';
import { TabSettingsModel } from '../../../../../models';
import {
    BreadcrumbsEntities,
    GeneralObjectRouteParams,
    GeneralObjectSettingSections,
    TabRoute,
} from '../../../../../types';
import { ServerBreadcrumbs as ServerBreadcrumbsInj } from '../../../../breadcrumbs';
import { InfoTab as InfoTabInj } from '../../../../InfoTab';
import { InfoTabs as InfoTabsInj } from '../../../../InfoTabs';
import { RoutedTabContext as RoutedTabContextInj } from '../../../../RoutedTabContext';
import { GeneralObjectEditTitleBtn as GeneralObjectEditTitleBtnInj } from '../../general-object-edit-title';
import { GeneralObjectTabSettingsContent as GeneralObjectTabSettingsContentInj } from './general-object-tab-settings-content';
import { GeneralObjectTabSettingsAccess as GeneralObjectTabSettingsAccessInj } from './GeneralObjectTabSettingsAccess';

export const GeneralObjectTabSettings = observer((): JSX.Element => {
    const [ServerBreadcrumbs] = di([ServerBreadcrumbsInj], GeneralObjectTabSettings);
    const [GeneralObjectTabSettingsAccess] = di([GeneralObjectTabSettingsAccessInj], GeneralObjectTabSettings);
    const [GeneralObjectTabSettingsContent] = di([GeneralObjectTabSettingsContentInj], GeneralObjectTabSettings);
    const [GeneralObjectEditTitleBtn] = di([GeneralObjectEditTitleBtnInj], GeneralObjectTabSettings);
    const [InfoTabs] = di([InfoTabsInj], GeneralObjectTabSettings);
    const [InfoTab] = di([InfoTabInj], GeneralObjectTabSettings);
    const [RoutedTabContext] = di([RoutedTabContextInj], GeneralObjectTabSettings);

    const { tabId, id } = useParams<GeneralObjectRouteParams>();
    const { routes, objectStore } = useGeneralObjectContext();
    const rootStore = useStore();
    const { intlStore } = rootStore;
    const settings = useMemo<TabSettingsModel>(
        () => new TabSettingsModel(id, tabId, rootStore, objectStore),
        [id, tabId, rootStore, objectStore],
    );

    useEffect(() => {
        settings.load();
    }, [intlStore.locale]);

    const { title, loadTitles, saveTitles } = settings;

    const tabRoutes: TabRoute[] = [
        {
            tab: GeneralObjectSettingSections.content,
            path: routes.settingsTabContent,
        },
        {
            tab: GeneralObjectSettingSections.access,
            path: routes.settingsTabAccess,
        },
    ];

    return (
        <Container maxWidth="lg">
            <Box pt={5.5} pb={15}>
                <Box pb={5}>
                    <ServerBreadcrumbs
                        entityTitle={title}
                        entityId={tabId}
                        entityType={BreadcrumbsEntities.tabSetting}
                    />
                </Box>
                <Box pb={5}>
                    <Grid container direction="column" spacing={5}>
                        <Grid container item direction="row" wrap="nowrap" spacing={1}>
                            <Grid item>
                                <Typography variant="h4" component="h1">
                                    {title}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <GeneralObjectEditTitleBtn loadTitles={loadTitles} saveTitles={saveTitles} />
                            </Grid>
                        </Grid>
                        <Grid container item alignItems="center">
                            <RoutedTabContext settings={tabRoutes}>
                                <InfoTabs>
                                    {tabRoutes.map((p) => {
                                        const { tab, path } = p;
                                        return (
                                            <InfoTab
                                                // @ts-ignore
                                                component={NavLink}
                                                key={tab}
                                                value={tab}
                                                label={
                                                    <FormattedMessage id={`objectSettings.settingsSections.${tab}`} />
                                                }
                                                to={generatePath(path, { id, tabId })}
                                            />
                                        );
                                    })}
                                </InfoTabs>
                            </RoutedTabContext>
                        </Grid>
                    </Grid>
                </Box>
                <Switch>
                    <Route path={routes.settingsTabContent}>
                        <GeneralObjectTabSettingsContent />
                    </Route>
                    <Route path={routes.settingsTabAccess}>
                        <GeneralObjectTabSettingsAccess />
                    </Route>
                </Switch>
            </Box>
        </Container>
    );
});
