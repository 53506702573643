import { action, makeObservable, observable } from 'mobx';
import { SeverityType } from '../../stores';
import { MessageType, NotificationModel } from './NotificationModel';

const maxNotificationsLength = 5;

export const NotificationListModelProps = {
    notifications: observable,
    pushNotification: action.bound,
    removeAfterTimeout: action.bound,
    deleteNotification: action.bound,
};

export class NotificationListModel {
    notifications: Array<NotificationModel> = [];

    constructor() {
        makeObservable(this, NotificationListModelProps);
    }

    pushNotification(title: string, withAutoClose: boolean, severity: SeverityType): void {
        const notification = new NotificationModel();
        const messageDetails: MessageType = { title, severity };
        if (this.notifications.length >= maxNotificationsLength) {
            setTimeout(() => {
                this.notifications.shift();
                notification.load(messageDetails);
                this.notifications.push(notification);
            }, 300);
        } else {
            notification.load(messageDetails);
            this.notifications.push(notification);
        }
        withAutoClose && this.removeAfterTimeout(notification.id);
    }

    removeAfterTimeout(id: string): void {
        setTimeout(() => {
            this.deleteNotification(id);
        }, 3000);
    }

    deleteNotification(id: string): void {
        const notificationIndex = this.notifications.findIndex((n) => n.id === id);
        const notification = this.notifications[notificationIndex];
        notification?.closeNotification();
        setTimeout(() => {
            this.notifications.splice(notificationIndex, 1);
        }, 300);
    }
}
