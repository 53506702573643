import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { ActionMenuItem as ActionMenuItemInj } from '../../../../../../components';
import { useFlag } from '../../../../../../hooks';
import { ActionItemProps } from '../../../../../../types';
import { UserPageFullNameModal as UserPageFullNameModalInj } from './UserPageRolesModal';

export const UserPageFullNameButton = observer((props: ActionItemProps): JSX.Element => {
    const [ActionMenuItem] = di([ActionMenuItemInj], UserPageFullNameButton);
    const [UserPageFullNameModal] = di([UserPageFullNameModalInj], UserPageFullNameButton);

    const { hideMenu } = props;
    const [isDialogOpen, setIsDialogOpen, setIsDialogClosed] = useFlag();

    return (
        <React.Fragment>
            <ActionMenuItem messageId="user.settings.fullName" onClick={setIsDialogOpen} />
            <UserPageFullNameModal isOpen={isDialogOpen} setIsClosed={setIsDialogClosed} hideMenu={hideMenu} />
        </React.Fragment>
    );
});
