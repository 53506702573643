import { TableBody } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useMemo, useState } from 'react';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { useFlag, useGeneralObjectContext, useStore, useYup } from '../../../../../hooks';
import { GeneralObjectTabAccessModel, TabPermissionSettingsModel } from '../../../../../models';
import { ErrorCode, GeneralObjectRouteParams, NewTabPermissionDTO, PermissionSettingsDTO } from '../../../../../types';
import { InfoDialog as InfoDialogInj } from '../../../../InfoDialog';
import {
    GeneralObjectSystemEntityAccessSettings,
    GeneralObjectSystemEntityAccessSettingsPermissionsTableHeader,
} from '../general-object-system-entity-access-settings';
import { GeneralObjectTabAccessItem } from './GeneralObjectTabAccessItem';
import { GeneralObjectTabAccessModalAdditionalFields } from './GeneralObjectTabAccessModalAdditionalFields';

export const GeneralObjectTabSettingsAccess = observer((): JSX.Element => {
    const [InfoDialog] = di([InfoDialogInj], GeneralObjectTabSettingsAccess);

    const { tabId, id } = useParams<GeneralObjectRouteParams>();
    const { objectStore } = useGeneralObjectContext();
    const [isEditModalOpen, openEditModal, closeEditModal] = useFlag();
    const [errorMessage, setErrorMessage] = useState<string>();
    const rootStore = useStore();
    const { Yup } = useYup();

    const loadPermissions = (): Promise<PermissionSettingsDTO> => {
        return objectStore.getTabPermissionSettings(id, tabId).catch((error) => {
            if (error.response.status === ErrorCode.serverError) {
                setErrorMessage(error.response.data[0].message);
            }
            return Promise.reject(error);
        });
    };

    const updatePermissions = (dto: NewTabPermissionDTO[]): Promise<void> => {
        return objectStore.updateTabPermission(id, tabId, dto);
    };

    const additionalFieldsValidationSchema = {
        permissions: Yup.object().shape({
            filesDownload: Yup.boolean(),
            edit: Yup.boolean(),
            editForm: Yup.boolean(),
            filesManagement: Yup.boolean(),
            filesUpload: Yup.boolean(),
            filesEdit: Yup.boolean(),
        }),
    };

    const permissionSettingsModel = useMemo<TabPermissionSettingsModel>(
        () => new TabPermissionSettingsModel(id, tabId, rootStore, loadPermissions, updatePermissions),
        [id, tabId, rootStore],
    );

    const accessModel = useMemo(
        () => new GeneralObjectTabAccessModel(rootStore, permissionSettingsModel, additionalFieldsValidationSchema),
        [permissionSettingsModel],
    );

    const renderPermissionTableHeader = (): React.ReactNode => (
        <GeneralObjectSystemEntityAccessSettingsPermissionsTableHeader />
    );

    const renderPermissionTableBody = (
        openPermissionEditModal: (permissionIndex: number) => () => void,
        openPermissionDeleteModal: (permissionIndex: number) => () => void,
    ): (() => React.ReactNode) => {
        return (): React.ReactNode => (
            <TableBody>
                {permissionSettingsModel.permissions.map((permission, index) => {
                    const openEditModal = openPermissionEditModal(index);
                    const openDeleteModal = openPermissionDeleteModal(index);
                    return (
                        <GeneralObjectTabAccessItem
                            permissionData={permission}
                            key={index}
                            openEditModal={openEditModal}
                            openDeleteModal={openDeleteModal}
                        />
                    );
                })}
            </TableBody>
        );
    };

    return (
        <React.Fragment>
            <InfoDialog isOpen={isEditModalOpen} setIsClosed={closeEditModal} message={errorMessage} />
            <GeneralObjectSystemEntityAccessSettings
                accessModel={accessModel}
                AdditionalFields={GeneralObjectTabAccessModalAdditionalFields}
                renderPermissionTableHeader={renderPermissionTableHeader}
                renderPermissionTableBody={renderPermissionTableBody}
                {...(errorMessage && { onAddRuleClick: openEditModal })}
            />
        </React.Fragment>
    );
});
