import { Button, Grid, ThemeProvider, Typography } from '@mui/material';
import { DraggableList, EmptyList } from '@platform/front-core';
import { EmptyListProps } from '@platform/front-core/lib/components/list/EmptyList';
import { useFlag } from '@platform/front-utils';
import { TitlesDTO } from '@platform/multi-lang-field';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useGeneralObjectContext, useGeneralObjectSettingsContext } from '../../../../../../../../hooks';
import { ChartSettingsListModel } from '../../../../../../../../models';
import { redesignTheme } from '../../../../../../../../theme';
import { ChartSettingCreateDTO, GeneralObjectRouteParams } from '../../../../../../../../types';
import { GeneralObjectEditTitleModal as GeneralObjectEditTitleModalInj } from '../../../../../general-object-edit-title';
import {
    renderChartTableBody as renderChartTableBodyInj,
    renderChartTableHead as renderChartTableHeadInj,
} from './index';

export const GeneralObjectChartList = observer((): JSX.Element => {
    const [renderChartTableBody] = di([renderChartTableBodyInj], GeneralObjectChartList);
    const [renderChartTableHead] = di([renderChartTableHeadInj], GeneralObjectChartList);
    const [GeneralObjectEditTitleModal] = di([GeneralObjectEditTitleModalInj], GeneralObjectChartList);

    const { objectStore, routes } = useGeneralObjectContext();
    const { shouldLoadListItems, setShouldntLoadListItems } = useGeneralObjectSettingsContext();
    const { formatMessage } = useIntl();
    const history = useHistory();
    const { id, tabId } = useParams<GeneralObjectRouteParams>();
    const [isCreateDialogOpen, createDialogOpen, createDialogClose] = useFlag();

    const model = useMemo(
        (): ChartSettingsListModel => new ChartSettingsListModel(id, tabId, objectStore),
        [id, tabId, objectStore],
    );

    const { changePosition, getChartSettings } = model;

    const createButtonProps: EmptyListProps['createButtonProps'] = {
        title: formatMessage({ id: 'objectSettings.addVisualization' }),
        createItem: createDialogOpen,
    };

    const renderDraggableListEmpty = (): JSX.Element => (
        <EmptyList
            noItemsTitle={formatMessage({ id: 'objectSettings.noVisualizations' })}
            createButtonProps={createButtonProps}
        />
    );

    const createChart = useCallback(
        (titles: TitlesDTO): Promise<void> => {
            const dto: ChartSettingCreateDTO = {
                titles,
            };
            return objectStore.createChartSetting(tabId, dto).then((chartSettingId) => {
                history.push(generatePath(routes.chart, { id, tabId, chartSettingId }));
            });
        },
        [objectStore, id, tabId],
    );

    return (
        <Grid container direction="column" spacing={5}>
            <Grid item>
                <Grid container item justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h3" component="h2">
                            <FormattedMessage id="objectSettings.visualizations" />
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={createDialogOpen}>
                            <FormattedMessage id="objectSettings.addVisualization" />
                        </Button>
                        <GeneralObjectEditTitleModal
                            isOpen={isCreateDialogOpen}
                            saveTitles={createChart}
                            setIsClosed={createDialogClose}
                            dialogTitle={formatMessage({ id: 'objectSettings.newVisualization' })}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <ThemeProvider theme={redesignTheme}>
                    <DraggableList
                        isWithWrapperProps={false}
                        changeItemPosition={changePosition}
                        loadListItems={getChartSettings}
                        renderDraggableListBody={renderChartTableBody}
                        renderDraggableListHead={renderChartTableHead}
                        renderDraggableListEmpty={renderDraggableListEmpty}
                        setShouldntLoadListItems={setShouldntLoadListItems}
                        shouldLoadListItems={shouldLoadListItems}
                    />
                </ThemeProvider>
            </Grid>
        </Grid>
    );
});
