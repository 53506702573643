import { Button } from '@mui/material';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { entities, permissions } from '../../../authSchemeConfig';
import { AuthorizationCheck as AuthorizationCheckInj } from '../../../components';
import { useFlag } from '../../../hooks';
import { portfolioMessages } from '../../../resources';
import { PortfolioRegistryCreateDialog as PortfolioRegistryCreateDialogInj } from './PortfolioRegistryCreateDialog';

export const PortfolioRegistryCreateButton = observer((): JSX.Element => {
    const [PortfolioRegistryCreateDialog] = di([PortfolioRegistryCreateDialogInj], PortfolioRegistryCreateButton);
    const [AuthorizationCheck] = di([AuthorizationCheckInj], PortfolioRegistryCreateButton);

    const [isCreateDialogOpen, openCreateDialog, closeCreateDialog] = useFlag();

    return (
        <React.Fragment>
            <AuthorizationCheck entityCode={entities.system} permCode={permissions.system.CreatePortfolio}>
                <Button variant="contained" color="primary" onClick={openCreateDialog}>
                    <FormattedMessage {...portfolioMessages.create} />
                </Button>
            </AuthorizationCheck>
            <PortfolioRegistryCreateDialog isOpen={isCreateDialogOpen} setIsClosed={closeCreateDialog} />
        </React.Fragment>
    );
});
