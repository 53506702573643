import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import {
    FieldValues as LangTitle,
    getRuTitleInitialValue,
    MultiLangField,
    TitlesDTO,
    titlesDTOtoTitles,
    titlesToTitlesDTO,
    titlesYupSchema,
} from '@platform/multi-lang-field';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useYup } from '../../../../hooks';
import { ServerErrorsModel } from '../../../../models';
import { ModalProps, TitlesFields } from '../../../../types';
import { disableSubmitOnEnterKeyPress } from '../../../../utils';
import { ServerErrorHelper as ServerErrorHelperInj } from '../../../ServerErrorHelper';

export type GeneralObjectEditTitleModalProps = ModalProps & {
    loadTitles?: () => Promise<TitlesDTO>;
    saveTitles: (dto: TitlesDTO) => Promise<void>;
    dialogTitle?: string;
};

export type GeneralObjectEditTitleValues = {
    titles: LangTitle[];
};

export const GeneralObjectEditTitleModal = observer((props: GeneralObjectEditTitleModalProps): JSX.Element => {
    const [ServerErrorHelper] = di([ServerErrorHelperInj], GeneralObjectEditTitleModal);
    const { formatMessage, locale } = useIntl();
    const {
        isOpen,
        setIsClosed,
        loadTitles,
        saveTitles,
        dialogTitle = formatMessage({ id: 'generalObject.editTitle' }),
    } = props;
    const serverErrors = useMemo(() => new ServerErrorsModel<TitlesFields>(), []);
    const { dropServerFormErrors, setServerFormErrors, serverFormErrors } = serverErrors;
    const { titles: titlesField } = TitlesFields;
    const serverTitlesError = serverFormErrors[titlesField];

    const [titles, setTitles] = useState<LangTitle[]>(getRuTitleInitialValue());

    const onClose = (): void => {
        setIsClosed();
        dropServerFormErrors();
    };

    useEffect(() => {
        if (isOpen && loadTitles) {
            loadTitles().then((dto: TitlesDTO) => {
                setTitles(Object.keys(dto).length > 0 ? titlesDTOtoTitles(dto) : getRuTitleInitialValue());
            });
        }
    }, [isOpen, loadTitles]);

    const { Yup } = useYup();
    const validationSchema = Yup.object({
        titles: titlesYupSchema(Yup, true).min(1),
    });
    const initialValues: GeneralObjectEditTitleValues = {
        titles,
    };

    const onSubmit = (values: GeneralObjectEditTitleValues): void => {
        dropServerFormErrors();
        saveTitles(titlesToTitlesDTO(values.titles)).then(onClose).catch(setServerFormErrors);
    };

    return (
        <Dialog fullWidth={true} maxWidth="sm" open={isOpen}>
            <DialogTitle>{dialogTitle}</DialogTitle>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
            >
                <Form onKeyDown={disableSubmitOnEnterKeyPress}>
                    <DialogContent>
                        <MultiLangField systemLanguage={locale} fieldName={titlesField} ruEngLangs={true} />
                        <ServerErrorHelper serverError={serverTitlesError} />
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={onClose}>
                            <FormattedMessage id="common.cancel" />
                        </Button>
                        <Button color="primary" variant="contained" type="submit">
                            <FormattedMessage id="common.save" />
                        </Button>
                    </DialogActions>
                </Form>
            </Formik>
        </Dialog>
    );
});
