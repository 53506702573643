import { FullSubmissionWithAdditionalInfo } from '@platform/formiojs-react';
import { AsyncCheckActionType, AsyncCheckStore } from '@platform/front-utils';
import { TitlesDTO } from '@platform/multi-lang-field';
import { AxiosPromise } from 'axios';
import { action, observable } from 'mobx';
import { generatePath } from 'react-router-dom';
import { apiConfigs } from '../apiConfigs';
import { entities, permissions } from '../authSchemeConfig';
import { clientRoute } from '../clientRoute';
import {
    ChartFormDataDTO,
    ChartSettingCreateDTO,
    ChartSettingDTO,
    ChartSettingField,
    ChartSettingItemDTO,
    ChartSettingListDTO,
    CodeTitle,
    CreateCategoryFields,
    CreateDescendantFields,
    CreatePortfolioFields,
    CreateProjectFields,
    DataSourceOptionsDTO,
    ErrorCode,
    GeneralObject,
    GeneralObjectCard,
    GeneralObjectContent,
    GeneralObjectCreateRegistryFields,
    GeneralObjectField,
    GeneralObjectFieldName,
    GeneralObjectServiceInfoDTO,
    GeneralObjectStore,
    GeneralObjectTab,
    NewObjectTabSettingsDTO,
    NewTabPermissionDTO,
    ObjectCreateInfoType,
    ObjectCreateType,
    ObjectInfoTypes,
    ObjectTabSettingsDTO,
    PermissionSettingsDTO,
    PrintFormCreationInfo,
    PrintFormDTO,
    PrintFormFields,
    PrintFormItemDTO,
    PrintFormNewPermissionDTO,
    PrintFormPermissionSettingsDTO,
    PrintFormSettingDTO,
    SettingsContentDTO,
    SettingsContentFields,
    SettingsTabFields,
    TabSettingsContentInfo,
    TabSettingsDTO,
    TabSettingsPermissionsFields,
    TitlesFields,
    TransitionDTO,
} from '../types';
import { getGeneralObjectLinkValue } from '../utils';
import { ApiStore } from './ApiStore';
import { IntlStore } from './IntlStore';
import { RootStore } from './RootStore';

export const GeneralObjectExtendStoreProps = {
    rootStore: observable,
    api: observable,
    mapObjectDTOToCard: action.bound,
    mapServiceInfoToDescription: action.bound,
    getSyslogDescription: action.bound,
    createObject: action.bound,
    getObjectCreateDTO: action.bound,
    deleteObject: action.bound,
    getTransitions: action.bound,
    transitionToNextLifeCycleStep: action.bound,
    loadCard: action.bound,
    loadTabs: action.bound,
    loadServiceDescription: action.bound,
    loadTitles: action.bound,
    saveTitles: action.bound,
    getTabsSettings: action.bound,
    loadCurrentTabContent: action.bound,
    // TabSettings
    addNewTab: action.bound,
    getTabSettings: action.bound,
    saveTabTitles: action.bound,
    loadTabTitles: action.bound,
    getTabPermissionSettings: action.bound,
    getTabContentSettings: action.bound,
    updateTabPermission: action.bound,
    updateTabContent: action.bound,
    deleteTab: action.bound,
    changeTabPosition: action.bound,
    getTabSettingsContentInfo: action.bound,
    getPrintFormsCreationInfo: action.bound,
    downloadAllFiles: action.bound,
    // ChartSettings
    getChartSources: action.bound,
    getRegistries: action.bound,
    getTabsWithForm: action.bound,
    createChartSetting: action.bound,
    changeChartSettingPosition: action.bound,
    getChartSettings: action.bound,
    deleteChartSetting: action.bound,
    getChartSettingInfo: action.bound,
    submitChartSetting: action.bound,
    getPreviewData: action.bound,
    getTabChartSettings: action.bound,
    getTabChartSettingData: action.bound,
    saveChartSettingTitles: action.bound,
    getChartSettingTitles: action.bound,
};

const saveTitlesFields = Object.values(TitlesFields);

const GeneralObjectCreateFields: Record<GeneralObject, GeneralObjectCreateRegistryFields[]> = {
    portfolio: Object.values(CreatePortfolioFields),
    category: Object.values(CreateCategoryFields),
    project: Object.values(CreateProjectFields),
    childObject: Object.values(CreateDescendantFields),
};

export abstract class GeneralObjectExtendStore<
    ObjectDTO extends ObjectInfoTypes,
    ObjectCreateDTO extends ObjectCreateType,
    ObjectCreateInfo extends ObjectCreateInfoType,
> implements GeneralObjectStore
{
    protected rootStore: RootStore;
    protected api: ApiStore;
    protected asyncCheckStore: AsyncCheckStore;
    protected intlStore: IntlStore;

    abstract objectType: GeneralObject;
    abstract isWithTabs: boolean;
    abstract isWithCreateCopy: boolean;
    abstract isWithChartContent: boolean;
    protected objectId = '';

    protected constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
        this.asyncCheckStore = rootStore.asyncCheckStore;
        this.intlStore = rootStore.intlStore;
    }

    abstract mapObjectDTOToCard(data: ObjectDTO): GeneralObjectCard;

    createObject(dto: ObjectCreateDTO, tabId?: string): Promise<string> {
        return this.api
            .userActionClient(
                apiConfigs.createObject(dto, this.objectType, tabId),
                GeneralObjectCreateFields[this.objectType],
            )
            .then((r) => r.data);
    }

    getObjectCreateDTO(): Promise<ObjectCreateInfo> {
        return this.api.client(apiConfigs.getObjectCreateInfo(this.objectType)).then((r) => r.data);
    }

    deleteObject(id: string, withAsyncCheck = false): Promise<void> {
        return this.api
            .userActionClient(apiConfigs.deleteObject(id, this.objectType))
            .then(this.asyncCheckStore.asyncCheck(AsyncCheckActionType.registry, withAsyncCheck))
            .then((r) => r.data);
    }

    getTransitions(id: string): Promise<TransitionDTO[]> {
        return this.api.client(apiConfigs.transitionsObject(id, this.objectType)).then((r) => r.data);
    }

    transitionToNextLifeCycleStep(transitionId: string, objectId: string): Promise<void> {
        return this.api
            .userActionClient(
                apiConfigs.transitionToNextLifeCycleStepObject(transitionId, objectId, this.objectType),
                [],
                [ErrorCode.badRequest],
            )
            .then((r) => r.data);
    }

    loadCard(id: string): Promise<GeneralObjectCard> {
        return this.api
            .mainInfoClient(apiConfigs.getObject(id, this.objectType))
            .then((r) => r.data)
            .then((data: ObjectDTO) => {
                return this.mapObjectDTOToCard(data);
            });
    }

    loadTabs(id: string): Promise<GeneralObjectTab> {
        return this.api.client(apiConfigs.getGeneralTabs(id, this.objectType)).then((r) => r.data);
    }

    loadServiceDescription(id: string): Promise<GeneralObjectField[]> {
        return this.api
            .client(apiConfigs.getObjectServiceInfo(id, this.objectType))
            .then((r) => r.data)
            .then((data: GeneralObjectServiceInfoDTO) => {
                return this.mapServiceInfoToDescription(data);
            });
    }

    mapServiceInfoToDescription(data: GeneralObjectServiceInfoDTO): GeneralObjectField[] {
        const { lifecycleDTO, formInfoDTO, lastModified } = data;
        return [
            {
                id: GeneralObjectFieldName.lifecycle,
                value: lifecycleDTO,
                isExternalUrl: true,
            },
            {
                id: GeneralObjectFieldName.form,
                value: formInfoDTO,
                isExternalUrl: true,
            },
            {
                id: GeneralObjectFieldName.modified,
                value: lastModified,
                isDate: true,
            },
        ];
    }

    getSyslogDescription(id: string): GeneralObjectField {
        return {
            id: GeneralObjectFieldName.syslog,
            isObjectField: true,
            title: '',
            authorization: {
                view: {
                    entityCode: entities.system,
                    permCode: permissions.system.Administration,
                },
            },
            value: getGeneralObjectLinkValue(
                this.intlStore.intl.formatMessage({ id: 'syslog.single.nominative' }),
                generatePath(clientRoute[this.objectType].syslog, { id }),
            ),
        };
    }

    loadTitles(id: string): Promise<TitlesDTO> {
        return this.api.client(apiConfigs.getObjectTitles(id, this.objectType)).then((r) => r.data);
    }

    saveTitles(id: string, dto: TitlesDTO): Promise<void> {
        return this.api
            .userActionClient(apiConfigs.editObjectTitles(id, this.objectType, dto), saveTitlesFields)
            .then((r) => r.data);
    }

    getTabsSettings(id: string): Promise<ObjectTabSettingsDTO[]> {
        return this.api.mainInfoClient(apiConfigs.getObjectTabsSettings(id, this.objectType)).then((r) => r.data);
    }

    editTabArchived(objectId: string, tabId: string, archive: boolean): Promise<void> {
        return this.api
            .mainInfoClient(apiConfigs.editTabArchived(this.objectType, objectId, tabId, archive))
            .then((r) => r.data);
    }

    loadCurrentTabContent(objectId: string, tabId: string): Promise<GeneralObjectContent> {
        return this.api
            .mainInfoClient(apiConfigs.getObjectTabContent(this.objectType, objectId, tabId), true)
            .then((r) => r.data);
    }

    loadCurrentTabPrintForms(tabId: string): Promise<PrintFormDTO[]> {
        return this.api.mainInfoClient(apiConfigs.getObjectTabPrintForms(tabId)).then((r) => r.data);
    }

    saveForm(id: string, tabId: string, submissionData: FullSubmissionWithAdditionalInfo): Promise<void> {
        return this.api
            .userActionClient(apiConfigs.editObjectForm(this.objectType, id, tabId, submissionData))
            .then((r) => r.data);
    }

    // <editor-fold decs="Настройки вкладок">
    addNewTab(id: string, dto: NewObjectTabSettingsDTO): Promise<ObjectTabSettingsDTO> {
        return this.api
            .userActionClient(apiConfigs.addNewObjectTab(id, this.objectType, dto), Object.values(SettingsTabFields))
            .then((r) => r.data);
    }

    getTabSettings(objectId: string, tabId: string): Promise<TabSettingsDTO> {
        return this.api.mainInfoClient(apiConfigs.getTabSettings(objectId, this.objectType, tabId)).then((r) => r.data);
    }

    saveTabTitles(objectId: string, tabId: string, dto: TitlesDTO): Promise<void> {
        return this.api
            .userActionClient(apiConfigs.saveTabTitles(objectId, this.objectType, tabId, dto), saveTitlesFields)
            .then((r) => r.data);
    }

    loadTabTitles(objectId: string, tabId: string): Promise<TitlesDTO> {
        return this.api.client(apiConfigs.getTabTitles(objectId, this.objectType, tabId)).then((r) => r.data);
    }

    getTabPermissionSettings(objectId: string, tabId: string): Promise<PermissionSettingsDTO> {
        return this.api
            .mainInfoClient(apiConfigs.getTabPermissionSettings(objectId, this.objectType, tabId))
            .then((r) => r.data);
    }

    getPFPermissionSettings(tabId: string, pfId: string): Promise<PrintFormPermissionSettingsDTO> {
        return this.api.mainInfoClient(apiConfigs.getPFPermissionSettings(tabId, pfId)).then((r) => r.data);
    }

    getTabContentSettings(objectId: string, tabId: string): Promise<SettingsContentDTO> {
        return this.api
            .mainInfoClient(apiConfigs.getTabContentSettings(objectId, this.objectType, tabId))
            .then((r) => r.data);
    }

    updateTabPermission(objectId: string, tabId: string, dto: NewTabPermissionDTO[]): Promise<void> {
        return this.api
            .userActionClient(
                apiConfigs.updateTabPermission(objectId, this.objectType, tabId, dto),
                Object.values(TabSettingsPermissionsFields),
            )
            .then((r) => r.data);
    }

    updatePFPermission(tabId: string, pfId: string, dto: PrintFormNewPermissionDTO[]): Promise<void> {
        return this.api
            .userActionClient(
                apiConfigs.updatePFPermission(tabId, pfId, dto),
                Object.values(TabSettingsPermissionsFields),
            )
            .then((r) => r.data);
    }

    updateTabContent(objectId: string, tabId: string, dto: SettingsContentDTO): Promise<void> {
        return this.api
            .userActionClient(
                apiConfigs.updateTabContent(objectId, this.objectType, tabId, dto),
                Object.values(SettingsContentFields),
            )
            .then((r) => r.data);
    }

    deleteTab(objectId: string, tabId: string): Promise<void> {
        return this.api.userActionClient(apiConfigs.deleteTab(objectId, this.objectType, tabId)).then((r) => r.data);
    }

    changeTabPosition(objectId: string, tabId: string, newPosition: number): Promise<void> {
        return this.api
            .userActionClient(apiConfigs.changeTabPosition(objectId, this.objectType, tabId, newPosition))
            .then((r) => r.data);
    }

    getContentObjectList(): Promise<CodeTitle[]> {
        return this.api.client(apiConfigs.getContentObjectList(this.objectType)).then((r) => r.data);
    }

    getTabSettingsContentInfo(registryId: string, objectType: GeneralObject): Promise<TabSettingsContentInfo> {
        return this.api.client(apiConfigs.getTabSettingsContentInfo(registryId, objectType)).then((r) => r.data);
    }

    getPrintFormsList(objectId: string, tabId: string): Promise<PrintFormItemDTO[]> {
        return this.api.client(apiConfigs.getPrintFormsList(this.objectType, objectId, tabId)).then((r) => r.data);
    }

    changePFPosition(tabId: string, pfId: string, newPosition: number): Promise<void> {
        return this.api.userActionClient(apiConfigs.changePFPosition(tabId, pfId, newPosition)).then((r) => r.data);
    }

    getPrintForm(tabId: string, pfId: string): Promise<PrintFormSettingDTO> {
        return this.api.mainInfoClient(apiConfigs.getPrintForm(tabId, pfId)).then((r) => r.data);
    }

    getPrintFormsCreationInfo(tabId: string): Promise<PrintFormCreationInfo> {
        return this.api.client(apiConfigs.getPrintFormsCreationInfo(tabId)).then((r) => r.data);
    }

    addPrintForm(tabId: string, dto: FormData): Promise<void> {
        return this.api
            .userActionClient(apiConfigs.addPrintForm(tabId, dto), Object.values(PrintFormFields))
            .then((r) => r.data);
    }

    editPrintForm(tabId: string, pfId: string, dto: FormData): Promise<void> {
        return this.api
            .userActionClient(apiConfigs.editPrintForm(tabId, pfId, dto), Object.values(PrintFormFields))
            .then((r) => r.data);
    }

    downloadAllFiles(tabId: string): Promise<Blob> {
        return this.api.userActionClient(apiConfigs.downloadAllFiles(tabId)).then((r) => r.data);
    }

    downloadTemplatePrintForm(tabId: string, pfId: string): Promise<Blob> {
        return this.api.userActionClient(apiConfigs.downloadTemplatePrintForm(tabId, pfId)).then((r) => r.data);
    }

    downloadPrintForm(tabId: string, pfSettingId: string): Promise<Blob> {
        return this.api.userActionClient(apiConfigs.downloadPrintForm(tabId, pfSettingId)).then((r) => r.data);
    }

    deletePrintForm(tabId: string, pfId: string): Promise<void> {
        return this.api.userActionClient(apiConfigs.deletePrintForm(tabId, pfId)).then((r) => r.data);
    }

    generatePrintForm(tabId: string, pfSettingId: string): Promise<void> {
        return this.api.userActionClient(apiConfigs.generatePrintForm(tabId, pfSettingId)).then((r) => r.data);
    }

    changeChartSettingPosition(tabId: string, chartSettingId: string, newPosition: number): AxiosPromise<void> {
        return this.api.userActionClient(apiConfigs.changeChartSettingPosition(tabId, chartSettingId, newPosition));
    }

    deleteChartSetting(chartSettingId: string): Promise<void> {
        return this.api.userActionClient(apiConfigs.deleteChartSetting(chartSettingId)).then((r) => r.data);
    }

    getChartSettings(tabId: string, objectId: string): Promise<ChartSettingListDTO[]> {
        return this.api
            .mainInfoClient(apiConfigs.getChartSettingList(this.objectType, objectId, tabId))
            .then((r) => r.data);
    }

    createChartSetting(tabId: string, dto: ChartSettingCreateDTO): Promise<string> {
        return this.api
            .userActionClient(apiConfigs.createChartSetting(tabId, dto), Object.values(TitlesFields))
            .then((r) => r.data);
    }

    getTabsWithForm(objectId: string): Promise<CodeTitle[]> {
        return this.api.client(apiConfigs.getObjectTabsSettingsWithForm(objectId, this.objectType)).then((r) => r.data);
    }

    getChartSources(): Promise<CodeTitle[]> {
        return this.api.client(apiConfigs.getChartSources).then((r) => r.data);
    }

    getRegistries(objectId: string): Promise<CodeTitle[]> {
        return this.api.client(apiConfigs.getRegistries(objectId, this.objectType)).then((r) => r.data);
    }

    getChartSettingInfo(chartSettingId: string): Promise<ChartSettingItemDTO> {
        return this.api.mainInfoClient(apiConfigs.getChartSettingPage(chartSettingId)).then((r) => r.data);
    }

    submitChartSetting(chartSettingId: string, dto: ChartSettingDTO): Promise<void> {
        return this.api
            .userActionClient(apiConfigs.submitChartSetting(chartSettingId, dto), Object.values(ChartSettingField))
            .then((r) => r.data);
    }

    getPreviewData(chartSettingId: string, dto: DataSourceOptionsDTO): Promise<ChartFormDataDTO> {
        return this.api
            .userActionClient(apiConfigs.getPreviewData(chartSettingId, dto), Object.values(ChartSettingField))
            .then((r) => r.data);
    }

    saveChartSettingTitles(chartSettingId: string, dto: TitlesDTO): Promise<void> {
        return this.api
            .userActionClient(apiConfigs.saveChartSettingTitles(chartSettingId, dto), Object.values(TitlesFields))
            .then((r) => r.data);
    }

    getChartSettingTitles(chartSettingId: string): Promise<TitlesDTO> {
        return this.api.client(apiConfigs.getChartSettingTitles(chartSettingId)).then((r) => r.data);
    }

    getTabChartSettings(tabId: string): Promise<ChartSettingItemDTO[]> {
        return this.api.client(apiConfigs.getTabChartSettings(tabId)).then((r) => r.data);
    }

    getTabChartSettingData(chartSettingId: string): Promise<ChartFormDataDTO> {
        return this.api.client(apiConfigs.getTabChartSettingData(chartSettingId)).then((r) => r.data);
    }

    // <editor-fold>
}
