import { getRuTitleInitialValue, TitlesDTO, titlesToTitlesDTO } from '@platform/multi-lang-field';
import { History } from 'history';
import { action, makeObservable, observable } from 'mobx';
import { generatePath } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { CreateCategoryCopyDialogValues } from '../../pages';
import { categoryMessages } from '../../resources';
import { CategoryStore, IntlStore, NotificationStore, RootStore } from '../../stores';
import { CategoryCreateDTO, CreateCategoryCopyFields } from '../../types';
import { ServerErrorsModel } from '../ServerErrorsModel';

export const CreateCategoryCopyModelProps = {
    categoryStore: observable,
    history: observable,
    intlStore: observable,
    prototypeCategoryId: observable,
    prototypeCategoryTitles: observable,
    isPrototypeCategoryTitlesLoaded: observable,
    serverErrors: observable,
    loadPrototypeCategoryTitles: action.bound,
    createCategoryCopy: action.bound,
    setPrototypeCategoryTitles: action.bound,
    setIsPrototypeCategoryTitlesLoaded: action.bound,
};

export class CreateCategoryCopyModel {
    private categoryStore: CategoryStore;
    private notificationStore: NotificationStore;
    private intlStore: IntlStore;
    private history: History;

    prototypeCategoryId: string;
    prototypeCategoryTitles: TitlesDTO;
    isPrototypeCategoryTitlesLoaded = false;
    serverErrors: ServerErrorsModel<CreateCategoryCopyFields>;

    constructor(rootStore: RootStore, prototypeCategoryId: string) {
        makeObservable(this, CreateCategoryCopyModelProps);
        this.categoryStore = rootStore.categoryStore;
        this.notificationStore = rootStore.notificationStore;
        this.history = rootStore.history;
        this.intlStore = rootStore.intlStore;
        this.prototypeCategoryId = prototypeCategoryId;
        this.prototypeCategoryTitles = titlesToTitlesDTO(getRuTitleInitialValue());
        this.serverErrors = new ServerErrorsModel<CreateCategoryCopyFields>();
    }

    async loadPrototypeCategoryTitles(isModalOpen: boolean): Promise<void> {
        if (isModalOpen && !this.prototypeCategoryTitles.isLoaded) {
            try {
                const prototypeCategoryTitles = await this.categoryStore.loadTitles(this.prototypeCategoryId);
                const prototypeCategoryTitlesWithSuffixes: TitlesDTO = {};
                Object.keys(prototypeCategoryTitles).forEach((lang) => {
                    prototypeCategoryTitlesWithSuffixes[lang] = prototypeCategoryTitles[lang] + ' (1)';
                });
                this.setPrototypeCategoryTitles(prototypeCategoryTitlesWithSuffixes);
            } finally {
                this.setIsPrototypeCategoryTitlesLoaded(true);
            }
        }
    }

    async createCategoryCopy(values: CreateCategoryCopyDialogValues): Promise<void> {
        this.serverErrors.dropServerFormErrors();

        const dto: CategoryCreateDTO = {
            titles: titlesToTitlesDTO(values.titles),
        };

        try {
            const createdCategoryId = await this.categoryStore.createCategoryCopy(dto, this.prototypeCategoryId);
            this.notificationStore.onSuccess(
                this.intlStore.formatMessageFromDefineMessage(categoryMessages.copyCreated),
            );
            this.history.push(
                generatePath(clientRoute.category.card, {
                    id: createdCategoryId,
                }),
            );
        } catch (e: any) {
            this.serverErrors.setServerFormErrors(e);
        }
    }

    setPrototypeCategoryTitles(titles: TitlesDTO): void {
        this.prototypeCategoryTitles = titles;
    }

    setIsPrototypeCategoryTitlesLoaded(isLoaded: boolean): void {
        this.isPrototypeCategoryTitlesLoaded = isLoaded;
    }
}
