import { TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const renderChartTableHead = (): JSX.Element => {
    return (
        <TableHead>
            <TableRow>
                <TableCell width="31.5%">
                    <Typography variant="body1">
                        <FormattedMessage id="objectSettings.visualizationFields.titles" />
                    </Typography>
                </TableCell>
                <TableCell width="19.5%">
                    <Typography variant="body1">
                        <FormattedMessage id="objectSettings.visualizationFields.type" />
                    </Typography>
                </TableCell>
                <TableCell width="19.5%">
                    <Typography variant="body1">
                        <FormattedMessage id="objectSettings.visualizationFields.source" />
                    </Typography>
                </TableCell>
                <TableCell width="29.5%" />
            </TableRow>
        </TableHead>
    );
};
