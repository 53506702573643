import { FieldValues as LangTitle, TitlesDTO } from '@platform/multi-lang-field';
import { ConfirmationDialogProps } from '../components';
import { CodeTitleGroupRC } from '../stores';
import { GeneralObjectWithDescendantObject, ResponsibleFields } from './generalObject';
import { PrintFormFields, PrintFormSettingsPermissionsFields } from './printForms';
import { CommonSystemEntityPermissionSettingsFields } from './systemEntities';
import { CodeTitle, CodeTitleNull, IdTitle } from './withTitle';

export type TabSettingsContentInfo = {
    categories: IdTitle[];
    presets: IdTitle[];
};

export type TabSettingsDTO = {
    id: string;
    title: string;
};

export type ObjectTabSettingsDTO = {
    id: string;
    code: string;
    contentType: SettingsContentType[];
    title: string;
    positionRank: number;
    archived: boolean;
};

export type NewObjectTabSettings = {
    titles: LangTitle[];
    code: string;
};

export type NewObjectTabSettingsDTO = {
    titles: TitlesDTO;
    code: string;
};

export type PermissionSettingsDTO = {
    processCode: string;
    permissions: TabAccessPermissionDTO[];
};

export type TabAccessPermissions = {
    filesDownload: boolean;
    editForm: boolean;
    filesUpload: boolean;
    filesEdit: boolean;
};

export type TabAccessPermissionValues = TabAccessPermissionDTO & {
    permissions: TabAccessPermissions & {
        edit: boolean;
        filesManagement: boolean;
    };
};

export type TabAccessPermissionDTO = {
    roleCondition: CodeTitleGroupRC;
    whenObjectStateIsOneOf: CodeTitle[];
    permissions: TabAccessPermissions;
};

export type NewTabPermissionDTO = {
    role?: string;
    when?: string;
    whenObjectStateIsOneOf: string[];
    permissions: TabAccessPermissions;
};

export type LoadPermissions = () => Promise<PermissionSettingsDTO>;
export type UpdatePermissions = (dto: NewTabPermissionDTO[]) => Promise<void>;

export type EntityCodeTitle = {
    code: GeneralObjectWithDescendantObject;
    title: string;
};

export type SettingsContent = {
    form: SettingsContentForm;
    list: SettingsContentList;
};

export type SettingsContentList = {
    entity: EntityCodeTitle | null;
    preset: IdTitle | null;
    categories: IdTitle[];
};

export type SettingsContentForm = {
    formData: CodeTitleNull;
    isDownloadAllFilesButtonShown: boolean;
};

export type SettingsContentDTO = {
    form:
        | (CodeTitle & {
              batchDownloadFiles: boolean;
          })
        | null;
    list: SettingsContentListDTO | null;
};

export type SettingsContentListDTO = {
    entity: EntityCodeTitle;
    preset: IdTitle;
    categories: IdTitle[];
};

export enum SettingsContentType {
    form = 'FORM',
    list = 'LIST',
    pf = 'PF',
    CHART = 'CHART',
}

export enum TitlesFields {
    titles = 'titles',
}

export enum SettingsTabFields {
    titles = 'titles',
    code = 'code',
}

export enum TabSettingsPermissionsFields {
    roleCondition = 'roleCondition',
    whenObjectStateIsOneOf = 'whenObjectStateIsOneOf',
    filesDownload = 'permissions.filesDownload',
    edit = 'permissions.edit',
    editForm = 'permissions.editForm',
    filesManagement = 'permissions.filesManagement',
    filesUpload = 'permissions.filesUpload',
    filesEdit = 'permissions.filesEdit',
}

export enum SettingsContentFields {
    formData = 'form.formData',
    isDownloadAllFilesButtonShown = 'form.isDownloadAllFilesButtonShown',
    entity = 'list.entity',
    preset = 'list.preset',
    categories = 'list.categories',
}

export enum NumberField {
    number = 'number',
}

export type SettingsFields =
    | TitlesFields
    | SettingsTabFields
    | TabSettingsPermissionsFields
    | SettingsContentFields
    | PrintFormFields
    | PrintFormSettingsPermissionsFields
    | NumberField
    | ResponsibleFields
    | CommonSystemEntityPermissionSettingsFields;

export enum TabSettingConfirmModalType {
    deleteTab = 'deleteTab',
    archiveTab = 'archiveTab',
    unarchiveTab = 'unarchiveTab',
}

export type ComputedConfirmationDialogProps = Record<
    TabSettingConfirmModalType,
    Pick<ConfirmationDialogProps, 'id' | 'title' | 'message' | 'onConfirm'>
>;
