import { action, makeObservable, observable } from 'mobx';
import { FileDTO, GeneralObjectStore, PrintFormDTO, PrintFormStatus, PrintFormStatusCode } from '../../types';

export const PrintFormModelProps = {
    objectStore: observable,
    tabId: observable,
    pfSettingId: observable,
    filename: observable,
    pfId: observable,
    pf: observable,
    docType: observable,
    status: observable,
    generated: observable,
    isGenerating: observable,
    required: observable,
    load: action.bound,
    generatePrintForm: action.bound,
    setIsGenerating: action.bound,
};

export class PrintFormModel {
    private objectStore: GeneralObjectStore;

    tabId = '';
    pfSettingId = '';
    filename = '';
    required = '';
    pfId?: string;
    pf?: FileDTO;
    docType?: string;
    status: PrintFormStatus = {
        code: PrintFormStatusCode.new,
        title: '',
    };
    generated?: string;

    isGenerating = false;

    constructor(dto: PrintFormDTO, tabId: string, objectStore: GeneralObjectStore) {
        makeObservable(this, PrintFormModelProps);
        this.tabId = tabId;
        this.objectStore = objectStore;
        this.load(dto);
    }

    load(dto: PrintFormDTO): void {
        const { pfSettingId, filename, pfId, pf, docType, status, generated, required } = dto;
        this.pfSettingId = pfSettingId;
        this.filename = filename;
        this.pfId = pfId;
        this.pf = pf;
        this.docType = docType;
        this.status = status;
        this.generated = generated;
        this.required = required;

        const isGenerating = status.code === PrintFormStatusCode.processing;
        this.setIsGenerating(isGenerating);
    }

    generatePrintForm(): Promise<void> {
        this.setIsGenerating(true);
        return this.objectStore.generatePrintForm(this.tabId, this.pfSettingId);
    }

    setIsGenerating(isGenerating: boolean): void {
        this.isGenerating = isGenerating;
    }
}
