import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TextField as MuiTextField } from '@mui/material';
import { getOptionLabelCreator } from '@platform/multi-lang-field';
import { Field, FieldProps, useFormikContext } from 'formik';
import { Autocomplete, AutocompleteRenderInputParams, TextFieldProps } from 'formik-mui';
import { observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useFormikErrors as useFormikErrorsInj } from '../../hooks';
import { CodeTitleOrIdTitle, FormValues } from '../../types';
import { RequiredLabel as RequiredLabelInj } from '../RequiredLabel';

export type AutocompleteFieldProps<T extends CodeTitleOrIdTitle> = {
    options: T[];
    label?: ReactNode;
    fieldName: string;
    autocompleteParams?: Partial<FieldProps>;
    textFieldParams?: Partial<TextFieldProps>;
    filterSelectedOptions?: boolean;
    disableClearable?: boolean;
    disabled?: boolean;
    multiple?: boolean;
    required?: boolean;
    groupBy?: (option: T) => string;
    getLabel?: (option: T) => string;
    onChange?: (value: T) => void;
    serverError?: string;
    id?: string;
    loading?: boolean;
};

export const chipSxStyles = {
    maxWidth: 300,
};

export const AutocompleteField = observer(
    <T extends CodeTitleOrIdTitle>(props: AutocompleteFieldProps<T>): JSX.Element => {
        const [RequiredLabel] = di([RequiredLabelInj], AutocompleteField);
        const [useFormikErrors] = di([useFormikErrorsInj], AutocompleteField);

        const {
            options,
            label,
            fieldName,
            textFieldParams,
            disableClearable = false,
            disabled = false,
            filterSelectedOptions = false,
            multiple = false,
            required = false,
            groupBy,
            getLabel,
            onChange,
            serverError,
            id,
            loading = false,
        } = props;
        const intl = useIntl();
        const { locale } = intl;
        const formikContext = useFormikContext<FormValues>();
        const { setFieldValue } = formikContext;
        const [error, helperText] = useFormikErrors(formikContext, fieldName, serverError);
        const [searchValue, setSearchValue] = React.useState<string>('');

        const getOptionLabel = getLabel || getOptionLabelCreator(locale);

        const handleChange = (event: React.ChangeEvent<HTMLInputElement>, value: T): void => {
            setFieldValue(fieldName, value);
            onChange && onChange(value);
        };

        // Исправляет срабатывание поиска
        const triggerSearch = (value: string): void => {
            setSearchValue(value + ' ');
            setSearchValue(value);
        };

        const onSearchInputChange = (event: React.ChangeEvent<HTMLInputElement> | null, value: string): void => {
            setSearchValue(value);
            if (event) {
                setTimeout(() => triggerSearch(value), 0);
            }
        };

        const getSelectedOption = (option: T, value: T): boolean => {
            if (option.id) {
                return option.id === value.id;
            }

            return option.code === value.code;
        };

        const requiredLabel = required ? <RequiredLabel text={label} /> : label;
        const inputLabel = label ? requiredLabel : '';
        const inputLabelProps = !label && { shrink: false };

        const chipProps = {
            sx: chipSxStyles,
        };

        const renderInput = (params: AutocompleteRenderInputParams): JSX.Element => (
            <MuiTextField
                {...params}
                {...textFieldParams}
                {...(inputLabelProps && { InputLabelProps: inputLabelProps })}
                name={fieldName}
                error={error}
                helperText={helperText}
                label={inputLabel}
                variant="outlined"
                color="primary"
                margin="dense"
            />
        );

        return (
            <Field
                fullWidth
                onChange={handleChange}
                disabled={disabled}
                disableClearable={disableClearable}
                filterSelectedOptions={filterSelectedOptions}
                name={fieldName}
                size="small"
                id={id}
                component={Autocomplete}
                options={options}
                multiple={multiple}
                getOptionLabel={getOptionLabel}
                getOptionSelected={getSelectedOption}
                isOptionEqualToValue={getSelectedOption}
                {...(groupBy && { groupBy })}
                popupIcon={<ExpandMoreIcon />}
                ChipProps={chipProps}
                inputValue={searchValue}
                onInputChange={onSearchInputChange}
                renderInput={renderInput}
                loading={loading}
                noOptionsText={intl.formatMessage({ id: 'autocompleteField.noResultsFound' })}
            />
        );
    },
);
