import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { LangFieldGeneralProps, MultiLangFieldGroup, titlesYupSchema } from '@platform/multi-lang-field';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { ServerErrorHelper as ServerErrorHelperInj } from '../../../../../../components';
import { useAntiDoubleClick, useStore, useUserPageContext, useYup } from '../../../../../../hooks';
import { FullNameEditModel } from '../../../../../../models';
import { ActionItemModalProps, FullNameFields, FullNameGroupFields, FullNameSettings } from '../../../../../../types';
import { disableSubmitOnEnterKeyPress, stopPropagation } from '../../../../../../utils';

export const UserPageFullNameModal = observer((props: ActionItemModalProps): JSX.Element => {
    const [ServerErrorHelper] = di([ServerErrorHelperInj], UserPageFullNameModal);

    const { isOpen, setIsClosed, hideMenu } = props;
    const { userStore } = useStore();
    const intl = useIntl();
    const { locale } = intl;
    const { userModel } = useUserPageContext();
    const { id, load } = userModel;

    const model = useMemo(() => new FullNameEditModel(userStore, id), [userStore, id]);
    const { loadFullName, serverErrors, setFullName, lastName, middleName, firstName } = model;
    const { dropServerFormErrors, serverFormErrors } = serverErrors;
    const { fullName } = FullNameFields;
    const { middleName: middleNameField, lastName: lastNameField, firstName: firstNameField } = FullNameGroupFields;
    const serverFullNameError = serverFormErrors[fullName];

    useEffect(() => {
        loadFullName();
    }, []);

    const onClose = (): void => {
        setIsClosed();
        dropServerFormErrors();
    };

    const initialValues: FullNameSettings = {
        lastName,
        firstName,
        middleName,
    };

    const { Yup } = useYup();
    const validationSchema = Yup.object({
        lastName: titlesYupSchema(Yup, true).min(1),
        firstName: titlesYupSchema(Yup, true).min(1),
        middleName: titlesYupSchema(Yup, false),
    });

    const onSuccess = (): void => {
        load();
        onClose();
        hideMenu && hideMenu();
    };

    const onSubmit = (values: FullNameSettings): Promise<void> => {
        return setFullName(values, onSuccess);
    };

    const [isSending, endIcon, createHandler] = useAntiDoubleClick(onSubmit);

    const fieldGroup: LangFieldGeneralProps[] = [
        {
            fieldName: firstNameField,
            label: intl.formatMessage({ id: 'user.fullNameFields.firstName' }),
        },
        {
            fieldName: lastNameField,
            label: intl.formatMessage({ id: 'user.fullNameFields.lastName' }),
        },
        {
            fieldName: middleNameField,
            label: intl.formatMessage({ id: 'user.fullNameFields.middleName' }),
            isRequired: false,
        },
    ];

    return (
        <Dialog fullWidth={true} maxWidth="sm" open={isOpen} onKeyDown={stopPropagation}>
            <DialogTitle>
                <FormattedMessage id="user.settings.fullName" />
            </DialogTitle>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={createHandler}
                enableReinitialize={true}
            >
                <Form onKeyDown={disableSubmitOnEnterKeyPress}>
                    <DialogContent>
                        <MultiLangFieldGroup
                            fieldGroup={fieldGroup}
                            groupFieldName={fullName}
                            systemLanguage={locale}
                            ruEngLangs={true}
                        />
                        <ServerErrorHelper serverError={serverFullNameError} />
                    </DialogContent>

                    <DialogActions>
                        <Button color="primary" onClick={onClose}>
                            <FormattedMessage id="common.cancel" />
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={isSending}
                            endIcon={endIcon}
                        >
                            <FormattedMessage id="common.save" />
                        </Button>
                    </DialogActions>
                </Form>
            </Formik>
        </Dialog>
    );
});
