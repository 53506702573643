import { Grid, Theme, Typography } from '@mui/material';
import { TTable, TTableStoreVisibleSettings } from '@platform/ttable';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { entities, permissions } from '../../../../../../authSchemeConfig';
import { useFlag, useGeneralObjectContext, useLocale, useStore } from '../../../../../../hooks';
import {
    AuthorizationCheckQuery,
    GeneralObjectRegistryRow,
    GeneralObjectRouteParams,
    GeneralObjectTabRegistryParams,
    GeneralObjectWithDescendantObject,
    RegistryCode,
    SettingsContentListDTO,
} from '../../../../../../types';
import { AuthorizationCheckAll as AuthorizationCheckAllInj } from '../../../../../AuthorizationCheckAll';
import { ErrorBoundary as ErrorBoundaryInj } from '../../../../../ErrorBoundary';
import {
    generalObjectTabContentSxStyles as sx,
    generalObjectTabContextInnerSx,
    generalObjectTabContextWrapperSx,
} from '../../useGeneralObjectTabContentStyles';
import { GeneralObjectRegistryDescendantCreateButton as GeneralObjectRegistryDescendantCreateButtonInj } from './GeneralObjectRegistryDescendantCreateButton';
import { GeneralObjectWithoutAvailableRegistry as GeneralObjectWithoutAvailableRegistryInj } from './GeneralObjectWithoutAvailableRegistry';

export type GeneralObjectContentRegistryProps = {
    registry: SettingsContentListDTO;
};

const createRegistryPermissionQueries = (presetId: string): AuthorizationCheckQuery[] => [
    {
        entityCode: entities.system,
        permCode: permissions.system.Administration,
    },
    {
        entityCode: entities.preset,
        permCode: permissions.preset.View,
        entityId: presetId,
    },
];

const createPermissionButtonQueries = (tabId: string, isEntityTypeProject: boolean): AuthorizationCheckQuery[] => {
    const queries: AuthorizationCheckQuery[] = [
        {
            entityCode: entities.tab,
            permCode: permissions.tab.Edit,
            entityId: tabId,
        },
    ];
    if (isEntityTypeProject) {
        queries.push({
            entityCode: entities.system,
            permCode: permissions.system.CreateProject,
        });
    }

    return queries;
};

const registryVisibleSettings: TTableStoreVisibleSettings = {
    toolbar: {
        isWithPresets: false,
        isWithAddCustomColumns: false,
        isWithCollapseRowMode: true,
        isWithFilters: false,
    },
    column: {
        isWithColumnMenu: false,
        isWithDraggableColumns: false,
    },
};

export const GeneralObjectContentRegistry = observer((props: GeneralObjectContentRegistryProps): JSX.Element => {
    const [AuthorizationCheckAll] = di([AuthorizationCheckAllInj], GeneralObjectContentRegistry);
    const [ErrorBoundary] = di([ErrorBoundaryInj], GeneralObjectContentRegistry);
    const [GeneralObjectRegistryDescendantCreateButton] = di(
        [GeneralObjectRegistryDescendantCreateButtonInj],
        GeneralObjectContentRegistry,
    );
    const [GeneralObjectWithoutAvailableRegistry] = di(
        [GeneralObjectWithoutAvailableRegistryInj],
        GeneralObjectContentRegistry,
    );

    const { registry } = props;
    const { entity, preset } = registry;
    const entityCode = entity.code;
    const { catalogStore, userStore, api } = useStore();
    const intl = useIntl();
    const { id, tabId } = useParams<GeneralObjectRouteParams>();
    const { objectStore, renderRegistryModals } = useGeneralObjectContext();
    const [isRegistryError, setIsRegistryError] = useFlag();

    const apiParams: GeneralObjectTabRegistryParams = {
        tabOwnerId: id,
        tabId,
        entityType: objectStore.objectType,
    };

    const CreateDescendantDialog = renderRegistryModals && renderRegistryModals[entityCode];
    const isWithCreateButton = !isRegistryError && CreateDescendantDialog;
    const isEntityTypeProject = entityCode === GeneralObjectWithDescendantObject.project;

    const permissionButtonQueries = createPermissionButtonQueries(tabId, isEntityTypeProject);
    const registryPermissionQueries = createRegistryPermissionQueries(preset.id);

    const replacementComponent = (
        <GeneralObjectWithoutAvailableRegistry title={intl.formatMessage({ id: 'generalObject.registryWithError' })} />
    );
    const [tableTitle] = useLocale(`${entityCode}.plural.accusative`);

    return (
        <Grid container direction="column" sx={generalObjectTabContextWrapperSx}>
            <Grid container item justifyContent="space-between" sx={generalObjectTabContextInnerSx}>
                <Grid item>
                    <Typography variant="h5" component="h3">
                        <FormattedMessage id="common.list" />
                    </Typography>
                </Grid>
                {isWithCreateButton && (
                    <Grid item>
                        <GeneralObjectRegistryDescendantCreateButton
                            CreateDescendantDialog={CreateDescendantDialog}
                            permissionQueries={permissionButtonQueries}
                        />
                    </Grid>
                )}
            </Grid>
            <Grid item sx={sx.fullwidth}>
                <ErrorBoundary setIsError={setIsRegistryError} replacementComponent={replacementComponent}>
                    <AuthorizationCheckAll or={false} queries={registryPermissionQueries}>
                        {(permissions: boolean[]): JSX.Element => {
                            const [isAdministrationAllowed, isViewPresetRegistryAllowed] = permissions;
                            return isViewPresetRegistryAllowed ? (
                                <TTable<GeneralObjectRegistryRow>
                                    tableTitle={tableTitle}
                                    client={api.client}
                                    registryCode={RegistryCode.tab}
                                    fetchCatalog={catalogStore.getCatalog}
                                    fetchSelectDataByUrl={catalogStore.getSelectDataByUrl}
                                    lang={intl.locale}
                                    fetchUserRoleList={userStore.userRoleList}
                                    isAdmin={isAdministrationAllowed}
                                    monoPresetId={preset.id}
                                    visibleSettings={registryVisibleSettings}
                                    mainApiParams={apiParams}
                                    key={tabId}
                                />
                            ) : (
                                <GeneralObjectWithoutAvailableRegistry
                                    title={intl.formatMessage({ id: 'generalObject.registryIsNotAvailable' })}
                                />
                            );
                        }}
                    </AuthorizationCheckAll>
                </ErrorBoundary>
            </Grid>
        </Grid>
    );
});
