import { Grid } from '@mui/material';
import { SxStyle } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { mailToLink as mailToLinkInj } from '../../../../formatters';
import { useGeneralObjectCardContext, useStore } from '../../../../hooks';
import { SearchIcon } from '../../../../resources';
import { getEmailFromText } from '../../../../utils';
import {
    CommonTypography as CommonTypographyInj,
    TitleBoldTypography as TitleBoldTypographyInj,
} from '../../../typography';
import { generalObjectWithoutAvailableInfoSxStyles as sx } from './useGeneralObjectTabContentStyles';

const searchIconWrapperSxStyle: SxStyle = { marginBottom: 5 };

export const GeneralObjectWithoutAvailableContent = observer((): JSX.Element => {
    const [mailToLink] = di([mailToLinkInj], GeneralObjectWithoutAvailableContent);
    const [CommonTypography] = di([CommonTypographyInj], GeneralObjectWithoutAvailableContent);
    const [TitleBoldTypography] = di([TitleBoldTypographyInj], GeneralObjectWithoutAvailableContent);

    const { objectModel } = useGeneralObjectCardContext();
    const { isWithoutAvailableTabs, isTabsLoading } = objectModel;
    const { env } = useStore();
    const { totMail = '' } = env;
    const hrefEmail = getEmailFromText(totMail);

    const administrationContactFormattedMessageValues = {
        a: mailToLink(hrefEmail),
    };

    return (
        <React.Fragment>
            {!isTabsLoading && (
                <Grid
                    container
                    item
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ flexBasis: '70%' }}
                    spacing={1}
                >
                    <Grid item sx={searchIconWrapperSxStyle}>
                        <SearchIcon />
                    </Grid>
                    <Grid item>
                        <TitleBoldTypography sx={sx.title}>
                            {isWithoutAvailableTabs ? (
                                <FormattedMessage id="generalObject.withoutAvailableTabs" />
                            ) : (
                                <FormattedMessage id="generalObject.emptyTabs" />
                            )}
                        </TitleBoldTypography>
                    </Grid>
                    <Grid item>
                        {hrefEmail && (
                            <CommonTypography sx={sx.text}>
                                <FormattedMessage
                                    id="generalObject.administrationContact"
                                    values={administrationContactFormattedMessageValues}
                                />
                            </CommonTypography>
                        )}
                    </Grid>
                </Grid>
            )}
        </React.Fragment>
    );
});
