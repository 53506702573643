import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';
import { FieldAttributes } from 'formik/dist/Field';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { useFormikErrors as useFormikErrorsInj } from '../../hooks';
import { FormValues } from '../../types';
import { RequiredLabel as RequiredLabelInj } from '../RequiredLabel';

export type FieldWithServerErrorProps = FieldAttributes<any> & {
    serverError?: string;
    disabled?: boolean;
};

export const FieldWithServerError = observer((props: FieldWithServerErrorProps): JSX.Element => {
    const [useFormikErrors] = di([useFormikErrorsInj], FieldWithServerError);
    const [RequiredLabel] = di([RequiredLabelInj], FieldWithServerError);

    const {
        size = 'small',
        component = TextField,
        name,
        serverError,
        helperText: defaultHelperText,
        disabled = false,
        required = false,
        label,
        ...fieldProps
    } = props;
    const formikContext = useFormikContext<FormValues>();
    const [error, helperText] = useFormikErrors(formikContext, name, serverError, defaultHelperText);

    const requiredLabel = required ? <RequiredLabel text={label} /> : label;

    return (
        <Field
            component={component}
            size={size}
            name={name}
            helperText={helperText}
            error={error}
            disabled={disabled}
            margin="dense"
            label={requiredLabel}
            {...fieldProps}
        />
    );
});
