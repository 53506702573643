import { LocalesTree, ObjectLocales } from '../../../types';
import { createLocalesWithoutCases } from '../../../utils';

const category: ObjectLocales & LocalesTree = {
    ...createLocalesWithoutCases('category', 'categories'),
    createFields: {
        titles: 'Title',
        form: 'Form',
        process: 'Lifecycle',
    },
    phrases: {
        create: 'Create {category}',
        createCopy: 'Create a copy of the {category}',
        copyCreated: '{category} copy created',
        tabDeleteText: 'The tab is going to be deleted in all the objects based on this {category}',
        tabSettingsList: '{category} of objects to be created',
    },
};

export default category;
