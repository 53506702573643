import { Button, CircularProgress, TableCell, TableRow } from '@mui/material';
import { SxStyle } from '@platform/front-ui';
import downloadFile from 'js-file-download';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useEffect, useState } from 'react';
import { FormattedDate, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { fileDownloadTableSx } from '../../../../../../constants';
import { useGeneralObjectCardContext, useGeneralObjectContext } from '../../../../../../hooks';
import { PrintFormModel } from '../../../../../../models';
import { red } from '../../../../../../theme';
import { ErrorEntityType, GeneralObjectRouteParams, PrintFormStatusCode } from '../../../../../../types';
import { FileDownload as FileDownloadInj } from '../../../../../FileDownload';

export type GeneralObjectPFItemProps = {
    printFormModel: PrintFormModel;
    isGeneratingPfAllowed: boolean;
};

const generalTableCellSx: SxStyle = { width: '25%' };
const dateTableCellSx: SxStyle = { width: '15%' };
const actionTableCellSx: SxStyle = { width: '10%', minWidth: '96px' };
const wrapperSx: SxStyle = { backgroundColor: `${red[200]} !important` };

export const GeneralObjectPFItem = observer((props: GeneralObjectPFItemProps): JSX.Element => {
    const [FileDownload] = di([FileDownloadInj], GeneralObjectPFItem);

    const { printFormModel, isGeneratingPfAllowed } = props;
    const { pfSettingId, filename, docType, status, generated, pf, pfId, required } = printFormModel;
    const { tabId } = useParams<GeneralObjectRouteParams>();
    const intl = useIntl();
    const { objectStore } = useGeneralObjectContext();
    const { objectModel } = useGeneralObjectCardContext();
    const { objectContentErrorsModel } = objectModel;
    const { errorsByTabs } = objectContentErrorsModel;
    const [isNewStatus, setIsNewStatus] = useState<boolean>(false);
    const errors = errorsByTabs[tabId];

    useEffect(() => {
        if (errors && status.code === PrintFormStatusCode.generated && isNewStatus) {
            errors.removeError(ErrorEntityType.printForm, pfSettingId);
        }
    }, [status]);

    const generatedDate = generated && new Date(generated);
    let file: ReactNode;
    let pfButton: ReactNode;

    if (pf) {
        const downloadPFFile = (): void => {
            const { filename, mimeType } = pf;
            objectStore.downloadPrintForm(tabId, pfSettingId).then((file) => downloadFile(file, filename, mimeType));
        };

        file = (
            <FileDownload
                fileName={pf.filename}
                title={filename}
                sx={fileDownloadTableSx}
                downloadFile={downloadPFFile}
            />
        );
    } else {
        file = filename;
    }

    if (isGeneratingPfAllowed) {
        const generatePf = (): void => {
            printFormModel.generatePrintForm().then(() => {
                setIsNewStatus(true);
            });
        };

        const pfButtonTextMessage = pfId
            ? intl.formatMessage({ id: 'generalObject.printFormItemActions.update' })
            : intl.formatMessage({ id: 'generalObject.printFormItemActions.generate' });

        const startIcon = printFormModel.isGenerating && <CircularProgress size={20} />;

        pfButton = (
            <Button
                color="secondary"
                variant="contained"
                disabled={printFormModel.isGenerating}
                startIcon={startIcon}
                onClick={generatePf}
            >
                {pfButtonTextMessage}
            </Button>
        );
    }

    const isWithError = errors && errors.isWithError(ErrorEntityType.printForm, pfSettingId);

    return (
        <TableRow hover {...(isWithError && { sx: wrapperSx })}>
            <TableCell sx={generalTableCellSx}>{file}</TableCell>
            <TableCell sx={generalTableCellSx}>{docType}</TableCell>
            <TableCell sx={generalTableCellSx}>{required}</TableCell>
            <TableCell sx={generalTableCellSx}>{status.title}</TableCell>
            <TableCell sx={dateTableCellSx}>
                {generatedDate && (
                    <FormattedDate
                        day="numeric"
                        month="numeric"
                        year="numeric"
                        hour="numeric"
                        minute="numeric"
                        value={generatedDate}
                    />
                )}
            </TableCell>
            <TableCell align="right" sx={actionTableCellSx}>
                {pfButton}
            </TableCell>
        </TableRow>
    );
});
