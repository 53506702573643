import { TextField } from 'formik-mui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { RequiredLabel } from '../RequiredLabel';
import { FieldWithServerError as FieldWithServerErrorInj } from './FieldWithServerError';
import { RegistrationPageFields } from '../../types';

export type EmailFieldProps = {
    name?: string;
    serverError?: string;
    required?: boolean;
};

export const EmailField = observer((props: EmailFieldProps): JSX.Element => {
    const [FieldWithServerError] = di([FieldWithServerErrorInj], EmailField);

    const { required, name = RegistrationPageFields.email, serverError } = props;
    const intl = useIntl();

    const label = required ? (
        <RequiredLabel text={intl.formatMessage({ id: 'authentication.email' })} />
    ) : (
        intl.formatMessage({ id: 'authentication.email' })
    );

    return (
        <FieldWithServerError
            component={TextField}
            size="normal"
            name={name}
            disabled={false}
            inputProps={{
                className: 't-email-field',
            }}
            FormHelperTextProps={{
                className: 't-email-field-help-text',
            }}
            type="email"
            fullWidth
            label={label}
            variant="outlined"
            serverError={serverError}
        />
    );
});
