import { History } from 'history';
import { action, makeObservable, observable } from 'mobx';
import { di } from 'react-magnetic-di';
import { generatePath } from 'react-router-dom';
import { apiConfigs } from '../apiConfigs';
import { clientRoute } from '../clientRoute';
import { UserNameModel } from '../models';
import { childObjectMessages } from '../resources';

import {
    ChildObjectCreateDTO,
    ChildObjectDTO,
    DescendantCreateInfo,
    GeneralObject,
    GeneralObjectCard,
    GeneralObjectField,
    GeneralObjectFieldName,
    NumberField,
    ResponsibleCheck,
    ResponsibleFields,
} from '../types';
import { getGeneralObjectLinkValue, getNumberDescription, getResponsibleDescription } from '../utils';
import { GeneralObjectExtendStore, GeneralObjectExtendStoreProps } from './GeneralObjectExtendsStore';
import { RootStore } from './RootStore';

export const ChildObjectStoreProps = {
    ...GeneralObjectExtendStoreProps,

    history: observable,
    projectId: observable,

    editChildObjectNumber: action.bound,
    editChildObjectExecutor: action.bound,
    getDescendantCreateInfo: action.bound,
    setProjectId: action.bound,
};

export class ChildObjectStore extends GeneralObjectExtendStore<
    ChildObjectDTO,
    ChildObjectCreateDTO,
    DescendantCreateInfo
> {
    private history: History;
    objectType = GeneralObject.childObject;
    isWithTabs = true;
    isWithCreateCopy = false;
    isWithChartContent = false;
    projectId = '';

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this, ChildObjectStoreProps);
        this.history = rootStore.history;
    }

    mapObjectDTOToCard(data: ChildObjectDTO): GeneralObjectCard {
        const { id, title, metaInfo, portfolio, project, identifier, parentObject, category, executor, version } = data;
        const { author, created, state } = metaInfo;
        const portfolioId = portfolio.id;
        const projectId = project.id;
        this.setProjectId(projectId);

        const getResponsibleCheckData = (fieldName: GeneralObjectFieldName): ResponsibleCheck => ({
            object: this.objectType,
            entityId: id,
            fieldName,
        });

        const parentObjectDescription: GeneralObjectField | undefined = parentObject && {
            id: GeneralObjectFieldName.parentObject,
            value: getGeneralObjectLinkValue(
                parentObject.title,
                generatePath(clientRoute.childObject.card, { id: parentObject.id }),
            ),
        };

        const commonDescription: GeneralObjectField[] = [
            getNumberDescription(identifier, false),
            {
                id: GeneralObjectFieldName.version,
                isObjectField: true,
                value: [
                    version.title + ' | ',
                    getGeneralObjectLinkValue(
                        this.intlStore.formatMessageFromDefineMessage(childObjectMessages.allVersions),
                        generatePath(clientRoute[this.objectType].versions, { id }),
                    ),
                ],
                shouldSeparateArrayValuesWithComma: false,
                withBottomSeparator: true,
            },
            getResponsibleDescription(
                getResponsibleCheckData(GeneralObjectFieldName.childObjectExecutor),
                executor,
                true,
            ),
            {
                id: GeneralObjectFieldName.portfolio,
                isObjectField: true,
                value: getGeneralObjectLinkValue(
                    portfolio.title,
                    generatePath(clientRoute.portfolio.card, { id: portfolioId }),
                ),
            },
            {
                id: GeneralObjectFieldName.project,
                isObjectField: true,
                value: getGeneralObjectLinkValue(
                    project.title,
                    generatePath(clientRoute.project.card, { id: projectId }),
                ),
            },
            {
                id: GeneralObjectFieldName.category,
                isObjectField: true,
                value: getGeneralObjectLinkValue(
                    category.title,
                    generatePath(clientRoute.category.card, { id: category.id }),
                ),
                withBottomSeparator: true,
            },
            {
                id: GeneralObjectFieldName.created,
                value: created,
                isDate: true,
            },
            {
                id: GeneralObjectFieldName.author,
                isObjectField: true,
                value: getGeneralObjectLinkValue(
                    new UserNameModel().load(author).name,
                    generatePath(clientRoute.user, { id: author.userId }),
                ),
                withBottomSeparator: true,
            },
            this.getSyslogDescription(id),
        ];

        if (parentObjectDescription) {
            commonDescription.splice(5, 0, parentObjectDescription);
        }

        return {
            id,
            title,
            state,
            commonDescription,
        };
    }

    editChildObjectNumber(id: string, number: string, onSuccess: () => void): Promise<void> {
        return this.api
            .userActionClient(apiConfigs.editChildObjectNumber(id, number), Object.values(NumberField))
            .then(onSuccess);
    }

    editChildObjectExecutor(id: string, executorId: string, onSuccess: () => void): Promise<void> {
        return this.api
            .userActionClient(apiConfigs.editChildObjectExecutor(id, executorId), Object.values(ResponsibleFields))
            .then(onSuccess);
    }

    getDescendantCreateInfo(projectId: string, tabId: string): Promise<DescendantCreateInfo> {
        return this.api
            .client(
                apiConfigs.getObjectCreateInfo(this.objectType, {
                    tabId,
                    projectId,
                }),
            )
            .then((r) => r.data);
    }

    setProjectId(projectId: string): void {
        this.projectId = projectId;
    }
}

export const getChildObjectStore = (): any => {
    const [_ChildObjectStore] = di([ChildObjectStore], getChildObjectStore);
    return _ChildObjectStore;
};
