import { Box, CircularProgress, Grid, Paper } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { di } from 'react-magnetic-di';
import { Redirect, useParams } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { TotBackground as TotBackgroundInj } from '../../components/TotBackground';
import { useStore } from '../../hooks';
import { RegistrationStatus } from '../../models';

type RouterProps = {
    token: string | undefined;
};

export const RegistrationConfirmPage = observer((): JSX.Element => {
    const [TotBackground] = di([TotBackgroundInj], RegistrationConfirmPage);

    const { authenticationStore } = useStore();
    const { registrationConfirmModel } = authenticationStore;

    const { token } = useParams<RouterProps>();

    useEffect(() => {
        registrationConfirmModel.confirmEmail(token);
    }, [token]);

    const renderByStatus = useMemo((): JSX.Element | null => {
        switch (registrationConfirmModel.status) {
            case RegistrationStatus.pending:
                return <CircularProgress />;
            case RegistrationStatus.success:
                return (
                    <Redirect
                        to={
                            clientRoute.root
                            // clientRoute.campaigns
                        }
                    />
                );
            case RegistrationStatus.expired:
                return <Redirect to={clientRoute.registrationInfoExpired} />;
            case RegistrationStatus.notFound:
                return <Redirect to={clientRoute.registrationInfoNotFound} />;
            default:
                return null;
        }
    }, [registrationConfirmModel.status]);

    return (
        <TotBackground withBackdrop={true}>
            <Grid container item direction="column" alignItems="center" justifyContent="center">
                <Paper elevation={24}>
                    <Box>{renderByStatus}</Box>
                </Paper>
            </Grid>
        </TotBackground>
    );
});
