import { LocalesTree, ObjectLocales } from '../../../types';

const portfolio: ObjectLocales & LocalesTree = {
    single: {
        nominative: 'портфель',
        accusative: 'портфель',
        genitive: 'портфеля',
    },
    plural: {
        nominative: 'портфели',
        accusative: 'портфели',
    },
    createFields: {
        number: 'Номер',
        process: 'Жизненный цикл',
    },
    phrases: {
        create: 'Создать {portfolio}',
        tabDeleteText: 'Вкладка будет удалена на странице {portfolio}',
    },
};

export default portfolio;
