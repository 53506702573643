import { Edit as EditIcon } from '@mui/icons-material';
import { Grid, IconButton, Tooltip } from '@mui/material';
import React, { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { permissionsConfig } from '../authSchemeConfig';
import { useFlag, useGeneralObjectCardContext, useGeneralObjectContext } from '../hooks';
import { GeneralObjectField } from '../types';
import { AuthorizationCheck as AuthorizationCheckInj } from './AuthorizationCheck';
import { CommonInfoLine as CommonInfoLineInj } from './CommonInfoLine';
import { InfoLineSeparator as InfoLineSeparatorInj } from './InfoLineSeparator';

export type InfoLineProps = {
    title?: ReactNode;
    value?: ReactNode;
    field: GeneralObjectField;
};

export const InfoLine = (props: InfoLineProps): JSX.Element => {
    const [AuthorizationCheck] = di([AuthorizationCheckInj], InfoLine);
    const [CommonInfoLine] = di([CommonInfoLineInj], InfoLine);
    const [InfoLineSeparator] = di([InfoLineSeparatorInj], InfoLine);
    const [isEditModalOpen, setEditModalOpen, setEditModalClosed] = useFlag();
    const { title, value, field } = props;
    const { rawValue, withBottomSeparator, authorization } = field;
    const intl = useIntl();
    const { renderEditModals, objectStore } = useGeneralObjectContext();
    const { objectModel } = useGeneralObjectCardContext();
    const { id } = objectModel;
    const { objectType } = objectStore;
    const EditModal = field && renderEditModals && renderEditModals[field.id];
    const objectModelId = objectModel.id;
    const isWithEditModal = EditModal && objectModelId && rawValue !== undefined;

    const updatePermissionsProps = authorization?.update || permissionsConfig.updateGeneralObject(objectType, id);

    const [isEditIconVisible, setIsEditIconVisible, setIsEditIconHidden] = useFlag();

    const closeEditModal = (): void => {
        setEditModalClosed();
        setIsEditIconHidden();
    };

    return (
        <React.Fragment>
            <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
                wrap="nowrap"
                onMouseEnter={setIsEditIconVisible}
                onMouseLeave={setIsEditIconHidden}
            >
                <Grid item xs={10}>
                    <CommonInfoLine title={title} value={value} isColumnDirection={true} />
                </Grid>
                {isWithEditModal && (
                    <AuthorizationCheck {...updatePermissionsProps}>
                        <React.Fragment>
                            <Grid item xs={2}>
                                {isEditIconVisible && (
                                    <Tooltip title={intl.formatMessage({ id: 'common.edit' })}>
                                        <IconButton onClick={setEditModalOpen}>
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Grid>
                            <EditModal
                                setIsClosed={closeEditModal}
                                isOpen={isEditModalOpen}
                                objectId={objectModelId}
                                rawValue={rawValue}
                            />
                        </React.Fragment>
                    </AuthorizationCheck>
                )}
            </Grid>
            {withBottomSeparator && (
                <Grid item>
                    <InfoLineSeparator />
                </Grid>
            )}
        </React.Fragment>
    );
};
