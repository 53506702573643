import { action, computed, makeObservable, observable } from 'mobx';
import { getEmptyCreationInfo } from '../constants';
import { DescendantCreateInfo } from '../types';

export const CreationInfoModelProps = {
    creationInfo: observable,
    isCreationInfoSet: observable,
    isCreateButtonShown: computed,
    setCreationInfo: action.bound,
    setIsCreationInfoSet: action.bound,
};

export class CreationInfoModel {
    creationInfo: DescendantCreateInfo;
    isCreationInfoSet = false;

    constructor() {
        makeObservable(this, CreationInfoModelProps);
        this.creationInfo = getEmptyCreationInfo();
    }

    get isCreateButtonShown(): boolean {
        return this.creationInfo.categories.length > 0;
    }

    setCreationInfo(creationInfo: DescendantCreateInfo): void {
        this.creationInfo = creationInfo;
        this.setIsCreationInfoSet(true);
    }

    setIsCreationInfoSet(isSet: boolean): void {
        this.isCreationInfoSet = isSet;
    }
}
