import { observer } from 'mobx-react-lite';
import { fromPromise } from 'mobx-utils';
import React, { useCallback, useEffect, useState } from 'react';
import { useStore } from '../hooks';
import { AuthorizationCheckQuery } from '../types';

type RenderChildrenOr = (allowed: boolean) => JSX.Element;
type RenderChildrenAll = (result: boolean[]) => JSX.Element;

export type AuthorizationCheckAllProps = {
    or: boolean;
    isAllRequired?: boolean;
    queries: AuthorizationCheckQuery[];
    pendingElement?: JSX.Element;
    errorElement?: JSX.Element;
    children?: RenderChildrenOr | RenderChildrenAll | JSX.Element;
};

// раньше компонент работал по-другому, теперь он реагирует на изменение queries - перезапрашивает права
export const AuthorizationCheckAll = observer((props: AuthorizationCheckAllProps): JSX.Element => {
    const { queries, children, pendingElement, errorElement, or, isAllRequired = false } = props;
    const { authorizationStore } = useStore();

    const checkIsAllAllowed = useCallback(() => {
        return authorizationStore.checkAll(queries);
    }, [queries]);

    const [isAllAllowed, setIsAllAllowed] = useState<Promise<boolean[]>>(Promise.resolve([]));

    useEffect(() => {
        setIsAllAllowed(checkIsAllAllowed());
    }, [checkIsAllAllowed]);

    const renderChildrenOr = useCallback(
        (isAllAllowed: boolean[]): JSX.Element => {
            const allowed = isAllRequired ? isAllAllowed.every((_) => _) : isAllAllowed.some((_) => _);
            if (typeof children === 'function') {
                const render = children as RenderChildrenOr;
                return render(allowed);
            }
            if (allowed) {
                return children as JSX.Element;
            }
            return <React.Fragment />;
        },
        [children],
    );

    const renderChildrenAll = useCallback(
        (isAllAllowed: boolean[]): JSX.Element => {
            if (typeof children === 'function') {
                const render = children as RenderChildrenAll;
                return render(isAllAllowed);
            } else {
                throw new Error(
                    'AuthorizationCheckAll без установленной опции "or" в качестве children допускает только render-функцию `(isAllAllowed: boolean[]) => ReactNode`',
                );
            }
        },
        [children],
    );

    return fromPromise(isAllAllowed).case({
        pending: () => pendingElement || <React.Fragment />,
        fulfilled: or ? renderChildrenOr : renderChildrenAll,
        rejected: () => errorElement || <React.Fragment />,
    });
});
