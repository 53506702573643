import { FieldValues as LangTitle, titlesToTitlesDTO } from '@platform/multi-lang-field';
import { History } from 'history';
import { action, makeObservable, observable } from 'mobx';
import { generatePath } from 'react-router-dom';
import { clientRoute } from '../clientRoute';
import { getEmptyIdentifier } from '../constants';
import { CatalogStore, ProjectStore, RootStore } from '../stores';
import { CreateProjectFields, Identifier, IdTitle, IdTitleNull, ProjectCreateDTO } from '../types';
import { ServerErrorsModel } from './ServerErrorsModel';

export type ProjectCreateDialogValues = {
    titles: LangTitle[];
    number: string;
    category: IdTitleNull;
    portfolio: IdTitleNull;
};

export const ProjectCreateModelProps = {
    rootStore: observable,
    projectStore: observable,
    catalogStore: observable,
    history: observable,
    serverErrors: observable,
    identifier: observable,
    portfoliosList: observable,
    categoriesList: observable,
    createProject: action.bound,
    getPortfolioList: action.bound,
    getProjectCreateInfo: action.bound,
    setIdentifier: action.bound,
    setCategoriesList: action.bound,
    setPortfoliosList: action.bound,
};

export class ProjectCreateModel {
    private rootStore: RootStore;
    private projectStore: ProjectStore;
    private catalogStore: CatalogStore;
    private history: History;

    serverErrors = new ServerErrorsModel<CreateProjectFields>();

    identifier: Identifier = getEmptyIdentifier();

    portfoliosList: IdTitle[] = [];
    categoriesList: IdTitle[] = [];

    constructor(rootStore: RootStore) {
        makeObservable(this, ProjectCreateModelProps);
        this.rootStore = rootStore;
        this.projectStore = rootStore.projectStore;
        this.catalogStore = rootStore.catalogStore;
        this.history = rootStore.history;
    }

    createProject(formValues: ProjectCreateDialogValues): Promise<void> {
        this.serverErrors.dropServerFormErrors();
        const titlesDTO = titlesToTitlesDTO(formValues.titles);
        const dto: ProjectCreateDTO = {
            titles: titlesDTO,
            identifier: {
                prefix: this.identifier.prefix,
                number: formValues.number,
            },
            categoryId: (formValues.category as IdTitle).id,
            portfolioId: (formValues.portfolio as IdTitle).id,
        };

        return this.projectStore
            .createObject(dto)
            .then((id) => {
                this.history.push(
                    generatePath(clientRoute.project.card, {
                        id,
                    }),
                );
            })
            .catch(this.serverErrors.setServerFormErrors);
    }

    getPortfolioList(): void {
        this.catalogStore.getPortfoliosList().then(this.setPortfoliosList);
    }

    getProjectCreateInfo(portfolioId: string): Promise<string> {
        return this.projectStore.getProjectCreateInfo(portfolioId).then((data) => {
            this.setCategoriesList(data.categories);
            this.setIdentifier(data.identifier);
            return data.identifier.number;
        });
    }

    setIdentifier(identifier: Identifier): void {
        this.identifier = identifier;
    }

    setCategoriesList(activeCategories: IdTitle[]): void {
        this.categoriesList = activeCategories;
    }

    setPortfoliosList(activePortfolios: IdTitle[]): void {
        this.portfoliosList = activePortfolios;
    }
}
