import { Grid } from '@mui/material';
import { useFeature } from 'feature-toggle-jsx';
import React from 'react';
import { useIntl } from 'react-intl';

export const FooterLogo = (): JSX.Element => {
    const [footerLogo, footerLogoConfig] = useFeature('footerLogo');
    const intl = useIntl();
    const footerLogoAlt = intl.formatMessage({ id: 'feature.phrases.footerLogoAlt' });
    const { isEnabled: isFooterLogoEnabled, src: footerLogoSrc, style: footerLogoStyle } = footerLogoConfig;

    return (
        <React.Fragment>
            {footerLogo && isFooterLogoEnabled && footerLogoSrc && (
                <Grid item>
                    <img src={footerLogoSrc} alt={footerLogoAlt} {...(footerLogoStyle && { style: footerLogoStyle })} />
                </Grid>
            )}
        </React.Fragment>
    );
};
