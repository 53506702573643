import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useAntiDoubleClick, useGeneralObjectCardContext, useStore, useYup } from '../../../../hooks';
import { ServerErrorsModel, UserNameModel } from '../../../../models';
import { usersMessages } from '../../../../resources';
import { EditModalProps, IdTitle, IdTitleNull, Responsible, ResponsibleFields, UserRole } from '../../../../types';
import { disableSubmitOnEnterKeyPress, emptyIdTitle } from '../../../../utils';
import { AutocompleteField as AutocompleteFieldInj, AutocompleteField } from '../../../fields';

export type GeneralObjectEditResponsibleProps = EditModalProps & {
    title: string;
    roleToSelectBy?: UserRole;
} & (
        | {
              editResponsible?: never;
              editMultipleResponsible: (id: string, responsible: string[], onSuccess: () => void) => Promise<void>;
          }
        | {
              editResponsible: (id: string, responsibleId: string, onSuccess: () => void) => Promise<void>;
              editMultipleResponsible?: never;
          }
    );

export const GeneralObjectEditResponsible = observer((props: GeneralObjectEditResponsibleProps): JSX.Element => {
    const [AutocompleteField] = di([AutocompleteFieldInj], GeneralObjectEditResponsible);

    const {
        isOpen,
        setIsClosed,
        rawValue,
        objectId,
        title,
        roleToSelectBy = UserRole.executor,
        editResponsible,
        editMultipleResponsible,
    } = props;
    const { catalogStore, intlStore } = useStore();
    const { objectModel } = useGeneralObjectCardContext();
    const { load: loadObjectModel, responsibleUsersByRole, loadUsersByRole } = objectModel;

    const serverErrors = useMemo(() => new ServerErrorsModel<ResponsibleFields>(), []);
    const { dropServerFormErrors, serverFormErrors, setServerFormErrors } = serverErrors;

    const allUsers = responsibleUsersByRole[roleToSelectBy].users;

    const { responsible } = ResponsibleFields;
    const editingValue: Responsible[keyof Responsible] = rawValue
        ? Array.isArray(rawValue)
            ? rawValue.map((personData): IdTitle => {
                  return new UserNameModel().load(personData, true).asIdTitle;
              })
            : 'userId' in rawValue
            ? new UserNameModel().load(rawValue, true).asIdTitle
            : null
        : null;

    useEffect((): void => {
        loadUsersByRole(roleToSelectBy);
    }, [catalogStore]);

    const onClose = (): void => {
        setIsClosed();
        dropServerFormErrors();
    };

    const { Yup } = useYup();
    const validationSchema = Yup.object().shape({
        responsible: editMultipleResponsible ? Yup.array().required() : Yup.object().nullable(),
    });

    const initialValues: Responsible = {
        responsible: editingValue,
    };

    const getOnSubmitFunction = (responsible: Responsible[keyof Responsible]): Promise<void> => {
        if (editMultipleResponsible && Array.isArray(responsible)) {
            const userIds = responsible.map((user) => user.id);
            return editMultipleResponsible(objectId, userIds, loadObjectModel);
        }
        if (editResponsible) {
            const responsibleData = responsible as IdTitleNull;
            const idValue = responsibleData ? responsibleData.id : emptyIdTitle.id;
            return editResponsible(objectId, idValue, loadObjectModel);
        }
        return Promise.reject();
    };

    const onSubmit = (values: Responsible): Promise<void> => {
        dropServerFormErrors();
        const responsible = values.responsible;
        return getOnSubmitFunction(responsible).then(onClose).catch(setServerFormErrors);
    };

    const [isSending, endIcon, handleSubmit] = useAntiDoubleClick(onSubmit);

    const autocompleteFieldLabel = editResponsible
        ? intlStore.formatMessageFromDefineMessage(usersMessages.selectUser)
        : intlStore.formatMessageFromDefineMessage(usersMessages.selectUsers);

    return (
        <Dialog open={isOpen} fullWidth={true}>
            <DialogTitle>{title}</DialogTitle>
            <Formik
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                enableReinitialize={true}
            >
                <Form onKeyDown={disableSubmitOnEnterKeyPress}>
                    <DialogContent>
                        <AutocompleteField
                            multiple={!!editMultipleResponsible}
                            options={allUsers}
                            fieldName={responsible}
                            serverError={serverFormErrors[responsible]}
                            label={autocompleteFieldLabel}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={onClose}>
                            <FormattedMessage id="common.cancel" />
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={isSending}
                            endIcon={endIcon}
                        >
                            <FormattedMessage id="common.save" />
                        </Button>
                    </DialogActions>
                </Form>
            </Formik>
        </Dialog>
    );
});
