import { makeObservable } from 'mobx';
import { AnySchema } from 'yup';
import { RootStore } from '../../stores';
import { GeneralObjectPermissionTrigger, PrintFormAccessPermissionDTO } from '../../types';
import { PrintFormPermissionSettingsModel } from '../print-form';
import {
    GeneralObjectSystemEntityAccessModel,
    GeneralObjectSystemEntityAccessModelProps,
} from './GeneralObjectSystemEntityAccessModel';

export const GeneralObjectPFAccessModelProps = {
    ...GeneralObjectSystemEntityAccessModelProps,
};

const emptyInitialValues: PrintFormAccessPermissionDTO = {
    roleCondition: {
        code: '',
        title: '',
        group: GeneralObjectPermissionTrigger.role,
    },
    whenObjectStateIsOneOf: [],
    allowEdit: false,
};

export class GeneralObjectPFAccessModel extends GeneralObjectSystemEntityAccessModel<
    PrintFormPermissionSettingsModel,
    PrintFormAccessPermissionDTO
> {
    constructor(
        rootStore: RootStore,
        settingsModel: PrintFormPermissionSettingsModel,
        additionalFieldsValidationSchema: Record<string, AnySchema>,
    ) {
        super(rootStore, settingsModel, additionalFieldsValidationSchema);
        makeObservable(this, GeneralObjectPFAccessModelProps);
    }

    get initialValues(): PrintFormAccessPermissionDTO {
        if (this.selectedPermissionIndex !== null) {
            return this.permissionSettingsModel.permissions[this.selectedPermissionIndex];
        }
        return emptyInitialValues;
    }

    onSubmit(values: PrintFormAccessPermissionDTO): Promise<void> {
        return this.permissionSettingsModel.updatePermissions(values, this.selectedPermissionIndex);
    }
}
