import { IconButton, IconButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { fontFamily, mainFontSize, redesignTotPalette } from '../theme';
import { AppTheme } from '../types';

export type TotLocaleButtonProps = IconButtonProps & {
    isDarkBackground?: boolean;
};

export const TotLocaleButton = styled(IconButton)<TotLocaleButtonProps>(
    ({ theme, isDarkBackground }) => `
       font-Size: ${mainFontSize};
       font-family: ${fontFamily};
       font-weight: 500;
       line-height: 20px;
       color: ${
           isDarkBackground
               ? (theme as AppTheme)?.variables.palette.footer.textMain
               : redesignTotPalette.languageButton.default.color
       } !important;
    
       &.MuiIconButton-colorSecondary:hover {
           color: ${
               isDarkBackground
                   ? (theme as AppTheme)?.variables.palette.footer.textHover
                   : redesignTotPalette.languageButton.hover.color
           } !important;
       },
       
       .MuiTouchRipple-root {
           color: ${
               isDarkBackground
                   ? (theme as AppTheme)?.variables.palette.footer.textMain
                   : redesignTotPalette.languageButton.default.color
           };  
       }
`,
);
