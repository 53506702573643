import { ObjectLayout } from '@platform/front-core';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import {
    GeneralObjectChartSettingsPageContext,
    GeneralObjectChartSettingsPageContextType,
} from '../../../../../../../../contexts';
import { useGeneralObjectContext, useStore } from '../../../../../../../../hooks';
import { ChartSettingsModel } from '../../../../../../../../models';
import { GeneralObjectChartParams } from '../../../../../../../../types';
import { GeneralObjectChartSettingsPageContent as GeneralObjectChartSettingsPageContentInj } from './GeneralObjectChartSettingsPageContent';
import { GeneralObjectChartSettingsPageHeader as GeneralObjectChartSettingsPageHeaderInj } from './GeneralObjectChartSettingsPageHeader';

export const GeneralObjectChartSettingsPage = observer((): JSX.Element => {
    const [GeneralObjectChartSettingsPageHeader] = di(
        [GeneralObjectChartSettingsPageHeaderInj],
        GeneralObjectChartSettingsPage,
    );
    const [GeneralObjectChartSettingsPageContent] = di(
        [GeneralObjectChartSettingsPageContentInj],
        GeneralObjectChartSettingsPage,
    );

    const rootStore = useStore();
    const { objectStore } = useGeneralObjectContext();
    const { id, chartSettingId } = useParams<GeneralObjectChartParams>();
    const { locale } = useIntl();

    const model = useMemo(
        () => new ChartSettingsModel(chartSettingId, rootStore, objectStore),
        [rootStore, objectStore],
    );
    const { load, loadChartSources, loadTabs, loadRegistries } = model;

    useEffect((): void => {
        Promise.all([loadChartSources(), loadTabs(id), loadRegistries(id)]).finally(() => {
            return load();
        });
    }, [id, chartSettingId, locale]);

    const headerLayout = <GeneralObjectChartSettingsPageHeader />;
    const contentLayout = {
        content: <GeneralObjectChartSettingsPageContent />,
    };

    const contextValues: GeneralObjectChartSettingsPageContextType = {
        model,
    };

    return (
        <GeneralObjectChartSettingsPageContext.Provider value={contextValues}>
            <ObjectLayout header={headerLayout} contentLayout={contentLayout} />;
        </GeneralObjectChartSettingsPageContext.Provider>
    );
});
