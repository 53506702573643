import { Paper, Table, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { DragDropContext, Draggable, DraggableProvided, Droppable } from 'react-beautiful-dnd';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useFlag } from '../../../../../../../../hooks';
import { PrintFromSettingsListModel } from '../../../../../../../../models';
import { PrintFormItemDTO } from '../../../../../../../../types';
import { onDragEndCreator } from '../../../../../../../../utils';
import { ConfirmationDialog as ConfirmationDialogInj } from '../../../../../../../ConfirmationDialog';
import { DroppableTableProvide as DroppableTableProvideInj } from '../../../../../../../DroppableTableProvide';
import { GeneralObjectTabSettingsPFItem } from './GeneralObjectTabSettingsPFItem';

export type GeneralObjectTabSettingsPFListProps = {
    model: PrintFromSettingsListModel;
};

const getPrintFormItemInj =
    (printForm: PrintFormItemDTO, openDeleteModal: () => void): ((provided: DraggableProvided) => JSX.Element) =>
    (provided) =>
        <GeneralObjectTabSettingsPFItem printForm={printForm} provided={provided} openDeleteModal={openDeleteModal} />;

export const GeneralObjectTabSettingsPFList = observer((props: GeneralObjectTabSettingsPFListProps): JSX.Element => {
    const [DroppableTableProvide] = di([DroppableTableProvideInj], GeneralObjectTabSettingsPFList);
    const [getPrintFormItem] = di([getPrintFormItemInj], GeneralObjectTabSettingsPFList);
    const [ConfirmationDialog] = di([ConfirmationDialogInj], GeneralObjectTabSettingsPFList);

    const { model } = props;
    const { changePFPosition, reorderPFList, deletePF, setSelectedDeleteId, printForms } = model;
    const onDragEnd = onDragEndCreator(changePFPosition, reorderPFList);
    const [deleteModalIsOpen, setDeleteModalIsOpen, setDeleteModalIsClosed] = useFlag();
    const intl = useIntl();

    const openDeleteModal =
        (pfId: string): (() => void) =>
        (): void => {
            setDeleteModalIsOpen();
            setSelectedDeleteId(pfId);
        };

    const onDeleteConfirm = (): Promise<void> => {
        return deletePF().finally(setDeleteModalIsClosed);
    };

    const draggablePrintForms = useMemo((): JSX.Element[] => {
        return printForms.map((printForm, index) => {
            const id = printForm.id;

            return (
                <Draggable draggableId={id} index={index} key={id}>
                    {getPrintFormItem(printForm, openDeleteModal(id))}
                </Draggable>
            );
        });
    }, [printForms, printForms.length]);

    return (
        <React.Fragment>
            <ConfirmationDialog
                id="delete"
                isOpen={deleteModalIsOpen}
                title={intl.formatMessage({ id: 'common.confirmDeletion' })}
                message={intl.formatMessage({ id: 'objectSettings.printFormDeleteText' })}
                onConfirm={onDeleteConfirm}
                setIsClosed={setDeleteModalIsClosed}
                keepMounted
            />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography>
                                    <FormattedMessage id="objectSettings.printFormFields.titles" />
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>
                                    <FormattedMessage id="objectSettings.printFormList.fileFormat" />
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>
                                    <FormattedMessage id="objectSettings.printFormList.required" />
                                </Typography>
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">{DroppableTableProvide(draggablePrintForms)}</Droppable>
                    </DragDropContext>
                </Table>
            </TableContainer>
        </React.Fragment>
    );
});
