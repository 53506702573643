import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { clientRoute } from '../../clientRoute';
import { GeneralObjectMainComponent as GeneralObjectMainComponentInj } from '../../components';
import { GeneralObjectContext } from '../../contexts';
import { useStore } from '../../hooks';

export const CategoryPage = observer((): JSX.Element => {
    const [GeneralObjectMainComponent] = di([GeneralObjectMainComponentInj], CategoryPage);

    const { categoryStore } = useStore();

    const routes = clientRoute[categoryStore.objectType];
    return (
        <GeneralObjectContext.Provider
            value={{
                objectStore: categoryStore,
                routes,
            }}
        >
            <GeneralObjectMainComponent />
        </GeneralObjectContext.Provider>
    );
});
