import { Box, Grid, Link, TabsActions } from '@mui/material';
import { makeSxStylesWithProps, mergeSxStyles, SxStyles } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import sx from 'mui-sx';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { stickyZIndex } from '../../../../constants';
import { useGeneralObjectContext, useStore } from '../../../../hooks';
import { GeneralObjectTabs as GeneralObjectTabsInj } from '../general-object-tabs/GeneralObjectTabs';

export type UseGeneralObjectTabsPanelStylesProps = {
    generalObjectHeaderHeight: number;
    summaryStickyOffsetRight: number;
};

export const useGeneralObjectTabsPanelSxStyles = makeSxStylesWithProps(
    (props: UseGeneralObjectTabsPanelStylesProps) => ({
        controlsWrapper: {
            padding: '15px',
            paddingRight: '50px',
        },
        controlsWrapperEmpty: {
            paddingBottom: 0,
            paddingTop: 0,
        },
        openInfoButton: {
            whiteSpace: 'nowrap',
        },
        sticky: {
            position: 'fixed',
            top: `${props.generalObjectHeaderHeight}px`,
            zIndex: stickyZIndex,
            overflow: 'hidden',
            width: `calc(100% - ${props.summaryStickyOffsetRight}px)`,
        },
        stickyTab: {
            backgroundColor: 'white',
            borderBottom: (theme) => `1px solid ${theme.variables.palette.hoverInLists}`,
        },
    }),
);

export type GeneralObjectTabsPanelProps = {
    isDescriptionPanelOpen: boolean;
    openDescription: () => void;
    tabsActions: React.Ref<TabsActions>;
    tabsContainerRef: React.Ref<HTMLDivElement>;
};

export const GeneralObjectTabsPanel = observer((props: GeneralObjectTabsPanelProps): JSX.Element => {
    const [GeneralObjectTabs] = di([GeneralObjectTabsInj], GeneralObjectTabsPanel);

    const { isDescriptionPanelOpen, openDescription, tabsActions, tabsContainerRef } = props;
    const { stickyElementsStore } = useStore();
    const { generalObjectHeaderHeight, generalObjectHeadIsSticky, summaryStickyOffsetRight } = stickyElementsStore;
    const { objectStore } = useGeneralObjectContext();
    const { isWithTabs } = objectStore;

    const sxStyles = useGeneralObjectTabsPanelSxStyles({ generalObjectHeaderHeight, summaryStickyOffsetRight });
    const generalObjectHeadWrapperSxStyles = sx({
        condition: generalObjectHeadIsSticky,
        sx: sxStyles.sticky as SxStyles,
    });
    const generalObjectHeadStickySxStyles = mergeSxStyles(sxStyles.controlsWrapper, sxStyles.stickyTab);
    const generalObjectHeadSxStyles = generalObjectHeadIsSticky
        ? generalObjectHeadStickySxStyles
        : sxStyles.controlsWrapper;

    return (
        <Box height="78px">
            <Box sx={generalObjectHeadWrapperSxStyles}>
                <Grid
                    container
                    item
                    justifyContent={isWithTabs ? 'space-between' : 'flex-end'}
                    alignItems="center"
                    wrap="nowrap"
                    sx={generalObjectHeadSxStyles}
                    ref={tabsContainerRef}
                >
                    {isWithTabs && (
                        <GeneralObjectTabs isInfoSidebarOpen={isDescriptionPanelOpen} tabsActions={tabsActions} />
                    )}
                    <Grid container item alignItems="center" justifyContent="flex-end" sx={{ width: 'auto' }}>
                        {!isDescriptionPanelOpen && (
                            <Link
                                component="button"
                                underline="none"
                                sx={sxStyles.openInfoButton}
                                onClick={openDescription}
                            >
                                <FormattedMessage id="generalObject.descriptionPanel.open" />
                            </Link>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
});
