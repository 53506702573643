import { LocalesTree, ObjectLocales } from '../../../types';

const category: ObjectLocales & LocalesTree = {
    single: {
        nominative: 'категория',
        accusative: 'категорию',
        genitive: 'категории',
    },
    plural: {
        nominative: 'категории',
        accusative: 'категории',
    },
    createFields: {
        titles: 'Наименование',
        form: 'Форма',
        process: 'Жизненный цикл',
    },
    phrases: {
        create: 'Создать {category}',
        createCopy: 'Создать копию {category}',
        copyCreated: 'Копия {category} создана',
        tabDeleteText: 'Вкладка будет удалена во всех объектах, основанных на этой {category}',
        tabSettingsList: '{category} создаваемых объектов',
    },
};

export default category;
