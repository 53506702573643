import { MenuItem } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export type DeleteActionMenuItemProps = {
    onClick?: () => void;
    tabIndex?: number;
};

export const DeleteActionMenuItem = (props: DeleteActionMenuItemProps): JSX.Element => {
    const { tabIndex, onClick } = props;
    return (
        // @ts-ignore
        <MenuItem dense component="button" tabIndex={tabIndex} onClick={onClick}>
            <FormattedMessage id="common.delete" />
        </MenuItem>
    );
};
