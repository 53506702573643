import { LocalesTree } from '../../../types';

const errorPage: LocalesTree = {
    notExist: 'Page not found ',
    actionNotAllowed: 'You have not enough permissions for this action',
    goTo: 'Go to the ',
    main: 'Main page ',
    orReturn: 'or return to the ',
    back: 'Previous one',
};

export default errorPage;
