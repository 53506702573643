import { Grid } from '@mui/material';
import { makeSxStyles } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { CommonInfoLine as CommonInfoLineInj, TitleTypography as TitleTypographyInj } from '../../../../components';
import { descriptionPanelWidth } from '../../../../constants';
import { useUserPageContext } from '../../../../hooks';
import { borderAppBar } from '../../../../theme';
import { UserPageSidebarActions as UserPageSidebarActionsInj } from './user-page-sidebar-actions';

const sx = makeSxStyles({
    wrapper: {
        padding: (theme) => `${theme.spacing(6)} ${theme.spacing(4)}`,
        maxWidth: descriptionPanelWidth,
        width: '100%',
        borderLeft: borderAppBar,
    },
});

export const UserPageSidebar = observer((): JSX.Element => {
    const [TitleTypography] = di([TitleTypographyInj], UserPageSidebar);
    const [UserPageSidebarActions] = di([UserPageSidebarActionsInj], UserPageSidebar);
    const [CommonInfoLine] = di([CommonInfoLineInj], UserPageSidebar);

    const { userModel } = useUserPageContext();
    const intl = useIntl();
    const { lang } = userModel;

    return (
        <Grid container item direction="column" sx={sx.wrapper}>
            <Grid container item justifyContent="space-between" alignItems="center" sx={{ marginBottom: 4 }}>
                <Grid item>
                    <TitleTypography>
                        <FormattedMessage id="user.info" />
                    </TitleTypography>
                </Grid>
                <Grid item>
                    <UserPageSidebarActions />
                </Grid>
            </Grid>
            <Grid container item>
                <CommonInfoLine
                    title={intl.formatMessage({ id: 'user.mainLanguage' })}
                    value={lang && intl.formatMessage({ id: `locale.${lang}` })}
                    isColumnDirection={true}
                />
            </Grid>
        </Grid>
    );
});
