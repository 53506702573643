import { Grid, SxProps, Typography } from '@mui/material';
import { ResponsivePie } from '@nivo/pie';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useGeneralObjectContext } from '../../../../../../hooks';
import { ChartData, ChartSettingItemDTO } from '../../../../../../types';
import { parsingSubmissionEditGrid } from '../../../../../../utils';

export type GeneralObjectContentChartProps = {
    chart: ChartSettingItemDTO;
};

const sxChart: SxProps = {
    width: '100%',
    height: '400px',
};

export const GeneralObjectContentChart = observer((props: GeneralObjectContentChartProps): JSX.Element => {
    const [chartSettingsData, setChartSettingsData] = useState<ChartData[]>([]);
    const { chart } = props;
    const { id, title, settings } = chart;

    const { objectStore } = useGeneralObjectContext();
    const { locale } = useIntl();

    useEffect((): void => {
        objectStore.getTabChartSettingData(id).then((data) => {
            if (settings) {
                const { chartOptions } = settings;
                const { dataOptions } = chartOptions;
                const { dataLabelsFieldCode, valuesFieldCode, element } = dataOptions;

                const editGridData = parsingSubmissionEditGrid(data, dataLabelsFieldCode, valuesFieldCode, element);

                setChartSettingsData(editGridData || []);
            }
        });
    }, [locale]);

    return !!chartSettingsData.length && !!settings ? (
        <Grid container direction="column" spacing={3}>
            <Grid item>
                <Typography variant="h5" component="h3">
                    {title}
                </Typography>
            </Grid>
            <Grid item sx={sxChart}>
                <ResponsivePie {...settings.chartOptions.visualizationOptions} data={chartSettingsData} />
            </Grid>
        </Grid>
    ) : (
        <React.Fragment />
    );
});
