import { Fab, Grid } from '@mui/material';
import { FormApi } from '@platform/formiojs-react';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Sticky from 'react-sticky-el';
import { rootId, stickyButtonOffsetVerticalMultiplier, stickyZIndex } from '../constants';
import { useStore } from '../hooks';

export type StickyFormSaveButtonsProps = {
    returnPath: string;
    onSubmit: () => Promise<void>;
    stickyOffsetRight: number;
    containerSelector: string;
    onCloseForm: () => void;
    isWithValidate?: boolean;
    formApi?: FormApi;
    horizontalMargin?: number;
};

export const StickyFormSaveButtons = observer((props: StickyFormSaveButtonsProps): JSX.Element => {
    const {
        containerSelector,
        stickyOffsetRight,
        returnPath,
        formApi,
        isWithValidate = false,
        horizontalMargin = 0,
        onCloseForm,
        onSubmit,
    } = props;
    const { history } = useStore();

    const goBack = (): void => {
        onCloseForm();
        setTimeout(() => {
            history.push(returnPath);
        }, 0);
    };

    const handleClose = (): void => {
        goBack();
        formApi && formApi.setSubmissionFromStartSubmission();
    };

    const handleSubmit = (): void => {
        // && formApi.validate()
        if (formApi) {
            formApi
                .submit(isWithValidate)
                .then(onSubmit)
                .then(() => {
                    formApi && formApi.setStartSubmission();
                    goBack();
                });
        }
    };

    return (
        <Grid item alignSelf="end">
            <Sticky
                mode="bottom"
                scrollElement={rootId}
                positionRecheckInterval={500}
                boundaryElement={containerSelector}
                stickyStyle={{
                    zIndex: stickyZIndex,
                    width: 'auto',
                    right: stickyOffsetRight,
                }}
                hideOnBoundaryHit={true}
            >
                <Grid container spacing={4} sx={{ mb: stickyButtonOffsetVerticalMultiplier, mx: horizontalMargin }}>
                    <Grid item>
                        <Fab variant="extended" size="small" color="secondary" onClick={handleClose}>
                            <FormattedMessage id="common.cancel" />
                        </Fab>
                    </Grid>
                    <Grid item>
                        <Fab variant="extended" size="small" color="primary" onClick={handleSubmit}>
                            <FormattedMessage id="common.save" />
                        </Fab>
                    </Grid>
                </Grid>
            </Sticky>
        </Grid>
    );
});
