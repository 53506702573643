import { action, computed, makeAutoObservable, observable } from 'mobx';
import { IntlShape } from 'react-intl';
import { di } from 'react-magnetic-di';
import { NotificationListModel } from '../models';
import { ResponseErrorDTO } from '../types';
import { RootStore } from './RootStore';

export enum SeverityType {
    error = 'error',
    warning = 'warning',
    info = 'info',
    success = 'success',
}

export const NotificationStoreProps = {
    rootStore: observable,
    model: observable,
    intl: computed,
    onSuccess: action.bound,
    onError: action.bound,
    onInfo: action.bound,
    onWarning: action.bound,
};

export class NotificationStore {
    private rootStore: RootStore;

    model: NotificationListModel = new NotificationListModel();

    constructor(rootStore: RootStore) {
        makeAutoObservable(this, NotificationStoreProps);
        this.rootStore = rootStore;
    }

    private get intl(): IntlShape {
        return this.rootStore.intlStore.intl;
    }

    onSuccess(text: string, withAutoClose = true): void {
        this.model.pushNotification(text, withAutoClose, SeverityType.success);
    }

    onError(text?: ResponseErrorDTO | string | { $$typeof: symbol }, withAutoClose = true): void {
        const defaultErrorText = this.intl.formatMessage({ id: 'common.errorText' });
        const errorText = text
            ? typeof text === 'object'
                ? 'message' in text
                    ? text.message
                    : Symbol.keyFor(text.$$typeof) === 'react.element'
                    ? (text as unknown as string)
                    : defaultErrorText
                : text.toString()
            : defaultErrorText;
        this.model.pushNotification(errorText, withAutoClose, SeverityType.error);
    }

    onInfo(text: string, withAutoClose = true): void {
        this.model.pushNotification(text, withAutoClose, SeverityType.info);
    }

    onWarning(text: string, withAutoClose = true): void {
        this.model.pushNotification(text, withAutoClose, SeverityType.warning);
    }
}

export const getNotificationStore = (): any => {
    const [_NotificationStore] = di([NotificationStore], getNotificationStore);
    return _NotificationStore;
};
