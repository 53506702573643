import React from 'react';
import { di } from 'react-magnetic-di';
import { useFlag } from '../../../../hooks';
import { CreateCategoryCopyDialog as CreateCategoryCopyDialogInj } from '../../../../pages';
import { ActionMenuItem as ActionMenuItemInj } from '../../../menu';

export type GeneralObjectCreateCopyBtnProps = {
    prototypeCategoryId: string;
};

export const CreateCategoryCopyBtn = (props: GeneralObjectCreateCopyBtnProps): JSX.Element => {
    const [CreateCategoryCopyDialog] = di([CreateCategoryCopyDialogInj], CreateCategoryCopyBtn);
    const [ActionMenuItem] = di([ActionMenuItemInj], CreateCategoryCopyBtn);

    const { prototypeCategoryId } = props;
    const [isCreateCopyDialogOpen, openCreateCopyDialog, closeCreateCopyDialog] = useFlag();

    return (
        <React.Fragment>
            <ActionMenuItem messageId="generalObject.createCopy" onClick={openCreateCopyDialog} />
            <CreateCategoryCopyDialog
                prototypeCategoryId={prototypeCategoryId}
                isOpen={isCreateCopyDialogOpen}
                setIsClosed={closeCreateCopyDialog}
            />
        </React.Fragment>
    );
};
