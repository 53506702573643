import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { GeneralObjectEditResponsible as GeneralObjectEditResponsibleInj } from '../../../../components';
import { useStore } from '../../../../hooks';
import { EditModalProps } from '../../../../types';

export const EditPortfolioViewers = observer((props: EditModalProps): JSX.Element => {
    const [GeneralObjectEditResponsible] = di([GeneralObjectEditResponsibleInj], EditPortfolioViewers);

    const { portfolioStore } = useStore();
    const intl = useIntl();
    const title = intl.formatMessage({ id: 'viewer.plural.nominative' });

    return (
        <GeneralObjectEditResponsible
            {...props}
            editMultipleResponsible={portfolioStore.editPortfolioViewers}
            title={title}
        />
    );
});
