import { Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { useFlag, useGeneralObjectCardContext, useGeneralObjectContext, useStore } from '../../../../../hooks';
import { ObjectTransitionsModel } from '../../../../../models';
import { GeneralObjectRouteParams, TransitionDTO } from '../../../../../types';
import { ConfirmationDialog as ConfirmationDialogInj } from '../../../../ConfirmationDialog';
import { GeneralObjectControlTransitionBtn as GeneralObjectControlTransitionBtnInj } from './GeneralObjectControlTransitionBtn';
import { GeneralObjectTransitionErrorDialog as GeneralObjectTransitionErrorDialogInj } from './GeneralObjectTransitionErrorDialog';

export const GeneralObjectControlTransitions = observer((): JSX.Element => {
    const [ConfirmationDialog] = di([ConfirmationDialogInj], GeneralObjectControlTransitions);
    const [GeneralObjectControlTransitionBtn] = di(
        [GeneralObjectControlTransitionBtnInj],
        GeneralObjectControlTransitions,
    );
    const [GeneralObjectTransitionErrorDialog] = di(
        [GeneralObjectTransitionErrorDialogInj],
        GeneralObjectControlTransitions,
    );

    const { intlStore } = useStore();
    const intl = useIntl();
    const { objectStore } = useGeneralObjectContext();
    const { objectModel, updateStickyElementsHeight } = useGeneralObjectCardContext();
    const { id, tabId } = useParams<GeneralObjectRouteParams>();
    const [isConfirmDialogOpen, setIsConfirmDialogOpen, setIsConfirmDialogClose] = useFlag();
    const [isErrorsDialogOpen, openErrorsDialog, closeErrorsDialog] = useFlag();

    const model = useMemo(() => new ObjectTransitionsModel(id, objectStore), [objectStore]);

    useEffect(() => {
        model.load();
    }, [intlStore.locale]);

    const { transitions, lifeCycleTransition, setSelectedTransition, selectedTransition, isLoaded } = model;
    const { objectContentErrorsModel } = objectModel;
    const { lifeCycleTransitionErrorHandler } = objectContentErrorsModel;

    useEffect(() => {
        if (isLoaded) {
            updateStickyElementsHeight();
        }
    }, [isLoaded]);

    const handleConfirm = (): Promise<void> => {
        return lifeCycleTransition(() => objectModel.loadMainInfo(tabId))
            .catch((error) => lifeCycleTransitionErrorHandler(error, openErrorsDialog))
            .finally(setIsConfirmDialogClose);
    };

    const handleTransition =
        (transition: TransitionDTO): (() => void) =>
        (): void => {
            setSelectedTransition(transition);
            setIsConfirmDialogOpen();
        };

    return (
        <Grid container item alignItems="center" justifyContent="flex-start" spacing={4}>
            {selectedTransition && (
                <ConfirmationDialog
                    id="confirm-transition"
                    keepMounted
                    isOpen={isConfirmDialogOpen}
                    onConfirm={handleConfirm}
                    setIsClosed={setIsConfirmDialogClose}
                    title={intl.formatMessage({ id: 'common.lifeCycleConfirmTitle' })}
                    message={intl.formatMessage(
                        { id: 'common.lifeCycleConfirmText' },
                        {
                            toState: selectedTransition.toStateTitle,
                        },
                    )}
                />
            )}
            <GeneralObjectTransitionErrorDialog
                isOpen={isErrorsDialogOpen}
                setIsClosed={closeErrorsDialog}
                keepMounted
                id="transition-error-dialog"
            />
            {transitions.map((transition) => (
                <Grid item key={transition.id}>
                    <GeneralObjectControlTransitionBtn
                        transition={transition}
                        handleTransition={handleTransition(transition)}
                    />
                </Grid>
            ))}
        </Grid>
    );
});
