import { PrintFormPermissionSettingsModel, TabPermissionSettingsModel } from '../models';
import { PrintFormAccessPermissionDTO } from './printForms';
import { TabAccessPermissionValues } from './tabSettings';

export enum CommonSystemEntityPermissionSettingsFields {
    roleCondition = 'roleCondition',
    whenObjectStateIsOneOf = 'whenObjectStateIsOneOf',
}

export type SystemEntityPermissionSettingsModelType = TabPermissionSettingsModel | PrintFormPermissionSettingsModel;

export type SystemEntityPermissionFormValues = TabAccessPermissionValues | PrintFormAccessPermissionDTO;
