import { AxiosPromise } from 'axios';
import { action, computed, makeObservable, observable } from 'mobx';
import { di } from 'react-magnetic-di';
import { apiConfigs } from '../apiConfigs';
import {
    LoginModel,
    NewPasswordModel,
    RecoveryPasswordModel,
    RegistrationConfirmModel,
    RegistrationModel,
} from '../models';
import { clearStorageLocales } from '../utils';
import { ApiStore } from './ApiStore';
import { RootStore } from './RootStore';

export const AuthenticationStoreProps = {
    rootStore: observable,
    api: observable,
    loginModel: computed,
    registrationModel: computed,
    registrationConfirmModel: computed,
    recoveryPasswordModel: computed,
    newPasswordModel: action.bound,
    esiaGetAuthUrl: action.bound,
    esiaLogin: action.bound,
    logout: action.bound,
    loginAnonymously: action.bound,
};

export class AuthenticationStore {
    private rootStore: RootStore;
    private api: ApiStore;

    constructor(rootStore: RootStore) {
        makeObservable(this, AuthenticationStoreProps);
        this.rootStore = rootStore;
        this.api = rootStore.api;
    }

    get loginModel(): LoginModel {
        return new LoginModel(this.rootStore.api, this.rootStore.intlStore, this.rootStore.personStore);
    }

    get registrationModel(): RegistrationModel {
        return new RegistrationModel(this.rootStore.api, this.rootStore.intlStore);
    }

    get registrationConfirmModel(): RegistrationConfirmModel {
        return new RegistrationConfirmModel(this.rootStore);
    }

    get recoveryPasswordModel(): RecoveryPasswordModel {
        return new RecoveryPasswordModel(this.rootStore.api, this.rootStore.intlStore);
    }

    newPasswordModel(token: string): NewPasswordModel {
        return new NewPasswordModel(this.rootStore, token);
    }

    esiaGetAuthUrl(): Promise<string> {
        return this.api.client(apiConfigs.esiaAuthUrl).then((r) => r.data);
    }

    esiaLogin(search: string): Promise<void> {
        return this.api.client(apiConfigs.esiaLogin(search)).then((r) => r.data);
    }

    logout(): AxiosPromise<void> {
        clearStorageLocales();
        return this.api.client(apiConfigs.logout);
    }

    loginAnonymously(): AxiosPromise<void> {
        return this.api.client(apiConfigs.loginAnonymously);
    }
}

export const getAuthenticationStore = (): any => {
    const [_AuthenticationStore] = di([AuthenticationStore], getAuthenticationStore);
    return _AuthenticationStore;
};
