export default {
    search: false,
    startPageLinks: true,
    startPage: true,
    loginWithoutRegister: true,
    startPageLogoImg: {
        isEnabled: true,
        style: { width: '80px', height: '40px' },
        locales: {
            ru: {
                src: require('./mas-logo.png'),
                alt: 'TOT Record',
            },
            en: {
                src: require('./mas-logo.png'),
                alt: 'TOT Record',
            },
        },
    },
    startPageLogoText: true,
    headerLogoImg: {
        isEnabled: true,
        locales: {
            ru: {
                src: require('./mas-logo.png'),
                alt: 'TOT Record',
            },
            en: {
                src: require('./mas-logo.png'),
                alt: 'TOT Record',
            },
        },
        style: { width: '40px', height: '20px' },
    },
    logo: {},
    headerLogoText: true,
    customBackground: false,
    customSidebarIcon: false,
    helpIcon: true,
    notificationIcon: true,
    phoneNumber: true,
    startPageDefaultText: true,
    esiaLogin: true,
    showCreateRequestButton: true,
    showRequestsLamp: true,
    loginDialogClose: true,
    loginDialogAlert: false,
    yaMetrika: false,
    modularNavigation: true,
    footerLogo: false,
    samlAuth: {
        isEnabled: true,
        providers: [
            {
                code: 'onelogin',
                title: 'OneLogin',
            },
        ],
    },
};
