import React from 'react';
import { di } from 'react-magnetic-di';
import { ActionMenuItem as ActionMenuItemInj, UserStatusModal as UserStatusModalInj } from '../../../components';
import { useFlag } from '../../../hooks';
import { ActionItemProps, UserTableRow } from '../../../types';
import { actionCodeByUserStatus } from '../../../utils';

export type UserStatusButtonProps = ActionItemProps & {
    userTableRow: UserTableRow;
    reloadData: () => void;
    toggleIsRowBlocked: () => void;
    rowErrorHandler: () => void;
};

export const UserStatusButton = (props: UserStatusButtonProps) => {
    const [ActionMenuItem] = di([ActionMenuItemInj], UserStatusButton);
    const [UserStatusModal] = di([UserStatusModalInj], UserStatusButton);

    const { userTableRow, reloadData, hideMenu, toggleIsRowBlocked, rowErrorHandler } = props;
    const [isConfirmationDialogOpen, setConfirmationDialogOpen, setConfirmationDialogClosed] = useFlag();

    const customData = userTableRow.customData;
    const { name, login } = customData;
    const code = userTableRow.customData.state.code;
    const id = userTableRow.id;

    const closeDialogAndMenu = (): void => {
        setConfirmationDialogClosed();
        hideMenu && hideMenu();
    };

    return (
        <React.Fragment>
            <ActionMenuItem
                messageId={`user.action.${actionCodeByUserStatus[code]}`}
                onClick={setConfirmationDialogOpen}
            />
            <UserStatusModal
                id={id}
                name={name.title}
                login={login.title}
                userStatusCode={code}
                isOpen={isConfirmationDialogOpen}
                reloadData={reloadData}
                setIsClosed={closeDialogAndMenu}
                toggleIsRowBlocked={toggleIsRowBlocked}
                rowErrorHandler={rowErrorHandler}
            />
        </React.Fragment>
    );
};
