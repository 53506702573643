import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { borderAppBar } from '../../../../theme';

export const TotObjectHeader = styled(Grid)(
    ({ theme }) => `
            color: ${theme.variables.palette.textDark};
            border-bottom: ${borderAppBar};
            background-color: ${theme.palette.primary.contrastText};
            padding: ${theme.spacing(2)} ${theme.spacing(4)};
    `,
);
