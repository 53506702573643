import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { fontStylesMain, redesignTotPalette } from '../../../../theme';

export const UserButtonStyle: SxProps<Theme> = {
    height: '32px',
    width: '32px',
    ...fontStylesMain,
    color: redesignTotPalette.userButton.default.color,
    backgroundColor: redesignTotPalette.userButton.default.background,
};
