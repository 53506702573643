import { Link } from '@mui/material';
import React from 'react';

export const mailToLink =
    (link: string) =>
    (...chunks: string[]): JSX.Element => {
        return (
            <Link sx={{ display: 'inline' }} color="primary" underline="always" href={`mailto:${link}`}>
                {chunks}
            </Link>
        );
    };
