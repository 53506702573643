import { LinkType, TTableRow } from '@platform/ttable';
import { DescendantObjectCreateDTO, Identifier } from './generalObject';
import { CodeTitle, IdTitle } from './withTitle';

export type PortfolioInfo = {
    id: LinkType;
    title: LinkType;
    identifier: string;
    manager: IdTitle;
    viewer: IdTitle;
};

export type ProjectTableRow = TTableRow & {
    customData: {
        identifier: LinkType;
        title: LinkType;
        state: CodeTitle & {
            processId: string;
            processTitle: string;
        };
        curator: IdTitle;
        manager: IdTitle;
        portfolio: PortfolioInfo;
        category: LinkType;
        created: string; // date
        monitor: IdTitle;
        author: IdTitle;
        viewers: IdTitle[];
    };
};

export type ProjectCreateInfo = {
    identifier: Identifier;
    categories: IdTitle[];
};

export type ProjectCreateDTO = DescendantObjectCreateDTO & {
    portfolioId: string;
};

export enum CreateProjectFields {
    titles = 'titles',
    portfolio = 'portfolio',
    category = 'category',
    number = 'number',
}
