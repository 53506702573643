import { Box, Grid } from '@mui/material';
import { useFeature } from 'feature-toggle-jsx';
import React from 'react';
import { di } from 'react-magnetic-di';
import { contactSx } from '../../../constants';
import { MailIcon, PhoneIcon } from '../../../resources';
import { getEmailFromText as getEmailFromTextInj } from '../../../utils';
import { getPhoneNumberFromText as getPhoneNumberFromTextInj } from '../../../utils/getPhoneNumberFromText';
import { FooterButton as FooterButtonInj } from './FooterButton';
import { FooterTypography as FooterTypographyInj } from './FooterTypography';

export type FooterContactInformationProps = {
    phoneNumberText?: string;
    emailText?: string;
};

export const FooterContactInformation = (props: FooterContactInformationProps): JSX.Element => {
    const [FooterButton] = di([FooterButtonInj], FooterContactInformation);
    const [FooterTypography] = di([FooterTypographyInj], FooterContactInformation);
    const [getEmailFromText] = di([getEmailFromTextInj], FooterContactInformation);
    const [getPhoneNumberFromText] = di([getPhoneNumberFromTextInj], FooterContactInformation);

    const [phoneNumber] = useFeature('phoneNumber');

    const { phoneNumberText = '', emailText = '' } = props;

    const hrefTel = getPhoneNumberFromText(phoneNumberText);

    const hrefEmail = getEmailFromText(emailText);

    return (
        <Grid item>
            <Grid container direction="row" alignItems="center" justifyContent="flex-end">
                {phoneNumber && hrefTel && (
                    <Grid item>
                        <Box pr={3}>
                            <FooterButton
                                // Здесь и далее игнорим ошибку "Property 'component' does not exist on type...",
                                // фикса для которой пока не существует (проблема в typescript)
                                // @ts-ignore
                                component="a"
                                href={`tel:${hrefTel}`}
                                startIcon={<PhoneIcon />}
                                variant="text"
                                color="secondary"
                            >
                                {/* @ts-ignore */}
                                <FooterTypography component="span">{phoneNumberText}</FooterTypography>
                            </FooterButton>
                        </Box>
                    </Grid>
                )}
                {hrefEmail && (
                    <Grid item>
                        <FooterButton
                            // @ts-ignore
                            component="a"
                            href={`mailto:${hrefEmail}`}
                            startIcon={<MailIcon />}
                            variant="text"
                            color="secondary"
                        >
                            {/* @ts-ignore */}
                            <FooterTypography component="span" sx={contactSx}>
                                {emailText}
                            </FooterTypography>
                        </FooterButton>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};
