import { LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDateFns';
import { ThemeProvider } from '@mui/material';
import { createGenerateClassName, StylesProvider } from '@mui/styles';
import enLocale from 'date-fns/locale/en-US';
import ruLocale from 'date-fns/locale/ru';
import { FeaturesProvider } from 'feature-toggle-jsx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { di, DiProvider } from 'react-magnetic-di';
import { Router } from 'react-router-dom';
import { App } from './App';
import { AppLoader } from './components';
import { ru } from './constants';
import { StoreContext } from './contexts';
import { useStore } from './hooks';
import { TotIntlProvider } from './providers';
import { localesEn, localesRu } from './resources';
import { RootStore as RootStoreInj } from './stores';
import { createAppTheme } from './theme';
import { AppTheme, LocalizedMessages, RootProps, RootStorePropsType } from './types';
import { getFullLocales } from './utils';

const defaultLocales: RootStorePropsType['locales'] = { ru: localesRu, en: localesEn };

const generateClassName = createGenerateClassName({
    productionPrefix: 'mas-front-core',
    seed: 'mas-front-core',
});

const RootUnwrapped = observer((): JSX.Element => {
    const { appTheme, intlStore } = useStore();
    return (
        <React.Fragment>
            <StylesProvider generateClassName={generateClassName}>
                <ThemeProvider theme={appTheme as AppTheme}>
                    <TotIntlProvider>
                        <LocalizationProvider
                            dateAdapter={DateAdapter}
                            locale={intlStore.locale === ru ? ruLocale : enLocale}
                        >
                            <App />
                        </LocalizationProvider>
                    </TotIntlProvider>
                </ThemeProvider>
            </StylesProvider>
        </React.Fragment>
    );
});

const RootWithFeatures = observer((): JSX.Element => {
    const { features } = useStore();
    return (
        <FeaturesProvider features={features as any}>
            <RootUnwrapped />
        </FeaturesProvider>
    );
});

const RootWithStore = (props: RootStorePropsType): JSX.Element => {
    const [RootStore] = di([RootStoreInj], RootWithStore);
    const store = new RootStore(props);
    const theme = createAppTheme(store, props.createAppThemeOptions);
    store.setAppTheme(theme);
    return (
        <Router history={store.history}>
            <StoreContext.Provider value={store}>
                <RootWithFeatures />
            </StoreContext.Provider>
        </Router>
    );
};

export const Root = (props: RootProps): JSX.Element => {
    const { env, use = [] } = props;
    const [locales, setLocales] = useState<LocalizedMessages>();

    useEffect(() => {
        getFullLocales(defaultLocales, env).then(setLocales);
    }, []);

    return (
        <React.Fragment>
            {locales ? (
                <DiProvider use={use}>
                    <RootWithStore {...props} locales={locales} />
                </DiProvider>
            ) : (
                <AppLoader />
            )}
        </React.Fragment>
    );
};
