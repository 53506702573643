import { PieSvgProps } from '@nivo/pie';
import { CodeTitle, CodeTitleNull, IdTitle } from '@platform/front-utils';
import { TitlesDTO } from '@platform/multi-lang-field';

export type ChartSettingListDTO = IdTitle & {
    positionRank: number;
    chartType: ChartType;
    sourceType: string;
};

export type ChartFormData = {
    [key: string]: any;
};

export type ChartFormDataDTO = ChartFormData | null;

// типы диаграмм
export enum ChartSourceType {
    form = 'form',
    registry = 'registry',
}

// типы диаграмм
export enum ChartType {
    Pie = 'Pie',
}

export type ChartSettingCreateDTO = {
    titles: TitlesDTO;
};

export enum ChartSettingField {
    source = 'source',
    typeChart = 'typeChart',
    objectTab = 'objectTab',
    element = 'element',
    dataLabelsFieldCode = 'dataLabelsFieldCode',
    valuesFieldCode = 'valuesFieldCode',
    chartSettings = 'chartSettings',
    objectRegistry = 'objectRegistry',
    registryQuery = 'registryQuery',
}

export type ChartSettingFormValues = ChartDataGeneralFields & {
    objectTab: CodeTitleNull;
    typeChart: CodeTitleNull;
    source: CodeTitleNull;
    objectRegistry: CodeTitleNull;
};

export type PieChartDefaultSettings = Omit<PieSvgProps<ChartData>, 'data' | 'width' | 'height'>;

export type ChartData = {
    id: string;
    label: string;
    value: number;
};

export type ChartSettingItemDTO = {
    id: string;
    positionRank: number;
    title: string;
    settings?: ChartSettingDTO;
};

export type ChartSettingDTO = {
    chartType: string;
    chartOptions: ChartOptionDTO;
    dataSourceOptions: DataSourceOptionsDTO;
};

export type ChartOptionDTO = {
    visualizationOptions: any; // настройки у каждой диаграммы будут свои, нет смысла их типизировать
    dataOptions: ChartDataOptions;
};

export type ChartDataGeneralFields = {
    element: string;
    dataLabelsFieldCode: string;
    valuesFieldCode: string;
};

export type ChartDataOptions = Partial<ChartDataGeneralFields> & {
    source: string;
};

export type ChartSettingFormValuesDTO = Partial<ChartDataGeneralFields> & {
    source?: CodeTitle;
    typeChart?: CodeTitle;
    tab?: CodeTitle;
    registry?: CodeTitle;
};

export type DataSourceOptionsDTO = {
    tabCode?: string;
    registry?: DataSourceRegistryDTO;
};

export type DataSourceRegistryDTO = {
    entityType: string; // тип объекта из реестра
    query: any; // запрос сотавляется так же с помощью json, нет смысла типизации
};
