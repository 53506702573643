import { useFeature } from 'feature-toggle-jsx';
import { toJS } from 'mobx';
import React from 'react';
import { useStore } from '../hooks';
import { CustomLogoFeature } from '../types';

export type CustomLogoImgProps = {
    logoFeatureName: CustomLogoFeature;
};

export const CustomLogoImg = (props: CustomLogoImgProps): JSX.Element => {
    const { logoFeatureName } = props;

    const [logo, logoConfig] = useFeature(logoFeatureName);
    const { intlStore } = useStore();
    const { locale } = intlStore;
    const { alt, src } = logoConfig.locales[locale];
    const customLogoStyle = toJS(logoConfig.style);

    return <img src={src} alt={alt} style={customLogoStyle} />;
};
