import { Grid } from '@mui/material';
import { NotificationWidget } from '@platform/notification-widget';
import { useFeature } from 'feature-toggle-jsx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { useStore } from '../../../../hooks';

export const NotificationButton = observer((): JSX.Element => {
    const [notificationIcon] = useFeature('notificationIcon');
    const { personStore, lkNotificationStore } = useStore();
    const { loadNotifications, loadCountNotViewedMessages, changeViewed, deleteNotification } = lkNotificationStore;
    const intl = useIntl();
    const userId = personStore.user.id;

    return (
        <React.Fragment>
            {notificationIcon && userId && (
                <Grid item>
                    <NotificationWidget
                        locale={intl.locale}
                        loadNotifications={loadNotifications}
                        loadCountNotViewedMessages={loadCountNotViewedMessages}
                        changeViewed={changeViewed}
                        deleteNotification={deleteNotification}
                        userId={userId}
                    />
                </Grid>
            )}
        </React.Fragment>
    );
});
