import { FullSubmissionWithAdditionalInfo } from '@platform/formiojs-react';
import { TitlesDTO } from '@platform/multi-lang-field';
import { NotificationsQueryData } from '@platform/notification-widget';
import { AxiosRequestConfig } from 'axios';
import {
    AuthorizationCheckQuery,
    ChartSettingCreateDTO,
    ChartSettingDTO,
    ConfirmEmailDTO,
    CreateCategoryCopyDTO,
    DataSourceOptionsDTO,
    EditStatusDTO,
    EmailDTO,
    FullNameSettingsDTO,
    GeneralObject,
    LoginDTO,
    NewObjectTabSettingsDTO,
    NewPasswordDTO,
    NewTabPermissionDTO,
    ObjectCreateType,
    PrintFormNewPermissionDTO,
    RegistrationDTO,
    RolesSettingsDTO,
    SettingsContentDTO,
    TotLocale,
    UserRole,
    UserUpdateBody,
} from './types';

class ApiConfigs {
    login: (data: LoginDTO) => AxiosRequestConfig = (data) => {
        return {
            url: '/login',
            method: 'PUT',
            data,
        };
    };

    esiaAuthUrl: AxiosRequestConfig = {
        url: '/authUrl/esia',
        method: 'GET',
    };

    esiaLogin: (search: string) => AxiosRequestConfig = (search) => {
        return {
            url: `/login/esia${search}`,
            method: 'PUT',
        };
    };

    samlAuthUrl: (provider: string, backUrl: string) => AxiosRequestConfig = (provider, backUrl) => {
        const backUrlParam = backUrl ? `?backUrl=${backUrl}` : '';
        return {
            url: `/saml/${provider}/loginUrl${backUrlParam}`,
            method: 'GET',
        };
    };

    logout: AxiosRequestConfig = {
        url: '/logout',
        method: 'PUT',
    };

    resetPassword: (data: NewPasswordDTO) => AxiosRequestConfig = (data) => {
        return {
            url: '/reset-password',
            method: 'PUT',
            data,
        };
    };

    forgotPassword: (data: EmailDTO) => AxiosRequestConfig = (data) => {
        return {
            url: '/forgot-password',
            method: 'PUT',
            data,
        };
    };

    confirmEmail: (data: ConfirmEmailDTO) => AxiosRequestConfig = (data) => {
        return {
            url: '/confirm-email',
            method: 'PUT',
            data,
        };
    };

    register: (data: RegistrationDTO) => AxiosRequestConfig = (data) => {
        return {
            url: '/register',
            method: 'PUT',
            data,
        };
    };

    authorizationCheck: (queries: Readonly<AuthorizationCheckQuery[]>) => AxiosRequestConfig = (queries) => {
        return {
            url: '/check',
            method: 'PUT',
            data: queries,
        };
    };

    person: (lang: string) => AxiosRequestConfig = (lang) => {
        return {
            url: '/person',
            method: 'GET',
            params: {
                lang,
            },
        };
    };

    setLang: (lang: string) => AxiosRequestConfig = (lang) => {
        return {
            url: '/person',
            method: 'PUT',
            params: {
                lang,
            },
        };
    };

    loadUser: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/administration/user/${id}`,
            method: 'GET',
        };
    };

    saveUser: (id: string, submissionData: FullSubmissionWithAdditionalInfo) => AxiosRequestConfig = (
        id,
        submissionData,
    ) => {
        return {
            url: `/administration/user/${id}`,
            method: 'PUT',
            data: submissionData,
        };
    };

    loginAsUser: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/login-as/${id}`,
            method: 'PUT',
        };
    };

    loginAnonymously: AxiosRequestConfig = {
        url: '/login-anonymous',
        method: 'PUT',
    };

    checkIsPasswordRecoveryTokenValid = (token: string): AxiosRequestConfig => {
        return {
            url: `/checkPasswordResetToken?token=${token}`,
            method: 'GET',
        };
    };

    userRoleList: AxiosRequestConfig = {
        url: '/roles',
        method: 'GET',
    };

    saveUserHead: (id: string, body: UserUpdateBody) => AxiosRequestConfig = (id, body) => {
        return {
            url: `/administration/user/${id}/update`,
            method: 'PUT',
            data: body,
        };
    };

    editUserStatus: (id: string, data: EditStatusDTO) => AxiosRequestConfig = (id, data) => {
        return {
            url: `/administration/user/${id}/status`,
            method: 'PUT',
            data,
        };
    };

    setRoles: (id: string, data: RolesSettingsDTO) => AxiosRequestConfig = (id, data) => {
        return {
            url: `/administration/user/${id}/roles`,
            method: 'PUT',
            data,
        };
    };

    setUserLang: (id: string, lang: TotLocale) => AxiosRequestConfig = (id, lang) => {
        return {
            url: `/administration/user/${id}/lang`,
            method: 'PUT',
            params: {
                lang,
            },
        };
    };

    userFullName: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/administration/user/${id}/fullName`,
            method: 'GET',
        };
    };

    setFullName: (id: string, data: FullNameSettingsDTO) => AxiosRequestConfig = (id, data) => {
        return {
            url: `/administration/user/${id}/fullName`,
            method: 'PUT',
            data,
        };
    };

    deleteUser: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/administration/user/${id}`,
            method: 'DELETE',
        };
    };

    //<editor-fold desc="Пользователи (селект)">
    getAllNonBlockedUsers = (): AxiosRequestConfig => ({
        url: '/select/users/v2',
        method: 'GET',
        params: {
            blocked: false,
        },
    });

    getAllActiveEmployees: () => AxiosRequestConfig = () => {
        return {
            url: '/select/users?role=Employee&role=SeniorEmployee',
            method: 'GET',
            params: {
                blocked: false,
            },
        };
    };

    getAllEmployees: () => AxiosRequestConfig = () => {
        return {
            url: '/select/users?role=Employee&role=SeniorEmployee',
            method: 'GET',
        };
    };

    getActiveUsersByRole: (roleCode: UserRole) => AxiosRequestConfig = (roleCode): AxiosRequestConfig => {
        return {
            url: '/select/users',
            params: {
                blocked: false,
                role: roleCode,
            },
        };
    };
    //</editor-fold>

    catalogItems: (catalogCode: string, lang: string) => AxiosRequestConfig = (catalogCode, lang) => {
        return {
            url: `/catalogItems`,
            method: 'GET',
            params: {
                catalogCode,
                lang,
            },
        };
    };

    getLifecycleList: (processCode: string) => AxiosRequestConfig = (processCode) => {
        return {
            url: `/select/states/byProcessCode`,
            method: 'GET',
            params: {
                processCode,
            },
        };
    };

    tabFormsList: AxiosRequestConfig = {
        url: `/select/forms/search?code=Tab`,
        method: 'GET',
    };

    roleConditionsList: AxiosRequestConfig = {
        url: '/select/tabSetting/permission',
        method: 'GET',
    };

    deleteTab: (objectId: string, objectType: GeneralObject, tabId: string) => AxiosRequestConfig = (
        objectId,
        objectType,
        tabId,
    ) => {
        return {
            url: `/${objectType}/${objectId}/tabSetting/${tabId}`,
            method: 'DELETE',
        };
    };

    changeTabPosition: (
        objectId: string,
        objectType: GeneralObject,
        tabId: string,
        position: number,
    ) => AxiosRequestConfig = (objectId, objectType, tabId, position) => {
        return {
            url: `/${objectType}/${objectId}/tabSetting/${tabId}/position/${position}`,
            method: 'PUT',
        };
    };

    getTabSettings: (objectId: string, objectType: GeneralObject, tabId: string) => AxiosRequestConfig = (
        objectId,
        objectType,
        tabId,
    ) => {
        return {
            url: `/${objectType}/${objectId}/tabSetting/${tabId}`,
            method: 'GET',
        };
    };

    saveTabTitles: (objectId: string, objectType: GeneralObject, tabId: string, dto: TitlesDTO) => AxiosRequestConfig =
        (objectId, objectType, tabId, dto) => {
            return {
                url: `/${objectType}/${objectId}/tabSetting/${tabId}/titles`,
                method: 'PUT',
                data: dto,
            };
        };

    getTabTitles: (objectId: string, objectType: GeneralObject, tabId: string) => AxiosRequestConfig = (
        objectId,
        objectType,
        tabId,
    ) => {
        return {
            url: `/${objectType}/${objectId}/tabSetting/${tabId}/titles`,
            method: 'GET',
        };
    };

    getTabPermissionSettings: (objectId: string, objectType: GeneralObject, tabId: string) => AxiosRequestConfig = (
        objectId,
        objectType,
        tabId,
    ) => {
        return {
            url: `/${objectType}/${objectId}/tabSetting/${tabId}/permissions`,
            method: 'GET',
        };
    };

    getPFPermissionSettings: (tabId: string, pfId: string) => AxiosRequestConfig = (tabId, pfId) => {
        return {
            url: `/tabSetting/${tabId}/pfSetting/${pfId}/permissions`,
            method: 'GET',
        };
    };

    getTabContentSettings: (objectId: string, objectType: GeneralObject, tabId: string) => AxiosRequestConfig = (
        objectId,
        objectType,
        tabId,
    ) => {
        return {
            url: `/${objectType}/${objectId}/tabSetting/${tabId}/content`,
            method: 'GET',
        };
    };

    getGeneralTabs: (objectId: string, objectType: GeneralObject) => AxiosRequestConfig = (objectId, objectType) => {
        return {
            url: `/${objectType}/${objectId}/tabs`,
        };
    };

    updateTabPermission: (
        objectId: string,
        objectType: GeneralObject,
        tabId: string,
        data: NewTabPermissionDTO[],
    ) => AxiosRequestConfig = (objectId, objectType, tabId, data) => {
        return {
            url: `/${objectType}/${objectId}/tabSetting/${tabId}/update/permissions`,
            method: 'PUT',
            data,
        };
    };

    updatePFPermission: (tabId: string, pfId: string, data: PrintFormNewPermissionDTO[]) => AxiosRequestConfig = (
        tabId,
        pfId,
        data,
    ) => {
        return {
            url: `/tabSetting/${tabId}/pfSetting/${pfId}/permissions`,
            method: 'PUT',
            data,
        };
    };

    updateTabContent: (
        objectId: string,
        objectType: GeneralObject,
        tabId: string,
        data: SettingsContentDTO,
    ) => AxiosRequestConfig = (objectId, objectType, tabId, data) => {
        return {
            url: `/${objectType}/${objectId}/tabSetting/${tabId}/update/content`,
            method: 'PUT',
            data,
        };
    };

    //<editor-fold desc="Уведомления">
    loadNotifications = (queryData: NotificationsQueryData): AxiosRequestConfig => {
        return {
            url: '/notifications/lk',
            method: 'PUT',
            data: queryData,
        };
    };

    loadCountNotViewedMessages = (): AxiosRequestConfig => ({
        url: '/notifications/lk/countUnviewedMessage',
        method: 'GET',
    });

    changeViewed = (id: string, viewed: boolean): AxiosRequestConfig => {
        return {
            url: '/notification/lk/changeViewed',
            method: 'PUT',
            params: {
                deliveredNotificationId: id,
                viewed,
            },
        };
    };

    deleteNotification = (id: string): AxiosRequestConfig => {
        return {
            url: '/notification/lk',
            method: 'DELETE',
            params: {
                deliveredNotificationId: id,
            },
        };
    };
    //</editor-fold>

    loadModulesList: AxiosRequestConfig = {
        url: '/getRoutes',
        method: 'GET',
    };

    //<editor-fold desc="Объект">
    deleteObject: (objectId: string, objectType: GeneralObject) => AxiosRequestConfig = (objectId, objectType) => {
        return {
            url: `/${objectType}/${objectId}`,
            method: 'DELETE',
        };
    };

    getObjectCreateInfo: (objectType: GeneralObject, params?: Record<string, string>) => AxiosRequestConfig = (
        objectType,
        params,
    ) => {
        return {
            url: `/${objectType}/creationInfo`,
            method: 'GET',
            params,
        };
    };

    getProjectCreateInfo: (objectType: GeneralObject, id: string) => AxiosRequestConfig = (objectType, id) => {
        return {
            url: `/${objectType}/creationInfo`,
            method: 'GET',
            params: {
                portfolioId: id,
            },
        };
    };

    editProjectNumber: (projectId: string, number: string) => AxiosRequestConfig = (projectId, number) => {
        const params = number ? { number } : {};
        return {
            url: `/project/${projectId}/identifier`,
            method: 'PUT',
            params,
        };
    };

    editProjectManager: (projectId: string, managerId: string) => AxiosRequestConfig = (projectId, managerId) => {
        const params = managerId ? { managerId } : {};
        return {
            url: `/project/${projectId}/manager`,
            method: 'PUT',
            params,
        };
    };

    editProjectCurator: (projectId: string, curatorId: string) => AxiosRequestConfig = (projectId, curatorId) => {
        const params = curatorId ? { curatorId } : {};
        return {
            url: `/project/${projectId}/curator`,
            method: 'PUT',
            params,
        };
    };

    editProjectMonitor: (projectId: string, monitorId: string) => AxiosRequestConfig = (projectId, monitorId) => {
        const params = monitorId ? { monitorId } : {};
        return {
            url: `/project/${projectId}/monitor`,
            method: 'PUT',
            params,
        };
    };

    editProjectViewers: (projectId: string, viewerIds: string[]) => AxiosRequestConfig = (projectId, viewerIds) => {
        return {
            url: `/project/${projectId}/viewers`,
            method: 'PUT',
            data: viewerIds,
        };
    };

    editProjectExecutors: (projectId: string, executorIds: string[]) => AxiosRequestConfig = (
        projectId,
        executorIds,
    ) => {
        return {
            url: `/project/${projectId}/executors`,
            method: 'PUT',
            data: executorIds,
        };
    };

    editChildObjectNumber: (childObjectId: string, number: string) => AxiosRequestConfig = (childObjectId, number) => {
        const params = number ? { number } : {};
        return {
            url: `/childObject/${childObjectId}/identifier`,
            method: 'PUT',
            params,
        };
    };

    editChildObjectExecutor: (childObjectId: string, executorId: string) => AxiosRequestConfig = (
        childObjectId,
        executorId,
    ) => {
        const params = executorId ? { executorId } : {};
        return {
            url: `/childObject/${childObjectId}/executor`,
            method: 'PUT',
            params,
        };
    };

    createObject: <T extends ObjectCreateType>(
        dto: T,
        objectType: GeneralObject,
        tabId?: string,
    ) => AxiosRequestConfig = (dto, objectType, tabId) => {
        const params = tabId ? { tabId } : {};
        const defaultUrl = `/${objectType}`;
        const url = tabId ? defaultUrl + '/byTab' : defaultUrl;

        return {
            url,
            method: 'POST',
            data: dto,
            params,
        };
    };

    getObject: (objectId: string, objectType: GeneralObject) => AxiosRequestConfig = (objectId, objectType) => {
        return {
            url: `/${objectType}/${objectId}`,
            method: 'GET',
        };
    };

    getObjectServiceInfo: (objectId: string, objectType: GeneralObject) => AxiosRequestConfig = (
        portfolioId,
        objectType,
    ) => {
        return {
            url: `/${objectType}/${portfolioId}/utilInfo`,
            method: 'GET',
        };
    };

    getObjectTitles: (objectId: string, objectType: GeneralObject) => AxiosRequestConfig = (objectId, objectType) => {
        return {
            url: `/${objectType}/${objectId}/titles`,
            method: 'GET',
        };
    };

    editObjectTitles: (objectId: string, objectType: GeneralObject, dto: TitlesDTO) => AxiosRequestConfig = (
        objectId,
        objectType,
        dto,
    ) => {
        return {
            url: `/${objectType}/${objectId}/titles`,
            method: 'PUT',
            data: dto,
        };
    };

    transitionsObject: (objectId: string, objectType: GeneralObject) => AxiosRequestConfig = (objectId, objectType) => {
        return {
            url: `/${objectType}/${objectId}/transitions`,
            method: 'GET',
        };
    };

    transitionToNextLifeCycleStepObject: (
        transitionId: string,
        objectId: string,
        objectType: GeneralObject,
    ) => AxiosRequestConfig = (transitionId, objectId, objectType) => {
        return {
            url: `/${objectType}/${objectId}/changeState`,
            method: 'PUT',
            params: {
                transition: transitionId,
            },
        };
    };

    addNewObjectTab: (
        objectId: string,
        objectType: GeneralObject,
        data: NewObjectTabSettingsDTO,
    ) => AxiosRequestConfig = (objectId, objectType, data) => {
        return {
            url: `/${objectType}/${objectId}/tabSettings/create`,
            method: 'POST',
            data,
        };
    };

    getObjectTabsSettings: (objectId: string, objectType: GeneralObject) => AxiosRequestConfig = (
        objectId,
        objectType,
    ) => {
        return {
            url: `/${objectType}/${objectId}/tabSettings`,
            method: 'GET',
        };
    };

    getObjectTabsSettingsWithForm: (objectId: string, objectType: GeneralObject) => AxiosRequestConfig = (
        objectId,
        objectType,
    ) => {
        return {
            url: `/${objectType}/${objectId}/tabSettings/withForms`,
            method: 'GET',
        };
    };

    getRegistries: (objectId: string, objectType: GeneralObject) => AxiosRequestConfig = (objectId, objectType) => {
        return {
            url: `/${objectType}/${objectId}/tabSettings/chart-registry-sources`,
            method: 'GET',
        };
    };

    getContentObjectList: (objectType: GeneralObject) => AxiosRequestConfig = (objectType) => {
        return {
            url: `/${objectType}/select/entityTypes`,
            method: 'GET',
        };
    };

    getTabSettingsContentInfo: (registryCode: string, objectType: GeneralObject) => AxiosRequestConfig = (
        registryCode,
        objectType,
    ) => {
        return {
            url: `/${objectType}/select/${registryCode}/infos`,
            method: 'GET',
        };
    };

    getPrintFormsList: (objectType: GeneralObject, objectId: string, tabId: string) => AxiosRequestConfig = (
        objectType,
        objectId,
        tabId,
    ) => {
        return {
            url: `/${objectType}/${objectId}/tabSetting/${tabId}/pfSettings`,
            method: 'GET',
        };
    };

    generatePrintForm: (tabId: string, pfSettingId: string) => AxiosRequestConfig = (tabId, pfSettingId) => {
        return {
            url: `/tab/${tabId}/printForm/${pfSettingId}`,
            method: 'PUT',
        };
    };

    changePFPosition: (tabId: string, pfId: string, position: number) => AxiosRequestConfig = (
        tabId,
        pfId,
        position,
    ) => {
        return {
            url: `/tabSetting/${tabId}/pfSetting/${pfId}/position/${position}`,
            method: 'PUT',
        };
    };

    getPrintForm: (tabId: string, pfId: string) => AxiosRequestConfig = (tabId, pfId) => {
        return {
            url: `/tabSetting/${tabId}/pfSetting/${pfId}`,
            method: 'GET',
        };
    };

    getPrintFormsCreationInfo: (tabId: string) => AxiosRequestConfig = (tabId) => {
        return {
            url: `/tabSetting/${tabId}/pfSetting/creationInfo`,
            method: 'GET',
        };
    };

    addPrintForm: (tabId: string, data: FormData) => AxiosRequestConfig = (tabId, data) => {
        return {
            url: `/tabSetting/${tabId}/pfSetting`,
            method: 'POST',
            data,
        };
    };

    downloadTemplatePrintForm: (tabId: string, pfId: string) => AxiosRequestConfig = (tabId, pfId) => {
        return {
            url: `/tabSetting/${tabId}/pfSetting/${pfId}/download`,
            method: 'GET',
            responseType: 'blob',
        };
    };

    downloadAllFiles: (tabId: string) => AxiosRequestConfig = (tabId) => {
        return {
            url: `/tab/${tabId}/batch-download-files`,
            method: 'GET',
            responseType: 'blob',
        };
    };

    downloadPrintForm: (tabId: string, pfSettingId: string) => AxiosRequestConfig = (tabId, pfSettingId) => {
        return {
            url: `/tab/${tabId}/printForm/${pfSettingId}/download`,
            method: 'GET',
            responseType: 'blob',
        };
    };

    deletePrintForm: (tabId: string, pfId: string) => AxiosRequestConfig = (tabId, pfId) => {
        return {
            url: `/tabSetting/${tabId}/pfSetting/${pfId}`,
            method: 'DELETE',
        };
    };

    editPrintForm: (tabId: string, pfId: string, data: FormData) => AxiosRequestConfig = (tabId, pfId, data) => {
        return {
            url: `/tabSetting/${tabId}/pfSetting/${pfId}`,
            method: 'PUT',
            data,
        };
    };

    editCategoryForm: (categoryId: string, submissionData: FullSubmissionWithAdditionalInfo) => AxiosRequestConfig = (
        categoryId,
        submissionData,
    ) => {
        return {
            url: `/category/${categoryId}`,
            method: 'PUT',
            data: submissionData,
        };
    };

    editObjectForm: (
        objectType: GeneralObject,
        objectId: string,
        tabId: string,
        submissionData: FullSubmissionWithAdditionalInfo,
    ) => AxiosRequestConfig = (objectType, objectId, tabId, submissionData) => {
        return {
            url: `/${objectType}/${objectId}/tab/${tabId}`,
            method: 'PUT',
            data: submissionData,
        };
    };

    editPortfolioNumber: (portfolioId: string, number: string) => AxiosRequestConfig = (portfolioId, number) => {
        return {
            url: `/portfolio/${portfolioId}/identifier`,
            method: 'PUT',
            params: {
                number,
            },
        };
    };

    editPortfolioManager: (portfolioId: string, managerId: string) => AxiosRequestConfig = (portfolioId, managerId) => {
        const params = managerId ? { managerId } : {};
        return {
            url: `/portfolio/${portfolioId}/manager`,
            method: 'PUT',
            params,
        };
    };

    editPortfolioViewers: (portfolioId: string, viewerIds: string[]) => AxiosRequestConfig = (
        portfolioId,
        viewerIds,
    ) => {
        return {
            url: `/portfolio/${portfolioId}/viewers`,
            method: 'PUT',
            data: viewerIds,
        };
    };

    getPortfoliosList: () => AxiosRequestConfig = () => {
        return {
            url: `/select/portfolios/active`,
            method: 'GET',
        };
    };

    getObjectTabContent: (objectType: GeneralObject, objectId: string, tabId: string) => AxiosRequestConfig = (
        objectType,
        objectId,
        tabId,
    ) => {
        return {
            url: `/${objectType}/${objectId}/tab/${tabId}`,
            method: 'GET',
        };
    };

    getObjectTabPrintForms: (tabId: string) => AxiosRequestConfig = (tabId) => {
        return {
            url: `/tab/${tabId}/printForms`,
            method: 'GET',
        };
    };

    editTabArchived: (
        objectType: GeneralObject,
        objectId: string,
        tabId: string,
        archive: boolean,
    ) => AxiosRequestConfig = (objectType, objectId, tabId, archive: boolean) => {
        return {
            url: `/${objectType}/${objectId}/tabSetting/${tabId}/archive`,
            method: 'PUT',
            params: {
                archive,
            },
        };
    };

    getServerRoutes: (entityType: string, entityId?: string, location?: string) => AxiosRequestConfig = (
        entityType,
        entityId,
        location,
    ) => {
        const params: Record<string, string> = {
            entityType,
        };

        if (location) {
            params.location = location;
        }

        if (entityId) {
            params.entityId = entityId;
        }

        return {
            url: '/breadcrumbs',
            method: 'GET',
            params,
        };
    };

    runScript: (script: string) => AxiosRequestConfig = (script) => {
        return {
            url: `/console/script`,
            method: 'POST',
            responseType: 'blob',
            data: script,
        };
    };

    createCategoryCopy: (dto: CreateCategoryCopyDTO, prototypeCategoryId: string) => AxiosRequestConfig = (
        dto,
        prototypeCategoryId,
    ) => {
        return {
            url: '/category/copy',
            method: 'POST',
            data: {
                titles: dto.titles,
                categoryId: prototypeCategoryId,
            },
        };
    };

    changeChartSettingPosition: (tabId: string, chartSettingId: string, newPosition: number) => AxiosRequestConfig = (
        tabId,
        chartSettingId,
        newPosition,
    ) => {
        return {
            url: `/chartContent/${chartSettingId}/position/${newPosition}`,
            method: 'PUT',
        };
    };

    getChartSettingList: (objectType: GeneralObject, objectId: string, tabId: string) => AxiosRequestConfig = (
        objectType,
        objectId,
        tabId,
    ) => {
        return {
            url: `/${objectType}/${objectId}/tabSetting/${tabId}/chartContents`,
            method: 'GET',
        };
    };

    createChartSetting: (tabId: string, dto: ChartSettingCreateDTO) => AxiosRequestConfig = (tabId, dto) => {
        return {
            url: `/tabSetting/${tabId}/chartContent`,
            method: 'POST',
            data: dto,
        };
    };

    getChartSources: AxiosRequestConfig = {
        url: '/select/chart-data-source-types',
        method: 'GET',
    };

    getChartSettingPage: (chartSettingId: string) => AxiosRequestConfig = (chartSettingId) => {
        return {
            url: `/chartContent/${chartSettingId}`,
            method: 'GET',
        };
    };
    submitChartSetting: (chartSettingId: string, dto: ChartSettingDTO) => AxiosRequestConfig = (
        chartSettingId,
        dto,
    ) => {
        return {
            url: `/chartContent/${chartSettingId}/setting`,
            method: 'PUT',
            data: dto,
        };
    };

    getPreviewData: (chartSettingId: string, dto: DataSourceOptionsDTO) => AxiosRequestConfig = (
        chartSettingId,
        dto,
    ) => {
        return {
            url: `/chartContent/${chartSettingId}/dataSource/preview`,
            method: 'PUT',
            data: dto,
        };
    };

    saveChartSettingTitles: (chartSettingId: string, dto: TitlesDTO) => AxiosRequestConfig = (chartSettingId, dto) => {
        return {
            url: `/chartContent/${chartSettingId}/titles`,
            method: 'PUT',
            data: dto,
        };
    };

    getChartSettingTitles: (chartSettingId: string) => AxiosRequestConfig = (chartSettingId) => {
        return {
            url: `/chartContent/${chartSettingId}/titles`,
            method: 'GET',
        };
    };

    getTabChartSettings: (tabId: string) => AxiosRequestConfig = (tabId) => {
        return {
            url: `/tab/${tabId}/chartContents`,
            method: 'GET',
        };
    };

    getTabChartSettingData: (chartSettingId: string) => AxiosRequestConfig = (chartSettingId) => {
        return {
            url: `/tab/chartContent/${chartSettingId}/data`,
            method: 'GET',
        };
    };

    deleteChartSetting: (chartSettingId: string) => AxiosRequestConfig = (chartSettingId) => {
        return {
            url: `/chartContent/${chartSettingId}`,
            method: 'DELETE',
        };
    };

    //</editor-fold>
}

export const apiConfigs = new ApiConfigs();
