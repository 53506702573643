import { TableBody } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { useGeneralObjectContext, useStore, useYup } from '../../../../../hooks';
import { GeneralObjectPFAccessModel, PrintFormPermissionSettingsModel } from '../../../../../models';
import {
    GeneralObjectPFRouteParams,
    PrintFormNewPermissionDTO,
    PrintFormPermissionSettingsDTO,
} from '../../../../../types';
import {
    GeneralObjectSystemEntityAccessSettings as GeneralObjectSystemEntityAccessSettingsInj,
    GeneralObjectSystemEntityAccessSettingsPermissionsTableHeader as GeneralObjectSystemEntityAccessSettingsPermissionsTableHeaderInj,
} from '../general-object-system-entity-access-settings';
import { GeneralObjectPFAccessItem as GeneralObjectPFAccessItemInj } from './GeneralObjectPFAccessItem';
import { GeneralObjectPFAccessModalAdditionalFields as GeneralObjectPFAccessModalAdditionalFieldsInj } from './GeneralObjectPFAccessModalAdditionalFields';

export const GeneralObjectPFSettingsAccess = observer((): JSX.Element => {
    const [GeneralObjectPFAccessItem] = di([GeneralObjectPFAccessItemInj], GeneralObjectPFSettingsAccess);
    const [GeneralObjectSystemEntityAccessSettings] = di(
        [GeneralObjectSystemEntityAccessSettingsInj],
        GeneralObjectPFSettingsAccess,
    );
    const [GeneralObjectSystemEntityAccessSettingsPermissionsTableHeader] = di(
        [GeneralObjectSystemEntityAccessSettingsPermissionsTableHeaderInj],
        GeneralObjectPFSettingsAccess,
    );
    const [GeneralObjectPFAccessModalAdditionalFields] = di(
        [GeneralObjectPFAccessModalAdditionalFieldsInj],
        GeneralObjectPFSettingsAccess,
    );

    const { id, tabId, pfId } = useParams<GeneralObjectPFRouteParams>();
    const { objectStore } = useGeneralObjectContext();
    const rootStore = useStore();
    const { Yup } = useYup();

    const loadPermissions = (): Promise<PrintFormPermissionSettingsDTO> => {
        return objectStore.getPFPermissionSettings(tabId, pfId);
    };

    const updatePermissions = (dto: PrintFormNewPermissionDTO[]): Promise<void> => {
        return objectStore.updatePFPermission(tabId, pfId, dto);
    };

    const additionalFieldsValidationSchema = {
        allowEdit: Yup.boolean(),
    };

    const permissionSettingsModel = useMemo<PrintFormPermissionSettingsModel>(
        () => new PrintFormPermissionSettingsModel(id, tabId, rootStore, loadPermissions, updatePermissions),
        [id, tabId, rootStore],
    );

    const accessModel = useMemo(
        () => new GeneralObjectPFAccessModel(rootStore, permissionSettingsModel, additionalFieldsValidationSchema),
        [permissionSettingsModel],
    );

    const renderPermissionTableHeader = (): React.ReactNode => (
        <GeneralObjectSystemEntityAccessSettingsPermissionsTableHeader />
    );

    const renderPermissionTableBody = (
        openPermissionEditModal: (permissionIndex: number) => () => void,
        openPermissionDeleteModal: (permissionIndex: number) => () => void,
    ): (() => React.ReactNode) => {
        return (): React.ReactNode => (
            <TableBody>
                {permissionSettingsModel.permissions.map((permission, index) => {
                    const openEditModal = openPermissionEditModal(index);
                    const openDeleteModal = openPermissionDeleteModal(index);
                    return (
                        <GeneralObjectPFAccessItem
                            permissionData={permission}
                            key={index}
                            openEditModal={openEditModal}
                            openDeleteModal={openDeleteModal}
                        />
                    );
                })}
            </TableBody>
        );
    };

    return (
        <GeneralObjectSystemEntityAccessSettings
            accessModel={accessModel}
            AdditionalFields={GeneralObjectPFAccessModalAdditionalFields}
            renderPermissionTableHeader={renderPermissionTableHeader}
            renderPermissionTableBody={renderPermissionTableBody}
        />
    );
});
