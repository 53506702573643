import { ThemeProvider } from '@mui/material';
import { DraggableItem, MovableTableCell, RenderDraggableListBody } from '@platform/front-core';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, useParams } from 'react-router-dom';
import { useGeneralObjectContext, useGeneralObjectSettingsContext, useStore } from '../../../../../../../../hooks';
import { AppTheme, ChartSettingListDTO, GeneralObjectRouteParams } from '../../../../../../../../types';
import { GeneralObjectDeleteBtn } from '../../../../../general-object-delete';
import { GeneralObjectChartListDeleteIconButton as GeneralObjectChartListDeleteIconButtonInj } from './GeneralObjectChartListDeleteIconButton';

export const renderChartTableBody: RenderDraggableListBody<ChartSettingListDTO> = (props) => {
    const [GeneralObjectChartListDeleteIconButton] = di(
        [GeneralObjectChartListDeleteIconButtonInj],
        renderChartTableBody,
    );

    const { listItems, draggableId } = props;
    const { routes } = useGeneralObjectContext();
    const { setShouldLoadListItems } = useGeneralObjectSettingsContext();
    const params = useParams<GeneralObjectRouteParams>();
    const { tabId } = params;

    const listItemsComponents = listItems.map((chart, index) => {
        const { id, title, sourceType, chartType } = chart;

        const { formatMessage } = useIntl();

        const { objectStore } = useGeneralObjectContext();
        const { appTheme } = useStore();

        const onDeleteClick = useCallback((): Promise<void> => {
            return objectStore.deleteChartSetting(id).then(setShouldLoadListItems);
        }, [id, tabId]);

        const renderDeleteBtn = useCallback(
            (): JSX.Element => (
                <ThemeProvider theme={appTheme as AppTheme}>
                    <GeneralObjectDeleteBtn
                        title={title}
                        object={formatMessage({ id: 'visualization.single.accusative' })}
                        onDeleteClick={onDeleteClick}
                    >
                        <GeneralObjectChartListDeleteIconButton color="secondary" />
                    </GeneralObjectDeleteBtn>
                </ThemeProvider>
            ),
            [title, onDeleteClick],
        );

        return (
            <DraggableItem
                key={id}
                index={index}
                item={chart}
                editPath={generatePath(routes.chart, { ...params, chartSettingId: id })}
                draggableId={draggableId}
                renderDeleteBtn={renderDeleteBtn}
            >
                <MovableTableCell size="small" maxWidth="19.5%">
                    {chartType}
                </MovableTableCell>
                <MovableTableCell size="small" maxWidth="19.5%">
                    {sourceType}
                </MovableTableCell>
            </DraggableItem>
        );
    });

    return <React.Fragment>{listItemsComponents}</React.Fragment>;
};
