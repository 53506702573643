import { Grid, ThemeProvider } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { useStore } from '../../../../../../../../hooks';
import { AppTheme } from '../../../../../../../../types';
import { GeneralObjectChartSettingsPageChart as GeneralObjectChartSettingsPageChartInj } from './GeneralObjectChartSettingsPageChart';
import { GeneralObjectChartSettingsPageForm as GeneralObjectChartSettingsPageFormInj } from './GeneralObjectChartSettingsPageForm';

export const GeneralObjectChartSettingsPageContent = observer((): JSX.Element => {
    const [GeneralObjectChartSettingsPageForm] = di(
        [GeneralObjectChartSettingsPageFormInj],
        GeneralObjectChartSettingsPageContent,
    );
    const [GeneralObjectChartSettingsPageChart] = di(
        [GeneralObjectChartSettingsPageChartInj],
        GeneralObjectChartSettingsPageContent,
    );

    const { appTheme } = useStore();

    return (
        <ThemeProvider theme={appTheme as AppTheme}>
            <Grid container wrap="nowrap" spacing={2}>
                <Grid item width="40%">
                    <GeneralObjectChartSettingsPageForm />
                </Grid>
                <Grid item width="60%" flexGrow={1}>
                    <GeneralObjectChartSettingsPageChart />
                </Grid>
            </Grid>
        </ThemeProvider>
    );
});
