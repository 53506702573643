import { Portal } from '@mui/base';
import { AlertProps } from '@mui/lab';
import { Box, BoxProps, Snackbar, SnackbarProps } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { di } from 'react-magnetic-di';
import { NotificationListModel } from '../../models';
import { Notification as NotificationInj } from './Notification';

export type NotificationsComponentProps = {
    model: NotificationListModel;
    snackbarProps?: SnackbarProps;
    snackbarBoxProps?: BoxProps;
    alertProps?: AlertProps;
    alertBoxProps?: BoxProps;
};

export const defaultSnackbarProps: SnackbarProps = {
    anchorOrigin: { vertical: 'top', horizontal: 'right' },
    style: {
        top: '8px',
        right: '16px',
    },
};

export const defaultSnackbarBoxProps: BoxProps = {
    style: {
        maxHeight: 'calc(100vh - 16px)',
        overflowY: 'auto',
    },
};

export const Notifications = observer((props: NotificationsComponentProps): JSX.Element => {
    const [Notification] = di([NotificationInj], Notifications);

    const { model, snackbarProps = {}, snackbarBoxProps = {}, alertBoxProps = {}, alertProps = {} } = props;
    const { notifications } = model;

    const snackbarStyle = snackbarProps.style;
    const snackbarBoxStyle = snackbarBoxProps.style;

    const mergedSnackbarStyle = { ...defaultSnackbarProps.style, ...snackbarStyle };
    const mergedSnackbarBoxStyle = { ...defaultSnackbarBoxProps.style, ...snackbarBoxStyle };

    return (
        <Portal>
            <Snackbar
                className="t-notifications"
                open={!!notifications.length}
                anchorOrigin={defaultSnackbarProps.anchorOrigin}
                {...snackbarProps}
                style={mergedSnackbarStyle}
            >
                <Box {...snackbarBoxProps} style={mergedSnackbarBoxStyle}>
                    {notifications.map((notificationModel) => {
                        const { id } = notificationModel;

                        const closeNotification = (): void => {
                            model.deleteNotification(id);
                        };

                        return (
                            <Notification
                                key={id}
                                model={notificationModel}
                                closeNotification={closeNotification}
                                alertProps={alertProps}
                                alertBoxProps={alertBoxProps}
                            />
                        );
                    })}
                </Box>
            </Snackbar>
        </Portal>
    );
});
