import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { Form, Formik } from 'formik';
import { TextFieldProps } from 'formik-mui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAntiDoubleClick, useLocale } from '../../../../../hooks';
import { ServerErrorsModel } from '../../../../../models';
import { GeneralObjectSystemEntityAccessModel } from '../../../../../models';
import { CodeTitleGroupRC } from '../../../../../stores';
import {
    CommonSystemEntityPermissionSettingsFields,
    ModalProps,
    SystemEntityPermissionFormValues,
    SystemEntityPermissionSettingsModelType,
} from '../../../../../types';
import { disableSubmitOnEnterKeyPress } from '../../../../../utils';
import { AutocompleteField } from '../../../../fields';

export type GeneralObjectSystemEntityAccessSettingsModalProps<
    PermissionSettingsModel extends SystemEntityPermissionSettingsModelType,
    FormValues extends SystemEntityPermissionFormValues,
> = ModalProps & {
    AdditionalFields?: React.ElementType;
    accessModel: GeneralObjectSystemEntityAccessModel<PermissionSettingsModel, FormValues>;
};

const roleConditionWrapperStyles: SxProps<Theme> = { paddingBottom: '10px' };

export const GeneralObjectSystemEntityAccessSettingsModal = observer(
    <
        PermissionSettingsModel extends SystemEntityPermissionSettingsModelType,
        FormValues extends SystemEntityPermissionFormValues,
    >(
        props: GeneralObjectSystemEntityAccessSettingsModalProps<PermissionSettingsModel, FormValues>,
    ): JSX.Element => {
        const { isOpen, setIsClosed, accessModel, AdditionalFields } = props;
        const { permissionSettingsModel, selectedPermissionIndex, validationSchema, initialValues, onSubmit } =
            accessModel;
        const { roleConditionList, lifecyclesList, serverErrors } = permissionSettingsModel;

        const handleSubmit = (values: FormValues): Promise<void> => {
            return onSubmit(values).then(setIsClosed);
        };

        const [isSending, endIcon, createHandler] = useAntiDoubleClick(handleSubmit);

        const [allMessage, allId] = useLocale('common.all');
        const intl = useIntl();

        const roleConditionGroupBy = (option: CodeTitleGroupRC): string => {
            const [message] = useLocale(`objectSettings.permissionTrigger.${option.group}`);
            return message;
        };

        const { serverFormErrors } = serverErrors as ServerErrorsModel<CommonSystemEntityPermissionSettingsFields>;

        const { roleCondition, whenObjectStateIsOneOf } = CommonSystemEntityPermissionSettingsFields;

        return (
            <Dialog fullWidth={true} maxWidth="sm" open={isOpen} scroll="body">
                <DialogTitle>
                    {selectedPermissionIndex === null ? (
                        <FormattedMessage id="objectSettings.addPermissionModalTitle" />
                    ) : (
                        <FormattedMessage id="objectSettings.editPermissionModalTitle" />
                    )}
                </DialogTitle>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={createHandler}>
                    {({ values }): JSX.Element => {
                        const lifecyclesAutocompleteFieldTextFieldParams: Partial<TextFieldProps> = {
                            placeholder: values.whenObjectStateIsOneOf.length ? '' : allMessage,
                            InputLabelProps: {
                                shrink: true,
                            },
                        };

                        return (
                            <Form onKeyDown={disableSubmitOnEnterKeyPress}>
                                <DialogContent>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item sx={roleConditionWrapperStyles}>
                                            <AutocompleteField
                                                fieldName={roleCondition}
                                                disableClearable={true}
                                                label={intl.formatMessage({
                                                    id: 'objectSettings.accessFields.roleCondition',
                                                })}
                                                required={true}
                                                groupBy={roleConditionGroupBy}
                                                options={roleConditionList}
                                                serverError={serverFormErrors?.[roleCondition]}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <AutocompleteField
                                                fieldName={whenObjectStateIsOneOf}
                                                label={intl.formatMessage({
                                                    id: 'objectSettings.accessFields.lifecycle',
                                                })}
                                                options={lifecyclesList}
                                                multiple={true}
                                                textFieldParams={lifecyclesAutocompleteFieldTextFieldParams}
                                                id={allId}
                                                serverError={serverFormErrors?.[whenObjectStateIsOneOf]}
                                            />
                                        </Grid>
                                        {AdditionalFields && <AdditionalFields />}
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={setIsClosed} variant="text">
                                        <FormattedMessage id="common.cancel" />
                                    </Button>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        disabled={isSending}
                                        endIcon={endIcon}
                                        type="submit"
                                    >
                                        <FormattedMessage id="common.save" />
                                    </Button>
                                </DialogActions>
                            </Form>
                        );
                    }}
                </Formik>
            </Dialog>
        );
    },
);
