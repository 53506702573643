import { TabList } from '@mui/lab';
import { styled } from '@mui/material/styles';

export const StyledTabList = styled(TabList)(
    ({ theme }) => `
    min-height: auto;
    
    & .MuiTabs-indicator {
        display: none;
    }
`,
);
