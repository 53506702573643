import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { GeneralObjectEditResponsible as GeneralObjectEditResponsibleInj } from '../../../../components';
import { useStore } from '../../../../hooks';
import { EditModalProps } from '../../../../types';

export const EditProjectMonitor = observer((props: EditModalProps) => {
    const [GeneralObjectEditResponsible] = di([GeneralObjectEditResponsibleInj], EditProjectMonitor);

    const { projectStore } = useStore();
    const intl = useIntl();
    const title = intl.formatMessage({ id: 'monitor.single.nominative' });

    return <GeneralObjectEditResponsible {...props} editResponsible={projectStore.editProjectMonitor} title={title} />;
});
