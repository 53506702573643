import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import {
    FieldValues as LangTitle,
    getRuTitleInitialValue,
    MultiLangField,
    titlesToTitlesDTO,
    titlesYupSchema,
} from '@platform/multi-lang-field';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, useHistory } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import {
    AutocompleteField as AutocompleteFieldInj,
    RequiredLabel as RequiredLabelInj,
    ServerErrorHelper as ServerErrorHelperInj,
} from '../../../components';
import { useStore, useYup } from '../../../hooks';
import { ServerErrorsModel } from '../../../models';
import { categoryMessages } from '../../../resources';
import { CategoryCreateDTO, CodeTitle, CodeTitleNull, CreateCategoryFields, ModalProps } from '../../../types';
import { disableSubmitOnEnterKeyPress } from '../../../utils';

export type CategoryCreateDialogValues = {
    titles: LangTitle[];
    form: CodeTitleNull;
    process: CodeTitleNull;
};

const initialValues: CategoryCreateDialogValues = {
    titles: getRuTitleInitialValue(),
    form: null,
    process: null,
};

export const CategoryCreateDialog = observer((props: ModalProps): JSX.Element => {
    const [RequiredLabel] = di([RequiredLabelInj], CategoryCreateDialog);
    const [AutocompleteField] = di([AutocompleteFieldInj], CategoryCreateDialog);
    const [ServerErrorHelper] = di([ServerErrorHelperInj], CategoryCreateDialog);

    const { isOpen, setIsClosed } = props;
    const { categoryStore } = useStore();
    const intl = useIntl();

    const [formOptions, setFormOptions] = useState<CodeTitle[]>([]);
    const [processOptions, setProcessOptions] = useState<CodeTitle[]>([]);
    const serverErrors = useMemo(() => new ServerErrorsModel<CreateCategoryFields>(), []);
    const { dropServerFormErrors, setServerFormErrors, serverFormErrors } = serverErrors;
    const { titles, form, process } = CreateCategoryFields;
    const serverTitlesError = serverFormErrors[titles];

    const onClose = (): void => {
        setIsClosed();
        dropServerFormErrors();
    };

    useEffect(() => {
        categoryStore.getObjectCreateDTO().then((info) => {
            setFormOptions(info.forms);
            setProcessOptions(info.processes);
        });
    }, []);

    const { Yup } = useYup();
    const validationSchema = Yup.object({
        titles: titlesYupSchema(Yup, true).min(1),
        form: Yup.object().nullable().required(),
        process: Yup.object().nullable().required(),
    });

    const history = useHistory();

    const onSubmit = (values: CategoryCreateDialogValues): void => {
        dropServerFormErrors();

        const titlesDTO = titlesToTitlesDTO(values.titles);
        const dto: CategoryCreateDTO = {
            titles: titlesDTO,
            formCode: values.form?.code,
            processCode: values.process?.code,
        };
        categoryStore
            .createObject(dto)
            .then((id: string): void => {
                history.push(
                    generatePath(clientRoute.category.formEdit, {
                        id,
                    }),
                );
            })
            .catch(setServerFormErrors);
    };

    const formLabel = <RequiredLabel text={intl.formatMessage({ id: 'category.createFields.form' })} />;
    const processLabel = <RequiredLabel text={intl.formatMessage({ id: 'category.createFields.process' })} />;

    return (
        <Dialog fullWidth={true} maxWidth="sm" open={isOpen}>
            <DialogTitle>
                <FormattedMessage {...categoryMessages.create} />
            </DialogTitle>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                <Form onKeyDown={disableSubmitOnEnterKeyPress}>
                    <DialogContent>
                        <MultiLangField systemLanguage={intl.locale} fieldName={titles} ruEngLangs={true} />
                        <ServerErrorHelper serverError={serverTitlesError} />
                        <AutocompleteField
                            fieldName={form}
                            label={formLabel}
                            options={formOptions}
                            serverError={serverFormErrors[form]}
                        />
                        <AutocompleteField
                            fieldName={process}
                            label={processLabel}
                            options={processOptions}
                            serverError={serverFormErrors[process]}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={onClose}>
                            <FormattedMessage id="common.cancel" />
                        </Button>
                        <Button color="primary" variant="contained" type="submit">
                            <FormattedMessage id="common.save" />
                        </Button>
                    </DialogActions>
                </Form>
            </Formik>
        </Dialog>
    );
});
