import { action, makeObservable, observable } from 'mobx';
import { GeneralObjectStore, PrintFormItemDTO } from '../../types';
import { reorderList } from '../../utils';

export const PrintFromSettingsListModelProps = {
    objectStore: observable,
    tabId: observable,
    objectId: observable,
    selectedDeleteId: observable,
    printForms: observable,
    load: action.bound,
    changePFPosition: action.bound,
    reorderPFList: action.bound,
    deletePF: action.bound,
    setPrintForms: action.bound,
    setSelectedDeleteId: action.bound,
};

export class PrintFromSettingsListModel {
    private objectStore: GeneralObjectStore;

    tabId = '';
    objectId = '';
    selectedDeleteId = '';
    printForms: PrintFormItemDTO[] = [];

    constructor(objectId: string, tabId: string, objectStore: GeneralObjectStore) {
        makeObservable(this, PrintFromSettingsListModelProps);
        this.tabId = tabId;
        this.objectId = objectId;
        this.objectStore = objectStore;
    }

    load(): void {
        this.objectStore.getPrintFormsList(this.objectId, this.tabId).then(this.setPrintForms);
    }

    changePFPosition(id: string, newPosition: number): void {
        this.objectStore.changePFPosition(this.tabId, id, newPosition);
    }

    reorderPFList(sourceIndex: number, destinationIndex: number): void {
        const reordered = reorderList<PrintFormItemDTO>(this.printForms, sourceIndex, destinationIndex);
        this.setPrintForms(reordered);
    }

    deletePF(): Promise<void> {
        return this.objectStore.deletePrintForm(this.tabId, this.selectedDeleteId).then(this.load);
    }

    setPrintForms(printForms: PrintFormItemDTO[]): void {
        this.printForms = printForms;
    }

    setSelectedDeleteId(selectedDeleteId: string): void {
        this.selectedDeleteId = selectedDeleteId;
    }
}
