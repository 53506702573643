import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { getRuTitleInitialValue, MultiLangField, titlesToTitlesDTO, titlesYupSchema } from '@platform/multi-lang-field';
import { FieldValues as LangTitle } from '@platform/multi-lang-field/dist/MultiLangField/types';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, useHistory } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import {
    AutocompleteField as AutocompleteFieldInj,
    IdentifierField as IdentifierFieldInj,
    RequiredLabel as RequiredLabelInj,
    ServerErrorHelper as ServerErrorHelperInj,
} from '../../../components';
import { getEmptyIdentifier } from '../../../constants';
import { useAntiDoubleClick, useStore, useYup } from '../../../hooks';
import { ServerErrorsModel } from '../../../models';
import { portfolioMessages } from '../../../resources';
import {
    CodeTitle,
    CodeTitleNull,
    CreatePortfolioFields,
    Identifier,
    ModalProps,
    PortfolioCreateDTO,
} from '../../../types';
import { disableSubmitOnEnterKeyPress } from '../../../utils';

export type PortfolioCreateDialogValues = {
    titles: LangTitle[];
    number: string;
    process: CodeTitleNull;
};

export const PortfolioRegistryCreateDialog = observer((props: ModalProps): JSX.Element => {
    const [RequiredLabel] = di([RequiredLabelInj], PortfolioRegistryCreateDialog);
    const [IdentifierField] = di([IdentifierFieldInj], PortfolioRegistryCreateDialog);
    const [AutocompleteField] = di([AutocompleteFieldInj], PortfolioRegistryCreateDialog);
    const [ServerErrorHelper] = di([ServerErrorHelperInj], PortfolioRegistryCreateDialog);

    const { isOpen, setIsClosed } = props;
    const { portfolioStore } = useStore();
    const intl = useIntl();

    const [identifier, setIdentifier] = useState<Identifier>(getEmptyIdentifier());
    const [processOptions, setProcessOptions] = useState<CodeTitle[]>([]);
    const serverErrors = useMemo(() => new ServerErrorsModel<CreatePortfolioFields>(), []);
    const { dropServerFormErrors, setServerFormErrors, serverFormErrors } = serverErrors;
    const { titles, number, process } = CreatePortfolioFields;
    const serverTitlesError = serverFormErrors[titles];

    const initialValues: PortfolioCreateDialogValues = {
        titles: getRuTitleInitialValue(),
        number: identifier.number,
        process: null,
    };

    const onClose = (): void => {
        setIsClosed();
        dropServerFormErrors();
    };

    useEffect(() => {
        portfolioStore.getObjectCreateDTO().then((info) => {
            setProcessOptions(info.processes);
            setIdentifier(info.identifier);
        });
    }, [setProcessOptions, portfolioStore, setIdentifier]);

    const { Yup } = useYup();

    const validationSchema = Yup.object({
        titles: titlesYupSchema(Yup, true).min(1),
        number: Yup.string().required(),
        process: Yup.object().nullable().required(),
    });

    const history = useHistory();

    const onSubmit = (values: PortfolioCreateDialogValues): Promise<void> => {
        dropServerFormErrors();

        const titlesDTO = titlesToTitlesDTO(values.titles);
        const dto: PortfolioCreateDTO = {
            titles: titlesDTO,
            identifier: {
                prefix: identifier.prefix,
                number: values.number,
            },
            processCode: values.process?.code as string,
        };

        return portfolioStore
            .createObject(dto)
            .then((id) => {
                history.push(
                    generatePath(clientRoute.portfolio.card, {
                        id,
                    }),
                );
            })
            .catch(setServerFormErrors);
    };

    const [isSending, endIcon, createHandler] = useAntiDoubleClick(onSubmit);

    const processLabel = <RequiredLabel text={intl.formatMessage({ id: 'portfolio.createFields.process' })} />;

    return (
        <Dialog fullWidth={true} maxWidth="sm" open={isOpen}>
            <DialogTitle>
                <FormattedMessage {...portfolioMessages.create} />
            </DialogTitle>
            <Formik initialValues={initialValues} onSubmit={createHandler} validationSchema={validationSchema}>
                <Form onKeyDown={disableSubmitOnEnterKeyPress}>
                    <DialogContent>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <MultiLangField systemLanguage={intl.locale} fieldName={titles} ruEngLangs={true} />
                                <ServerErrorHelper serverError={serverTitlesError} />
                            </Grid>
                            <Grid item>
                                <IdentifierField
                                    name={number}
                                    prefix={identifier.prefix}
                                    serverError={serverFormErrors[number]}
                                />
                            </Grid>
                            <Grid item>
                                <AutocompleteField
                                    fieldName={process}
                                    label={processLabel}
                                    options={processOptions}
                                    serverError={serverFormErrors[process]}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={onClose}>
                            <FormattedMessage id="common.cancel" />
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={isSending}
                            endIcon={endIcon}
                        >
                            <FormattedMessage id="common.save" />
                        </Button>
                    </DialogActions>
                </Form>
            </Formik>
        </Dialog>
    );
});
