import { Grid } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { Field, useFormikContext } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TabAccessPermissionValues, TabSettingsPermissionsFields } from '../../../../../types';

const {
    filesDownload,
    edit: editField,
    editForm: editFormField,
    filesManagement: filesManagementField,
    filesUpload: filesUploadField,
    filesEdit: filesEditField,
} = TabSettingsPermissionsFields;

const checkboxLabels: Partial<Record<TabSettingsPermissionsFields, { label: JSX.Element }>> = {
    [filesDownload]: {
        label: <FormattedMessage id="objectSettings.accessFields.filesDownload" />,
    },
    [editField]: {
        label: <FormattedMessage id="objectSettings.accessFields.edit" />,
    },
    [editFormField]: {
        label: <FormattedMessage id="objectSettings.accessFields.editForm" />,
    },
    [filesManagementField]: {
        label: <FormattedMessage id="objectSettings.accessFields.filesManagement" />,
    },
    [filesUploadField]: {
        label: <FormattedMessage id="objectSettings.accessFields.filesUpload" />,
    },
    [filesEditField]: {
        label: <FormattedMessage id="objectSettings.accessFields.filesEdit" />,
    },
};

const editChildrenGridItemSx: SxProps<Theme> = { ml: 4 };
const filesManagementChildrenGridItemSx: SxProps<Theme> = { ml: 8 };

export const GeneralObjectTabAccessModalAdditionalFields = observer((): JSX.Element => {
    const formikContext = useFormikContext<TabAccessPermissionValues>();
    const { values, setFieldValue } = formikContext;
    const { edit, editForm, filesUpload, filesEdit, filesManagement } = values.permissions;

    const atLeastOneOfFilesManagementChildrenFieldsChecked = filesUpload || filesEdit;
    const allFilesManagementChildrenFieldsChecked = filesUpload && filesEdit;

    const isEditFieldIndeterminate = editForm && !allFilesManagementChildrenFieldsChecked;
    const isFilesManagementFieldIndeterminate =
        !filesManagement &&
        atLeastOneOfFilesManagementChildrenFieldsChecked &&
        !allFilesManagementChildrenFieldsChecked;

    const isEditChildrenFieldsDisabled = !edit;

    if (allFilesManagementChildrenFieldsChecked && !filesManagement) {
        setFieldValue(filesManagementField, true);
    }

    if (!allFilesManagementChildrenFieldsChecked && filesManagement) {
        setFieldValue(filesManagementField, false);
    }

    const onEditFieldChange = (event: React.ChangeEvent<HTMLInputElement>, value: boolean): void => {
        setFieldValue(editField, value);
        editForm !== value && setFieldValue(editFormField, value);
        filesUpload !== value && setFieldValue(filesUploadField, value);
        filesEdit !== value && setFieldValue(filesEditField, value);
    };

    const onFilesManagementFieldChange = (event: React.ChangeEvent<HTMLInputElement>, value: boolean): void => {
        setFieldValue(filesManagementField, value);
        filesUpload !== value && setFieldValue(filesUploadField, value);
        filesEdit !== value && setFieldValue(filesEditField, value);
    };
    return (
        <Grid item container flexDirection="column">
            <Grid item>
                <Field
                    name={filesDownload}
                    disabled={false}
                    type="checkbox"
                    component={CheckboxWithLabel}
                    Label={checkboxLabels[filesDownload]}
                />
            </Grid>
            <Grid item>
                <Field
                    name={editField}
                    disabled={false}
                    indeterminate={isEditFieldIndeterminate}
                    onChange={onEditFieldChange}
                    type="checkbox"
                    component={CheckboxWithLabel}
                    Label={checkboxLabels[editField]}
                />
            </Grid>
            <Grid item sx={editChildrenGridItemSx}>
                <Field
                    name={editFormField}
                    disabled={true}
                    type="checkbox"
                    component={CheckboxWithLabel}
                    Label={checkboxLabels[editFormField]}
                />
                <Field
                    name={filesManagementField}
                    indeterminate={isFilesManagementFieldIndeterminate}
                    onChange={onFilesManagementFieldChange}
                    disabled={isEditChildrenFieldsDisabled}
                    type="checkbox"
                    component={CheckboxWithLabel}
                    Label={checkboxLabels[filesManagementField]}
                />
            </Grid>
            <Grid item sx={filesManagementChildrenGridItemSx}>
                <Field
                    name={filesUploadField}
                    disabled={isEditChildrenFieldsDisabled}
                    type="checkbox"
                    component={CheckboxWithLabel}
                    Label={checkboxLabels[filesUploadField]}
                />
                <Field
                    name={filesEditField}
                    disabled={isEditChildrenFieldsDisabled}
                    type="checkbox"
                    component={CheckboxWithLabel}
                    Label={checkboxLabels[filesEditField]}
                />
            </Grid>
        </Grid>
    );
});
