import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Link, Portal } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, Link as RouterLink, useParams } from 'react-router-dom';
import { useGeneralObjectCardContext, useGeneralObjectContext } from '../../../../../hooks';
import { GeneralObjectRouteParams, ModalProps } from '../../../../../types';
import { GeneralObjectTransitionErrorDialogItem as GeneralObjectTransitionErrorDialogItemInj } from './GeneralObjectTransitionErrorDialogItem';

export type GeneralObjectTransitionErrorDialogProps = ModalProps & {
    id: string;
    keepMounted: boolean;
    title?: ReactNode;
};

export const GeneralObjectTransitionErrorDialog = observer(
    (props: GeneralObjectTransitionErrorDialogProps): JSX.Element => {
        const [GeneralObjectTransitionErrorDialogItem] = di(
            [GeneralObjectTransitionErrorDialogItemInj],
            GeneralObjectTransitionErrorDialog,
        );

        const { isOpen, setIsClosed, title, ...rest } = props;
        const { objectModel } = useGeneralObjectCardContext();
        const { objectContentErrorsModel, tabs } = objectModel;
        const { errorsByTabs } = objectContentErrorsModel;
        const { objectStore, routes } = useGeneralObjectContext();
        const { id } = useParams<GeneralObjectRouteParams>();
        const { isWithTabs } = objectStore;
        const pathToEditForm = isWithTabs ? '' : generatePath(routes.formEdit, { id });

        const renderErrorItem = (tabId: string): JSX.Element => {
            const errorMessages = errorsByTabs[tabId].getMessages();
            const tabWithCorrespondingId = tabs.find((tab) => tab.id === tabId);
            const tabTitle = tabWithCorrespondingId ? tabWithCorrespondingId.title : '';

            return (
                <React.Fragment>
                    {!!errorMessages.length && (
                        <Grid item>
                            <GeneralObjectTransitionErrorDialogItem
                                key={tabId}
                                tabTitle={tabTitle}
                                errorMessages={errorMessages}
                            />
                        </Grid>
                    )}
                </React.Fragment>
            );
        };

        return (
            <Portal>
                <Dialog fullWidth={true} open={isOpen} maxWidth="xs" {...rest}>
                    <DialogTitle>{title || <FormattedMessage id="common.error" />}</DialogTitle>
                    <DialogContent>
                        <Grid container direction="column" spacing={4}>
                            {Object.keys(errorsByTabs).map(renderErrorItem)}

                            {!isWithTabs && (
                                <Grid item>
                                    <Link component={RouterLink} to={pathToEditForm}>
                                        <FormattedMessage id="common.correct" />
                                    </Link>
                                </Grid>
                            )}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus color="primary" onClick={setIsClosed}>
                            <FormattedMessage id="common.close" />
                        </Button>
                    </DialogActions>
                </Dialog>
            </Portal>
        );
    },
);
