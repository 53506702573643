import { FormControl } from '../../../types';

export default {
    borderRadius: 4,
    padding: '6px 18px',
    minHeight: 48,
    fontWeight: 500,
    fontSize: 15,
    lineHeight: 22,
    border: (color: string, width: number) => `0px 0px 0px ${width}px ${color}`,
} as FormControl;
