import React from 'react';
import { di } from 'react-magnetic-di';
import { GeneralObjectField } from '../../../../types';
import { GeneralObjectDescriptionFormattedFieldValue as GeneralObjectDescriptionFormattedFieldValueInj } from './GeneralObjectDescriptionFormattedFieldValue';

export type GeneralObjectDescriptionFormattedFieldProps = {
    field: GeneralObjectField;
};

export const GeneralObjectDescriptionFormattedField = (
    props: GeneralObjectDescriptionFormattedFieldProps,
): JSX.Element => {
    const [GeneralObjectDescriptionFormattedFieldValue] = di(
        [GeneralObjectDescriptionFormattedFieldValueInj],
        GeneralObjectDescriptionFormattedField,
    );

    const { field } = props;
    const {
        value,
        isDate,
        isExternalUrl,
        rawValue,
        shouldSeparateArrayValuesWithComma = true,
        withContactInformation = false,
    } = field;
    if (Array.isArray(value)) {
        const formattedValueArray = value.map((fieldValue, index) => (
            <GeneralObjectDescriptionFormattedFieldValue
                key={index}
                value={fieldValue}
                rawValue={rawValue}
                isDate={isDate}
                isExternalUrl={isExternalUrl}
                shouldSeparateArrayValuesWithComma={shouldSeparateArrayValuesWithComma}
                withContactInformation={withContactInformation}
                valueIndex={index}
            />
        ));
        const valuesLastIndex = formattedValueArray.length - 1;
        return (
            <React.Fragment>
                {formattedValueArray.map((formattedV, index) => {
                    const shouldRenderComma =
                        index !== valuesLastIndex && shouldSeparateArrayValuesWithComma && !withContactInformation;
                    return (
                        <React.Fragment key={index}>
                            {formattedV}
                            {shouldRenderComma && ', '}
                        </React.Fragment>
                    );
                })}
            </React.Fragment>
        );
    } else {
        return (
            <GeneralObjectDescriptionFormattedFieldValue
                value={value}
                rawValue={rawValue}
                isDate={isDate}
                isExternalUrl={isExternalUrl}
                shouldSeparateArrayValuesWithComma={shouldSeparateArrayValuesWithComma}
                withContactInformation={withContactInformation}
            />
        );
    }
};
