import { observer } from 'mobx-react';
import React, { cloneElement, PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import {
    ConfirmationDialog as ConfirmationDialogInj,
    DeleteActionMenuItem as DeleteActionMenuItemInj,
} from '../../../../components';
import { useFlag } from '../../../../hooks';

export type GeneralObjectDeleteBtnProps = {
    title: string;
    object: string;
    onDeleteClick: () => Promise<void>;
};

export const GeneralObjectDeleteBtn = observer((props: PropsWithChildren<GeneralObjectDeleteBtnProps>): JSX.Element => {
    const [DeleteActionMenuItem] = di([DeleteActionMenuItemInj], GeneralObjectDeleteBtn);
    const [ConfirmationDialog] = di([ConfirmationDialogInj], GeneralObjectDeleteBtn);

    const { title, object, onDeleteClick, children = <DeleteActionMenuItem /> } = props;
    const intl = useIntl();

    const [isConfirmationDialogOpen, setConfirmationDialogOpen, setConfirmationDialogClosed] = useFlag();

    const clone = cloneElement(children as JSX.Element, {
        onClick: setConfirmationDialogOpen,
    });

    const onDeleteConfirm = (): Promise<void> => {
        return onDeleteClick().finally(setConfirmationDialogClosed);
    };

    return (
        <React.Fragment>
            {clone}
            <ConfirmationDialog
                id="delete"
                keepMounted
                isOpen={isConfirmationDialogOpen}
                onConfirm={onDeleteConfirm}
                setIsClosed={setConfirmationDialogClosed}
                title={intl.formatMessage({ id: 'common.confirmDeletion' })}
                message={intl.formatMessage({ id: 'generalObject.confirmDeletionInfoText' }, { object, title })}
            />
        </React.Fragment>
    );
});
