import { action, computed, makeObservable, observable } from 'mobx';
import { GeneralObjectStore } from '../../types';
import { PrintFormModel } from './PrintFormModel';

export const PrintFormListModelProps = {
    objectStore: observable,
    printForms: observable,
    tabId: observable,
    updatePfListIntervalId: observable,
    isSomePrintFormGenerating: computed,
    load: action.bound,
    clearUpdatePfListInterval: action.bound,
    updateIfSomePrintFormIsGenerating: action.bound,
    setPrintForms: action.bound,
    setUpdatePfListIntervalId: action.bound,
    setTabId: action.bound,
};

export class PrintFormListModel {
    private objectStore: GeneralObjectStore;

    printForms: PrintFormModel[] = [];
    tabId = '';
    updatePfListIntervalId?: number;

    constructor(objectStore: GeneralObjectStore) {
        makeObservable(this, PrintFormListModelProps);
        this.objectStore = objectStore;
    }

    get isSomePrintFormGenerating(): boolean {
        return this.printForms.some((printFormModel: PrintFormModel) => {
            return printFormModel.isGenerating;
        });
    }

    async load(): Promise<void> {
        const printFormsDto = await this.objectStore.loadCurrentTabPrintForms(this.tabId);
        const printForms = printFormsDto.map((dto) => new PrintFormModel(dto, this.tabId, this.objectStore));
        this.setPrintForms(printForms);
    }

    clearUpdatePfListInterval() {
        if (this.updatePfListIntervalId) {
            clearInterval(this.updatePfListIntervalId);
            this.setUpdatePfListIntervalId(undefined);
        }
    }

    updateIfSomePrintFormIsGenerating(pfDelay: number): void {
        this.clearUpdatePfListInterval();
        if (this.isSomePrintFormGenerating) {
            const updatePfListInterval = setInterval(this.load, pfDelay);
            this.setUpdatePfListIntervalId(updatePfListInterval as unknown as number);
        }
    }

    setPrintForms(printForms: PrintFormModel[]) {
        this.printForms = printForms;
    }

    setUpdatePfListIntervalId(id?: number) {
        this.updatePfListIntervalId = id;
    }

    setTabId(tabId: string) {
        this.tabId = tabId;
    }
}
