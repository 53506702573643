import { Box } from '@mui/material';
import { makeSxStyles } from '@platform/front-ui';
import React, { ReactNode } from 'react';
import { di } from 'react-magnetic-di';
import { redesignTotPalette } from '../../../../../theme';
import { CommonTypography as CommonTypographyInj } from '../../../../typography';

export type GeneralObjectUserContactInformationInfoLineProps = {
    title: ReactNode;
    value?: ReactNode;
};

export const generalObjectUserContactInformationInfoLineSxStyles = makeSxStyles({
    title: {
        color: redesignTotPalette.typography.default.lighterColor,
        wordBreak: 'keep-all',
    },
    value: {
        wordWrap: 'break-word',
        color: redesignTotPalette.typography.default.dark,
    },
});

export const GeneralObjectUserContactInformationInfoLine = (
    props: GeneralObjectUserContactInformationInfoLineProps,
): JSX.Element => {
    const [CommonTypography] = di([CommonTypographyInj], GeneralObjectUserContactInformationInfoLine);
    const { title, value = '-' } = props;

    return (
        <React.Fragment>
            <Box>
                <CommonTypography sx={generalObjectUserContactInformationInfoLineSxStyles.title}>
                    {title}
                </CommonTypography>
            </Box>
            <Box>
                <CommonTypography component="span">{value}</CommonTypography>
            </Box>
        </React.Fragment>
    );
};
