import { AccessTime, Block, CheckCircleOutline } from '@mui/icons-material';
import { Box, Container, Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { Route, Switch } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { TotBackground as TotBackgroundInj } from '../../components';

export const AuthenticationInfoPage = (): JSX.Element => {
    const [TotBackground] = di([TotBackgroundInj], AuthenticationInfoPage);

    return (
        <TotBackground withBackdrop={true}>
            <Grid container item direction="column" alignItems="center" justifyContent="center">
                <Container className="t-authentication-info-page" maxWidth="xs">
                    <Paper elevation={24}>
                        <Box p={12} textAlign="center">
                            <Switch>
                                <Route path={clientRoute.registrationInfoSuccess}>
                                    <CheckCircleOutline fontSize="large" color="action" />
                                    <Typography>
                                        <FormattedMessage id="authentication.confirmingEmailSent" />
                                    </Typography>
                                </Route>
                                <Route path={clientRoute.registrationInfoExpired}>
                                    <AccessTime fontSize="large" color="action" />
                                    <Typography>
                                        <FormattedMessage id="authentication.confirmingEmailExpired" />
                                    </Typography>
                                </Route>
                                <Route path={clientRoute.registrationInfoNotFound}>
                                    <Block fontSize="large" color="action" />
                                    <Typography>
                                        <FormattedMessage id="authentication.confirmingEmailNotFound" />
                                    </Typography>
                                </Route>
                                <Route path={clientRoute.recoveryPasswordInfoSuccess}>
                                    <CheckCircleOutline fontSize="large" color="action" />
                                    <Typography>
                                        <FormattedMessage id="authentication.recoveryLinkSent" />
                                    </Typography>
                                </Route>
                            </Switch>
                        </Box>
                    </Paper>
                </Container>
            </Grid>
        </TotBackground>
    );
};
