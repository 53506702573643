import { GeneralObject } from './generalObject';

export enum AdditionalRegistryCode {
    tab = 'tab',
    account = 'account',
    logging = 'logging/entity',
    version = 'version',
}

export const RegistryCode = { ...GeneralObject, ...AdditionalRegistryCode };
export type RegistryCode = keyof typeof RegistryCode;
