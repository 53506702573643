import { Grid, Typography } from '@mui/material';
import { sxHeight100 } from '@platform/front-core';
import React from 'react';
import { useIntl } from 'react-intl';

export type GeneralObjectTabSettingsVisualizationSettingsPageEmptyChart = {
    title?: string;
};

export const GeneralObjectChartSettingsPageEmptyChart = (
    props: GeneralObjectTabSettingsVisualizationSettingsPageEmptyChart,
): JSX.Element => {
    const { formatMessage } = useIntl();
    const defaultTitle = formatMessage({ id: 'objectSettings.visualizationIsNotSet' });
    const { title = defaultTitle } = props;
    return (
        <Grid container sx={sxHeight100} justifyContent="center" alignContent="center">
            <Grid item>
                <Typography variant="h5">{title}</Typography>
            </Grid>
        </Grid>
    );
};
