import { action, computed, makeObservable, observable } from 'mobx';
import { IntlShape } from 'react-intl';
import { apiConfigs } from '../apiConfigs';
import { ApiStore, IntlStore } from '../stores';
import { EmailDTO, RecoveryPasswordPageFields, Status } from '../types';
import { ServerErrorsModel } from './ServerErrorsModel';

export const RecoveryPasswordModelProps = {
    api: observable,
    intl: observable,
    serverErrorsModel: observable,
    status: observable,
    sentSucceed: computed,
    sendToken: action.bound,
};

export class RecoveryPasswordModel {
    api: ApiStore;
    intl: IntlShape;
    serverErrorsModel: ServerErrorsModel<RecoveryPasswordPageFields>;

    status?: Status;

    constructor(api: ApiStore, intlStore: IntlStore) {
        makeObservable(this, RecoveryPasswordModelProps);
        this.api = api;
        this.intl = intlStore.intl;
        this.serverErrorsModel = new ServerErrorsModel<RecoveryPasswordPageFields>();
    }

    get sentSucceed(): boolean {
        return this.status === Status.success;
    }

    async sendToken(values: EmailDTO): Promise<void> {
        values.lang = this.intl.locale;
        this.serverErrorsModel.dropServerFormErrors();

        this.status = undefined;
        try {
            await this.api.userActionClient(
                apiConfigs.forgotPassword(values),
                Object.values(RecoveryPasswordPageFields),
            );
            this.status = Status.success;
        } catch (e: any) {
            this.serverErrorsModel.setServerFormErrors(e);
            this.status = Status.failed;
        }
    }
}
