import { action, computed, makeObservable, observable } from 'mobx';
import { IntlShape } from 'react-intl';
import { apiConfigs } from '../apiConfigs';
import { ApiStore, IntlStore } from '../stores';
import { RegistrationDTO, RegistrationPageFields } from '../types';
import { ServerErrorsModel } from './ServerErrorsModel';

export enum RegisterStatus {
    success = 'success',
    failed = 'failed',
    alreadyRegistered = 'Email already registered',
    notExpired = 'Confirm token still not expired',
}

export const RegistrationModelProps = {
    api: observable,
    intl: observable,
    serverErrorsModel: observable,
    termsIsAccepted: observable,
    registerStatus: observable,
    registerSucceed: computed,
    register: action.bound,
    onTermsIsAcceptedChange: action.bound,
    dropRegisterStatus: action.bound,
};

export class RegistrationModel {
    api: ApiStore;
    intl: IntlShape;
    serverErrorsModel: ServerErrorsModel<RegistrationPageFields>;

    termsIsAccepted = false;
    registerStatus?: RegisterStatus;

    constructor(api: ApiStore, intlStore: IntlStore) {
        makeObservable(this, RegistrationModelProps);
        this.api = api;
        this.intl = intlStore.intl;
        this.serverErrorsModel = new ServerErrorsModel<RegistrationPageFields>();
    }

    get registerSucceed(): boolean {
        return this.registerStatus === RegisterStatus.success;
    }

    async register(registrationData: RegistrationDTO): Promise<void> {
        registrationData.lang = this.intl.locale;
        this.serverErrorsModel.dropServerFormErrors();
        try {
            await this.api.userActionClient(
                apiConfigs.register(registrationData),
                Object.values(RegistrationPageFields),
            );
            this.registerStatus = RegisterStatus.success;
        } catch (e: any) {
            this.serverErrorsModel.setServerFormErrors(e);
        }
    }

    onTermsIsAcceptedChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void {
        this.termsIsAccepted = checked;
    }

    dropRegisterStatus(): void {
        this.registerStatus = undefined;
    }
}
