import { entities, responsiblePermissions } from '../authSchemeConfig';
import { UserNameModel } from '../models';
import { GeneralObjectField, PersonContact, ResponsibleCheck, ViewUpdatePermissions } from '../types';

export const getResponsibleDescription = (
    checkData: ResponsibleCheck,
    responsible?: PersonContact,
    withBottomSeparator = false,
): GeneralObjectField => {
    const { fieldName } = checkData;
    const responsibleModel = responsible && new UserNameModel().load(responsible, true);

    return {
        id: fieldName,
        value: responsibleModel?.name,
        isObjectField: true,
        rawValue: responsible || null,
        withBottomSeparator,
        withContactInformation: true,
        authorization: getResponsiblePermissions(checkData),
    };
};

export const getMultipleResponsibleDescription = (
    checkData: ResponsibleCheck,
    multipleResponsible?: PersonContact[],
    title?: string,
    withBottomSeparator = false,
): GeneralObjectField => {
    const { fieldName } = checkData;

    return {
        id: fieldName,
        title: title,
        value:
            multipleResponsible && multipleResponsible.length
                ? multipleResponsible.map((responsible) => new UserNameModel().load(responsible, true).name)
                : undefined,
        isObjectField: true,
        rawValue: multipleResponsible,
        withBottomSeparator,
        withContactInformation: true,
        authorization: getResponsiblePermissions(checkData),
    };
};

export const getResponsiblePermissions = (checkData: ResponsibleCheck): ViewUpdatePermissions => {
    const { fieldName, entityId, object } = checkData;
    return {
        view: {
            entityCode: entities.system,
            permCode: responsiblePermissions[object][fieldName].view,
            entityId,
        },
        update: {
            entityCode: entities.system,
            permCode: responsiblePermissions[object][fieldName].update,
            entityId,
        },
    };
};
