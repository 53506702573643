import { Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { entities, permissions } from '../../../authSchemeConfig';
import { AuthorizationCheck, AuthorizationCheck as AuthorizationCheckInj } from '../../../components';
import { useFlag } from '../../../hooks';
import { categoryMessages } from '../../../resources';
import { CategoryCreateDialog as CategoryCreateDialogInj } from './CategoryCreateDialog';

export const CategoryRegistryCreateButton = observer((): JSX.Element => {
    const [CategoryCreateDialog] = di([CategoryCreateDialogInj], CategoryRegistryCreateButton);
    const [AuthorizationCheck] = di([AuthorizationCheckInj], CategoryRegistryCreateButton);

    const [isCreateDialogOpen, openCreateDialog, closeCreateDialog] = useFlag();

    return (
        <React.Fragment>
            <AuthorizationCheck entityCode={entities.system} permCode={permissions.system.CreateCategory}>
                <Button variant="contained" color="primary" onClick={openCreateDialog}>
                    <FormattedMessage {...categoryMessages.create} />
                </Button>
            </AuthorizationCheck>
            <CategoryCreateDialog isOpen={isCreateDialogOpen} setIsClosed={closeCreateDialog} />
        </React.Fragment>
    );
});
