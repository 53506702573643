import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { AutocompleteField as AutocompleteFieldInj } from '../../../../../../components';
import { useAntiDoubleClick, useStore, useUserPageContext, useYup } from '../../../../../../hooks';
import { ServerErrorsModel } from '../../../../../../models';
import { ActionItemModalProps, CodeTitle, RolesFields, RolesSettings } from '../../../../../../types';
import { disableSubmitOnEnterKeyPress, stopPropagation } from '../../../../../../utils';

export const UserPageRolesModal = observer((props: ActionItemModalProps): JSX.Element => {
    const [AutocompleteField] = di([AutocompleteFieldInj], UserPageRolesModal);

    const { isOpen, setIsClosed, hideMenu } = props;
    const { userStore } = useStore();
    const intl = useIntl();
    const serverErrors = useMemo(() => new ServerErrorsModel<RolesFields>(), []);
    const { dropServerFormErrors, setServerFormErrors, serverFormErrors } = serverErrors;
    const { roles: rolesField } = RolesFields;
    const { userModel } = useUserPageContext();
    const { roles, setRoles } = userModel;
    const [allRoles, setAllRoles] = useState<CodeTitle[]>([]);

    useEffect(() => {
        userStore.userRoleList().then(setAllRoles);
    }, [intl.locale]);

    const onClose = (): void => {
        setIsClosed();
        dropServerFormErrors();
    };

    const initialValues: RolesSettings = {
        roles,
    };

    const { Yup } = useYup();
    const validationSchema = Yup.object({
        roles: Yup.array(),
    });

    const onSubmit = (values: RolesSettings): Promise<void> => {
        dropServerFormErrors();
        return setRoles(values)
            .then(() => {
                onClose();
                hideMenu && hideMenu();
            })
            .catch(setServerFormErrors);
    };

    const [isSending, endIcon, createHandler] = useAntiDoubleClick(onSubmit);

    return (
        <Dialog fullWidth={true} maxWidth="sm" open={isOpen} onKeyDown={stopPropagation}>
            <DialogTitle>
                <FormattedMessage id="user.settings.roles" />
            </DialogTitle>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={createHandler}
                enableReinitialize={true}
            >
                <Form onKeyDown={disableSubmitOnEnterKeyPress}>
                    <DialogContent>
                        <AutocompleteField
                            fieldName={rolesField}
                            label={intl.formatMessage({ id: 'user.fields.roles' })}
                            options={allRoles}
                            multiple={true}
                            serverError={serverFormErrors[rolesField]}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button color="primary" onClick={onClose}>
                            <FormattedMessage id="common.cancel" />
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={isSending}
                            endIcon={endIcon}
                        >
                            <FormattedMessage id="common.save" />
                        </Button>
                    </DialogActions>
                </Form>
            </Formik>
        </Dialog>
    );
});
