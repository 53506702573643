import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { PopoverOrigin } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useCallback } from 'react';
import { di } from 'react-magnetic-di';
import {
    ActionMenuItem as ActionMenuItemInj,
    MenuButton as MenuButtonInj,
    MenuButton,
} from '../../../../../components';
import { useUserPageContext } from '../../../../../hooks';
import { ActionItemProps, TotLocale } from '../../../../../types';

const locales = Object.values(TotLocale);

const anchorOrigin: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'left',
};

const transformOrigin: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'right',
};

export const UserPageLanguageButton = observer((props: ActionItemProps): JSX.Element => {
    const [MenuButton] = di([MenuButtonInj], UserPageLanguageButton);
    const [ActionMenuItem] = di([ActionMenuItemInj], UserPageLanguageButton);

    const { hideMenu } = props;
    const { userModel } = useUserPageContext();
    const { lang, setUserLang } = userModel;

    const onLangChangeCreator =
        (lang: TotLocale): (() => void) =>
        () => {
            hideMenu && hideMenu();
            setUserLang(lang);
        };

    const renderActionItems = useCallback((): ((hideMenu?: () => void) => ReactNode[]) => {
        const renderItems = (): ReactNode[] => {
            return locales.map((locale) => {
                const isActive = lang === locale;
                const onLangChange = onLangChangeCreator(locale);
                return (
                    <ActionMenuItem
                        messageId={`locale.${locale}`}
                        {...(!isActive && { onClick: onLangChange })}
                        isActive={isActive}
                        isWithIcon={isActive}
                        icon={<CheckCircleIcon />}
                    />
                );
            });
        };
        return renderItems;
    }, [locales, lang]);

    const renderButton = (
        onClick: (event: React.MouseEvent<HTMLElement>) => void,
        isMenuOpen: boolean,
    ): JSX.Element => {
        return (
            <ActionMenuItem
                messageId="user.settings.language"
                isActive={isMenuOpen}
                onClick={onClick}
                isWithIcon={true}
            />
        );
    };

    return (
        <React.Fragment>
            <MenuButton
                renderButton={renderButton}
                renderMenuItems={renderActionItems()}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
            />
        </React.Fragment>
    );
});
