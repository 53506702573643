import { titlesToTitlesDTO } from '@platform/multi-lang-field';
import { action, makeObservable, observable } from 'mobx';
import { CodeTitle, GeneralObjectStore, PrintFormCreationInfo, PrintFormFields, PrintFormSetting } from '../../types';
import { ServerErrorsModel } from '../ServerErrorsModel';

export const PrintFormCreateModelProps = {
    objectStore: observable,
    objectId: observable,
    tabId: observable,
    pfId: observable,
    acceptedFiles: observable,
    serverErrors: observable,
    fileName: observable,
    docTypes: observable,
    fileFormats: observable,
    loadSelects: action.bound,
    setFormSelects: action.bound,
    savePrintForm: action.bound,
    editPrintForm: action.bound,
    addPrintForm: action.bound,
    dropFileName: action.bound,
    setFileName: action.bound,
};

export class PrintFormCreateModel {
    private objectStore: GeneralObjectStore;

    objectId = '';
    tabId = '';
    pfId = '';
    acceptedFiles = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    serverErrors = new ServerErrorsModel<PrintFormFields>();
    fileName = '';

    docTypes: CodeTitle[] = [];
    fileFormats: CodeTitle[] = [];

    constructor(objectId: string, tabId: string, pfId: string, objectStore: GeneralObjectStore) {
        makeObservable(this, PrintFormCreateModelProps);
        this.objectId = objectId;
        this.tabId = tabId;
        this.pfId = pfId;
        this.objectStore = objectStore;
    }

    loadSelects(): void {
        this.objectStore.getPrintFormsCreationInfo(this.tabId).then(this.setFormSelects);
    }

    setFormSelects(dto: PrintFormCreationInfo): void {
        this.docTypes = dto.docTypes;
        this.fileFormats = dto.fileFormats;
    }

    savePrintForm(formValues: PrintFormSetting, isEdit: boolean, onSuccess: () => void): Promise<void> {
        this.serverErrors.dropServerFormErrors();

        const { titles, code, description, docType, fileFormat, required, file } = formValues;
        const data = new FormData();

        data.append('titles', JSON.stringify(titlesToTitlesDTO(titles)));
        data.append('required', required.toString());
        description && data.append('description', description);
        docType && data.append('docType', docType.code);
        fileFormat && data.append('fileFormat', fileFormat.code);
        file && data.append('file', file);
        !isEdit && data.append('code', code);

        const savePrintForm = isEdit ? this.editPrintForm : this.addPrintForm;

        return savePrintForm(data).then(onSuccess).catch(this.serverErrors.setServerFormErrors);
    }

    editPrintForm(data: FormData): Promise<void> {
        return this.objectStore.editPrintForm(this.tabId, this.pfId, data);
    }

    addPrintForm(data: FormData): Promise<void> {
        return this.objectStore.addPrintForm(this.tabId, data);
    }

    dropFileName(): void {
        this.fileName = '';
    }

    setFileName(fileName: string): void {
        this.fileName = fileName;
    }
}
