import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { contentInnerPadding } from '../../../../constants';

export const TotObjectLightContainer = styled(Grid)(
    ({ theme }) => `
            width: 100%;
            height: 100%;
            background-color: ${theme.palette.primary.contrastText};
    `,
);

export const TotObjectContentWrapper = styled(Grid)(
    ({ theme }) => `
            width: calc(100% - ${theme.variables.infoSidebar.width}px);
            transition: ${theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            })};
    `,
);

export const TotObjectContentInner = styled(Grid)(
    ({ theme }) => `
            width: 100%;
            padding: ${contentInnerPadding}px;
            padding-top: 0;
            flex-grow: 1;
    `,
);
