import { action, makeObservable } from 'mobx';
import { di } from 'react-magnetic-di';
import { generatePath } from 'react-router-dom';
import { apiConfigs } from '../apiConfigs';
import { clientRoute } from '../clientRoute';
import { UserNameModel } from '../models';
import {
    GeneralObject,
    GeneralObjectCard,
    GeneralObjectFieldName,
    NumberField,
    PortfolioCreateDTO,
    PortfolioCreateInfo,
    PortfolioDTO,
    ResponsibleCheck,
    ResponsibleFields,
} from '../types';
import {
    getGeneralObjectLinkValue,
    getMultipleResponsibleDescription,
    getNumberDescription,
    getResponsibleDescription,
} from '../utils';
import { GeneralObjectExtendStore, GeneralObjectExtendStoreProps } from './GeneralObjectExtendsStore';
import { RootStore } from './RootStore';

export const PortfolioStoreProps = {
    ...GeneralObjectExtendStoreProps,

    editPortfolioNumber: action.bound,
    editPortfolioManager: action.bound,
    editPortfolioViewers: action.bound,
};

export class PortfolioStore extends GeneralObjectExtendStore<PortfolioDTO, PortfolioCreateDTO, PortfolioCreateInfo> {
    objectType = GeneralObject.portfolio;
    isWithTabs = true;
    isWithCreateCopy = false;
    isWithChartContent = true;

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this, PortfolioStoreProps);
    }

    mapObjectDTOToCard(data: PortfolioDTO): GeneralObjectCard {
        const { id, title, metaInfo, identifier, manager, viewers } = data;
        const { author, created, state } = metaInfo;
        const authorPortfolio = new UserNameModel().load(author);
        const { intlStore } = this.rootStore;

        const getResponsibleCheckData = (fieldName: GeneralObjectFieldName): ResponsibleCheck => ({
            object: this.objectType,
            entityId: id,
            fieldName,
        });

        return {
            id,
            title,
            state,
            commonDescription: [
                getNumberDescription(identifier),
                getResponsibleDescription(getResponsibleCheckData(GeneralObjectFieldName.portfolioManager), manager),
                getMultipleResponsibleDescription(
                    getResponsibleCheckData(GeneralObjectFieldName.portfolioViewer),
                    viewers,
                    intlStore.intl.formatMessage({ id: 'viewer.plural.nominative' }),
                    true,
                ),
                {
                    id: GeneralObjectFieldName.created,
                    value: created,
                    isDate: true,
                },
                {
                    id: GeneralObjectFieldName.author,
                    isObjectField: true,
                    value: getGeneralObjectLinkValue(
                        authorPortfolio.name,
                        generatePath(clientRoute.user, { id: author.userId }),
                    ),
                    withBottomSeparator: true,
                },
                this.getSyslogDescription(id),
            ],
        };
    }

    editPortfolioNumber(id: string, number: string, onSuccess: () => void): Promise<void> {
        return this.api
            .userActionClient(apiConfigs.editPortfolioNumber(id, number), Object.values(NumberField))
            .then(onSuccess);
    }

    editPortfolioManager(id: string, managerId: string, onSuccess: () => void): Promise<void> {
        return this.api
            .userActionClient(apiConfigs.editPortfolioManager(id, managerId), Object.values(ResponsibleFields))
            .then(onSuccess);
    }

    editPortfolioViewers(id: string, viewerIds: string[], onSuccess: () => void): Promise<void> {
        return this.api
            .userActionClient(apiConfigs.editPortfolioViewers(id, viewerIds), Object.values(ResponsibleFields))
            .then(onSuccess);
    }
}

export const getPortfolioStore = (): any => {
    const [_PortfolioStore] = di([PortfolioStore], getPortfolioStore);
    return _PortfolioStore;
};
