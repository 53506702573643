import { ObjectLocales } from '../../../types';

const version: ObjectLocales = {
    single: {
        nominative: 'версия',
    },
    plural: {
        nominative: 'версии',
    },
};

export default version;
