import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Portal } from '@mui/material';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAntiDoubleClick } from '../hooks';
import { ModalProps } from '../types';

export type ConfirmationDialogProps = ModalProps & {
    id: string;
    keepMounted: boolean;
    onConfirm: () => Promise<void>;
    title: ReactNode;
    message?: ReactNode;
    confirmText?: string;
};

export const ConfirmationDialog = (props: ConfirmationDialogProps): JSX.Element => {
    const { confirmText, onConfirm, setIsClosed, isOpen, title, message, ...other } = props;
    const [isSending, endIcon, actionHandler] = useAntiDoubleClick(onConfirm);

    return (
        <Portal>
            <Dialog fullWidth={true} maxWidth="xs" aria-labelledby="confirmation-dialog-title" open={isOpen} {...other}>
                <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
                {message && <DialogContent>{message}</DialogContent>}
                <DialogActions>
                    <Button autoFocus onClick={setIsClosed} color="primary">
                        <FormattedMessage id="common.cancel" />
                    </Button>
                    <Button
                        onClick={actionHandler}
                        color="secondary"
                        variant="contained"
                        disabled={isSending}
                        endIcon={endIcon}
                    >
                        {confirmText || <FormattedMessage id="common.confirm" />}
                    </Button>
                </DialogActions>
            </Dialog>
        </Portal>
    );
};
