var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from 'styled-components';
import { sassVars } from '../../constants/sassVars';
import formLabel from '../mixins/formLabel';
export default function () {
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        .control-label {\n            ", ";\n\n            &.control-label--hidden {\n                display: initial;\n                ", "\n            }\n\n            + .formio-choices {\n                margin-top: 0;\n            }\n\n            &.field-required:after {\n                color: ", ";\n            }\n        }\n\n        .has-error .control-label {\n            color: ", ";\n        }\n    "], ["\n        .control-label {\n            ", ";\n\n            &.control-label--hidden {\n                display: initial;\n                ", "\n            }\n\n            + .formio-choices {\n                margin-top: 0;\n            }\n\n            &.field-required:after {\n                color: ", ";\n            }\n        }\n\n        .has-error .control-label {\n            color: ", ";\n        }\n    "])), formLabel(), formLabel(), sassVars.formElementLabelRequiredColor, sassVars.formElementLabelColor).join('');
}
var templateObject_1;
