import { action, computed, makeObservable, observable } from 'mobx';
import { CatalogStore, CodeTitleGroupRC, RootStore } from '../stores';
import {
    TabAccessPermissionDTO,
    TabAccessPermissionValues,
    CodeTitle,
    GeneralObjectPermissionTrigger,
    LoadPermissions,
    NewTabPermissionDTO,
    PermissionSettingsDTO,
    SelectedIndex,
    TabSettingsPermissionsFields,
    UpdatePermissions,
} from '../types';
import { ServerErrorsModel } from './ServerErrorsModel';

export const PermissionSettingsModelProps = {
    rootStore: observable,
    catalogStore: observable,
    onLoadPermissions: observable,
    onUpdatePermissions: observable,
    serverErrors: observable,
    tabId: observable,
    objectId: observable,
    processCode: observable,
    permissions: observable,
    roleConditionList: observable,
    lifecyclesList: observable,
    newPermissionsDTO: computed,
    load: action.bound,
    loadPermissions: action.bound,
    updatePermissions: action.bound,
    deletePermission: action.bound,
    accessPermissionMapToNewPermissionDTO: action.bound,
    getAccessPermissionValuesFromAccessPermissionDTO: action.bound,
    setMainFields: action.bound,
    setRoleConditionLis: action.bound,
    setLifecyclesList: action.bound,
};

export class TabPermissionSettingsModel {
    private rootStore: RootStore;
    private catalogStore: CatalogStore;

    private onLoadPermissions: LoadPermissions;
    private onUpdatePermissions: UpdatePermissions;

    serverErrors = new ServerErrorsModel<TabSettingsPermissionsFields>();

    tabId = '';
    objectId = '';
    processCode = '';

    permissions: TabAccessPermissionDTO[] = [];

    roleConditionList: CodeTitleGroupRC[] = [];
    lifecyclesList: CodeTitle[] = [];

    constructor(
        objectId: string,
        tabId: string,
        rootStore: RootStore,
        onLoadPermissions: LoadPermissions,
        onUpdatePermissions: UpdatePermissions,
    ) {
        makeObservable(this, PermissionSettingsModelProps);
        this.tabId = tabId;
        this.objectId = objectId;
        this.rootStore = rootStore;
        this.catalogStore = rootStore.catalogStore;

        this.onLoadPermissions = onLoadPermissions;
        this.onUpdatePermissions = onUpdatePermissions;
    }

    get newPermissionsDTO(): NewTabPermissionDTO[] {
        return this.permissions.map(this.accessPermissionMapToNewPermissionDTO);
    }

    load(): void {
        this.loadPermissions().then(() => {
            this.catalogStore.getRoleConditionsList().then(this.setRoleConditionLis);
            this.catalogStore.getLifecyclesList(this.processCode).then(this.setLifecyclesList);
        });
    }

    loadPermissions(): Promise<void> {
        return this.onLoadPermissions().then(this.setMainFields);
    }

    updatePermissions(formValues: TabAccessPermissionDTO, selectedPermissionIndex: SelectedIndex): Promise<void> {
        this.serverErrors.dropServerFormErrors();
        const isNewItem = selectedPermissionIndex === null;

        const dto = this.accessPermissionMapToNewPermissionDTO(formValues);
        const oldPermissions: NewTabPermissionDTO[] = this.newPermissionsDTO;
        const newPermissions = [...oldPermissions];

        if (isNewItem) {
            newPermissions.push(dto);
        } else {
            newPermissions.splice(selectedPermissionIndex, 1, dto);
        }

        return this.onUpdatePermissions(newPermissions)
            .then(() => {
                this.loadPermissions();
            })
            .catch(this.serverErrors.setServerFormErrors);
    }

    deletePermission(permissionIndex: number): Promise<void> {
        const oldPermissions: NewTabPermissionDTO[] = this.newPermissionsDTO;
        const newPermissions = [...oldPermissions];
        newPermissions.splice(permissionIndex, 1);

        return this.onUpdatePermissions(newPermissions).then(this.loadPermissions);
    }

    accessPermissionMapToNewPermissionDTO(permission: TabAccessPermissionDTO): NewTabPermissionDTO {
        const isWithRole = permission.roleCondition.group === GeneralObjectPermissionTrigger.role;
        const roleConditionCode = permission.roleCondition.code;
        const dto: NewTabPermissionDTO = {
            whenObjectStateIsOneOf: permission.whenObjectStateIsOneOf.map((state) => state.code),
            permissions: permission.permissions,
        };

        if (isWithRole) {
            dto.role = roleConditionCode;
        } else {
            dto.when = roleConditionCode;
        }

        return dto;
    }

    getAccessPermissionValuesFromAccessPermissionDTO(dto: TabAccessPermissionDTO): TabAccessPermissionValues {
        const { filesEdit, filesUpload, editForm } = dto.permissions;
        return {
            ...dto,
            permissions: {
                ...dto.permissions,
                edit: editForm || filesUpload || filesEdit,
                filesManagement: filesUpload || filesEdit,
            },
        };
    }

    setMainFields(dto: PermissionSettingsDTO): void {
        this.permissions = dto.permissions;
        this.processCode = dto.processCode;
    }

    setRoleConditionLis(roleConditionList: CodeTitleGroupRC[]): void {
        this.roleConditionList = roleConditionList;
    }

    setLifecyclesList(lifecyclesList: CodeTitle[]): void {
        this.lifecyclesList = lifecyclesList;
    }
}
