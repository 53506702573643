import { TitlesDTO, titlesDTOtoTitles } from '@platform/multi-lang-field';
import downloadFile from 'js-file-download';
import { action, computed, makeObservable, observable } from 'mobx';
import { CodeTitle, FileDTO, GeneralObjectStore, PrintFormSetting, PrintFormSettingDTO } from '../../types';

export const PrintFormSettingsModelProps = {
    objectStore: observable,
    tabId: observable,
    pfId: observable,
    titles: observable,
    code: observable,
    description: observable,
    docType: observable,
    fileFormat: observable,
    file: observable,
    required: observable,
    isLoading: observable,
    printForm: computed,
    load: action.bound,
    downloadFile: action.bound,
    setMainFields: action.bound,
    setIsLoading: action.bound,
};

export class PrintFormSettingsModel {
    private objectStore: GeneralObjectStore;

    tabId = '';
    pfId = '';
    titles: TitlesDTO = {} as TitlesDTO;
    code = '';
    description?: string;
    docType?: CodeTitle;
    fileFormat: CodeTitle = {} as CodeTitle;
    file: FileDTO = {} as FileDTO;
    required = false;

    isLoading = true;

    constructor(tabId: string, pfId: string, objectStore: GeneralObjectStore) {
        makeObservable(this, PrintFormSettingsModelProps);
        this.tabId = tabId;
        this.pfId = pfId;
        this.objectStore = objectStore;
    }

    get printForm(): PrintFormSetting {
        return {
            titles: titlesDTOtoTitles(this.titles),
            code: this.code,
            description: this.description,
            docType: this.docType,
            fileFormat: this.fileFormat,
            fileName: this.file.filename,
            required: this.required,
        };
    }

    load(): void {
        this.setIsLoading(true);
        this.objectStore.getPrintForm(this.tabId, this.pfId).then((dto) => {
            this.setMainFields(dto);
            this.setIsLoading(false);
        });
    }

    downloadFile(): Promise<void> {
        const { filename, mimeType } = this.file;

        return this.objectStore
            .downloadTemplatePrintForm(this.tabId, this.pfId)
            .then((file) => downloadFile(file, filename, mimeType));
    }

    setMainFields(dto: PrintFormSettingDTO): void {
        this.titles = dto.titles;
        this.docType = dto.docType;
        this.fileFormat = dto.fileFormat;
        this.file = dto.file;
        this.code = dto.code;
        this.description = dto.description;
        this.required = dto.required;
    }

    setIsLoading(isLoading: boolean): void {
        this.isLoading = isLoading;
    }
}
