import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useGeneralObjectContext } from '../../../../../hooks';

export type GeneralObjectTransitionErrorDialogItemProps = {
    errorMessages: string[];
    tabTitle: string;
};

export const GeneralObjectTransitionErrorDialogItem = (
    props: GeneralObjectTransitionErrorDialogItemProps,
): JSX.Element => {
    const { errorMessages, tabTitle } = props;
    const { objectStore } = useGeneralObjectContext();
    const { isWithTabs } = objectStore;

    return (
        <React.Fragment>
            {isWithTabs && tabTitle && <Typography variant="h6">{tabTitle}:</Typography>}
            <Grid container direction="column" spacing={2}>
                {errorMessages.map((message) => (
                    <Grid item>
                        <Typography>{message}</Typography>
                    </Grid>
                ))}
            </Grid>
        </React.Fragment>
    );
};
