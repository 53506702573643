import { TabContext } from '@mui/lab';
import { observer } from 'mobx-react';
import React, { PropsWithChildren, useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { RouteSetting } from '../types';

export type RoutedTabContextProps = PropsWithChildren<{
    settings: RouteSetting[];
}>;

export const RoutedTabContext = observer((props: RoutedTabContextProps): JSX.Element => {
    const { settings, children } = props;
    const location = useLocation();
    const { pathname } = location;

    const match = useMemo(() => {
        return settings.find((s) => !!matchPath(pathname, s));
    }, [settings, pathname]);

    const selected = useMemo(() => {
        return match === undefined ? '' : match.tab.toString();
    }, [match]);

    return <TabContext value={selected}>{children}</TabContext>;
});
