import { createBrowserHistory } from 'history';
import { RootProps } from './types';

const projectName = process.env.REACT_APP_PROJECT_NAME;

const env: RootProps['env'] = {
    apiUrl: process.env.REACT_APP_API_URL,
    projectName,
    totCopyrightRu: process.env.REACT_APP_TOT_COPYRIGHT_RU,
    totCopyrightEn: process.env.REACT_APP_TOT_COPYRIGHT_EN,
    totTel: process.env.REACT_APP_TOT_TEL,
    totMail: process.env.REACT_APP_TOT_MAIL,
    startPageText: process.env.REACT_APP_START_PAGE_TEXT,
    termsOfServiceLink: process.env.REACT_APP_TERMS_OF_SERVICE,
    termsOfServiceLinkRu: process.env.REACT_APP_TERMS_OF_SERVICE_RU,
    termsOfServiceLinkEn: process.env.REACT_APP_TERMS_OF_SERVICE_EN,
    pfDelay: Number(process.env.REACT_APP_PF_DELAY) || 30000,
    asyncCheckDelay: Number(process.env.REACT_APP_ASYNC_CHECK_DELAY) || 200,
    asyncCheckTimeout: Number(process.env.REACT_APP_ASYNC_CHECK_TIMEOUT) || 2000,
};

let features: RootProps['features'];
let themeOverrides: RootProps['themeOverrides'];
if (projectName) {
    features = require(`./customization/${projectName}/features`).default;
    themeOverrides = require(`./customization/${projectName}/theme`).default;
}

const history = createBrowserHistory();

export { env, features, themeOverrides, history };
