import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { grey } from '@platform/front-ui';
import { transitionDefault } from '../../../theme';
import { AppTheme } from '../../../types';

// Используется только в футере
// Имеет статичную цветовую схему
export const FooterButton = styled(Button)<ButtonProps>(
    `
       [class*="MuiButton-label"] {
           align-items: center;
       }
        
       [class*="MuiTypography-root"], [class*="MuiButton-startIcon"] {
           transition: ${transitionDefault};
           color: ${grey[500]};
       }
       
       [class*="MuiTouchRipple-root"] {
           color: ${grey[500]};
       }
       
       &:hover {
           & [class*="MuiTypography-root"], & [class*="MuiButton-startIcon"] {
                color: ${grey[700]};
           }
       }

       [class*="MuiTypography-root"] {
           font-weight: bold;
       }
`,
);

// Зависит от старой цветовой схемы и используется на стартовой странице
// В редизайне этой кнопки нет на стартовой странице, но футер договорились привести по цветам к редизайновскому
// Сделал так для того, чтобы не вносить изменения в форке
export const FooterButtonStartPage = styled(Button)<ButtonProps>(
    ({ theme }) => `
       [class*="MuiButton-label"] {
           align-items: center;
       }
        
       [class*="MuiTypography-root"], [class*="MuiButton-startIcon"] {
           transition: ${transitionDefault};
           color: ${(theme as AppTheme)?.variables.palette.footer.textMain};  
       }
       
       [class*="MuiTouchRipple-root"] {
           color: ${(theme as AppTheme)?.variables.palette.footer.textMain};  
       }
       
       &:hover {
           & [class*="MuiTypography-root"], & [class*="MuiButton-startIcon"] {
                color: ${(theme as AppTheme)?.variables.palette.footer.textHover};  
           }
       }    

       [class*="MuiTypography-root"] {
           font-weight: bold;
       }
`,
);
