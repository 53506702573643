import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useCallback } from 'react';
import { di } from 'react-magnetic-di';
import { MenuButton as MenuButtonInj, renderMenuButton as renderMenuButtonInj } from '../../../../../components';
import { UserPageFullNameButton as UserPageFullNameButtonInj } from './user-page-fullName-button';
import { UserPageRolesButton as UserPageRolesButtonInj } from './user-page-roles-button/UserPageRolesButton';

export const UserPageHeaderActions = observer((): JSX.Element => {
    const [MenuButton] = di([MenuButtonInj], UserPageHeaderActions);
    const [renderMenuButton] = di([renderMenuButtonInj], UserPageHeaderActions);
    const [UserPageFullNameButton] = di([UserPageFullNameButtonInj], UserPageHeaderActions);
    const [UserPageRolesButton] = di([UserPageRolesButtonInj], UserPageHeaderActions);

    const renderActionItems = useCallback((): ((hideMenu?: () => void) => ReactNode[]) => {
        const renderItems = (hideMenu?: () => void): ReactNode[] => {
            return [<UserPageFullNameButton hideMenu={hideMenu} />, <UserPageRolesButton hideMenu={hideMenu} />];
        };
        return renderItems;
    }, []);

    return <MenuButton renderButton={renderMenuButton(<MoreHorizIcon />)} renderMenuItems={renderActionItems()} />;
});
