import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { GeneralObjectEditResponsible as GeneralObjectEditResponsibleInj } from '../../../../components';
import { useStore } from '../../../../hooks';
import { EditModalProps } from '../../../../types';

export const EditProjectExecutor = observer((props: EditModalProps): JSX.Element => {
    const [GeneralObjectEditResponsible] = di([GeneralObjectEditResponsibleInj], EditProjectExecutor);
    const { projectStore } = useStore();
    const intl = useIntl();

    const dialogTitle = intl.formatMessage({ id: 'projectExecutor.plural.nominative' });

    return (
        <GeneralObjectEditResponsible
            title={dialogTitle}
            editMultipleResponsible={projectStore.editProjectExecutors}
            {...props}
        />
    );
});
