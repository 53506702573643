import { Answer, LocalesTree } from '../../../types';

const answer: Record<Answer, string> = {
    yes: 'Да',
    no: 'Нет',
};

const common: LocalesTree = {
    edit: 'Редактировать',
    save: 'Сохранить',
    delete: 'Удалить',
    cancel: 'Отменить',
    create: 'Создать',
    move: 'Переместить',
    correct: 'Исправить',
    discardChanges: 'Отменить изменения',
    close: 'Закрыть',
    hide: 'Скрыть',
    error: 'Ошибка',
    errorText: 'Что-то пошло не так',
    changesSaved: 'Изменения сохранены',
    confirm: 'Подтвердить',
    ok: 'Понятно',
    noData: 'Нет данных',
    from: 'c',
    to: 'до',
    until: 'по',
    state: 'Состояние',
    result: 'Результат',
    copy: 'Копировать',
    clear: 'Очистить',
    rowsPerPage: 'Отображать по:',
    pagingInfo: 'Отображено c {from, number} по {to, number}. Всего элементов {count, number}',
    resetFilters: 'Сбросить',
    filters: 'Фильтры',
    all: 'Все',
    actions: 'Действия',
    export: 'Выгрузить',
    confirmDeletion: 'Подтвердите удаление',
    dropzoneText: 'Перетащите или выберите файл',
    pageNotFound: 'Страница не найдена',
    downloadFile: 'Скачать {fileName}',
    defaultErrorMessage: 'Что-то пошло не так',
    lifeCycleConfirmTitle: 'Изменение статуса',
    lifeCycleConfirmText: 'Статус будет изменен на "{toState}"',
    search: 'Поиск',
    list: 'Список',
    answer,
};

export default common;
