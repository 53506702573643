import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useCallback } from 'react';
import { di } from 'react-magnetic-di';
import { MenuButton as MenuButtonInj, renderMenuButton as renderMenuButtonInj } from '../../../../../components';
import { UserPageLanguageButton as UserPageLanguageButtonInj } from './UserPageLanguageButton';

export const UserPageSidebarActions = observer((): JSX.Element => {
    const [MenuButton] = di([MenuButtonInj], UserPageSidebarActions);
    const [renderMenuButton] = di([renderMenuButtonInj], UserPageSidebarActions);
    const [UserPageLanguageButton] = di([UserPageLanguageButtonInj], UserPageSidebarActions);

    const renderActionItems = useCallback((): ((hideMenu: () => void) => ReactNode[]) => {
        const renderItems = (hideMenu: () => void): ReactNode[] => {
            return [<UserPageLanguageButton hideMenu={hideMenu} />];
        };
        return renderItems;
    }, []);

    return <MenuButton renderButton={renderMenuButton(<MoreHorizIcon />)} renderMenuItems={renderActionItems()} />;
});
