import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { getRuTitleInitialValue, MultiLangField, titlesYupSchema } from '@platform/multi-lang-field';
import { Field, Form, Formik } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { useAntiDoubleClick, useGeneralObjectContext, useYup } from '../../../../../hooks';
import { PrintFormCreateModel } from '../../../../../models';
import { GeneralObjectPFRouteParams, ModalProps, PrintFormFields, PrintFormSetting } from '../../../../../types';
import { disableSubmitOnEnterKeyPress } from '../../../../../utils';
import {
    AutocompleteField as AutocompleteFieldInj,
    FieldWithServerError as FieldWithServerErrorInj,
} from '../../../../fields';
import { RequiredLabel } from '../../../../RequiredLabel';
import { ServerErrorHelper as ServerErrorHelperInj } from '../../../../ServerErrorHelper';
import { GeneralObjectPFDropzone as GeneralObjectPFDropzoneInj } from './GeneralObjectPFDropzone';

export type GeneralObjectPFModalProps = ModalProps & {
    onSuccess: () => void;
    printForm?: PrintFormSetting;
};

export const GeneralObjectPFModal = observer((props: GeneralObjectPFModalProps): JSX.Element => {
    const [FieldWithServerError] = di([FieldWithServerErrorInj], GeneralObjectPFModal);
    const [AutocompleteField] = di([AutocompleteFieldInj], GeneralObjectPFModal);
    const [GeneralObjectPFDropzone] = di([GeneralObjectPFDropzoneInj], GeneralObjectPFModal);
    const [ServerErrorHelper] = di([ServerErrorHelperInj], GeneralObjectPFModal);

    const { isOpen, setIsClosed, onSuccess, printForm } = props;
    const intl = useIntl();
    const { objectStore } = useGeneralObjectContext();
    const { id, tabId, pfId } = useParams<GeneralObjectPFRouteParams>();

    const model = useMemo(() => new PrintFormCreateModel(id, tabId, pfId, objectStore), [id, tabId, objectStore]);
    const { loadSelects, serverErrors, acceptedFiles, docTypes, fileFormats, fileName, savePrintForm, setFileName } =
        model;
    const { serverFormErrors, dropServerFormErrors } = serverErrors;
    const { titles, code, description, docType, fileFormat: fileFormatField, file, required } = PrintFormFields;

    useEffect(() => {
        loadSelects();
    }, [intl.locale]);

    useEffect(() => {
        const fileName = printForm?.fileName;

        if (fileName) {
            // setTimeout ожидает закрытия модалки
            setTimeout(() => {
                setFileName(fileName);
            }, 100);
        }
    }, [printForm?.fileName, isOpen]);

    const onClose = (): void => {
        setIsClosed();
        dropServerFormErrors();
    };

    const initialValues: PrintFormSetting = {
        titles: printForm ? printForm.titles : getRuTitleInitialValue(),
        code: printForm ? printForm.code : '',
        description: printForm ? printForm.description : '',
        docType: printForm ? printForm.docType : null,
        fileFormat: printForm ? printForm.fileFormat : fileFormats[0],
        required: printForm ? printForm.required : false,
    };

    const { Yup } = useYup();
    const mixed = Yup.mixed();
    const schema = Yup.object().shape({
        titles: titlesYupSchema(Yup, true).min(1),
        code: Yup.string().required(),
        description: Yup.string(),
        docType: Yup.object().nullable(),
        file: fileName ? mixed : mixed.required(),
        fileFormat: Yup.object().required().nullable(),
        required: Yup.boolean(),
    });

    const onSuccessSubmit = (): void => {
        setIsClosed();
        onSuccess();
    };

    const handleSubmit = (values: PrintFormSetting): Promise<void> => {
        return savePrintForm(values, !!printForm, onSuccessSubmit);
    };

    const [isSending, endIcon, createHandler] = useAntiDoubleClick(handleSubmit);

    const dialogTitle = printForm ? (
        <FormattedMessage id="objectSettings.editPrintForm" />
    ) : (
        <FormattedMessage id="objectSettings.newPrintForm" />
    );

    const codeLabel = <RequiredLabel text={intl.formatMessage({ id: 'objectSettings.printFormFields.code' })} />;
    const fileFormatFieldLabel = (
        <RequiredLabel text={intl.formatMessage({ id: 'objectSettings.printFormFields.fileFormat' })} />
    );

    return (
        <Dialog fullWidth={true} maxWidth="sm" open={isOpen} scroll="body">
            <DialogTitle>{dialogTitle}</DialogTitle>
            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={createHandler}
                enableReinitialize={true}
            >
                <Form onKeyDown={disableSubmitOnEnterKeyPress}>
                    <DialogContent>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <MultiLangField systemLanguage={intl.locale} fieldName={titles} ruEngLangs={true} />
                                <ServerErrorHelper serverError={serverFormErrors[titles]} />
                            </Grid>
                            <Grid item>
                                <FieldWithServerError
                                    fullWidth
                                    disabled={!!printForm}
                                    variant="outlined"
                                    name={code}
                                    label={codeLabel}
                                    serverError={serverFormErrors[code]}
                                />
                            </Grid>
                            <Grid item>
                                <FieldWithServerError
                                    fullWidth
                                    variant="outlined"
                                    name={description}
                                    multiline
                                    rows={4}
                                    label={intl.formatMessage({ id: 'objectSettings.printFormFields.description' })}
                                    serverError={serverFormErrors[description]}
                                />
                            </Grid>
                            <Grid item>
                                <AutocompleteField
                                    fieldName={docType}
                                    label={intl.formatMessage({ id: 'objectSettings.printFormFields.docType' })}
                                    options={docTypes}
                                    serverError={serverFormErrors[docType]}
                                />
                            </Grid>
                            <Grid item>
                                <AutocompleteField
                                    fieldName={fileFormatField}
                                    label={fileFormatFieldLabel}
                                    options={fileFormats}
                                    serverError={serverFormErrors[fileFormatField]}
                                />
                            </Grid>
                            <Grid item sx={{ marginBottom: 4 }}>
                                <Field
                                    name={required}
                                    disabled={false}
                                    type="checkbox"
                                    component={CheckboxWithLabel}
                                    Label={{
                                        label: intl.formatMessage({ id: 'objectSettings.printFormFields.required' }),
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <GeneralObjectPFDropzone
                                    model={model}
                                    acceptedFiles={acceptedFiles}
                                    serverError={serverFormErrors[file]}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={onClose}>
                            <FormattedMessage id="common.cancel" />
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            disabled={isSending}
                            endIcon={endIcon}
                            type="submit"
                        >
                            <FormattedMessage id="common.save" />
                        </Button>
                    </DialogActions>
                </Form>
            </Formik>
        </Dialog>
    );
});
