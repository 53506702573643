import { Box, Container, Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, NavLink, Route, Switch, useParams } from 'react-router-dom';
import { useGeneralObjectContext, useStore } from '../../../../../hooks';
import { PrintFormSettingsModel } from '../../../../../models';
import {
    BreadcrumbsEntities,
    GeneralObjectPFRouteParams,
    GeneralObjectPFSettingSections,
    TabRoute,
} from '../../../../../types';
import { ServerBreadcrumbs as ServerBreadcrumbsInj } from '../../../../breadcrumbs';
import { InfoTab as InfoTabInj } from '../../../../InfoTab';
import { InfoTabs as InfoTabsInj } from '../../../../InfoTabs';
import { RoutedTabContext as RoutedTabContextInj } from '../../../../RoutedTabContext';
import { GeneralObjectPFSettingsAccess as GeneralObjectPFSettingsAccessInj } from './GeneralObjectPFSettingsAccess';
import { GeneralObjectPFSettingsMain as GeneralObjectPFSettingsMainInj } from './GeneralObjectPFSettingsMain';

export const GeneralObjectPFSettings = observer((): JSX.Element => {
    const [ServerBreadcrumbs] = di([ServerBreadcrumbsInj], GeneralObjectPFSettings);
    const [InfoTabs] = di([InfoTabsInj], GeneralObjectPFSettings);
    const [InfoTab] = di([InfoTabInj], GeneralObjectPFSettings);
    const [RoutedTabContext] = di([RoutedTabContextInj], GeneralObjectPFSettings);
    const [GeneralObjectPFSettingsMain] = di([GeneralObjectPFSettingsMainInj], GeneralObjectPFSettings);
    const [GeneralObjectPFSettingsAccess] = di([GeneralObjectPFSettingsAccessInj], GeneralObjectPFSettings);

    const { intlStore } = useStore();
    const { tabId, id, pfId } = useParams<GeneralObjectPFRouteParams>();
    const { routes, objectStore } = useGeneralObjectContext();
    const model = useMemo(() => new PrintFormSettingsModel(tabId, pfId, objectStore), [tabId, pfId, objectStore]);
    const { load, titles } = model;
    const title = titles[intlStore.locale] || '';

    useEffect(() => {
        load();
    }, [tabId, pfId, objectStore]);

    const tabRoutes: TabRoute[] = [
        {
            tab: GeneralObjectPFSettingSections.settings,
            path: routes.settingsPFMain,
        },
        {
            tab: GeneralObjectPFSettingSections.access,
            path: routes.settingsPFAccess,
        },
    ];

    return (
        <Container maxWidth="lg">
            <Box pt={5.5} pb={15}>
                <Box pb={5}>
                    <ServerBreadcrumbs entityTitle={title} entityId={pfId} entityType={BreadcrumbsEntities.pfSetting} />
                </Box>
                <Box pb={5}>
                    <Grid container direction="column" spacing={5}>
                        <Grid item>
                            <Typography variant="h4" component="h1">
                                {title}
                            </Typography>
                        </Grid>
                        <Grid container item alignItems="center">
                            <RoutedTabContext settings={tabRoutes}>
                                <InfoTabs>
                                    {tabRoutes.map((p) => {
                                        const { tab, path } = p;
                                        return (
                                            <InfoTab
                                                // @ts-ignore
                                                component={NavLink}
                                                key={tab}
                                                value={tab}
                                                label={
                                                    <FormattedMessage id={`objectSettings.pfSettingsSections.${tab}`} />
                                                }
                                                to={generatePath(path, { id, tabId, pfId })}
                                            />
                                        );
                                    })}
                                </InfoTabs>
                            </RoutedTabContext>
                        </Grid>
                    </Grid>
                </Box>
                <Switch>
                    <Route path={routes.settingsPFMain}>
                        <GeneralObjectPFSettingsMain model={model} />
                    </Route>
                    <Route path={routes.settingsPFAccess}>
                        <GeneralObjectPFSettingsAccess />
                    </Route>
                </Switch>
            </Box>
        </Container>
    );
});
