import { MenuItem } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { supportedLocales } from '../../../../constants';
import { useStore } from '../../../../hooks';
import { MenuButton as MenuButtonInj } from '../../../buttons';
import { TotLocaleButton as TotLocaleButtonInj } from '../../../TotLocaleButton';

export type LanguageButtonProps = {
    isDarkBackground?: boolean;
};

export const LanguageButton = observer((props: LanguageButtonProps): JSX.Element => {
    const [TotLocaleButton] = di([TotLocaleButtonInj], LanguageButton);
    const [MenuButton] = di([MenuButtonInj], LanguageButton);

    const { isDarkBackground } = props;
    const { intlStore } = useStore();

    const renderMenuItems = (hideMenu: () => void): JSX.Element[] => {
        return supportedLocales.map((l) => {
            const handleSelect = (): void => {
                intlStore.changeLocale(l);
                hideMenu();
            };
            return (
                <MenuItem
                    dense
                    key={l}
                    role="menuitem"
                    component="button"
                    selected={intlStore.locale === l}
                    onClick={handleSelect}
                    tabIndex={0}
                >
                    {l}
                </MenuItem>
            );
        });
    };

    const renderButton = (onClick: (event: React.MouseEvent<HTMLElement>) => void): JSX.Element => {
        return (
            <TotLocaleButton isDarkBackground={isDarkBackground} color="secondary" onClick={onClick}>
                {intlStore.locale === 'ru' ? 'Ru' : 'En'}
            </TotLocaleButton>
        );
    };

    return <MenuButton renderButton={renderButton} renderMenuItems={renderMenuItems} />;
});
