import { AxiosResponse } from 'axios';
import { action, computed, makeObservable, observable } from 'mobx';
import { di } from 'react-magnetic-di';
import { apiConfigs } from '../apiConfigs';
import { clientRoute } from '../clientRoute';
import { PersonModel } from '../models';
import { PersonInfo, RedirectByRole, RolePriority, UserModel, UserRole } from '../types';
import { ApiStore } from './ApiStore';
import { IntlStore } from './IntlStore';
import { RootStore } from './RootStore';

export const loginRedirectByRole: RedirectByRole = {
    Admin: clientRoute.portfolios,
    Curator: clientRoute.portfolios,
    Moderator: clientRoute.categories,
    ExpertiseCurator: clientRoute.childObjects,
    Supervisor: clientRoute.projects,
    Executor: clientRoute.projects,
    User: clientRoute.notAllowed,
};

export const rolePriorities: RolePriority = {
    User: 0,
    Executor: 1,
    Supervisor: 2,
    ExpertiseCurator: 3,
    Moderator: 4,
    Curator: 5,
    Admin: 6,
};

export const getRouteByRoles = (
    roles: UserRole[],
    rolePriorities: RolePriority,
    loginRedirectByRole: RedirectByRole,
): string => {
    const rolesLength = roles.length;

    let redirect = '';
    let priority = 0;
    for (let index = 0; index < rolesLength; index++) {
        const role = roles[index];
        const rolePriority = rolePriorities[role];
        if ((rolePriority || rolePriority === 0) && rolePriority >= priority) {
            priority = rolePriority;
            redirect = loginRedirectByRole[role];
        }
    }

    if (redirect) {
        return redirect;
    }

    return clientRoute.portfolios;
};

export const PersonStoreProps = {
    rootStore: observable,
    intlStore: observable,
    api: observable,
    user: observable,
    person: observable,
    roles: observable,
    rolePriorities: computed,
    loginRedirectByRole: computed,
    redirectPath: computed,
    getInfo: action.bound,
    clearPerson: action.bound,
};

export class PersonStore {
    private rootStore: RootStore;
    private intlStore: IntlStore;
    private api: ApiStore;

    user: UserModel = new UserModel();
    person: PersonModel = new PersonModel();
    roles: UserRole[] = [];

    constructor(rootStore: RootStore) {
        makeObservable(this, PersonStoreProps);
        this.rootStore = rootStore;
        this.intlStore = rootStore.intlStore;
        this.api = rootStore.api;
    }

    get rolePriorities(): RolePriority {
        return rolePriorities;
    }

    get loginRedirectByRole(): RedirectByRole {
        return loginRedirectByRole;
    }

    get redirectPath(): string {
        return getRouteByRoles(this.roles, this.rolePriorities, this.loginRedirectByRole);
    }

    async getInfo(): Promise<void> {
        const response: AxiosResponse<PersonInfo> = await this.api.client(apiConfigs.person(this.intlStore.locale));
        const info: PersonInfo = response.data;
        this.user = info.user;
        this.person.load(info.person);
        this.roles = info.roles;
    }

    clearPerson(): void {
        this.user = new UserModel();
        this.person = new PersonModel();
        this.roles = [];
    }
}

export const getPersonStore = (): any => {
    const [_PersonStore] = di([PersonStore], getPersonStore);
    return _PersonStore;
};
