import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

export const categoryMessages = defineMessages({
    create: {
        id: 'category.phrases.create',
        values: {
            category: <FormattedMessage id="category.single.accusative" />,
        },
    },
    createCopy: {
        id: 'category.phrases.createCopy',
        values: {
            category: <FormattedMessage id="category.single.genitive" />,
        },
    },
    copyCreated: {
        id: 'category.phrases.copyCreated',
        values: {
            category: <FormattedMessage id="category.single.genitive" />,
        },
    },
    tabSettingsList: {
        id: 'category.phrases.tabSettingsList',
        values: {
            category: <FormattedMessage id="category.plural.nominative" />,
        },
    },
});
