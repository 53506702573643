import { action, makeObservable, observable } from 'mobx';
import { FormFields } from '../types';

export const ServerErrorsModelProps = {
    serverFormErrors: observable,
    setServerFormErrors: action.bound,
    dropServerFormErrors: action.bound,
};

export class ServerErrorsModel<SettingsContentFields extends FormFields> {
    serverFormErrors: Partial<Record<SettingsContentFields, string>> = {};

    constructor() {
        makeObservable(this, ServerErrorsModelProps);
    }

    setServerFormErrors(errors: Record<SettingsContentFields, string>): void {
        Object.assign(this.serverFormErrors, errors);
    }

    dropServerFormErrors(): void {
        this.serverFormErrors = {};
    }
}
