import { action, computed, makeObservable, observable } from 'mobx';
import { IntlShape } from 'react-intl';
import { apiConfigs } from '../apiConfigs';
import { usersMessages } from '../resources';
import { ApiStore, IntlStore, PersonStore } from '../stores';
import { LoginDTO, LoginStatus } from '../types';

export const LoginModelProps = {
    api: observable,
    intlStore: observable,
    intl: observable,
    personStore: observable,
    loginStatus: observable,
    loginStatusCode: observable,
    loginFailedMessage: computed,
    loginSucceed: computed,
    login: action.bound,
};

export class LoginModel {
    api: ApiStore;
    intlStore: IntlStore;
    intl: IntlShape;
    personStore: PersonStore;

    loginStatus?: LoginStatus;
    loginStatusCode?: number;

    constructor(api: ApiStore, intlStore: IntlStore, personStore: PersonStore) {
        makeObservable(this, LoginModelProps);
        this.api = api;
        this.intlStore = intlStore;
        this.intl = intlStore.intl;
        this.personStore = personStore;
    }

    get loginFailedMessage(): string {
        switch (this.loginStatusCode) {
            case 401:
                return this.intl.formatMessage({ id: 'authentication.loginFailed' });
            case 403:
                return this.intl.formatMessage({ id: 'authentication.userBlocked' });
            case 404:
                return this.intl.formatMessage({ id: 'authentication.loginFailed' });
            case 405:
                return this.intl.formatMessage({ id: 'authentication.emailNotConfirmed' });
        }

        return '';
    }

    get loginSucceed(): boolean {
        return this.loginStatus === LoginStatus.success;
    }

    async login(loginData: LoginDTO): Promise<void> {
        try {
            await this.api.client(apiConfigs.login(loginData));
            await this.api.authVerify();
            await this.personStore.getInfo();
            this.loginStatus = LoginStatus.success;
        } catch (e: any) {
            const status = e?.response?.status;

            this.loginStatus = LoginStatus.failed;
            this.loginStatusCode = status;
        }
    }
}
