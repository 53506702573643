import { Delete, Edit } from '@mui/icons-material';
import { Box, IconButton, TableCell, TableRow, Tooltip } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { sxWidth30 } from '../../../../../constants';
import { TabAccessPermissionDTO } from '../../../../../types';

export type GeneralObjectTabAccessItemProps = {
    permissionData: TabAccessPermissionDTO;
    openEditModal: () => void;
    openDeleteModal: () => void;
};

const itemActionButtonsTableCellSx: SxProps<Theme> = { width: '10%', minWidth: '96px' };

export const GeneralObjectTabAccessItem = observer((props: GeneralObjectTabAccessItemProps): JSX.Element => {
    const { permissionData, openEditModal, openDeleteModal } = props;
    const intl = useIntl();
    const { roleCondition, whenObjectStateIsOneOf, permissions } = permissionData;
    const { editForm, filesUpload, filesEdit, filesDownload } = permissions;

    const states = useMemo(() => {
        if (!whenObjectStateIsOneOf.length) {
            return intl.formatMessage({ id: 'objectSettings.allStatuses' });
        }

        return whenObjectStateIsOneOf.map((state) => state.title).join(', ');
    }, [whenObjectStateIsOneOf]);

    return (
        <TableRow hover>
            <TableCell sx={sxWidth30}>{roleCondition.title}</TableCell>
            <TableCell sx={sxWidth30}>{states}</TableCell>
            <TableCell sx={sxWidth30}>
                {filesDownload && (
                    <Box>
                        <FormattedMessage id="objectSettings.accessFields.filesDownload" />
                        {', '}
                    </Box>
                )}
                {editForm && (
                    <Box>
                        <FormattedMessage id="objectSettings.accessFields.editForm" />
                        {', '}
                    </Box>
                )}
                {filesUpload && (
                    <Box>
                        <FormattedMessage id="objectSettings.accessFields.filesUpload" />
                        {', '}
                    </Box>
                )}
                {filesEdit && (
                    <Box>
                        <FormattedMessage id="objectSettings.accessFields.filesEdit" />
                        {', '}
                    </Box>
                )}
                <Box>
                    <FormattedMessage id="objectSettings.view" />
                </Box>
            </TableCell>
            <TableCell align="right" sx={itemActionButtonsTableCellSx}>
                <Tooltip title={<FormattedMessage id="common.edit" />}>
                    <IconButton size="small" onClick={openEditModal}>
                        <Edit />
                    </IconButton>
                </Tooltip>
                <Tooltip title={<FormattedMessage id="common.delete" />}>
                    <IconButton size="small" onClick={openDeleteModal}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
});
