import {
    FormDTO,
    FormioSidebarStore,
    FullSubmission,
    FullSubmissionWithAdditionalInfo,
} from '@platform/formiojs-react';
import { TitlesDTO } from '@platform/multi-lang-field';
import { History } from 'history';
import { action, makeObservable, observable } from 'mobx';
import { generatePath } from 'react-router-dom';
import { entities, permissions } from '../authSchemeConfig';
import { defaultLocale } from '../constants';
import { AuthorizationStore, IntlStore, RootStore } from '../stores';
import {
    CategoryFormDTO,
    ChartSettingItemDTO,
    GeneralObject,
    GeneralObjectField,
    GeneralObjectRoutes,
    GeneralObjectStore,
    GeneralObjectTab,
    IdTitle,
    ResponsibleUsersByRole,
    SettingsContentDTO,
    SettingsContentListDTO,
    UserRole,
} from '../types';
import { GeneralContentErrorsModel } from './GeneralContentErrorsModel';
import { PrintFormListModel } from './print-form';

export const GeneralObjectModelProps = {
    rootStore: observable,
    intlStore: observable,
    formioSidebarStore: observable,
    history: observable,
    objectStore: observable,
    authorizationStore: observable,
    id: observable,
    title: observable,
    state: observable,
    isWithServiceDescription: observable,
    commonDescription: observable,
    serviceDescription: observable,
    tabs: observable,
    formDTO: observable,
    registry: observable,
    charts: observable,
    tabFormData: observable,
    printFormListModel: observable,
    routes: observable,
    isContentLoading: observable,
    isTabsLoading: observable,
    isWithoutAvailableTabs: observable,
    isInfoModalOpen: observable,
    objectContentErrorsModel: observable,
    processCode: observable,
    responsibleUsersByRole: observable,
    loadMainInfo: action.bound,
    load: action.bound,
    loadTabs: action.bound,
    loadIsWithServiceDescription: action.bound,
    loadServiceDescription: action.bound,
    loadContent: action.bound,
    loadTabChartSettings: action.bound,
    loadUsersByRole: action.bound,
    loadTitles: action.bound,
    saveTitles: action.bound,
    deleteObject: action.bound,
    saveForm: action.bound,
    closeInfoModal: action.bound,
    dropContentData: action.bound,
    setIsInfoModalOpen: action.bound,
    setTabs: action.bound,
    setFormDTO: action.bound,
    setProcessCode: action.bound,
    setRegistry: action.bound,
    setIsWithoutAvailableTabs: action.bound,
    setIsTabsLoading: action.bound,
    setIsContentLoading: action.bound,
    setIsRoleUsersLoading: action.bound,
    setRoleUsers: action.bound,
    setResponsibleUsersByRole: action.bound,
    setCharts: action.bound,
};

export const getDefaultResponsibleUsersByRole = (): ResponsibleUsersByRole =>
    Object.keys(UserRole).reduce((result, role) => {
        result[UserRole[role as keyof typeof UserRole]] = {
            isLoading: false,
            users: [],
        };
        return result;
    }, {} as ResponsibleUsersByRole);

export class GeneralObjectModel {
    private rootStore: RootStore;
    private intlStore: IntlStore;
    private formioSidebarStore: FormioSidebarStore;
    private history: History;
    private objectStore: GeneralObjectStore;
    private authorizationStore: AuthorizationStore;

    id: string;
    title = '';
    state?: string;
    isWithServiceDescription = false;
    commonDescription: GeneralObjectField[] = [];
    serviceDescription?: GeneralObjectField[];
    tabs: GeneralObjectTab = [];
    formDTO?: FormDTO;
    registry?: SettingsContentListDTO;
    tabFormData?: SettingsContentDTO['form'];
    charts: ChartSettingItemDTO[] = [];
    printFormListModel: PrintFormListModel;
    routes: GeneralObjectRoutes;
    isContentLoading = true;
    isTabsLoading = true;
    isWithoutAvailableTabs = false;
    isInfoModalOpen = false;
    objectContentErrorsModel: GeneralContentErrorsModel;
    processCode = '';
    responsibleUsersByRole: ResponsibleUsersByRole;

    constructor(id: string, routes: GeneralObjectRoutes, rootStore: RootStore, objectStore: GeneralObjectStore) {
        makeObservable(this, GeneralObjectModelProps);
        this.id = id;
        this.rootStore = rootStore;
        this.intlStore = rootStore.intlStore;
        this.formioSidebarStore = rootStore.formioSidebarStore;
        this.history = rootStore.history;
        this.authorizationStore = rootStore.authorizationStore;
        this.objectStore = objectStore;
        this.routes = routes;
        this.printFormListModel = new PrintFormListModel(objectStore);
        this.objectContentErrorsModel = new GeneralContentErrorsModel(rootStore, objectStore);
        this.responsibleUsersByRole = getDefaultResponsibleUsersByRole();
        this.loadIsWithServiceDescription();
    }

    async loadMainInfo(currentTabId?: string): Promise<void> {
        this.load();
        if (this.objectStore.isWithTabs) {
            this.loadTabs(currentTabId);
        }
    }

    async load(): Promise<void> {
        const dto = await this.objectStore.loadCard(this.id);

        const { title, commonDescription, state } = dto;

        this.title = title;
        this.state = state;
        this.commonDescription = commonDescription;

        this.loadServiceDescription();
    }

    async loadTabs(currentTabId?: string): Promise<void> {
        this.setIsTabsLoading(true);

        try {
            const tabs = await this.objectStore.loadTabs(this.id);
            this.setTabs(tabs);
            this.setIsWithoutAvailableTabs(false);
            const tabId = tabs[0]?.id;
            if (!currentTabId) {
                this.history.replace(generatePath(this.routes.tab, { id: this.id, tabId }));
            }
        } catch (e: any) {
            this.setIsWithoutAvailableTabs(e.response.status === 403);
        }
    }

    async loadIsWithServiceDescription(): Promise<void> {
        this.isWithServiceDescription = await this.authorizationStore.check({
            entityCode: entities.system,
            permCode: permissions.system.Administration,
        });
    }

    async loadServiceDescription(): Promise<void> {
        if (this.isWithServiceDescription) {
            this.serviceDescription = await this.objectStore.loadServiceDescription(this.id);
        }
    }

    loadTabChartSettings(tabId: string): Promise<void> {
        return this.objectStore.getTabChartSettings(tabId).then(this.setCharts);
    }

    async loadContent(tabId: string): Promise<void> {
        const { isWithTabs, isWithChartContent, loadCurrentTabContent } = this.objectStore;
        try {
            this.setIsContentLoading(true);
            const tabContent = await loadCurrentTabContent(this.id, tabId);
            const { formInfo, content } = tabContent;

            this.setFormDTO(formInfo);
            formInfo?.submission && this.setProcessCode(formInfo?.submission, true);

            if (isWithTabs) {
                const { list, form } = content;
                this.setRegistry(list);
                this.setTabFormData(form);

                if (isWithChartContent) {
                    await this.loadTabChartSettings(tabId);
                }
            }
        } catch (error) {
            this.dropContentData();
        } finally {
            isWithTabs && this.printFormListModel.setTabId(tabId);
            this.setIsContentLoading(false);
        }
    }

    async loadUsersByRole(role: UserRole): Promise<void> {
        if (!this.responsibleUsersByRole[role].isLoading) {
            this.setIsRoleUsersLoading(role, true);
            const activeRoleUsers = await this.rootStore.catalogStore.getSelectActiveUsersByRole(role);
            this.setRoleUsers(role, activeRoleUsers);
        }
    }

    loadTitles(): Promise<TitlesDTO> {
        return this.objectStore.loadTitles(this.id);
    }

    saveTitles(dto: TitlesDTO): Promise<void> {
        return this.objectStore.saveTitles(this.id, dto).then(this.load);
    }

    deleteObject(): Promise<void> {
        return this.objectStore.deleteObject(this.id).then(() => this.history.push(this.routes.root));
    }

    saveForm(tabId: string, submissionData: FullSubmissionWithAdditionalInfo): Promise<void> {
        return this.objectStore.saveForm(this.id, tabId, submissionData).then(() => {
            this.load();
            this.setProcessCode(submissionData.submission);
        });
    }

    closeInfoModal(): void {
        this.setIsInfoModalOpen(false);
    }

    dropContentData(): void {
        this.setFormDTO(undefined);

        if (this.objectStore.isWithTabs) {
            this.setRegistry(null);
            this.setTabFormData(null);
        }
    }

    setIsInfoModalOpen(isOpen: boolean): void {
        this.isInfoModalOpen = isOpen;
    }

    setTabs(tabs: GeneralObjectTab): void {
        this.tabs = tabs;
    }

    setFormDTO(form?: FormDTO): void {
        this.formDTO = form ? form : undefined;
    }

    setProcessCode(submission: FullSubmission, isFirstSet = false): void {
        if (this.objectStore.objectType === GeneralObject.category) {
            const formData = submission[defaultLocale].data as CategoryFormDTO;
            const processCode = formData?.childObjectProcessCode || formData?.projectProcessCode;

            !isFirstSet && this.processCode && this.setIsInfoModalOpen(this.processCode !== processCode);

            this.processCode = processCode;
        }
    }

    setRegistry(registry: SettingsContentListDTO | null): void {
        this.registry = registry || undefined;
    }

    setTabFormData(formData: SettingsContentDTO['form']): void {
        this.tabFormData = formData || undefined;
    }

    setCharts(charts: ChartSettingItemDTO[]): void {
        this.charts = charts;
    }

    setIsWithoutAvailableTabs(isWithoutAvailableTabs: boolean): void {
        this.isWithoutAvailableTabs = isWithoutAvailableTabs;
        this.setIsTabsLoading(false);
    }

    setIsTabsLoading(isLoading: boolean): void {
        this.isTabsLoading = isLoading;
    }

    setIsContentLoading(isLoading: boolean): void {
        this.isContentLoading = isLoading;
    }

    setIsRoleUsersLoading(role: UserRole, isLoading: boolean): void {
        this.responsibleUsersByRole[role].isLoading = isLoading;
    }

    setRoleUsers(role: UserRole, users: IdTitle[]): void {
        this.responsibleUsersByRole[role].users = users;
    }

    setResponsibleUsersByRole(value: ResponsibleUsersByRole): void {
        this.responsibleUsersByRole = value;
    }
}
