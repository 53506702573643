import { action, makeObservable, observable } from 'mobx';
import { RootStore } from '../stores';
import { ContentErrorDTO, ErrorCode, ErrorsByTabs, GeneralObjectStore } from '../types';
import { ContentErrorsModel } from './ContentErrorsModel';

export const GeneralContentErrorsModelProps = {
    rootStore: observable,
    objectStore: observable,
    errorsByTabs: observable,
    shouldValidateFormAfterOpening: action.bound,
    lifeCycleTransitionErrorHandler: action.bound,
    setErrorsByTabs: action.bound,
};

const getDefaultErrorsByTabs = (): ErrorsByTabs => ({ withoutTab: new ContentErrorsModel() });

export class GeneralContentErrorsModel {
    private rootStore: RootStore;
    private objectStore: GeneralObjectStore;

    errorsByTabs: ErrorsByTabs = getDefaultErrorsByTabs();

    constructor(rootStore: RootStore, objectStore: GeneralObjectStore) {
        makeObservable(this, GeneralContentErrorsModelProps);
        this.rootStore = rootStore;
        this.objectStore = objectStore;
    }

    shouldValidateFormAfterOpening(isReadOnlyForm: boolean, isWithTabs: boolean, tabId: string): boolean {
        const tabErrors = this.errorsByTabs[tabId];
        const withoutTabErrors = this.errorsByTabs.withoutTab.errors.length;
        return !!((tabErrors && !isReadOnlyForm) || (!isWithTabs && withoutTabErrors));
    }

    lifeCycleTransitionErrorHandler(error: any, openErrorsDialog: () => void): Promise<void> {
        const responseErrorData: string | ContentErrorDTO[] | undefined = error?.response && error.response.data;
        const responseStatus: number = error?.response.status;
        if (responseStatus === ErrorCode.badRequest && Array.isArray(responseErrorData)) {
            this.setErrorsByTabs(responseErrorData);
            openErrorsDialog();
        }
        return Promise.reject(error);
    }

    setErrorsByTabs(errors: ContentErrorDTO[]): void {
        this.errorsByTabs = errors.reduce((resultErrors, currentErrorDTO) => {
            const { tabId } = currentErrorDTO;

            if (tabId) {
                if (!resultErrors[tabId]) {
                    resultErrors[tabId] = new ContentErrorsModel();
                }

                resultErrors[tabId].addError(currentErrorDTO);
            } else {
                resultErrors.withoutTab.addError(currentErrorDTO);
            }

            return resultErrors;
        }, getDefaultErrorsByTabs());
    }
}
