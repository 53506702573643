import downloadFile from 'js-file-download';
import { action, makeObservable, observable } from 'mobx';
import { tabSize } from '../constants';
import { RootStore } from '../stores';
import { ConsoleStore } from '../stores/ConsoleStore';

export const ConsoleModelProps = {
    rootStore: observable,
    consoleStore: observable,
    code: observable,
    result: observable,
    error: observable,
    runScript: action.bound,
    copyResult: action.bound,
    clearResult: action.bound,
    setCode: action.bound,
    setResult: action.bound,
    setError: action.bound,
};

export class ConsoleModel {
    private rootStore: RootStore;
    private consoleStore: ConsoleStore;

    code = '';
    result = '';
    error = '';

    constructor(rootStore: RootStore) {
        makeObservable(this, ConsoleModelProps);
        this.rootStore = rootStore;
        this.consoleStore = rootStore.consoleStore;
    }

    runScript(): Promise<void> {
        this.clearResult();

        return this.consoleStore
            .runScript(this.code)
            .then((result) => {
                const { blob, isFile, fileName } = result;

                if (isFile) {
                    downloadFile(blob, fileName);
                    return;
                }

                blob.text().then((text: string) => {
                    this.setResult(text);
                });
            })
            .catch((error) => {
                const errorBlob = error.response.data;
                errorBlob.text().then((errors: any) => {
                    const errorsParse = JSON.parse(errors);
                    this.setError(JSON.stringify(errorsParse, null, tabSize));
                });
            });
    }

    copyResult(): void {
        navigator.clipboard.writeText(this.error || this.result);
    }

    clearResult(): void {
        this.result = '';
        this.error = '';
    }

    setCode(code: string): void {
        this.code = code;
    }

    setResult(result: string): void {
        this.result = result;
    }

    setError(error: string): void {
        this.error = error;
    }
}
