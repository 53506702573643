import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

export const sxWidth10: SxProps<Theme> = { width: '10%' };
export const sxWidth15: SxProps<Theme> = { width: '15%' };
export const sxWidth20: SxProps<Theme> = { width: '20%' };
export const sxWidth25: SxProps<Theme> = { width: '25%' };
export const sxWidth30: SxProps<Theme> = { width: '30%' };
export const sxWidth35: SxProps<Theme> = { width: '35%' };
export const sxWidth40: SxProps<Theme> = { width: '40%' };
export const sxWidth45: SxProps<Theme> = { width: '45%' };

export const contactSx: SxProps = {
    '&::first-letter': {
        textTransform: 'lowercase !important',
    },
};
