import { Button, Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useFlag, useStore } from '../../../../../hooks';
import { GeneralObjectSystemEntityAccessModel } from '../../../../../models';
import { SystemEntityPermissionFormValues, SystemEntityPermissionSettingsModelType } from '../../../../../types';
import { ConfirmationDialog, ConfirmationDialog as ConfirmationDialogInj } from '../../../../ConfirmationDialog';
import { GeneralObjectSystemEntityAccessSettingsModal as GeneralObjectSystemEntityAccessSettingsModalInj } from './GeneralObjectSystemEntityAccessSettingsModal';
import { GeneralObjectSystemEntityAccessSettingsPermissionsTable as GeneralObjectSystemEntityAccessSettingsPermissionsTableInj } from './GeneralObjectSystemEntityAccessSettingsPermissionsTable';

export type GeneralObjectSystemEntityAccessSettingsProps<
    PermissionSettingsModel extends SystemEntityPermissionSettingsModelType,
    FormValues extends SystemEntityPermissionFormValues,
> = {
    onAddRuleClick?: () => void;
    accessModel: GeneralObjectSystemEntityAccessModel<PermissionSettingsModel, FormValues>;
    AdditionalFields?: React.ElementType;
    renderPermissionTableHeader: () => React.ReactNode;
    renderPermissionTableBody: (
        openEditModal: (permissionIndex: number) => () => void,
        openDeleteModal: (permissionIndex: number) => () => void,
    ) => () => React.ReactNode;
};

export const GeneralObjectSystemEntityAccessSettings = observer(
    <
        PermissionSettingsModel extends SystemEntityPermissionSettingsModelType,
        FormValues extends SystemEntityPermissionFormValues,
    >(
        props: GeneralObjectSystemEntityAccessSettingsProps<PermissionSettingsModel, FormValues>,
    ): JSX.Element => {
        const [ConfirmationDialog] = di([ConfirmationDialogInj], GeneralObjectSystemEntityAccessSettings);
        const [GeneralObjectSystemEntityAccessSettingsModal] = di(
            [GeneralObjectSystemEntityAccessSettingsModalInj],
            GeneralObjectSystemEntityAccessSettings,
        );
        const [GeneralObjectSystemEntityAccessSettingsPermissionsTable] = di(
            [GeneralObjectSystemEntityAccessSettingsPermissionsTableInj],
            GeneralObjectSystemEntityAccessSettings,
        );

        const {
            onAddRuleClick,
            accessModel,
            AdditionalFields,
            renderPermissionTableHeader,
            renderPermissionTableBody,
        } = props;
        const { setSelectedPermissionIndex, permissionSettingsModel } = accessModel;
        const { deletePermission } = permissionSettingsModel;
        const rootStore = useStore();
        const { intlStore } = rootStore;

        const [isAddModalOpen, openAddModal, closeAddModal] = useFlag();
        const [isDeleteModalOpen, openDeleteModal, closeDeleteModal] = useFlag();
        const [selectedDeleteIndex, setSelectedDeleteIndex] = useState<number>(0);
        const intl = useIntl();

        useEffect(() => {
            permissionSettingsModel.load();
        }, [intlStore.locale]);

        const onAddModalClose = (): void => {
            setSelectedPermissionIndex(null);
            closeAddModal();
        };

        const onAddEmptyModalOpen = (): void => {
            openAddModal();
            setSelectedPermissionIndex(null);
        };

        const onFilledAddModalOpen =
            (permissionIndex: number): (() => void) =>
            (): void => {
                openAddModal();
                setSelectedPermissionIndex(permissionIndex);
            };

        const onDeletePermissionModalOpen =
            (permissionIndex: number): (() => void) =>
            (): void => {
                openDeleteModal();
                setSelectedDeleteIndex(permissionIndex);
            };

        const onDeleteConfirm = (): Promise<void> => {
            return deletePermission(selectedDeleteIndex).then(closeDeleteModal);
        };

        return (
            <React.Fragment>
                <ConfirmationDialog
                    id="delete"
                    isOpen={isDeleteModalOpen}
                    title={intl.formatMessage({ id: 'common.confirmDeletion' })}
                    onConfirm={onDeleteConfirm}
                    setIsClosed={closeDeleteModal}
                    keepMounted
                />
                <GeneralObjectSystemEntityAccessSettingsModal
                    setIsClosed={onAddModalClose}
                    isOpen={isAddModalOpen}
                    accessModel={accessModel}
                    AdditionalFields={AdditionalFields}
                />
                <Grid container direction="column" spacing={4}>
                    <Grid item container justifyContent="flex-end">
                        <Button variant="contained" onClick={onAddRuleClick || onAddEmptyModalOpen}>
                            <FormattedMessage id="objectSettings.addRule" />
                        </Button>
                    </Grid>
                    <Grid item>
                        <GeneralObjectSystemEntityAccessSettingsPermissionsTable
                            renderPermissionTableHeader={renderPermissionTableHeader}
                            renderPermissionTableBody={renderPermissionTableBody(
                                onFilledAddModalOpen,
                                onDeletePermissionModalOpen,
                            )}
                        />
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    },
);
