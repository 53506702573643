import { Tab } from '@mui/material';
import { styled } from '@mui/material/styles';

export const InfoTab = styled(Tab)(
    ({ theme }) => `
    font-weight: 400;
    min-height: auto;
    height: 38px;
`,
);
