import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import {
    FieldValues as LangTitle,
    MultiLangField,
    titlesDTOtoTitles,
    titlesYupSchema,
} from '@platform/multi-lang-field';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { ServerErrorHelper as ServerErrorHelperInj } from '../../components';
import { useAntiDoubleClick, useStore, useYup } from '../../hooks';
import { CreateCategoryCopyModel } from '../../models';
import { categoryMessages } from '../../resources';
import { CreateCategoryCopyFields, ModalProps } from '../../types';
import { disableSubmitOnEnterKeyPress } from '../../utils';

export type CreateCategoryCopyDialogValues = {
    titles: LangTitle[];
};

export type CreateCategoryCopyDialogProps = ModalProps & {
    prototypeCategoryId: string;
};

export const CreateCategoryCopyDialog = observer((props: CreateCategoryCopyDialogProps): JSX.Element => {
    const [ServerErrorHelper] = di([ServerErrorHelperInj], CreateCategoryCopyDialog);

    const { isOpen, setIsClosed, prototypeCategoryId } = props;
    const rootStore = useStore();
    const intl = useIntl();

    const createCategoryCopyModel = useMemo(
        () => new CreateCategoryCopyModel(rootStore, prototypeCategoryId),
        [prototypeCategoryId],
    );
    const { serverErrors, prototypeCategoryTitles, loadPrototypeCategoryTitles, createCategoryCopy } =
        createCategoryCopyModel;
    const { dropServerFormErrors, serverFormErrors } = serverErrors;
    const { titles } = CreateCategoryCopyFields;
    const serverTitlesError = serverFormErrors[titles];

    useEffect(() => {
        loadPrototypeCategoryTitles(isOpen);
    }, [isOpen, prototypeCategoryId]);

    const initialValues: CreateCategoryCopyDialogValues = {
        titles: titlesDTOtoTitles(prototypeCategoryTitles),
    };

    const onClose = (): void => {
        setIsClosed();
        dropServerFormErrors();
    };

    const { Yup } = useYup();
    const validationSchema = Yup.object({
        titles: titlesYupSchema(Yup, true).min(1),
    });

    const [isSending, endIcon, createHandler] = useAntiDoubleClick(createCategoryCopy);

    return (
        <Dialog fullWidth={true} maxWidth="sm" open={isOpen}>
            <DialogTitle>
                <FormattedMessage {...categoryMessages.createCopy} />
            </DialogTitle>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={createHandler}
            >
                <Form onKeyDown={disableSubmitOnEnterKeyPress}>
                    <DialogContent>
                        <MultiLangField systemLanguage={intl.locale} fieldName={titles} ruEngLangs={true} />
                        <ServerErrorHelper serverError={serverTitlesError} />
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={onClose} disabled={isSending}>
                            <FormattedMessage id="common.cancel" />
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={isSending}
                            endIcon={endIcon}
                        >
                            <FormattedMessage id="common.save" />
                        </Button>
                    </DialogActions>
                </Form>
            </Formik>
        </Dialog>
    );
});
