import { makeObservable } from 'mobx';
import { AnySchema } from 'yup';
import { RootStore } from '../../stores';
import { GeneralObjectPermissionTrigger, TabAccessPermissionDTO, TabAccessPermissionValues } from '../../types';
import { TabPermissionSettingsModel } from '../TabPermissionSettingsModel';
import {
    GeneralObjectSystemEntityAccessModel,
    GeneralObjectSystemEntityAccessModelProps,
} from './GeneralObjectSystemEntityAccessModel';

export const GeneralObjectTabAccessModelProps = {
    ...GeneralObjectSystemEntityAccessModelProps,
};

const emptyInitialValues: TabAccessPermissionValues = {
    roleCondition: {
        code: '',
        title: '',
        group: GeneralObjectPermissionTrigger.role,
    },
    whenObjectStateIsOneOf: [],
    permissions: {
        filesDownload: true,
        edit: false,
        editForm: false,
        filesManagement: false,
        filesUpload: false,
        filesEdit: false,
    },
};

export class GeneralObjectTabAccessModel extends GeneralObjectSystemEntityAccessModel<
    TabPermissionSettingsModel,
    TabAccessPermissionValues
> {
    constructor(
        rootStore: RootStore,
        settingsModel: TabPermissionSettingsModel,
        additionalFieldsValidationSchema: Record<string, AnySchema>,
    ) {
        super(rootStore, settingsModel, additionalFieldsValidationSchema);
        makeObservable(this, GeneralObjectTabAccessModelProps);
    }

    get initialValues(): TabAccessPermissionValues {
        if (this.selectedPermissionIndex !== null) {
            return this.permissionSettingsModel.getAccessPermissionValuesFromAccessPermissionDTO(
                this.permissionSettingsModel.permissions[this.selectedPermissionIndex],
            );
        }
        return emptyInitialValues;
    }

    onSubmit(values: TabAccessPermissionValues): Promise<void> {
        const permissions = values.permissions;
        const dto: TabAccessPermissionDTO = {
            ...values,
            permissions: {
                filesDownload: permissions.filesDownload,
                filesUpload: permissions.filesUpload,
                filesEdit: permissions.filesEdit,
                editForm: permissions.editForm,
            },
        };
        return this.permissionSettingsModel.updatePermissions(dto, this.selectedPermissionIndex);
    }
}
