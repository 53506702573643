import { MessageDescriptor } from '@formatjs/intl';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { UserStatusAction } from '../../../types';

export const usersMessages = defineMessages({
    lang: {
        id: 'user.phrases.lang',
        values: {
            user: <FormattedMessage id="user.single.nominative" />,
        },
    },
    loginAsUser: {
        id: 'user.phrases.loginAsUser',
        values: {
            user: <FormattedMessage id="user.single.instrumental" />,
        },
    },
    editUser: {
        id: 'user.phrases.editUser',
        values: {
            user: <FormattedMessage id="user.single.accusative" />,
        },
    },
    deleteUser: {
        id: 'user.phrases.deleteUser',
        values: {
            user: <FormattedMessage id="user.single.accusative" />,
        },
    },
    deleteUserConfirmText: {
        id: 'user.phrases.deleteUserConfirmText',
        values: {
            user: <FormattedMessage id="user.single.nominative" />,
        },
    },
    profile: {
        id: 'user.phrases.profile',
        values: {
            user: <FormattedMessage id="user.single.accusative" />,
        },
    },
    selectUser: {
        id: 'user.phrases.selectUser',
        values: {
            user: <FormattedMessage id="user.single.accusative" />,
        },
    },
    selectUsers: {
        id: 'user.phrases.selectUsers',
        values: {
            user: <FormattedMessage id="user.plural.accusative" />,
        },
    },
});

export const usersConfirmStatusMessages: Record<UserStatusAction, MessageDescriptor> = defineMessages({
    block: {
        id: 'user.phrases.confirmStatusBlock',
        values: {
            user: <FormattedMessage id="user.single.accusative" />,
        },
    },
    activate: {
        id: 'user.phrases.confirmStatusActivate',
        values: {
            user: <FormattedMessage id="user.single.accusative" />,
        },
    },
    unblock: {
        id: 'user.phrases.confirmStatusUnblock',
        values: {
            user: <FormattedMessage id="user.single.accusative" />,
        },
    },
});

export const usersConfirmStatusTextMessages: Record<UserStatusAction, MessageDescriptor> = defineMessages({
    block: {
        id: 'user.phrases.confirmStatusTextBlock',
        values: {
            user: <FormattedMessage id="user.single.accusative" />,
        },
    },
    activate: {
        id: 'user.phrases.confirmStatusTextActivate',
        values: {
            user: <FormattedMessage id="user.single.accusative" />,
        },
    },
    unblock: {
        id: 'user.phrases.confirmStatusTextUnblock',
        values: {
            user: <FormattedMessage id="user.single.accusative" />,
        },
    },
});
