import { observer } from 'mobx-react';
import React, { ReactNode, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { entities, permissions } from '../../../authSchemeConfig';
import {
    AuthorizationCheck,
    GeneralObjectDeleteBtn as GeneralObjectDeleteBtnInj,
    MenuButton as MenuButtonInj,
    renderMenuButton as renderMenuButtonInj,
} from '../../../components';
import { useStore } from '../../../hooks';
import { DotMenu } from '../../../resources';
import { PortfolioTableRow } from '../../../types';

export type PortfolioRegistryActionsBtnsProps = {
    portfolioRow: PortfolioTableRow;
    reloadData: () => void;
    toggleIsRowBlocked: () => void;
    rowErrorHandler: () => void;
};

export const PortfolioRegistryActionsBtns = observer((props: PortfolioRegistryActionsBtnsProps): JSX.Element => {
    const [MenuButton] = di([MenuButtonInj], PortfolioRegistryActionsBtns);
    const [GeneralObjectDeleteBtn] = di([GeneralObjectDeleteBtnInj], PortfolioRegistryActionsBtns);
    const [renderMenuButton] = di([renderMenuButtonInj], PortfolioRegistryActionsBtns);

    const { portfolioRow, reloadData, toggleIsRowBlocked, rowErrorHandler } = props;
    const { id } = portfolioRow;

    const { portfolioStore, notificationStore } = useStore();
    const intl = useIntl();

    const handleDeletePortfolio = useCallback((): Promise<void> => {
        return portfolioStore.deleteObject(id, true).then(reloadData).catch(rowErrorHandler);
    }, [reloadData, rowErrorHandler, id, portfolioStore, notificationStore]);

    const renderActionItems = useCallback((): ((hideMenu?: () => void) => ReactNode[]) => {
        const renderItems = (hideMenu?: () => void): ReactNode[] => {
            const onDeleteClick = (): Promise<void> => {
                hideMenu && hideMenu();
                toggleIsRowBlocked();
                return handleDeletePortfolio();
            };

            return [
                <React.Fragment key="actionBtns">
                    <GeneralObjectDeleteBtn
                        object={intl.formatMessage({ id: 'portfolio.single.accusative' })}
                        title={portfolioRow.customData.title.title}
                        onDeleteClick={onDeleteClick}
                    />
                </React.Fragment>,
            ];
        };
        return renderItems;
    }, [portfolioRow, toggleIsRowBlocked]);

    return (
        <AuthorizationCheck entityCode={entities.portfolio} permCode={permissions.portfolio.Delete} entityId={id}>
            <MenuButton renderButton={renderMenuButton(<DotMenu />)} renderMenuItems={renderActionItems()} />
        </AuthorizationCheck>
    );
});
