import { createContext } from 'react';
import { GeneralObjectModel } from '../models';
import { GeneralObjectCardContextType } from '../types';

export const GeneralObjectCardContextDefault: GeneralObjectCardContextType = {
    objectModel: {} as GeneralObjectModel,
    updateStickyElementsHeight: () => {},
};

export const GeneralObjectCardContext = createContext<GeneralObjectCardContextType>(GeneralObjectCardContextDefault);
