import { Link, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import React from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';
import { di } from 'react-magnetic-di';
import { NavLink } from 'react-router-dom';
import { GeneralObjectFieldValue, RawValueType } from '../../../../types';
import { GeneralObjectUserContactInformationFieldValue as GeneralObjectUserContactInformationFieldValueInj } from './general-object-user-contact-information';

export type GeneralObjectDescriptionFormattedFieldValueProps = {
    value?: GeneralObjectFieldValue;
    rawValue?: RawValueType;
    isDate?: boolean;
    isExternalUrl?: boolean;
    shouldSeparateArrayValuesWithComma?: boolean;
    withContactInformation?: boolean;
    valueIndex?: number;
};

const linkSx: SxProps<Theme> = { display: 'inline-block' };

export const GeneralObjectDescriptionFormattedFieldValue = (
    props: GeneralObjectDescriptionFormattedFieldValueProps,
): JSX.Element => {
    const [GeneralObjectUserContactInformationFieldValue] = di(
        [GeneralObjectUserContactInformationFieldValueInj],
        GeneralObjectDescriptionFormattedFieldValue,
    );
    const {
        value,
        rawValue,
        isDate,
        isExternalUrl,
        shouldSeparateArrayValuesWithComma,
        withContactInformation,
        valueIndex,
    } = props;

    if (isDate && value) {
        return (
            <React.Fragment>
                <FormattedDate value={value as string} />{' '}
                <FormattedTime value={value as string} hour="numeric" minute="numeric" second="numeric" />
            </React.Fragment>
        );
    }

    if (
        value &&
        withContactInformation &&
        valueIndex !== undefined &&
        Array.isArray(rawValue) &&
        rawValue[valueIndex]
    ) {
        const contactInformation = rawValue[valueIndex];
        const isLast = valueIndex == rawValue.length - 1;
        return (
            <GeneralObjectUserContactInformationFieldValue
                value={value}
                isShouldSeparateArrayValuesWithComma={shouldSeparateArrayValuesWithComma}
                isLast={isLast}
                responsibleInfo={contactInformation}
            />
        );
    }

    if (value && withContactInformation && rawValue && !Array.isArray(rawValue) && 'userId' in rawValue) {
        return (
            <GeneralObjectUserContactInformationFieldValue
                value={value}
                isShouldSeparateArrayValuesWithComma={shouldSeparateArrayValuesWithComma}
                responsibleInfo={rawValue}
            />
        );
    }

    if (typeof value === 'object') {
        const { url, label } = value;
        return isExternalUrl ? (
            <Link component="a" href={url} target="_blank" rel="noopener" sx={linkSx}>
                {label}
            </Link>
        ) : (
            <Link component={NavLink} to={url} sx={linkSx}>
                {label}
            </Link>
        );
    }

    return <React.Fragment>{value || '-'}</React.Fragment>;
};
