import { AppFonts, AppIcons, AppPalette, ThemeOverrides } from '../../types';

export type SkolkovoColorNames =
    | 'brand0'
    | 'brand25'
    | 'brand100'
    | 'brand500'
    | 'brand600'
    | 'brand700'
    | 'secondary0'
    | 'secondary300'
    | 'secondary600'
    | 'secondary700'
    | 'secondary800'
    | 'black'
    | 'blackLight'
    | 'white'
    | 'yellow'
    | 'yellowDark'
    | 'greyMain'
    | 'greyLight'
    | 'greyDark'
    | 'greyWhite'
    | 'greyLighter'
    | 'greyMiddleLight'
    | 'greyMiddleLighter'
    | 'red'
    | 'green'
    | 'blue'
    | 'lighterBlue'
    | 'lighterGrey'
    | 'disabled'
    | 'blackLighter'
    | 'whiteGrey';

const skolkovoColors: Record<SkolkovoColorNames, string> = {
    brand0: '#282D2E',
    brand25: '#F1FFDA',
    brand100: '#E3FFB5',
    brand500: '#B1EC52',
    brand600: '#98D635',
    brand700: '#74BC00',
    secondary0: '#FFFFFF',
    secondary300: '#D2D2D2',
    secondary600: '#4D5759',
    secondary700: '#394042',
    secondary800: '#2F3536',
    black: '#000000',
    blackLight: '#1c1d1f',
    blackLighter: '#252629',
    white: '#ffffff',
    whiteGrey: '#ececec',
    yellow: '#ffff33',
    yellowDark: '#f5c722',
    greyMain: '#63666a',
    greyLight: '#85898f',
    greyDark: '#2c302e',
    greyWhite: '#f1f1f1',
    greyLighter: '#fafafa',
    greyMiddleLight: '#ccd3db',
    greyMiddleLighter: '#eff0f2',
    red: '#fb4d3d',
    green: '#008000',
    blue: '#1570EF',
    lighterBlue: 'rgba(53, 165, 255, 0.09)',
    lighterGrey: 'rgba(133, 147, 143, 0.09)',
    disabled: 'rgba(0, 0, 0, 0.12)',
};

const accentBackgroundGradient = skolkovoColors.brand500;

export const skolkovoPalette: AppPalette = {
    main: skolkovoColors.secondary600,
    textMain: skolkovoColors.brand0,
    textDark: skolkovoColors.brand0,
    linkMain: skolkovoColors.blue,
    mainMiddleLight: skolkovoColors.greyMiddleLight,
    transparentLight: skolkovoColors.greyMiddleLighter,
    mainLight: skolkovoColors.greyLight,
    mainDark: skolkovoColors.brand500,
    mainContrast: skolkovoColors.white,
    mainContrastDarker: skolkovoColors.greyLighter,
    accent: skolkovoColors.yellow,
    accentDark: skolkovoColors.brand500,
    accentContrast: skolkovoColors.brand0,
    accentBackgroundGradient,
    hover: skolkovoColors.brand100,
    hoverInLists: skolkovoColors.greyWhite,
    red: skolkovoColors.red,
    green: skolkovoColors.green,
    campaignList: {
        backgroundCard: skolkovoColors.brand100,
        selectedPaginationButton: skolkovoColors.lighterGrey,
    },
    table: {
        row: {
            even: skolkovoColors.white,
            odd: skolkovoColors.greyLighter,
        },
    },
    card: {
        header: {
            main: skolkovoColors.blackLighter,
        },
    },
    appBar: {
        mainContrast: skolkovoColors.brand700,
    },
    tooltip: {
        backgroundColor: skolkovoColors.blackLight,
    },
    button: {
        accentContrast: skolkovoColors.brand0,
        hoverBackground: skolkovoColors.brand600,
        disabled: skolkovoColors.brand100,
    },
    iconButton: {
        color: skolkovoColors.brand0,
        hoverColor: skolkovoColors.brand0,
        hoverBackgroundColor: skolkovoColors.brand600,
    },
    background: {
        textMain: skolkovoColors.black,
    },
    footer: {
        textMain: skolkovoColors.white,
        textHover: skolkovoColors.brand0,
        padding: 3.75,
    },
    tab: {
        backgroundColor: skolkovoColors.whiteGrey,
        color: skolkovoColors.greyMain,
        hoverBackgroundColor: skolkovoColors.greyMain,
        hoverColor: skolkovoColors.white,
    },
    panel: {
        markerBackgroundColor: skolkovoColors.brand100,
        markerColor: skolkovoColors.white,
    },
};

const gpngFonts: AppFonts = {
    mainFontAttrs: {
        fontSize: '13px',
        lineHeight: 1.69,
        letterSpacing: '0.2px',
    },
};

const skolkovoIcons: AppIcons = {
    fontFamily: 'glyphicons-theme',
    arrowIcon: {
        fontSize: 11,
        content: '"\\e90f"',
        width: 12,
        height: 7,
    },
};

export default {
    fonts: gpngFonts,
    palette: skolkovoPalette,
    icons: skolkovoIcons,
} as ThemeOverrides;
