import { LocalesTree } from '../../../types';

const validation: LocalesTree = {
    required: 'Обязательное поле',
    minLength: 'Минимальная длина {length, number} {length, plural, one {символ} few {символа} other {символов}}',
    maxLength: 'Максимальная длина {length, number} {length, plural, one {символ} few {символа} other {символов}}',
    dropzoneError: 'Файл не соответствует типу "doc" или "docx"',
    isNumber: 'Введите корректное число',
    bannedSymbols: 'В названии нельзя использовать символы: « {symbols} »',
    validEmail: 'Введите валидный email',
    uniqTabCode: 'Код должен быть уникален в рамках данной структуры вкладок',
    pattern: 'Допустимы только {symbols}',
    patternEnDigital: 'латинские буквы и цифры',
};

export default validation;
