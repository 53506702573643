var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { defaultFormsLocale } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { FormApi } from '../api/FormApi';
import { FormModel } from '../models/FormModel';
import { FormRaw } from './FormRaw';
import { MultiLangFormEdit } from './MultiLangFormEdit';
export var Form = observer(function (props) {
    var ownerEntityId = props.ownerEntityId, formDTO = props.formDTO, _a = props.lang, lang = _a === void 0 ? defaultFormsLocale : _a, _b = props.readOnly, readOnly = _b === void 0 ? false : _b, _c = props.multiLang, multiLang = _c === void 0 ? false : _c, intlStore = props.intlStore, formRawProps = __rest(props, ["ownerEntityId", "formDTO", "lang", "readOnly", "multiLang", "intlStore"]);
    var locale = intlStore.locale;
    var formModel = useMemo(function () { return new FormModel(ownerEntityId, formDTO); }, [ownerEntityId, formDTO]);
    var formApi = useMemo(function () {
        return new FormApi({
            lang: multiLang ? locale : lang,
            readOnly: readOnly,
            multiLang: multiLang,
        }, formModel);
    }, [formModel, lang, readOnly, multiLang]);
    return multiLang && !readOnly ? (React.createElement(MultiLangFormEdit, __assign({ intlStore: intlStore, formApi: formApi }, formRawProps))) : (React.createElement(FormRaw, __assign({ formApi: formApi, locale: locale }, formRawProps)));
});
