import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import {
    FieldValues as LangTitle,
    getRuTitleInitialValue,
    MultiLangField,
    titlesToTitlesDTO,
    titlesYupSchema,
} from '@platform/multi-lang-field';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useAntiDoubleClick, useYup } from '../../../../hooks';
import { ServerErrorsModel } from '../../../../models';
import {
    CreateDescendantFields,
    DescendantObjectCreateDTO,
    GeneralObjectWithDescendantObject,
    Identifier,
    IdTitle,
    IdTitleNull,
    ModalProps,
} from '../../../../types';
import { disableSubmitOnEnterKeyPress } from '../../../../utils';
import { AutocompleteField as AutocompleteFieldInj, IdentifierField as IdentifierFieldInj } from '../../../fields';
import { RequiredLabel as RequiredLabelInj } from '../../../RequiredLabel';
import { ServerErrorHelper as ServerErrorHelperInj } from '../../../ServerErrorHelper';

export type GeneralObjectDescendantModalProps = ModalProps & {
    descendantObject: GeneralObjectWithDescendantObject;
    identifier: Identifier;
    categories: IdTitle[];
    onSubmit: (descendantValues: DescendantObjectCreateDTO) => Promise<void>;
};

export type DescendantCreateDialogValues = {
    titles: LangTitle[];
    number: string;
    category: IdTitleNull;
};

export const GeneralObjectDescendantModal = observer((props: GeneralObjectDescendantModalProps): JSX.Element => {
    const [RequiredLabel] = di([RequiredLabelInj], GeneralObjectDescendantModal);
    const [AutocompleteField] = di([AutocompleteFieldInj], GeneralObjectDescendantModal);
    const [IdentifierField] = di([IdentifierFieldInj], GeneralObjectDescendantModal);
    const [ServerErrorHelper] = di([ServerErrorHelperInj], GeneralObjectDescendantModal);

    const { isOpen, setIsClosed, categories, descendantObject, identifier, onSubmit } = props;
    const serverErrors = useMemo(() => new ServerErrorsModel<CreateDescendantFields>(), []);
    const { dropServerFormErrors, serverFormErrors, setServerFormErrors } = serverErrors;
    const { titles, number, category } = CreateDescendantFields;
    const serverTitlesError = serverFormErrors[titles];
    const intl = useIntl();

    const onClose = (): void => {
        setIsClosed();
        dropServerFormErrors();
    };

    const initialValues: DescendantCreateDialogValues = {
        titles: getRuTitleInitialValue(),
        number: identifier.number,
        category: null,
    };

    const { Yup } = useYup();
    const validationSchema = Yup.object({
        titles: titlesYupSchema(Yup, true).min(1),
        number: Yup.string().required(),
        category: Yup.object().nullable().required(),
    });

    const handleSubmit = (values: DescendantCreateDialogValues): Promise<void> => {
        dropServerFormErrors();

        const titlesDTO = titlesToTitlesDTO(values.titles);
        const dto: DescendantObjectCreateDTO = {
            titles: titlesDTO,
            identifier: {
                prefix: identifier.prefix,
                number: values.number,
            },
            categoryId: (values.category as IdTitle).id,
        };

        return onSubmit(dto).catch(setServerFormErrors);
    };

    const [isSending, endIcon, createHandler] = useAntiDoubleClick(handleSubmit);

    const categoryLabel = <RequiredLabel text={intl.formatMessage({ id: 'category.single.nominative' })} />;

    return (
        <Dialog fullWidth={true} maxWidth="sm" open={isOpen}>
            <DialogTitle>
                <FormattedMessage
                    id="generalObject.newObject"
                    values={{
                        descendantObject: intl.formatMessage({ id: `${descendantObject}.single.nominative` }),
                    }}
                />
            </DialogTitle>

            <Formik
                initialValues={initialValues}
                onSubmit={createHandler}
                enableReinitialize={true}
                validationSchema={validationSchema}
            >
                <Form onKeyDown={disableSubmitOnEnterKeyPress}>
                    <DialogContent>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <MultiLangField systemLanguage={intl.locale} fieldName={titles} ruEngLangs={true} />
                                <ServerErrorHelper serverError={serverTitlesError} />
                            </Grid>
                            <Grid item>
                                <IdentifierField
                                    name={number}
                                    prefix={identifier.prefix}
                                    serverError={serverFormErrors[number]}
                                />
                            </Grid>
                            <Grid item>
                                <AutocompleteField
                                    fieldName={category}
                                    label={categoryLabel}
                                    options={categories}
                                    serverError={serverFormErrors[category]}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <Button color="primary" onClick={onClose}>
                            <FormattedMessage id="common.cancel" />
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={isSending}
                            endIcon={endIcon}
                        >
                            <FormattedMessage id="common.save" />
                        </Button>
                    </DialogActions>
                </Form>
            </Formik>
        </Dialog>
    );
});
