import { makeSxStyles, mergeSxStyles, SxStyle } from '@platform/front-ui';
import { mainFontSize, redesignTotPalette } from '../../../../theme';

export const generalObjectTabContentSxStyles = makeSxStyles({
    fullwidth: {
        maxWidth: '100% !important',
    },
});

export const generalObjectWithoutAvailableInfoSxStyles = makeSxStyles({
    title: {
        textAlign: 'center',
    },
    text: {
        textAlign: 'center',
        color: redesignTotPalette.typography.default.lightColor,
        '& a': {
            fontSize: mainFontSize,
        },
    },
});

export const generalObjectTabContextWrapperSx: SxStyle = mergeSxStyles(
    { padding: 5 },
    generalObjectTabContentSxStyles.fullwidth,
);
export const generalObjectTabContextInnerSx: SxStyle = mergeSxStyles(
    { marginBottom: 5 },
    generalObjectTabContentSxStyles.fullwidth,
);
