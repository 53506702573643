import { Box, Container, Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { registryPageTableSxStyles } from '../../components';
import { useServerTitleBreadcrumbs } from '../../hooks';
import { BreadcrumbsLocation, CategoryTableRow } from '../../types';
import {
    CategoryRegistryActionsBtns as CategoryRegistryActionsBtnsInj,
    CategoryRegistryCreateButton as CategoryRegistryCreateButtonInj,
    CategoryRegistryTable as CategoryRegistryTableInj,
} from './components';

export const CategoryRegistryPage = observer((): JSX.Element => {
    const [CategoryRegistryActionsBtns] = di([CategoryRegistryActionsBtnsInj], CategoryRegistryPage);
    const [CategoryRegistryTable] = di([CategoryRegistryTableInj], CategoryRegistryPage);
    const [CategoryRegistryCreateButton] = di([CategoryRegistryCreateButtonInj], CategoryRegistryPage);

    const [isConfirm, setIsConfirm] = useState<boolean>(false);
    useServerTitleBreadcrumbs({ location: BreadcrumbsLocation.category });

    const setRowActions = useCallback(
        (
            row: CategoryTableRow,
            reloadData: () => void,
            toggleIsRowBlocked: () => void,
            rowErrorHandler: () => void,
        ): JSX.Element => {
            return (
                <CategoryRegistryActionsBtns
                    categoryRow={row}
                    reloadData={reloadData}
                    isConfirm={isConfirm}
                    setIsConfirm={setIsConfirm}
                    toggleIsRowBlocked={toggleIsRowBlocked}
                    rowErrorHandler={rowErrorHandler}
                />
            );
        },
        [isConfirm],
    );

    return (
        <Container maxWidth={false}>
            <Box pt={5.5} pb={15}>
                <Grid container spacing={5}>
                    <Grid item container justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h1" component="h1">
                                <FormattedMessage id="category.plural.nominative" />
                            </Typography>
                        </Grid>
                        <Grid item>
                            <CategoryRegistryCreateButton />
                        </Grid>
                    </Grid>
                    <Grid item container direction="column">
                        <Grid item sx={registryPageTableSxStyles.tableWrapper}>
                            <CategoryRegistryTable setRowActions={setRowActions} />
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
});
