import { Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useFlag } from '../../../../../../hooks';
import { CreationInfoModel } from '../../../../../../models';
import { AuthorizationCheckQuery, CreateDescendantModalComponent } from '../../../../../../types';
import { AuthorizationCheckAll as AuthorizationCheckAllInj } from '../../../../../AuthorizationCheckAll';

type GeneralObjectRegistryDescendantCreateButtonProps = {
    permissionQueries: AuthorizationCheckQuery[];
    CreateDescendantDialog: CreateDescendantModalComponent;
};

export const GeneralObjectRegistryDescendantCreateButton = observer(
    (props: GeneralObjectRegistryDescendantCreateButtonProps): JSX.Element => {
        const [AuthorizationCheckAll] = di([AuthorizationCheckAllInj], GeneralObjectRegistryDescendantCreateButton);
        const creationInfoModel = useMemo(() => new CreationInfoModel(), []);
        const { isCreateButtonShown } = creationInfoModel;

        const { permissionQueries, CreateDescendantDialog } = props;
        const [isCreateDialogOpen, openCreateDialog, closeCreateDialog] = useFlag();

        return (
            <React.Fragment>
                {isCreateButtonShown && (
                    <AuthorizationCheckAll or={true} isAllRequired={true} queries={permissionQueries}>
                        <Button variant="contained" color="primary" onClick={openCreateDialog}>
                            <FormattedMessage id="common.create" />
                        </Button>
                    </AuthorizationCheckAll>
                )}
                <CreateDescendantDialog
                    creationInfoModel={creationInfoModel}
                    isOpen={isCreateDialogOpen}
                    setIsClosed={closeCreateDialog}
                />
            </React.Fragment>
        );
    },
);
