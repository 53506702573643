import { Box, Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { GeneralObjectSettingsContext, GeneralObjectSettingsContextType } from '../../../../../../contexts';
import { useFlag } from '../../../../../../hooks';
import { GeneralObjectChartList as GeneralObjectChartListInj } from './general-object-chart';
import { GeneralObjectTabSettingsPF as GeneralObjectTabSettingsPFInj } from './general-object-tab-settings-pf';
import { GeneralObjectTabSettingsContentForm as GeneralObjectTabSettingsContentFormInj } from './GeneralObjectTabSettingsContentForm';

export const GeneralObjectTabSettingsContent = observer(() => {
    const [GeneralObjectTabSettingsContentForm] = di(
        [GeneralObjectTabSettingsContentFormInj],
        GeneralObjectTabSettingsContent,
    );
    const [GeneralObjectTabSettingsPF] = di([GeneralObjectTabSettingsPFInj], GeneralObjectTabSettingsContent);
    const [GeneralObjectChartList] = di([GeneralObjectChartListInj], GeneralObjectTabSettingsContent);

    const { locale } = useIntl();

    const [shouldLoadListItems, setShouldLoadListItems, setShouldntLoadListItems] = useFlag();

    useEffect(setShouldLoadListItems, [locale]);

    const contextValues: GeneralObjectSettingsContextType = {
        setShouldntLoadListItems,
        shouldLoadListItems,
        setShouldLoadListItems,
    };

    return (
        <GeneralObjectSettingsContext.Provider value={contextValues}>
            <Box pt={4}>
                <Grid container direction="column">
                    <Grid item pb={10}>
                        <GeneralObjectChartList />
                    </Grid>
                    <GeneralObjectTabSettingsContentForm />
                    <GeneralObjectTabSettingsPF />
                </Grid>
            </Box>
        </GeneralObjectSettingsContext.Provider>
    );
});
