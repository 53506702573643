import { action, computed, makeObservable, observable } from 'mobx';
import { IntlShape } from 'react-intl';
import { CatalogStore, NotificationStore, RootStore } from '../stores';
import {
    CodeTitle,
    GeneralObject,
    GeneralObjectStore,
    IdTitle,
    SettingsContent,
    SettingsContentDTO,
    SettingsContentFields,
    SettingsContentForm,
    SettingsContentList,
    SettingsContentListDTO,
} from '../types';
import { ServerErrorsModel } from './ServerErrorsModel';

const getEmptySettingsContentList = (): SettingsContentList => ({
    entity: null,
    preset: null,
    categories: [],
});

const getEmptySettingsContentForm = (): SettingsContentForm => ({
    formData: null,
    isDownloadAllFilesButtonShown: false,
});

export const ContentSettingsModelProps = {
    rootStore: observable,
    catalogStore: observable,
    objectStore: observable,
    notificationStore: observable,
    intl: observable,
    serverErrors: observable,
    tabId: observable,
    objectId: observable,
    form: observable,
    list: observable,
    objectList: observable,
    formList: observable,
    presetList: observable,
    categoriesList: observable,
    isObjectListLoading: observable,
    isFormListLoading: observable,
    isPresetsAndCategoriesListLoading: observable,
    generalObjectType: computed,
    load: action.bound,
    loadContent: action.bound,
    loadTabSettingsContentInfo: action.bound,
    updateContent: action.bound,
    setObjectList: action.bound,
    setFormList: action.bound,
    setFormSelects: action.bound,
    setMainFields: action.bound,
    setIsObjectListLoading: action.bound,
    setIsFormListLoading: action.bound,
    setIsPresetsAndCategoriesListLoading: action.bound,
};

export class ContentSettingsModel {
    private rootStore: RootStore;
    private catalogStore: CatalogStore;
    private objectStore: GeneralObjectStore;
    private notificationStore: NotificationStore;
    private intl: IntlShape;

    serverErrors = new ServerErrorsModel<SettingsContentFields>();

    tabId = '';
    objectId = '';
    form: SettingsContentForm = getEmptySettingsContentForm();
    list: SettingsContentList = getEmptySettingsContentList();

    objectList: CodeTitle[] = [];
    formList: CodeTitle[] = [];
    presetList: IdTitle[] = [];
    categoriesList: IdTitle[] = [];

    isObjectListLoading = true;
    isFormListLoading = true;
    isPresetsAndCategoriesListLoading = true;

    constructor(objectId: string, tabId: string, rootStore: RootStore, objectStore: GeneralObjectStore) {
        makeObservable(this, ContentSettingsModelProps);
        this.tabId = tabId;
        this.objectId = objectId;
        this.rootStore = rootStore;
        this.catalogStore = rootStore.catalogStore;
        this.objectStore = objectStore;
        this.notificationStore = rootStore.notificationStore;
        this.intl = rootStore.intlStore.intl;
    }

    get generalObjectType(): GeneralObject {
        return this.objectStore.objectType;
    }

    load(): void {
        this.setIsFormListLoading(true);
        this.setFormList([]);
        this.setIsObjectListLoading(true);
        this.setObjectList([]);

        this.loadContent();

        this.catalogStore
            .getTabFormsList()
            .then(this.setFormList)
            .finally(() => this.setIsFormListLoading(false));
        this.objectStore
            .getContentObjectList()
            .then(this.setObjectList)
            .finally(() => this.setIsObjectListLoading(false));
    }

    loadContent(): void {
        this.objectStore.getTabContentSettings(this.objectId, this.tabId).then(this.setMainFields);
    }

    loadTabSettingsContentInfo(registryCode: string): void {
        this.setIsPresetsAndCategoriesListLoading(true);
        this.setFormSelects([], []);

        this.objectStore
            .getTabSettingsContentInfo(registryCode, this.generalObjectType)
            .then((data) => this.setFormSelects(data.presets, data.categories))
            .finally(() => this.setIsPresetsAndCategoriesListLoading(false));
    }

    updateContent(formValues: SettingsContent): Promise<void> {
        this.serverErrors.dropServerFormErrors();
        const dto: SettingsContentDTO = {
            form: formValues.form.formData
                ? {
                      code: formValues.form.formData.code,
                      title: formValues.form.formData.title,
                      batchDownloadFiles: formValues.form.isDownloadAllFilesButtonShown,
                  }
                : null,
            list: formValues.list.preset ? (formValues.list as SettingsContentListDTO) : null,
        };

        return this.objectStore
            .updateTabContent(this.objectId, this.tabId, dto)
            .then(() => {
                this.notificationStore.onSuccess(this.intl.formatMessage({ id: 'common.changesSaved' }));
            })
            .catch(this.serverErrors.setServerFormErrors);
    }

    setObjectList(objectList: CodeTitle[]): void {
        this.objectList = objectList;
    }

    setFormList(formsList: CodeTitle[]): void {
        this.formList = formsList;
    }

    setFormSelects(presetList: IdTitle[], categoryList: IdTitle[]): void {
        this.presetList = presetList;
        this.categoriesList = categoryList;
    }

    setMainFields(dto: SettingsContentDTO): void {
        this.list = dto.list || getEmptySettingsContentList();
        this.form = dto.form
            ? {
                  formData: {
                      code: dto.form.code,
                      title: dto.form.title,
                  },
                  isDownloadAllFilesButtonShown: dto.form.batchDownloadFiles,
              }
            : getEmptySettingsContentForm();
    }

    setIsObjectListLoading(isLoading: boolean): void {
        this.isObjectListLoading = isLoading;
    }

    setIsFormListLoading(isLoading: boolean): void {
        this.isFormListLoading = isLoading;
    }

    setIsPresetsAndCategoriesListLoading(isLoading: boolean): void {
        this.isPresetsAndCategoriesListLoading = isLoading;
    }
}
