import { Link, Tooltip } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { CommonTypography as CommonTypographyInj } from './typography';

export type FileDownloadProps = {
    downloadFile: () => void;
    fileName?: string;
    title?: string;
    sx?: SxProps<Theme>;
};

const linkSx: SxProps<Theme> = { textAlign: 'left', wordBreak: 'break-all' };

export const FileDownload = observer((props: FileDownloadProps): JSX.Element => {
    const [CommonTypography] = di([CommonTypographyInj], FileDownload);

    const { fileName, title, sx, downloadFile } = props;
    const intl = useIntl();
    const tooltipText = intl.formatMessage({ id: 'common.downloadFile' }, { fileName });

    const text = <CommonTypography sx={sx}>{title || fileName}</CommonTypography>;

    return (
        <React.Fragment>
            {fileName ? (
                <Tooltip title={tooltipText}>
                    <Link sx={linkSx} component="button" underline="none" onClick={downloadFile}>
                        {text}
                    </Link>
                </Tooltip>
            ) : (
                text
            )}
        </React.Fragment>
    );
});
