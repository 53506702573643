import { isCategoryWithTabs } from './constants';
import { GeneralObject, GeneralObjectRoutes } from './types';

const getGeneralObjectRoutes = (root: string, withTabs = true): GeneralObjectRoutes => {
    return {
        root,
        card: `${root}/:id`,
        tab: `${root}/:id/tab/:tabId`,
        syslog: `${root}/:id/syslog`,
        versions: `${root}/:id/versions`,
        formEdit: withTabs ? `${root}/:id/tab/:tabId/form` : `${root}/:id/form`,
        settings: `${root}/:id/tab-settings`,
        settingsTab: `${root}/:id/tab-settings/tab/:tabId`,
        settingsTabAccess: `${root}/:id/tab-settings/tab/:tabId/access`,
        settingsTabContent: `${root}/:id/tab-settings/tab/:tabId/content`,
        settingsPF: `${root}/:id/tab-settings/tab/:tabId/pf/:pfId`,
        settingsPFMain: `${root}/:id/tab-settings/tab/:tabId/pf/:pfId/content`,
        settingsPFAccess: `${root}/:id/tab-settings/tab/:tabId/pf/:pfId/access`,
        chart: `${root}/:id/tab-settings/tab/:tabId/chartSetting/:chartSettingId`,
    };
};

class ClientRoute implements Record<GeneralObject, GeneralObjectRoutes> {
    root = '/';
    notFound = '/404';
    notAllowed = '/403';
    logout = '/logout';
    console = '/console';

    users = '/users';
    user = '/users/:id';
    userEdit = '/users/:id/edit';

    login = '/login';
    recoveryPassword = '/recovery';
    newPassword = '/recovery/:token';
    registration = '/registration';
    authenticationInfo = '/info';
    registrationInfoSuccess = '/info/registration/success';
    registrationInfoExpired = '/info/registration/expired';
    registrationInfoNotFound = '/info/registration/404';
    recoveryPasswordInfoSuccess = '/info/recovery-password/success';
    registrationConfirm = '/registration/confirm/:token';

    esia = '/esia';
    esiaGetAuthUrl = '/esia/auth-url';
    esiaLogin = '/esia/login';

    saml = '/saml';
    samlGetAuthUrl = '/saml/:provider/auth-url';

    categories = '/categories';
    portfolios = '/portfolios';
    projects = '/projects';
    childObjects = '/childObjects';

    category: GeneralObjectRoutes = getGeneralObjectRoutes(this.categories, isCategoryWithTabs);
    portfolio: GeneralObjectRoutes = getGeneralObjectRoutes(this.portfolios);
    project: GeneralObjectRoutes = getGeneralObjectRoutes(this.projects);
    childObject: GeneralObjectRoutes = getGeneralObjectRoutes(this.childObjects);
}

export const clientRoute = new ClientRoute();
