import { Box, Container, Grid, Typography } from '@mui/material';
import { TTable, TTableStoreVisibleSettings } from '@platform/ttable';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { entities, permissions } from '../../authSchemeConfig';
import {
    AuthorizationCheck as AuthorizationCheckInj,
    ServerBreadcrumbs as ServerBreadcrumbsInj,
    registryPageTableSxStyles,
} from '../../components';
import { useGeneralObjectContext, useLocale, useStore } from '../../hooks';
import {
    BreadcrumbsLocation,
    ChildObjectVersionsRegistryParams,
    GeneralObjectRouteParams,
    RegistryCode,
} from '../../types';

const versionsRegistryVisibleSettings: TTableStoreVisibleSettings = {
    toolbar: {
        isWithPresets: false,
        isWithAddCustomColumns: false,
    },
    column: {
        isWithDraggableColumns: false,
    },
};

const titleLocaleId = 'version.plural.nominative';

export const ChildObjectVersionsPage = observer((): JSX.Element => {
    const [ServerBreadcrumbs] = di([ServerBreadcrumbsInj], ChildObjectVersionsPage);
    const [AuthorizationCheck] = di([AuthorizationCheckInj], ChildObjectVersionsPage);

    const { catalogStore, userStore, api } = useStore();
    const { handleMainInfoClientError } = api;
    const handleMainInfoError = handleMainInfoClientError();
    const intl = useIntl();
    const { id } = useParams<GeneralObjectRouteParams>();
    const { objectStore } = useGeneralObjectContext();
    const { objectType } = objectStore;
    const [tableTitle] = useLocale(titleLocaleId);

    const apiParams: ChildObjectVersionsRegistryParams = {
        entityId: id,
        entityType: objectType,
    };

    return (
        <Container maxWidth={false}>
            <Box pt={5.5} pb={15}>
                <Grid container direction="column" spacing={5}>
                    <Grid item>
                        <ServerBreadcrumbs
                            entityId={id}
                            entityType={objectType}
                            location={BreadcrumbsLocation.versions}
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant="h1" component="h1">
                            <FormattedMessage id={titleLocaleId} />
                        </Typography>
                    </Grid>
                    <Grid item sx={registryPageTableSxStyles.tableWrapper}>
                        <AuthorizationCheck entityCode={entities.system} permCode={permissions.system.Administration}>
                            {(allowed: boolean): JSX.Element => (
                                <TTable
                                    tableTitle={tableTitle}
                                    client={api.client}
                                    registryCode={RegistryCode.version}
                                    fetchCatalog={catalogStore.getCatalog}
                                    fetchSelectDataByUrl={catalogStore.getSelectDataByUrl}
                                    lang={intl.locale}
                                    fetchUserRoleList={userStore.userRoleList}
                                    isAdmin={allowed}
                                    visibleSettings={versionsRegistryVisibleSettings}
                                    handleMainInfoError={handleMainInfoError}
                                    mainApiParams={apiParams}
                                />
                            )}
                        </AuthorizationCheck>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
});
