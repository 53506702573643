import { TitlesDTO } from '@platform/multi-lang-field';
import { LinkType, TTableRow } from '@platform/ttable';
import { Identifier } from './generalObject';
import { CodeTitle, IdTitle } from './withTitle';

export type PortfolioTableRow = TTableRow & {
    customData: {
        identifier: LinkType;
        title: LinkType;
        manager: string;
        categories: IdTitle[];
        viewers: IdTitle[];
        deadline: string; //date
        state: CodeTitle & {
            processId: string;
            processTitle: string;
        };
        created: string; //date
        author: IdTitle;
    };
};

export type PortfolioCreateInfo = {
    identifier: Identifier;
    processes: Array<CodeTitle>;
};

export type PortfolioCreateDTO = {
    titles: TitlesDTO;
    processCode: string;
    identifier: Identifier;
};

export enum CreatePortfolioFields {
    titles = 'titles',
    number = 'number',
    process = 'process',
}
