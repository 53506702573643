import { createContext } from 'react';
import { GeneralObjectContextType, GeneralObjectStore } from '../types';

export const generalObjectContextDefault: GeneralObjectContextType = {
    objectStore: {} as GeneralObjectStore,
    routes: {
        root: '',
        card: '',
        tab: '',
        syslog: '',
        versions: '',
        formEdit: '',
        settings: '',
        settingsTab: '',
        settingsTabAccess: '',
        settingsTabContent: '',
        settingsPF: '',
        settingsPFMain: '',
        settingsPFAccess: '',
        chart: '',
    },
};

export const GeneralObjectContext = createContext<GeneralObjectContextType>(generalObjectContextDefault);
