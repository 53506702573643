import { action, computed, makeAutoObservable, observable } from 'mobx';
import { di } from 'react-magnetic-di';
import { descriptionPanelWidth, rootElement } from '../constants';

export const StickyElementsStoreProps = {
    appBarHeight: observable,
    visibleAppBarHeight: observable,
    generalObjectHeadIsSticky: observable,
    generalObjectHeaderHeight: observable,
    generalObjectTabsHeight: observable,
    visibleFooterHeight: observable,
    descriptionPanelWidth: observable,
    scrollBarWidth: observable,
    summaryStickyHeight: computed,
    summaryStickyOffsetRight: computed,
    toggleDescriptionPanel: action.bound,
    handleVisibleFooterHeight: action.bound,
    handleVisibleAppBarHeightCalculation: action.bound,
    setAppBarHeight: action.bound,
    setVisibleAppBarHeight: action.bound,
    setGeneralObjectHeadIsSticky: action.bound,
    setGeneralObjectElementsHeight: action.bound,
    setVisibleFooterHeight: action.bound,
};

export class StickyElementsStore {
    appBarHeight = 0;
    visibleAppBarHeight = 0;

    generalObjectHeadIsSticky = false;
    generalObjectHeaderHeight = 0;
    generalObjectTabsHeight = 0;
    visibleFooterHeight = 0;

    descriptionPanelWidth = descriptionPanelWidth;
    scrollBarWidth: number;

    constructor() {
        makeAutoObservable(this, StickyElementsStoreProps);
        this.scrollBarWidth = rootElement ? rootElement.offsetWidth - rootElement.clientWidth : 0;
    }

    get summaryStickyHeight(): number {
        return this.generalObjectHeaderHeight + this.generalObjectTabsHeight;
    }

    get summaryStickyOffsetRight(): number {
        return this.descriptionPanelWidth + this.scrollBarWidth;
    }

    toggleDescriptionPanel(): void {
        this.descriptionPanelWidth = this.descriptionPanelWidth ? 0 : descriptionPanelWidth;
    }

    handleVisibleFooterHeight(): void {
        const footerElement = rootElement?.querySelector('footer');
        if (rootElement && footerElement) {
            const { scrollTop, scrollHeight, offsetHeight } = rootElement;
            const scrollTopMax = scrollHeight - offsetHeight;
            const footerHeight = footerElement.clientHeight;
            const distanceToBottom = scrollTopMax - scrollTop;

            if (distanceToBottom <= footerHeight) {
                this.setVisibleFooterHeight(footerHeight - distanceToBottom);
            } else {
                this.setVisibleFooterHeight(0);
            }
        }
    }

    handleVisibleAppBarHeightCalculation(): void {
        const headerElement = rootElement?.querySelector('header');
        if (rootElement && headerElement) {
            const { top, height } = headerElement.getBoundingClientRect();
            const heightOfAppBarVisiblePart = height + top;
            this.setVisibleAppBarHeight(heightOfAppBarVisiblePart);
        }
    }

    setAppBarHeight(height: number): void {
        this.appBarHeight = height;
    }

    setVisibleAppBarHeight(height: number): void {
        height = height > 0 ? height : 0;
        this.visibleAppBarHeight = height;
    }

    setGeneralObjectHeadIsSticky(isSticky: boolean): void {
        this.generalObjectHeadIsSticky = isSticky;
    }

    setGeneralObjectElementsHeight(headerHeight = 0, tabsHeight = 0): void {
        this.generalObjectHeaderHeight = headerHeight;
        this.generalObjectTabsHeight = tabsHeight;
    }

    setVisibleFooterHeight(visibleFooterHeight: number): void {
        this.visibleFooterHeight = visibleFooterHeight;
    }
}

export const getStickyElementsStore = (): any => {
    const [_StickyElementsStore] = di([StickyElementsStore], getStickyElementsStore);
    return _StickyElementsStore;
};
