import { voidFunction, VoidFunction } from '@platform/front-utils';
import { createContext } from 'react';

export type GeneralObjectSettingsContextType = {
    shouldLoadListItems: boolean;
    setShouldLoadListItems: VoidFunction;
    setShouldntLoadListItems: VoidFunction;
};

export const GeneralObjectSettingsContext = createContext<GeneralObjectSettingsContextType>({
    shouldLoadListItems: false,
    setShouldLoadListItems: voidFunction,
    setShouldntLoadListItems: voidFunction,
});
