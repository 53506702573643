import { Box } from '@mui/material';
import React, { ReactNode } from 'react';

export type RequiredLabelProps = {
    text: ReactNode;
};

export const RequiredLabel = (props: RequiredLabelProps): JSX.Element => {
    const { text } = props;
    return (
        <React.Fragment>
            {text}
            <Box display="inline" component="span" sx={{ color: 'error.main' }}>
                {' *'}
            </Box>
        </React.Fragment>
    );
};
