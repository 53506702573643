import { Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { mailToLink as mailToLinkInj } from '../../../../../../formatters';
import { useStore } from '../../../../../../hooks';
import { getEmailFromText } from '../../../../../../utils';
import {
    CommonTypography as CommonTypographyInj,
    TitleBoldTypography as TitleBoldTypographyInj,
} from '../../../../../typography';
import { generalObjectWithoutAvailableInfoSxStyles as sx } from '../../useGeneralObjectTabContentStyles';

export type GeneralObjectWithoutAvailableRegistryProps = {
    title: string;
};

export const GeneralObjectWithoutAvailableRegistry = observer(
    (props: GeneralObjectWithoutAvailableRegistryProps): JSX.Element => {
        const [mailToLink] = di([mailToLinkInj], GeneralObjectWithoutAvailableRegistry);
        const [CommonTypography] = di([CommonTypographyInj], GeneralObjectWithoutAvailableRegistry);
        const [TitleBoldTypography] = di([TitleBoldTypographyInj], GeneralObjectWithoutAvailableRegistry);

        const { title } = props;
        const { env } = useStore();
        const { totMail = '' } = env;
        const hrefEmail = getEmailFromText(totMail);

        const administrationContactFormattedMessageValues = {
            a: mailToLink(hrefEmail),
        };

        return (
            <Grid container item direction="column" justifyContent="center" alignItems="center" spacing={1}>
                <Grid item>
                    <TitleBoldTypography sx={sx.title}>{title}</TitleBoldTypography>
                </Grid>
                <Grid item>
                    {hrefEmail && (
                        <CommonTypography sx={sx.text}>
                            <FormattedMessage
                                id="generalObject.registryIsNotAvailableAdministrationContact"
                                values={administrationContactFormattedMessageValues}
                            />
                        </CommonTypography>
                    )}
                </Grid>
            </Grid>
        );
    },
);
