import { TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { sxWidth10, sxWidth30 } from '../../../../../constants';

export const GeneralObjectSystemEntityAccessSettingsPermissionsTableHeader = (): JSX.Element => {
    return (
        <TableHead>
            <TableRow>
                <TableCell sx={sxWidth30}>
                    <Typography>
                        <FormattedMessage id="objectSettings.accessFields.roleCondition" />
                    </Typography>
                </TableCell>
                <TableCell sx={sxWidth30}>
                    <Typography>
                        <FormattedMessage id="objectSettings.accessFields.lifecycle" />
                    </Typography>
                </TableCell>
                <TableCell sx={sxWidth30}>
                    <Typography>
                        <FormattedMessage id="objectSettings.accessFields.access" />
                    </Typography>
                </TableCell>
                <TableCell sx={sxWidth10} />
            </TableRow>
        </TableHead>
    );
};
