import { Fab, Grid, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Sticky from 'react-sticky-el';
import { entities, permissions } from '../authSchemeConfig';
import { fabSmallHeight, rootId, stickyButtonOffsetVerticalMultiplier, stickyZIndex } from '../constants';
import { useStore } from '../hooks';
import { spacing } from '../theme';
import { AuthorizationCheck } from './AuthorizationCheck';

export type DownloadAllFilesButtonProps =
    | {
          isDownloadAllFilesButtonShown: boolean;
          tabId: string;
          onDownloadAllFiles: () => Promise<void>;
          downloadAllFileButtonStyles?: SxProps<Theme>;
      }
    | {
          isDownloadAllFilesButtonShown?: never;
          tabId?: never;
          onDownloadAllFiles?: never;
          downloadAllFileButtonStyles?: never;
      };

export type FormStickyButtons = {
    stickyOffsetRight: number;
    containerSelector: string;
    formEditPath: string;
    onEditForm: () => void;
    editButtonStyles?: SxProps<Theme>;
} & DownloadAllFilesButtonProps;

export const FormStickyButtons = observer((props: FormStickyButtons): JSX.Element => {
    const {
        containerSelector,
        stickyOffsetRight,
        editButtonStyles,
        formEditPath,
        onEditForm,
        isDownloadAllFilesButtonShown = false,
        tabId,
        onDownloadAllFiles,
        downloadAllFileButtonStyles,
    } = props;
    const { stickyElementsStore, history } = useStore();
    const { summaryStickyHeight } = stickyElementsStore;

    const onEdit = (): void => {
        history.push(formEditPath);
        onEditForm();
    };

    const topOffset = -summaryStickyHeight;
    const bottomOffset = topOffset - fabSmallHeight - spacing * stickyButtonOffsetVerticalMultiplier;

    return (
        <Grid item alignSelf="end">
            <Sticky
                scrollElement={rootId}
                boundaryElement={containerSelector}
                stickyStyle={{
                    zIndex: stickyZIndex,
                    width: 'auto',
                    right: stickyOffsetRight,
                    top: summaryStickyHeight,
                }}
                hideOnBoundaryHit={true}
                topOffset={topOffset}
                bottomOffset={bottomOffset}
            >
                {isDownloadAllFilesButtonShown && onDownloadAllFiles && tabId && (
                    <AuthorizationCheck
                        entityCode={entities.tab}
                        entityId={tabId}
                        permCode={permissions.tab.BatchDownloadFiles}
                    >
                        <Fab
                            variant="extended"
                            size="small"
                            color="primary"
                            onClick={onDownloadAllFiles}
                            {...(downloadAllFileButtonStyles && { sx: downloadAllFileButtonStyles })}
                        >
                            <FormattedMessage id="generalObject.downloadAllFiles" />
                        </Fab>
                    </AuthorizationCheck>
                )}
                <AuthorizationCheck entityCode={entities.tab} permCode={permissions.tab.Edit} entityId={tabId}>
                    <Fab
                        variant="extended"
                        size="small"
                        color="secondary"
                        onClick={onEdit}
                        {...(editButtonStyles && { sx: editButtonStyles })}
                    >
                        <FormattedMessage id="common.edit" />
                    </Fab>
                </AuthorizationCheck>
            </Sticky>
        </Grid>
    );
});
