import { LocalesTree, ObjectLocales } from '../../../types';

const childObject: ObjectLocales & LocalesTree = {
    single: {
        nominative: 'дочерний объект',
        accusative: 'дочерний объект',
    },
    plural: {
        nominative: 'дочерние объекты',
    },
    phrases: {
        allVersions: 'Все {versions}',
    },
};

export default childObject;
