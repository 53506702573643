import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

export const projectMessages = defineMessages({
    create: {
        id: 'project.phrases.create',
        values: {
            project: <FormattedMessage id="project.single.accusative" />,
        },
    },
    number: {
        id: 'project.phrases.number',
        values: {
            project: <FormattedMessage id="project.single.accusative" />,
        },
    },
});
