import { ChartData } from '../types';

export const parsingSubmissionEditGrid = (
    data: any,
    dataLabelsFieldCode = '',
    valuesFieldCode = '',
    element = '',
): ChartData[] | undefined => {
    const searchElement = getPropertyByStringPath(data, element);
    if (searchElement && Array.isArray(searchElement)) {
        return searchElement.map((item) => {
            return {
                id: (item[dataLabelsFieldCode] as string) || '',
                label: (item[dataLabelsFieldCode] as string) || '',
                value: (item[valuesFieldCode] as number) || 0,
            };
        });
    }
};

const getPropertyByStringPath = (obj: any, path: string): string => {
    return path.split('.').reduce((acc, pathPart) => (acc ? acc[pathPart] : undefined), obj);
};
