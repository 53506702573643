import { fabSmallHeight } from '../constants';
import { AppThemeOptions, ThemeOverrides } from '../types';
import { grey, blue } from './redesignCssProperties';
import { spacing, transitionDefault } from './vars';
import button from './vars/button';
import footer from './vars/footer';
import checkbox from './vars/formio/checkbox';
import checkRadioBox from './vars/formio/checkRadioBox';
import formControl from './vars/formio/formControl';
import formioDateTime from './vars/formio/formioDateTime';
import input, { inputHeight } from './vars/formio/input';
import select from './vars/formio/select';
import infoSidebar from './vars/infoSidebar';
import requestPanel from './vars/requestPanel';
import requestPrintForms from './vars/requestPrintForms';
import sidebar from './vars/sidebar';

const createAppThemeOptions = (overrides: ThemeOverrides): AppThemeOptions => {
    const { palette, fonts, icons } = overrides;

    return {
        palette: {
            primary: {
                light: palette.mainLight,
                main: palette.main,
                dark: palette.mainDark,
                contrastText: palette.mainContrast,
            },
            secondary: {
                main: palette.accent,
                dark: palette.accentDark,
                contrastText: palette.accentContrast,
            },
            grey,
        },
        typography: {
            fontSize: 13,
            fontWeightLight: 300,
            fontWeightRegular: 400,
            fontWeightMedium: 600,
            fontWeightBold: 700,
        },
        shadows: [
            'none',
            `0px 6px 32px 0 rgba(0, 0, 0, 0.09)`,
            `0px 3px 1px -2px ${palette.textDark}33,0px 2px 2px 0px ${palette.textDark}24,0px 1px 5px 0px ${palette.textDark}1f`,
            `0px 3px 3px -2px ${palette.textDark}33,0px 3px 4px 0px ${palette.textDark}24,0px 1px 8px 0px ${palette.textDark}1f`,
            `0px 2px 4px -1px ${palette.textDark}33,0px 4px 5px 0px ${palette.textDark}24,0px 1px 10px 0px ${palette.textDark}1f`,
            `0px 3px 5px -1px ${palette.textDark}33,0px 5px 8px 0px ${palette.textDark}24,0px 1px 14px 0px ${palette.textDark}1f`,
            `0px 3px 5px -1px ${palette.textDark}33,0px 6px 10px 0px ${palette.textDark}24,0px 1px 18px 0px ${palette.textDark}1f`,
            `0px 4px 5px -2px ${palette.textDark}33,0px 7px 10px 1px ${palette.textDark}24,0px 2px 16px 1px ${palette.textDark}1f`,
            `0px 5px 5px -3px ${palette.textDark}33,0px 8px 10px 1px ${palette.textDark}24,0px 3px 14px 2px ${palette.textDark}1f`,
            `0px 5px 6px -3px ${palette.textDark}33,0px 9px 12px 1px ${palette.textDark}24,0px 3px 16px 2px ${palette.textDark}1f`,
            `0px 6px 6px -3px ${palette.textDark}33,0px 10px 14px 1px ${palette.textDark}24,0px 4px 18px 3px ${palette.textDark}1f`,
            `0px 6px 7px -4px ${palette.textDark}33,0px 11px 15px 1px ${palette.textDark}24,0px 4px 20px 3px ${palette.textDark}1f`,
            `0px 7px 8px -4px ${palette.textDark}33,0px 12px 17px 2px ${palette.textDark}24,0px 5px 22px 4px ${palette.textDark}1f`,
            `0px 7px 8px -4px ${palette.textDark}33,0px 13px 19px 2px ${palette.textDark}24,0px 5px 24px 4px ${palette.textDark}1f`,
            `0px 7px 9px -4px ${palette.textDark}33,0px 14px 21px 2px ${palette.textDark}24,0px 5px 26px 4px ${palette.textDark}1f`,
            `0px 8px 9px -5px ${palette.textDark}33,0px 15px 22px 2px ${palette.textDark}24,0px 6px 28px 5px ${palette.textDark}1f`,
            `0px 8px 10px -5px ${palette.textDark}33,0px 16px 24px 2px ${palette.textDark}24,0px 6px 30px 5px ${palette.textDark}1f`,
            `0px 8px 11px -5px ${palette.textDark}33,0px 17px 26px 2px ${palette.textDark}24,0px 6px 32px 5px ${palette.textDark}1f`,
            `0px 9px 11px -5px ${palette.textDark}33,0px 18px 28px 2px ${palette.textDark}24,0px 7px 34px 6px ${palette.textDark}1f`,
            `0px 9px 12px -6px ${palette.textDark}33,0px 19px 29px 2px ${palette.textDark}24,0px 7px 36px 6px ${palette.textDark}1f`,
            `0px 10px 13px -6px ${palette.textDark}33,0px 20px 31px 3px ${palette.textDark}24,0px 8px 38px 7px ${palette.textDark}1f`,
            `0px 10px 13px -6px ${palette.textDark}33,0px 21px 33px 3px ${palette.textDark}24,0px 8px 40px 7px ${palette.textDark}1f`,
            `0px 10px 14px -6px ${palette.textDark}33,0px 22px 35px 3px ${palette.textDark}24,0px 8px 42px 7px ${palette.textDark}1f`,
            `0px 11px 14px -7px ${palette.textDark}33,0px 23px 36px 3px ${palette.textDark}24,0px 9px 44px 8px ${palette.textDark}1f`,
            `0px 11px 15px -7px ${palette.textDark}33,0px 24px 38px 3px ${palette.textDark}24,0px 9px 46px 8px ${palette.textDark}1f`,
        ],
        components: {
            MuiAppBar: {
                styleOverrides: {
                    colorDefault: {
                        backgroundColor: palette.appBar.mainContrast,
                        color: palette.mainLight,
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        fontSize: '13px',
                        fontWeight: 'bold',
                        lineHeight: '1.25',
                        letterSpacing: '0.2px',
                        height: `${button.sizes.medium}px`,
                        padding: '0 10px',
                        transition: `background-color ${transitionDefault},
                                 box-shadow ${transitionDefault},
                                 border ${transitionDefault},
                                 color ${transitionDefault}`,
                        textTransform: 'none',
                    },
                    contained: {
                        boxShadow: 'none',
                        backgroundColor: 'transparent',
                        color: palette.textDark,

                        '&:hover': {
                            boxShadow: 'none !important',
                            backgroundColor: palette.main,
                            color: palette.mainContrast,
                        },

                        '&.Mui-disabled': {
                            background: palette.button.disabled,
                        },
                    },
                    containedPrimary: {
                        boxShadow: 'none',
                        background: palette.accentBackgroundGradient,
                        color: palette.button.accentContrast,
                        '& [class^=MuiButton-startIcon]': {
                            color: palette.textDark,
                        },

                        '&.Mui-disabled [class^=MuiButton-startIcon]': {
                            color: 'rgba(0, 0, 0, 0.26)',
                        },

                        '&:hover': {
                            boxShadow: 'none',
                            background: 'none',
                            backgroundColor: palette.button.hoverBackground,
                            color: palette.mainContrast,
                            '& [class^=MuiButton-startIcon]': {
                                color: palette.mainContrast,
                            },
                            '&:focus': {
                                color: palette.mainContrast,
                            },
                        },
                        '&:focus': {
                            color: palette.accentContrastBackgroundGradient,
                        },
                    },
                    containedSecondary: {
                        boxShadow: 'none',
                        backgroundColor: palette.main,
                        color: palette.mainContrast,
                        '& [class^=MuiButton-startIcon]': {
                            color: palette.mainContrast,
                        },

                        '&:hover': {
                            boxShadow: 'none',
                            background: `${palette.accentBackgroundGradient}`,
                            color: palette.button.accentContrast,

                            '& [class^=MuiButton-startIcon]': {
                                color: palette.button.accentContrast,
                            },
                        },
                    },
                    containedSizeLarge: {
                        height: `${button.sizes.large}px`,
                        padding: '0 44px',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        lineHeight: '1.14',
                        letterSpacing: '0.84px',
                    },
                    containedSizeSmall: {
                        padding: '6px 16px',
                    },
                    text: {
                        lineHeight: 1.25, //1.85
                        letterSpacing: '1.04px',
                        padding: '8px 10px',

                        '&:hover': {
                            background: `${palette.accentBackgroundGradient}`,
                            color: palette.accentContrastBackgroundGradient,
                        },
                    },
                    textPrimary: {
                        color: palette.main,
                        '& [class^=MuiButton-startIcon]': {
                            color: palette.main,
                        },
                        '&:hover': {
                            background: palette.accentBackgroundGradient,
                            color: palette.button.accentContrast,
                        },
                        '&:hover [class^=MuiButton-startIcon]': {
                            color: palette.button.accentContrast,
                        },
                    },
                    textSecondary: {
                        color: palette.mainLight,

                        '&:hover': {
                            color: palette.hover,
                            background: 'transparent',
                            backgroundColor: 'transparent',
                        },
                        '&:hover [class^=MuiButton-startIcon]': {
                            color: palette.hover,
                        },

                        '&.active': {
                            color: palette.hover,
                        },
                        '&.active [class^=MuiButton-startIcon]': {
                            color: palette.hover,
                        },
                    },
                    textSizeSmall: {
                        padding: '6px 13px',
                        fontSize: 'inherit',
                    },
                    textSizeLarge: {
                        height: '48px',
                        padding: '6px 13px',
                        fontSize: 'inherit',
                    },
                    startIcon: {
                        marginLeft: 0,
                        color: palette.mainLight,
                        transition: `color ${transitionDefault}`,

                        '&:hover': {
                            color: palette.textDark,
                        },
                    },
                },
            },
            MuiAlert: {
                styleOverrides: {
                    message: {
                        '&::first-letter': {
                            textTransform: 'capitalize',
                        },
                    },
                },
            },
            MuiFab: {
                styleOverrides: {
                    root: {
                        borderRadius: '4px',
                        fontSize: '13px',
                        fontWeight: 'bold',
                        lineHeight: '1.25',
                        letterSpacing: '0.2px',
                        height: `${button.sizes.medium}px`,
                        padding: '0 10px',
                        transition: `background-color ${transitionDefault},
                                 box-shadow ${transitionDefault},
                                 border ${transitionDefault},
                                 color ${transitionDefault}`,
                        textTransform: 'none',
                        boxShadow: 'none',
                        backgroundColor: 'transparent',
                        color: palette.textDark,

                        '&:hover': {
                            boxShadow: 'none !important',
                            backgroundColor: palette.main,
                            color: palette.mainContrast,
                        },

                        '&.Mui-disabled': {
                            background: palette.button.disabled,
                        },
                    },
                    primary: {
                        boxShadow: 'none',
                        background: palette.accentBackgroundGradient,
                        color: palette.button.accentContrast,
                        '& [class^=MuiSvgIcon]': {
                            color: palette.textDark,
                        },

                        '&.Mui-disabled [class^=MuiSvgIcon]': {
                            color: 'rgba(0, 0, 0, 0.26)',
                        },

                        '&:hover': {
                            boxShadow: 'none',
                            background: 'none',
                            backgroundColor: palette.main,
                            color: palette.mainContrast,
                            '& [class^=MuiSvgIcon]': {
                                color: palette.mainContrast,
                            },
                            '&:focus': {
                                color: palette.mainContrast,
                            },
                        },
                        '&:focus': {
                            color: palette.accentContrastBackgroundGradient,
                        },
                    },
                    secondary: {
                        boxShadow: 'none',
                        backgroundColor: palette.main,
                        color: palette.mainContrast,
                        '& [class^=MuiSvgIcon]': {
                            color: palette.mainContrast,
                        },

                        '&:hover': {
                            boxShadow: 'none',
                            background: palette.accentBackgroundGradient,
                            color: palette.button.accentContrast,

                            '& [class^=MuiSvgIcon]': {
                                color: palette.button.accentContrast,
                            },
                        },
                    },
                    sizeSmall: {
                        height: `${fabSmallHeight}px !important`,
                    },
                },
            },
            MuiRadio: {
                styleOverrides: {
                    root: {
                        padding: 0,
                    },
                    colorSecondary: {
                        '&.Mui-checked': {
                            color: palette.main,
                        },
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        width: `${button.sizes.medium}px`,
                        height: `${button.sizes.medium}px`,
                        padding: 0,

                        color: grey[400],

                        '&.active': {
                            color: grey[800],
                            background: grey[200],
                        },

                        '&:hover': {
                            color: grey[600],
                            background: grey[100],
                        },

                        '&:disabled': {
                            background: 'none',
                            color: grey[200],
                        },
                    },

                    sizeSmall: {
                        width: `${button.sizes.small}px`,
                        height: `${button.sizes.small}px`,
                        padding: 0,
                    },

                    colorPrimary: {
                        color: palette.main,

                        '&:hover': {
                            background: `${palette.accentBackgroundGradient}`,
                            color: palette.accentContrast,
                        },
                    },

                    colorSecondary: {
                        color: `${palette.mainLight} !important`,

                        '&:hover': {
                            color: `${palette.hover} !important`,
                            backgroundColor: 'transparent !important',
                        },

                        '&.active': {
                            color: `${palette.hover} !important`,
                        },
                    },
                },
            },
            MuiLink: {
                defaultProps: {
                    underline: 'hover',
                },
                styleOverrides: {
                    root: {
                        color: blue[600],
                        cursor: 'pointer',
                        lineHeight: '1.25rem',
                        fontSize: '0.875rem',
                        textDecorationColor: blue[600],
                        '&:hover': {
                            color: blue[700],
                        },

                        '&:focus': {
                            outline: 'none',
                        },

                        '&[class*="Mui-focusVisible"], &:active, &[class*="active"], &.focus': {
                            outline: 'none',
                            textDecoration: 'underline',
                            color: blue[700],
                        },

                        '&:active, &[class*="active"]': {
                            color: blue[800],
                            textDecoration: 'underline',
                        },

                        '& .MuiSvgIcon-root': {
                            paddingRight: '12px',
                        },
                    },
                },
            },
            MuiTypography: {
                styleOverrides: {
                    root: {
                        flexGrow: '1',

                        '&::first-letter': {
                            textTransform: 'capitalize',
                        },
                    },

                    h1: {
                        fontSize: '24px',
                        fontWeight: 600,
                        fontStretch: 'normal',
                        fontStyle: 'normal',
                        lineHeight: 1.5,
                        letterSpacing: 'normal',
                    },
                    h2: {
                        fontSize: '3rem',
                        fontWeight: 600,
                    },
                    h3: {
                        fontSize: '18px',
                        fontWeight: 'bold',
                        fontStretch: 'normal',
                        fontStyle: 'normal',
                        lineHeight: 1.56,
                        letterSpacing: '0.36px',
                    },
                    body1: {
                        ...fonts.mainFontAttrs,
                    },
                    body2: {
                        fontSize: '15px',
                        fontWeight: 'normal',
                        fontStretch: 'normal',
                        fontStyle: 'normal',
                        lineHeight: 1.47,
                        letterSpacing: '0.3px',
                    },
                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        backgroundColor: palette.mainContrast,
                        paddingRight: `${spacing}px`,
                        display: 'flex',
                        '& > span': {
                            display: 'block',
                            '&::first-letter': {
                                textTransform: 'capitalize',
                            },
                        },
                    },
                    shrink: {
                        letterSpacing: '0.4px',
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    rounded: {
                        borderRadius: '8px',
                    },
                },
            },
            MuiCheckbox: {
                styleOverrides: {
                    colorPrimary: {
                        '&:hover': {
                            background: 'transparent',
                        },
                    },
                },
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    root: {
                        width: '100%',

                        '&:hover .MuiIconButton-label': {
                            color: palette.textDark,
                        },
                    },
                },
            },
            MuiListItem: {
                styleOverrides: {
                    root: {
                        outline: 'none',

                        '&.Mui-selected': {
                            color: palette.textDark,
                        },

                        '&.Mui-selected:hover': {
                            backgroundColor: palette.hoverInLists,
                        },
                    },
                    button: {
                        color: grey[800],

                        '&:hover': {
                            color: palette.textDark,
                            backgroundColor: grey[100],
                        },
                    },
                },
            },
            MuiTabs: {
                styleOverrides: {
                    indicator: {
                        height: '3px',
                    },
                    root: {
                        '&.MuiTabs-vertical .MuiTabs-indicator': {
                            width: '3px',
                        },
                    },
                    flexContainer: {
                        '&.MuiTabs-flexContainerVertical .MuiTab-wrapper': {
                            alignItems: 'flex-start',
                        },
                        '&.MuiTabs-flexContainerVertical .MuiTab-root': {
                            minHeight: 'auto',
                        },
                    },
                },
            },
            MuiDialogActions: {
                styleOverrides: {
                    spacing: {
                        padding: '16px 24px',
                    },
                },
            },
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        '&:nth-child(even)': {
                            backgroundColor: palette.table.row.even,
                        },
                        '&:nth-child(odd)': {
                            backgroundColor: palette.table.row.odd,
                        },
                        '&.MuiTableRow-head': {
                            backgroundColor: palette.mainContrast,
                        },
                        '&.MuiTableRow-footer': {
                            backgroundColor: palette.mainContrast,
                        },
                        '&.MuiTableRow-hover': {
                            '&:hover': {
                                backgroundColor: palette.hoverInLists,
                            },
                        },
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        padding: `${4 * spacing}px`,
                        borderBottomWidth: 0,
                        paddingTop: `${3 * spacing}px`,
                        paddingBottom: `${3 * spacing}px`,
                        paddingLeft: `${1.5 * spacing}px`,
                        paddingRight: `${1.5 * spacing}px`,
                        '&:first-child': {
                            paddingLeft: `${6 * spacing}px`,
                        },
                        '&:last-child': {
                            paddingRight: `${6 * spacing}px`,
                        },
                        verticalAlign: 'top',
                        fontSize: '0.875rem',
                    },
                    head: {
                        backgroundColor: 'white',
                        color: palette.textDark,
                        fontWeight: 600,
                    },
                },
            },

            MuiTableSortLabel: {
                styleOverrides: {
                    icon: {
                        fontSize: 'x-large',
                    },
                },
            },

            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        backgroundColor: palette.tooltip.backgroundColor,
                        fontSize: 12,
                    },
                },
            },
            MuiAutocomplete: {
                styleOverrides: {
                    listbox: {
                        maxHeight: '180px',
                    },
                    tag: {
                        borderRadius: '4px',
                        backgroundColor: palette.hoverInLists,
                        '&:hover': {
                            backgroundColor: palette.main,
                            color: palette.mainContrast,
                            '& .MuiChip-deleteIcon': {
                                color: palette.mainContrast,
                            },
                        },
                        '& .MuiChip-deleteIcon': {
                            color: palette.main,
                            flexShrink: 0,
                        },
                    },
                    clearIndicator: {
                        height: '24px',
                        width: '24px',
                    },
                    option: {
                        padding: `0 ${4 * spacing}px !important`,
                        '&[data-focus="true"]': {
                            backgroundColor: palette.hoverInLists,
                        },

                        '&[area-selected="true"]': {
                            backgroundColor: palette.hoverInLists,
                        },
                        '&:hover': {
                            backgroundColor: palette.hoverInLists,
                        },
                        '&:hover .MuiCheckbox-colorPrimary': {
                            color: palette.textDark,
                        },

                        '& .MuiCheckbox-colorPrimary.Mui-checked': {
                            color: palette.main,
                        },
                    },
                    popupIndicator: {
                        width: '24px',
                        height: '24px',
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    sizeSmall: {
                        '& .MuiAutocomplete-input': {
                            padding: '2.324px 28px 2.325px 6px !important',
                        },
                    },
                    input: {
                        '&[type="password"]::-ms-reveal': {
                            display: 'none',
                        },
                    },
                },
            },
            MuiCardActions: {
                styleOverrides: {
                    root: {
                        padding: `${4 * spacing}px ${6 * spacing}px`,
                    },
                },
            },
            MuiPaginationItem: {
                styleOverrides: {
                    root: {
                        color: palette.main,
                        '&:hover': {
                            color: palette.textDark,
                        },
                    },
                    textSecondary: {
                        '&:hover': {
                            background: palette.accentBackgroundGradient,
                        },
                        '&.Mui-selected': {
                            color: palette.textDark,
                            backgroundColor: palette.campaignList.selectedPaginationButton,
                        },
                    },
                },
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        width: '100%',
                    },
                },
            },
        },
        spacing,
        variables: {
            palette,
            fonts,
            icons,
            sass: {},
            button,
            requestPanel,
            infoSidebar,
            footer,
            sidebar,
            alertLink: {
                color: palette.mainDark,
                hoverColor: palette.mainLight,
            },
            requestPrintForms,
            formio: {
                input,
                checkbox,
                checkRadioBox,
                formControl,
                formioDateTime,
                select,
                dateTime: {
                    addonPadding: (inputHeight - 9) / 2, // icons.calendarIcon.width === 9
                },
            },
        },
    };
};

export default createAppThemeOptions;
