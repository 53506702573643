import { AxiosPromise } from 'axios';
import { action, makeObservable, observable } from 'mobx';
import { ChartSettingListDTO, GeneralObjectChartContentStore } from '../../types';

export const chartSettingsListModelObservables = {
    objectStore: observable,
    objectId: observable,
    tabId: observable,
    getChartSettings: action.bound,
    changePosition: action.bound,
};

export class ChartSettingsListModel {
    objectStore: GeneralObjectChartContentStore;
    objectId: string;
    tabId: string;

    constructor(objectId: string, tabId: string, objectStore: GeneralObjectChartContentStore) {
        makeObservable(this, chartSettingsListModelObservables);
        this.objectStore = objectStore;
        this.objectId = objectId;
        this.tabId = tabId;
    }

    getChartSettings(): Promise<ChartSettingListDTO[]> {
        return this.objectStore.getChartSettings(this.tabId, this.objectId);
    }

    changePosition(chatSettingId: string, newPosition: number): AxiosPromise<void> {
        return this.objectStore.changeChartSettingPosition(this.tabId, chatSettingId, newPosition);
    }
}
