import { Box, Grid, Typography } from '@mui/material';
import { makeSxStyles } from '@platform/front-ui';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { di } from 'react-magnetic-di';
import { ErrorCode } from '../../types';
import { RouterLinkToMainPage as RouterLinkToMainPageInj } from '../../components';

const sx = makeSxStyles({
    errorPage: {
        height: '100%',
    },
    contentContainer: {
        position: 'relative',
    },
    errorCodeContainer: {
        position: 'absolute',
        top: '35%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        zIndex: '-1',
    },
    errorCode: {
        fontSize: '400px',
        fontWeight: '700',
        color: (theme) => theme.variables.palette.transparentLight,
    },
    link: {
        display: 'inline',
        cursor: 'pointer',
        color: (theme) => theme.variables.palette.linkMain,
    },
});

export type ErrorPageProps = {
    errorCode: ErrorCode;
    errorText: ReactNode;
};

export const ErrorPage = (props: ErrorPageProps): JSX.Element => {
    const [RouterLinkToMainPage] = di([RouterLinkToMainPageInj], ErrorPage);

    const { errorCode, errorText } = props;

    const history = useHistory();

    return (
        <Grid container direction="column" wrap="nowrap" alignItems="center" justifyContent="center" sx={sx.errorPage}>
            <Grid
                item
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={4}
                sx={sx.contentContainer}
            >
                <Grid item sx={sx.errorCodeContainer}>
                    <Typography sx={sx.errorCode}>{errorCode}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h6" component="p" fontSize={24}>
                        <Box component="span" lineHeight="normal" mb={2}>
                            {errorText}
                        </Box>
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography component="div">
                        <Box lineHeight="normal" fontSize={18}>
                            <FormattedMessage id="errorPage.goTo" />
                            <RouterLinkToMainPage />
                            <React.Fragment>
                                <FormattedMessage id="errorPage.orReturn" />
                                <Box component="a" sx={sx.link} onClick={history.goBack}>
                                    <FormattedMessage id="errorPage.back" />
                                </Box>
                            </React.Fragment>
                        </Box>
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};
