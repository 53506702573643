import { LinkType, TTableRow } from '@platform/ttable';
import { CodeTitle, IdTitle } from './withTitle';

export type CategoryTableRow = TTableRow & {
    customData: {
        title: LinkType;
        author: IdTitle;
        state: CodeTitle & {
            processId: string;
            processTitle: string;
        };
        created: string; //date
    };
};

export enum CreateCategoryFields {
    titles = 'titles',
    form = 'form',
    process = 'process',
}
