import { action, makeObservable, observable } from 'mobx';
import { di } from 'react-magnetic-di';
import { apiConfigs } from '../apiConfigs';
import { LinkDTO } from '../types';
import { ApiStore } from './ApiStore';
import { RootStore } from './RootStore';

export const BreadcrumbsStoreProps = {
    rootStore: observable,
    api: observable,
    getServerRoutes: action.bound,
};

export class BreadcrumbsStore {
    private rootStore: RootStore;
    private api: ApiStore;

    constructor(rootStore: RootStore) {
        makeObservable(this, BreadcrumbsStoreProps);
        this.rootStore = rootStore;
        this.api = rootStore.api;
    }

    getServerRoutes(entityType: string, entityId?: string, location?: string): Promise<LinkDTO[]> {
        return this.api.client(apiConfigs.getServerRoutes(entityType, entityId, location)).then((r) => r.data);
    }
}

export const getBreadcrumbsStore = (): any => {
    const [_BreadcrumbsStore] = di([BreadcrumbsStore], getBreadcrumbsStore);
    return _BreadcrumbsStore;
};
