import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { Redirect, Route, Switch } from 'react-router-dom';
import { entities, permissions } from './authSchemeConfig';
import { clientRoute } from './clientRoute';
import {
    AuthorizationCheck as AuthorizationCheckInj,
    Header as HeaderInj,
    NotificationsWrapper as NotificationsWrapperInj,
} from './components';
import { AppContentContainer as AppContentContainerInj } from './components/AppContentContainer';
import { Authentication as AuthenticationInj } from './components/Authentication';
import { Footer as FooterInj } from './components/footer';
import { useStore } from './hooks';
import './index.scss';
import {
    CategoryPage as CategoryPageInj,
    CategoryRegistryPage as CategoryRegistryPageInj,
    ChildObjectPage as ChildObjectPageInj,
    ChildObjectRegistryPage as ChildObjectRegistryPageInj,
    ConsolePage as ConsolePageInj,
    ErrorPage as ErrorPageInj,
    HomePage as HomePageInj,
    PortfolioPage as PortfolioPageInj,
    PortfolioRegistryPage as PortfolioRegistryPageInj,
    ProjectPage as ProjectPageInj,
    ProjectRegistryPage as ProjectRegistryPageInj,
    UserPage as UserPageInj,
    UserRegistryPage as UserRegistryPageInj,
} from './pages';
import {
    AuthenticationInfoPage as AuthenticationInfoPageInj,
    NewPasswordPage as NewPasswordPageInj,
    RecoveryPasswordPage as RecoveryPasswordPageInj,
    RegistrationConfirmPage as RegistrationConfirmPageInj,
    RegistrationPage as RegistrationPageInj,
} from './pages/authentication';
import { ErrorCode } from './types';

export const App = observer((): JSX.Element => {
    const [AppContentContainer] = di([AppContentContainerInj], App);
    const [AuthorizationCheck] = di([AuthorizationCheckInj], App);
    const [HomePage] = di([HomePageInj], App);
    const [ErrorPage] = di([ErrorPageInj], App);
    const [Authentication] = di([AuthenticationInj], App);
    const [Header] = di([HeaderInj], App);
    const [Footer] = di([FooterInj], App);
    const [NotificationsWrapper] = di([NotificationsWrapperInj], App);
    const [RegistrationPage] = di([RegistrationPageInj], App);
    const [NewPasswordPage] = di([NewPasswordPageInj], App);
    const [RecoveryPasswordPage] = di([RecoveryPasswordPageInj], App);
    const [AuthenticationInfoPage] = di([AuthenticationInfoPageInj], App);
    const [RegistrationConfirmPage] = di([RegistrationConfirmPageInj], App);
    const [CategoryPage] = di([CategoryPageInj], App);
    const [PortfolioRegistryPage] = di([PortfolioRegistryPageInj], App);
    const [CategoryRegistryPage] = di([CategoryRegistryPageInj], App);
    const [PortfolioPage] = di([PortfolioPageInj], App);
    const [ProjectRegistryPage] = di([ProjectRegistryPageInj], App);
    const [ChildObjectRegistryPage] = di([ChildObjectRegistryPageInj], App);
    const [ProjectPage] = di([ProjectPageInj], App);
    const [ChildObjectPage] = di([ChildObjectPageInj], App);
    const [UserRegistryPage] = di([UserRegistryPageInj], App);
    const [UserPage] = di([UserPageInj], App);
    const [ConsolePage] = di([ConsolePageInj], App);

    const { personStore } = useStore();
    const intl = useIntl();

    const notFoundErrorMessage = intl.formatMessage({ id: 'errorPage.notExist' });
    const notAllowedErrorMessage = intl.formatMessage({ id: 'errorPage.actionNotAllowed' });

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Switch>
                <Route path={clientRoute.login} exact>
                    <AppContentContainer>
                        <HomePage />
                    </AppContentContainer>
                </Route>
                <Route>
                    <Header />
                    <AppContentContainer>
                        <Switch>
                            <Route path={[clientRoute.notFound, clientRoute.notAllowed]}>
                                <Authentication redirectUrl={clientRoute.login} backUrl={clientRoute.root}>
                                    <Switch>
                                        <Route path={clientRoute.notFound} exact>
                                            <ErrorPage
                                                errorCode={ErrorCode.notFound}
                                                errorText={notFoundErrorMessage}
                                            />
                                        </Route>
                                        <Route path={clientRoute.notAllowed} exact>
                                            <ErrorPage
                                                errorCode={ErrorCode.notAllowed}
                                                errorText={notAllowedErrorMessage}
                                            />
                                        </Route>
                                    </Switch>
                                </Authentication>
                            </Route>
                            <Route path={clientRoute.newPassword}>
                                <NewPasswordPage />
                            </Route>
                            <Route path={clientRoute.recoveryPassword}>
                                <RecoveryPasswordPage />
                            </Route>
                            <Route path={clientRoute.authenticationInfo}>
                                <AuthenticationInfoPage />
                            </Route>
                            <Route path={clientRoute.registrationConfirm}>
                                <RegistrationConfirmPage />
                            </Route>
                            <Route path={clientRoute.registration}>
                                <RegistrationPage />
                            </Route>
                            <Route
                                path={[
                                    clientRoute.portfolios,
                                    clientRoute.categories,
                                    clientRoute.projects,
                                    clientRoute.childObjects,
                                    clientRoute.users,
                                ]}
                            >
                                <Authentication>
                                    <Switch>
                                        <Route path={clientRoute.categories} exact>
                                            <CategoryRegistryPage />
                                        </Route>
                                        <Route path={clientRoute.category.card}>
                                            <CategoryPage />
                                        </Route>

                                        <Route path={clientRoute.portfolios} exact>
                                            <PortfolioRegistryPage />
                                        </Route>
                                        <Route path={clientRoute.portfolio.card}>
                                            <PortfolioPage />
                                        </Route>

                                        <Route path={clientRoute.projects} exact>
                                            <ProjectRegistryPage />
                                        </Route>
                                        <Route path={clientRoute.project.card}>
                                            <ProjectPage />
                                        </Route>

                                        <Route path={clientRoute.childObjects} exact>
                                            <ChildObjectRegistryPage />
                                        </Route>
                                        <Route path={clientRoute.childObject.card}>
                                            <ChildObjectPage />
                                        </Route>
                                        <Route path={clientRoute.users} exact>
                                            <UserRegistryPage />
                                        </Route>
                                        <Route path={clientRoute.user}>
                                            <UserPage />
                                        </Route>
                                    </Switch>
                                </Authentication>
                            </Route>
                            <Route path={clientRoute.console}>
                                <Authentication>
                                    <AuthorizationCheck
                                        entityCode={entities.system}
                                        permCode={permissions.system.Administration}
                                        isWithRedirect={true}
                                    >
                                        <ConsolePage />
                                    </AuthorizationCheck>
                                </Authentication>
                            </Route>
                            <Route path={clientRoute.root} exact>
                                <Authentication redirectUrl={clientRoute.login}>
                                    <Redirect to={personStore.redirectPath} />
                                </Authentication>
                            </Route>
                            <Route>
                                <Redirect to={clientRoute.notFound} />
                            </Route>
                        </Switch>
                        <NotificationsWrapper />
                    </AppContentContainer>
                    <Footer />
                </Route>
            </Switch>
        </div>
    );
});
