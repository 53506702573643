import { Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { TransitionDTO } from '../../../../../types';

export type GeneralObjectControlTransitionBtnProps = {
    transition: TransitionDTO;
    handleTransition: () => void;
};

export const GeneralObjectControlTransitionBtn = observer(
    (props: GeneralObjectControlTransitionBtnProps): JSX.Element => {
        const { transition, handleTransition } = props;
        const { title, params } = transition;

        return (
            <Button color={params.btnType} onClick={handleTransition} variant="contained">
                {title}
            </Button>
        );
    },
);
