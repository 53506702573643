import { UserStatusAction } from './userPerson';

export type LoginDTO = {
    email: string;
    password: string;
    rememberMe: boolean;
};

export type NewPasswordDTO = {
    token?: string;
    newPassword: string;
    repeatPassword: string;
};

export type EmailDTO = {
    email: string;
    lang: string;
};

export type ConfirmEmailDTO = {
    token?: string;
    lang: string;
};

export type RegistrationDTO = {
    lastName: string;
    firstName: string;
    middleName?: string;
    email: string;
    password: string;
    lang: string;
};

export enum LoginStatus {
    success,
    failed,
}

export enum RegistrationPageFields {
    email = 'email',
    password = 'password',
    firstName = 'firstName',
    middleName = 'middleName',
    lastName = 'lastName',
    lang = 'lang',
}

export enum RecoveryPasswordPageFields {
    email = 'email',
}

export enum NewPasswordPageFields {
    newPassword = 'newPassword',
    repeatPassword = 'repeatPassword',
}

export type AuthenticationFields = RegistrationPageFields | RecoveryPasswordPageFields | NewPasswordPageFields;
