import { Box, Button, CircularProgress, Container, Grid, Link, Paper, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { Link as RouterLink, Redirect, useParams } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { PasswordField as PasswordFieldInj } from '../../components/fields';
import { TotBackground as TotBackgroundInj } from '../../components/TotBackground';
import { useStore, useYup } from '../../hooks';
import { NewPasswordModel } from '../../models';
import { NewPasswordDTO, NewPasswordPageFields } from '../../types';
import { disableSubmitOnEnterKeyPress } from '../../utils';
import { passwordMinLength } from './RegistrationPage';
import { RouterLinkToMainPage as RouterLinkToMainPageInj } from '../../components';

type RouteParams = {
    token: string | undefined;
};

const initialValues: NewPasswordDTO = {
    newPassword: '',
    repeatPassword: '',
};

export const NewPasswordPage = observer((): JSX.Element => {
    const [TotBackground] = di([TotBackgroundInj], NewPasswordPage);
    const [PasswordField] = di([PasswordFieldInj], NewPasswordPage);
    const [RouterLinkToMainPage] = di([RouterLinkToMainPageInj], NewPasswordPage);

    const { token = '' } = useParams<RouteParams>();
    const { authenticationStore } = useStore();
    const intl = useIntl();

    const newPasswordModel = useMemo<NewPasswordModel>(() => {
        return authenticationStore.newPasswordModel(token);
    }, [token]);
    const { changedSucceed, tokenError, isCheckingToken } = newPasswordModel;
    const { newPassword, repeatPassword } = NewPasswordPageFields;
    const { Yup } = useYup();

    const schema = Yup.object().shape({
        newPassword: Yup.string().required().min(passwordMinLength),
        repeatPassword: Yup.string()
            .required()
            .oneOf([Yup.ref('newPassword'), null], intl.formatMessage({ id: 'authentication.passwordsMustMatch' })),
    });

    const handleConfirm = (values: NewPasswordDTO): void => {
        newPasswordModel.updatePassword(values);
    };

    useEffect(() => {
        newPasswordModel.validateToken();
    }, [token]);

    const newPasswordLabel = intl.formatMessage({ id: 'authentication.newPassword' });
    const repeatPasswordLabel = intl.formatMessage({ id: 'authentication.repeatPassword' });

    return (
        <TotBackground withBackdrop={true}>
            <Grid container item direction="column" alignItems="center" justifyContent="center">
                <Container className="t-new-password-page" maxWidth="xs">
                    <Paper elevation={24}>
                        {!isCheckingToken && !tokenError ? (
                            <Box p={12}>
                                <Grid container justifyContent="center">
                                    <Grid item>
                                        <Typography variant="h5" className="t-new-password-title">
                                            <Box fontWeight="fontWeightBold">
                                                <FormattedMessage id="authentication.newPasswordTitle" />
                                            </Box>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Box pt={8}>
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={schema}
                                        onSubmit={handleConfirm}
                                    >
                                        <Form onKeyDown={disableSubmitOnEnterKeyPress}>
                                            <Grid container spacing={6} direction="column">
                                                <Grid item>
                                                    <PasswordField name={newPassword} label={newPasswordLabel} />
                                                </Grid>
                                                <Grid item>
                                                    <PasswordField name={repeatPassword} label={repeatPasswordLabel} />
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        className="t-confirm"
                                                        fullWidth
                                                        size="large"
                                                        variant="contained"
                                                        type="submit"
                                                    >
                                                        <FormattedMessage id="authentication.confirm" />
                                                    </Button>
                                                </Grid>

                                                <Grid item container justifyContent="center">
                                                    <Grid item>
                                                        <Link
                                                            className="t-login"
                                                            component={RouterLink}
                                                            to={clientRoute.login}
                                                            underline="none"
                                                        >
                                                            <Typography variant="caption">
                                                                <FormattedMessage id="authentication.login" />
                                                            </Typography>
                                                        </Link>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    </Formik>
                                </Box>
                            </Box>
                        ) : tokenError ? (
                            <Box p={12}>
                                <Typography align="center" className="t-failed-message">
                                    <FormattedMessage id="authentication.incorrectLink" />{' '}
                                    <FormattedMessage id="errorPage.goTo" />
                                    <RouterLinkToMainPage />
                                    <FormattedMessage id="authentication.doStepsAgain" />
                                </Typography>
                            </Box>
                        ) : (
                            <Box p={6}>
                                <Grid container justifyContent="center">
                                    <Grid item>
                                        <CircularProgress />
                                    </Grid>
                                </Grid>
                            </Box>
                        )}
                    </Paper>
                    {changedSucceed && (
                        <Redirect
                            to={
                                clientRoute.root
                                // clientRoute.campaigns
                            }
                        />
                    )}
                </Container>
            </Grid>
        </TotBackground>
    );
});
