import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { ActionMenuItem as ActionMenuItemInj } from '../../../../../../components';
import { useFlag } from '../../../../../../hooks';
import { ActionItemProps } from '../../../../../../types';
import { UserPageRolesModal as UserPageRolesModalInj } from './UserPageRolesModal';

export const UserPageRolesButton = observer((props: ActionItemProps): JSX.Element => {
    const [ActionMenuItem] = di([ActionMenuItemInj], UserPageRolesButton);
    const [UserPageRolesModal] = di([UserPageRolesModalInj], UserPageRolesButton);

    const { hideMenu } = props;
    const [isDialogOpen, setIsDialogOpen, setIsDialogClosed] = useFlag();

    return (
        <React.Fragment>
            <ActionMenuItem messageId="user.settings.roles" onClick={setIsDialogOpen} />
            <UserPageRolesModal isOpen={isDialogOpen} setIsClosed={setIsDialogClosed} hideMenu={hideMenu} />
        </React.Fragment>
    );
});
