import { Delete, Edit, Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, SvgIcon, TableCell, TableRow, Tooltip } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, Link as RouterLink, useParams } from 'react-router-dom';
import { sxWidth25, sxWidth30 } from '../../../../constants';
import { useFlag, useGeneralObjectContext } from '../../../../hooks';
import { MoveIcon } from '../../../../resources';
import { GeneralObjectRouteParams, ObjectTabSettingsDTO, SettingsContentType } from '../../../../types';

export type GeneralObjectSettingsTabItemProps = {
    tabModel: ObjectTabSettingsDTO;
    provided: DraggableProvided;
    openDeleteModal: () => void;
    openEditArchivedModal: () => void;
};

const renderContentType = (contentType: SettingsContentType[]): JSX.Element => {
    const lastContentTypeIndex = contentType.length - 1;
    return (
        <React.Fragment>
            {contentType.map((content, i) => {
                return (
                    <React.Fragment>
                        <FormattedMessage id={`objectSettings.contentTypes.${content}`} />
                        {i !== lastContentTypeIndex && ', '}
                    </React.Fragment>
                );
            })}
        </React.Fragment>
    );
};

const actionsRowSx: SxProps<Theme> = { width: '15%', minWidth: '152px' };

export const GeneralObjectSettingsTabItem = observer((props: GeneralObjectSettingsTabItemProps): JSX.Element => {
    const { provided, tabModel, openDeleteModal, openEditArchivedModal } = props;
    const { code, contentType, title, archived } = tabModel;
    const { routes } = useGeneralObjectContext();
    const { id } = useParams<GeneralObjectRouteParams>();
    const intl = useIntl();

    const [isCursorInsideTableRow, setIsCursorInsideTableRowTrue, setIsCursorInsideTableRowFalse] = useFlag();

    const visibilityButton: JSX.Element = archived ? (
        <Tooltip title={<FormattedMessage id="objectSettings.showTab" />}>
            <IconButton onClick={openEditArchivedModal}>
                <VisibilityOff />
            </IconButton>
        </Tooltip>
    ) : isCursorInsideTableRow ? (
        <Tooltip title={<FormattedMessage id="common.hide" />}>
            <IconButton onClick={openEditArchivedModal}>
                <Visibility />
            </IconButton>
        </Tooltip>
    ) : (
        <React.Fragment />
    );

    return (
        <TableRow
            key={code}
            onMouseOver={setIsCursorInsideTableRowTrue}
            onMouseLeave={setIsCursorInsideTableRowFalse}
            ref={provided.innerRef}
            {...provided.draggableProps}
        >
            <TableCell sx={sxWidth30}>{title}</TableCell>
            <TableCell sx={sxWidth25}>{code}</TableCell>
            <TableCell sx={sxWidth25}>{renderContentType(contentType)}</TableCell>
            <TableCell align="right" sx={actionsRowSx}>
                {visibilityButton}
                <Tooltip title={intl.formatMessage({ id: 'common.move' })}>
                    <IconButton {...provided.dragHandleProps}>
                        <SvgIcon viewBox="0 0 20 20">
                            <MoveIcon />
                        </SvgIcon>
                    </IconButton>
                </Tooltip>
                <Tooltip title={intl.formatMessage({ id: 'common.edit' })}>
                    <IconButton
                        component={RouterLink}
                        to={generatePath(routes.settingsTabContent, { id, tabId: tabModel.id })}
                        size="small"
                    >
                        <Edit />
                    </IconButton>
                </Tooltip>
                <Tooltip title={<FormattedMessage id="common.delete" />}>
                    <IconButton size="small" onClick={openDeleteModal}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
});
