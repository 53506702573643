import { Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { entities, permissions } from '../../../authSchemeConfig';
import { AuthorizationCheck as AuthorizationCheckInj } from '../../../components';
import { useFlag } from '../../../hooks';
import { projectMessages } from '../../../resources';
import { ProjectRegistryCreateDialog as ProjectRegistryCreateDialogInj } from './ProjectRegistryCreateDialog';

export const ProjectRegistryCreateButton = observer((): JSX.Element => {
    const [AuthorizationCheck] = di([AuthorizationCheckInj], ProjectRegistryCreateButton);
    const [ProjectRegistryCreateDialog] = di([ProjectRegistryCreateDialogInj], ProjectRegistryCreateButton);

    const [isCreateDialogOpen, openCreateDialog, closeCreateDialog] = useFlag();

    return (
        <React.Fragment>
            <AuthorizationCheck entityCode={entities.system} permCode={permissions.system.CreateProject}>
                <Button variant="contained" color="primary" onClick={openCreateDialog}>
                    <FormattedMessage {...projectMessages.create} />
                </Button>
            </AuthorizationCheck>
            <ProjectRegistryCreateDialog isOpen={isCreateDialogOpen} setIsClosed={closeCreateDialog} />
        </React.Fragment>
    );
});
