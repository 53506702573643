import React from 'react';

export const stopPropagation = (event: React.KeyboardEvent<HTMLElement>): void => {
    event.stopPropagation();
};

export const disableSubmitOnEnterKeyPress = (event: React.KeyboardEvent<HTMLFormElement>): void => {
    if (event.code === 'Enter') {
        event.preventDefault();
    }
};
