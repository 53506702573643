import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { EditGeneralObjectNumber as EditGeneralObjectNumberInj } from '../../../../components';
import { useStore } from '../../../../hooks';
import { EditModalProps } from '../../../../types';

export const EditPortfolioNumber = observer((props: EditModalProps): JSX.Element => {
    const [EditGeneralObjectNumber] = di([EditGeneralObjectNumberInj], EditPortfolioNumber);
    const { portfolioStore } = useStore();
    const intl = useIntl();
    const dialogTitle = intl.formatMessage({ id: 'generalObject.changeNumber' });

    return (
        <EditGeneralObjectNumber
            dialogTitle={dialogTitle}
            generalObjectEditNumber={portfolioStore.editPortfolioNumber}
            {...props}
        />
    );
});
