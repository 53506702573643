import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { Route } from 'react-router-dom';
import { useGeneralObjectContext } from '../../../../../hooks';
import { GeneralObjectControlTransitions as GeneralObjectControlTransitionsInj } from './GeneralObjectControlTransitions';

export const GeneralObjectControlPanel = observer((): JSX.Element => {
    const [GeneralObjectControlTransitions] = di([GeneralObjectControlTransitionsInj], GeneralObjectControlPanel);
    const { routes } = useGeneralObjectContext();

    return (
        <Route path={[routes.card, routes.tab]} exact>
            <GeneralObjectControlTransitions />
        </Route>
    );
});
