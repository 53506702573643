import { Box, Container, Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { registryPageTableSxStyles } from '../../components';
import { useServerTitleBreadcrumbs } from '../../hooks';
import { BreadcrumbsLocation, ProjectTableRow } from '../../types';
import {
    ProjectRegistryActionsBtns as ProjectRegistryActionsBtnsInj,
    ProjectRegistryTable as ProjectRegistryTableInj,
} from './components';
import { ProjectRegistryCreateButton as ProjectRegistryCreateButtonInj } from './components/ProjectRegistryCreateButton';

export const ProjectRegistryPage = observer((): JSX.Element => {
    const [ProjectRegistryCreateButton] = di([ProjectRegistryCreateButtonInj], ProjectRegistryPage);
    const [ProjectRegistryActionsBtns] = di([ProjectRegistryActionsBtnsInj], ProjectRegistryPage);
    const [ProjectRegistryTable] = di([ProjectRegistryTableInj], ProjectRegistryPage);

    useServerTitleBreadcrumbs({ location: BreadcrumbsLocation.project });

    const setRowActions = (
        row: ProjectTableRow,
        reloadData: () => void,
        toggleIsRowBlocked: () => void,
        rowErrorHandler: () => void,
    ): JSX.Element => {
        return (
            <ProjectRegistryActionsBtns
                projectTableRow={row}
                reloadData={reloadData}
                toggleIsRowBlocked={toggleIsRowBlocked}
                rowErrorHandler={rowErrorHandler}
            />
        );
    };

    return (
        <Container maxWidth={false}>
            <Box pt={5.5} pb={15}>
                <Grid container spacing={5}>
                    <Grid item container justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h1" component="h1">
                                <FormattedMessage id="project.plural.nominative" />
                            </Typography>
                        </Grid>
                        <Grid item>
                            <ProjectRegistryCreateButton />
                        </Grid>
                    </Grid>
                    <Grid item container direction="column">
                        <Grid item sx={registryPageTableSxStyles.tableWrapper}>
                            <ProjectRegistryTable setRowActions={setRowActions} />
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
});
