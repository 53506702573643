import React, { PropsWithChildren } from 'react';
import { useFlag } from '../hooks';

export type ErrorBoundaryProps = PropsWithChildren<{
    setIsError?: () => void;
    replacementComponent?: JSX.Element;
}>;

export type ErrorBoundaryCatchProps = ErrorBoundaryProps & {
    hasError: boolean;
    setHasError: () => void;
};

export const ErrorBoundary = (props: ErrorBoundaryProps): JSX.Element => {
    const [hasError, setHasError] = useFlag();
    return <ErrorBoundaryCatch {...props} hasError={hasError} setHasError={setHasError} />;
};

class ErrorBoundaryCatch extends React.Component<ErrorBoundaryCatchProps> {
    componentDidCatch() {
        const { setIsError, setHasError } = this.props;
        setIsError && setIsError();
        setHasError();
    }

    render() {
        const { hasError, replacementComponent = <React.Fragment />, children } = this.props;
        if (hasError) {
            return replacementComponent;
        }

        return children;
    }
}
