import { Grid } from '@mui/material';
import { makeSxStyles } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { Loader as LoaderInj } from '../../components';
import { UserPageContext } from '../../contexts';
import { useStore } from '../../hooks';
import { UserCardModel } from '../../models';
import {
    UserPageHeader as UserPageHeaderInj,
    UserPageMainContent as UserPageMainContentInj,
    UserPageSidebar as UserPageSidebarInj,
} from './components';

export type UserPageRouteParams = {
    id: string;
};

const sx = makeSxStyles({
    wrapper: {
        height: '100%',
    },
    fullSpace: {
        flexGrow: 1,
    },
});

export const UserPage = observer((): JSX.Element => {
    const [UserPageHeader] = di([UserPageHeaderInj], UserPage);
    const [Loader] = di([LoaderInj], UserPage);
    const [UserPageMainContent] = di([UserPageMainContentInj], UserPage);
    const [UserPageSidebar] = di([UserPageSidebarInj], UserPage);

    const { id } = useParams<UserPageRouteParams>();
    const { userStore, intlStore } = useStore();
    const model = useMemo(() => new UserCardModel(userStore, id), [userStore, id]);

    useEffect(() => {
        model.load();
    }, [id, intlStore.locale]);

    const { formInfo, isLoading } = model;

    return (
        <UserPageContext.Provider
            value={{
                userModel: model,
            }}
        >
            <Grid container direction="column" sx={sx.wrapper}>
                <UserPageHeader />
                <Grid container item wrap="nowrap" sx={sx.fullSpace}>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <React.Fragment>{formInfo && <UserPageMainContent formDTO={formInfo} />}</React.Fragment>
                    )}
                    <UserPageSidebar />
                </Grid>
            </Grid>
        </UserPageContext.Provider>
    );
});
