import { LocalesTree } from '../../../types';

const feature: LocalesTree = {
    phrases: {
        productTitle: 'TOT Record',
        logoAlt: 'TOT Record logo',
        footerLogoAlt: 'TOT Record logo',
        productName: 'Project and contract management system',
        productDescription: 'Control projects and contracts on all their lifecycle stages',
    },
};

export default feature;
