import { GeneralObjectField, GeneralObjectFieldName, IdTitle } from '../types';

export const getDownloadAllFilesArchiveName = (
    commonDescription: GeneralObjectField[],
    tabs: IdTitle[],
    tabId: string,
): string => {
    const generalObjectNumber = commonDescription.find(
        (generalObjectField) => generalObjectField.id === GeneralObjectFieldName.number,
    );
    const generalObjectNumberValue = typeof generalObjectNumber?.value === 'string' ? generalObjectNumber.value : '';

    const currentTabData = tabs.find((tabData) => tabData.id === tabId);
    const currentTabTitle = currentTabData ? currentTabData.title : '';
    const allFilesArchiveName = `${generalObjectNumberValue}_${currentTabTitle}.zip`;

    return allFilesArchiveName;
};
