import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';
import { TextField } from 'formik-mui';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { RegistrationPageFields } from '../../types';
import { RequiredLabel } from '../RequiredLabel';
import { FieldWithServerError as FieldWithServerErrorInj } from './FieldWithServerError';

export type PasswordFieldProps = {
    label?: React.ReactNode;
    newPassword?: boolean;
    name?: string;
    serverError?: string;
    required?: boolean;
};

export const PasswordField = observer((props: PasswordFieldProps): JSX.Element => {
    const [FieldWithServerError] = di([FieldWithServerErrorInj], PasswordField);

    const { label, newPassword = true, name = RegistrationPageFields.password, required, serverError } = props;
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const intl = useIntl();
    const fieldType = showPassword ? 'text' : 'password';

    const toggleVisibilityPassword = (): void => {
        setShowPassword(!showPassword);
    };

    const fieldLabel = label || intl.formatMessage({ id: 'authentication.password' });

    return (
        <FieldWithServerError
            component={TextField}
            name={name}
            serverError={serverError}
            size="normal"
            disabled={false}
            inputProps={{
                className: 't-password-field',
                ...(newPassword && { autocomplete: 'new-password' }),
            }}
            FormHelperTextProps={{
                className: 't-password-field-help-text',
            }}
            fullWidth
            label={required ? <RequiredLabel text={fieldLabel} /> : fieldLabel}
            variant="outlined"
            type={fieldType}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton className="t-visibility-password" onClick={toggleVisibilityPassword}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
});
