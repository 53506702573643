import { FormDTO } from '@platform/formiojs-react';
import { FieldValues as LangTitle, TitlesGroupDTO } from '@platform/multi-lang-field';
import { UserCardModel } from '../models';
import { TotLocale } from './intl';
import { CodeTitle } from './withTitle';

export type FullName = {
    lastName: string;
    firstName: string;
    middleName?: string;
};

export type PersonDTO = FullName & {
    uuid: string;
    recipientId: string;
    userId: string;
    lang: string;
    blocks: [];
};

// чаще всего приходит в качестве значения "автора", "исполнителя", "ответственного" и т.д.
export type UserPersonDTO = {
    userId: string;
    person: PersonDTO;
};

export type PersonInfo = {
    user: UserModel;
    person: PersonDTO;
    roles: UserRole[];
};

export class UserModel {
    id = '';
    email = '';
    blocked = false;
}

export type UserDTO = {
    fullName: string;
    userId: string;
    lang: TotLocale;
    login: string;
    status: UserState;
    roles: CodeTitle[];
    formInfo: FormDTO;
};

export type UserPageContextType = {
    userModel: UserCardModel;
};

export type RolesSettings = {
    roles: CodeTitle[];
};

export type RolesSettingsDTO = {
    roles: string[];
};

export type FullNameSettings = {
    lastName: LangTitle[];
    firstName: LangTitle[];
    middleName: LangTitle[];
};

export enum FullNameGroupFields {
    lastName = 'lastName',
    firstName = 'firstName',
    middleName = 'middleName',
}

export type FullNameSettingsDTO = TitlesGroupDTO<FullNameGroupFields>;

export type UserUpdateBody = {
    email: string;
    lang: TotLocale;
    roles: string[];
};

export enum RolesFields {
    roles = 'roles',
}

export enum FullNameFields {
    fullName = 'fullName',
}

export enum UserRole {
    admin = 'Admin',
    curator = 'Curator',
    moderator = 'Moderator',
    expertiseCurator = 'ExpertiseCurator',
    supervisor = 'Supervisor',
    executor = 'Executor',
    user = 'User',
}

export enum UserStatus {
    active = 'active',
    inactive = 'inactive',
    blocked = 'blocked',
}

export enum UserStatusAction {
    activate = 'activate',
    unblock = 'unblock',
    block = 'block',
}

export type UserState = {
    code: UserStatus;
    title: string;
};

export type EditStatusDTO = {
    action: UserStatusAction;
};

export type RolePriority = Record<UserRole, number>;
export type RedirectByRole = Record<UserRole, string>;

export type UserPageFields = RolesFields | FullNameFields;
