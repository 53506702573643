import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { getRuTitleInitialValue, MultiLangField, titlesYupSchema } from '@platform/multi-lang-field';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useAntiDoubleClick, useYup } from '../../../../hooks';
import { ObjectSettingsModel } from '../../../../models';
import { ModalProps, NewObjectTabSettings, SettingsTabFields } from '../../../../types';
import { disableSubmitOnEnterKeyPress } from '../../../../utils';
import { FieldWithServerError as FieldWithServerErrorInj } from '../../../fields';
import { RequiredLabel as RequiredLabelInj } from '../../../RequiredLabel';
import { ServerErrorHelper as ServerErrorHelperInj } from '../../../ServerErrorHelper';

export type GeneralObjectTabFormModalProps = ModalProps & {
    settingsModel: ObjectSettingsModel;
};

const initialValues: NewObjectTabSettings = {
    titles: getRuTitleInitialValue(),
    code: '',
};

export const GeneralObjectTabFormModal = observer((props: GeneralObjectTabFormModalProps): JSX.Element => {
    const [RequiredLabel] = di([RequiredLabelInj], GeneralObjectTabFormModal);
    const [FieldWithServerError] = di([FieldWithServerErrorInj], GeneralObjectTabFormModal);
    const [ServerErrorHelper] = di([ServerErrorHelperInj], GeneralObjectTabFormModal);

    const { isOpen, setIsClosed, settingsModel } = props;
    const { addNewTab, serverErrors } = settingsModel;
    const { serverFormErrors } = serverErrors;
    const { code, titles } = SettingsTabFields;
    const serverTitlesError = serverFormErrors[titles];
    const intl = useIntl();
    const { Yup } = useYup();

    const handleSubmit = (values: NewObjectTabSettings): Promise<void> => {
        return addNewTab(values, setIsClosed);
    };

    const [isSending, endIcon, createHandler] = useAntiDoubleClick(handleSubmit);

    const schema = Yup.object().shape({
        titles: titlesYupSchema(Yup, true).min(1),
        code: Yup.string().required(),
    });

    const codeLabel = <RequiredLabel text={intl.formatMessage({ id: 'objectSettings.tabFields.code' })} />;

    return (
        <Dialog fullWidth={true} maxWidth="sm" open={isOpen} scroll="body">
            <DialogTitle>
                <FormattedMessage id="objectSettings.tabModalTitle" />
            </DialogTitle>
            <Formik initialValues={initialValues} validationSchema={schema} onSubmit={createHandler}>
                <Form onKeyDown={disableSubmitOnEnterKeyPress}>
                    <DialogContent>
                        <Grid container direction="column" spacing={4}>
                            <Grid item>
                                <MultiLangField systemLanguage={intl.locale} fieldName={titles} ruEngLangs={true} />
                                <ServerErrorHelper serverError={serverTitlesError} />
                            </Grid>
                            <Grid item>
                                <FieldWithServerError
                                    name={code}
                                    variant="outlined"
                                    fullWidth
                                    label={codeLabel}
                                    helperText={<FormattedMessage id="objectSettings.codeHelperText" />}
                                    serverError={serverFormErrors[code]}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={setIsClosed} variant="text">
                            <FormattedMessage id="common.cancel" />
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={isSending}
                            endIcon={endIcon}
                        >
                            <FormattedMessage id="common.create" />
                        </Button>
                    </DialogActions>
                </Form>
            </Formik>
        </Dialog>
    );
});
