var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from 'styled-components';
import NoViewIcon from '../../images/no-view-icon.svg';
import ViewIcon from '../../images/view-icon.svg';
export default function () {
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        .password-view {\n            font-size: 6px;\n            letter-spacing: 2px;\n        }\n\n        .password {\n            position: relative;\n        }\n\n        .password-view-control {\n            position: absolute;\n            right: 33px;\n            bottom: 34px;\n            display: inline-block;\n            width: 20px;\n            height: 20px;\n            border: none;\n            background: url(", ") 0 0 no-repeat;\n\n            &--show {\n                background: url(", ") 0 0 no-repeat;\n            }\n        }\n    "], ["\n        .password-view {\n            font-size: 6px;\n            letter-spacing: 2px;\n        }\n\n        .password {\n            position: relative;\n        }\n\n        .password-view-control {\n            position: absolute;\n            right: 33px;\n            bottom: 34px;\n            display: inline-block;\n            width: 20px;\n            height: 20px;\n            border: none;\n            background: url(", ") 0 0 no-repeat;\n\n            &--show {\n                background: url(", ") 0 0 no-repeat;\n            }\n        }\n    "])), NoViewIcon, ViewIcon).join('');
}
var templateObject_1;
