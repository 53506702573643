import { navigation_ru } from '@platform/modular-navigation-widget';
import { notification_ru } from '@platform/notification-widget';
import { ttable_ru } from '@platform/ttable';
import { recordToLang } from '../../../utils';
import authentication from './authentication';
import author from './author';
import autocompleteField from './autocompleteField';
import category from './category';
import childObject from './childObject';
import childObjectExecutor from './childObjectExecutor';
import common from './common';
import confirmLeavePage from './confirmLeavePage';
import console from './console';
import errorPage from './errorPage';
import feature from './feature';
import generalObject from './generalObject';
import locale from './locale';
import monitor from './monitor';
import notifications from './notifications';
import objectSettings from './objectSettings';
import portfolio from './portfolio';
import portfolioManager from './portfolioManager';
import project from './project';
import projectCurator from './projectCurator';
import projectManager from './projectManager';
import sidebar from './sidebar';
import startPage from './startPage';
import syslog from './syslog';
import user from './user';
import userInfo from './userInfo';
import validation from './validation';
import version from './version';
import viewer from './viewer';
import visualization from './visualization';

export const localesRu: Record<string, string> = {
    ...recordToLang({
        feature,
        locale,
        author,
        autocompleteField,
        authentication,
        validation,
        errorPage,
        startPage,
        user,
        common,
        sidebar,
        notifications,
        generalObject,
        objectSettings,
        portfolio,
        category,
        project,
        confirmLeavePage,
        viewer,
        portfolioManager,
        monitor,
        projectCurator,
        projectManager,
        childObjectExecutor,
        childObject,
        syslog,
        console,
        version,
        userInfo,
        visualization,
    }),
    ...recordToLang(
        {
            navigation: navigation_ru,
            notification: notification_ru,
            ttable: ttable_ru,
        },
        false,
    ),
};
