import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link, ListItemIcon, MenuItem, MenuItemProps } from '@mui/material';
import { makeSxStylesWithProps } from '@platform/front-ui';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { redesignTotPalette } from '../../theme';

export type ActionMenuItemProps = {
    messageId: string;
    path?: string;
    isActive?: boolean;
    isWithIcon?: boolean;
    icon?: JSX.Element;
} & Omit<MenuItemProps, 'button'>;

export type UseActionMenuItemSxStylesProps = {
    isActive: boolean;
};

export const useActionMenuItemSxStyles = makeSxStylesWithProps(({ isActive }: UseActionMenuItemSxStylesProps) => ({
    wrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: isActive ? redesignTotPalette.actionButton.active.background : 'transparent',
        width: '100%',
    },
    icon: {
        display: 'flex',
        justifyContent: 'flex-end',
        color: isActive ? redesignTotPalette.iconButton.active.color : redesignTotPalette.iconButton.default.color,
    },
}));

export const ActionMenuItem = (props: ActionMenuItemProps): JSX.Element => {
    const { path, messageId, isActive = false, isWithIcon, icon, ...rest } = props;
    const sx = useActionMenuItemSxStyles({ isActive });

    return (
        // @ts-ignore
        <MenuItem component="button" sx={sx.wrapper} dense {...rest}>
            {path ? (
                <Link component={NavLink} underline="none" to={path} style={{ color: 'inherit' }}>
                    <FormattedMessage id={messageId} />
                </Link>
            ) : (
                <FormattedMessage id={messageId} />
            )}
            {isWithIcon && (
                <ListItemIcon sx={sx.icon}>
                    {icon ? icon : <ArrowForwardIosIcon sx={{ height: '12px' }} />}
                </ListItemIcon>
            )}
        </MenuItem>
    );
};
