import { observer } from 'mobx-react';
import React from 'react';
import { generatePath } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { InlineLink } from '../../../components';

export type PortfolioLinkProps = {
    id: string;
    title: string;
};

export const PortfolioLink = observer((props: PortfolioLinkProps): JSX.Element => {
    const { id, title } = props;

    return <InlineLink title={title} to={generatePath(clientRoute.portfolio.card, { id })} />;
});
