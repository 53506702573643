import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { clientRoute } from '../../clientRoute';
import { GeneralObjectMainComponent as GeneralObjectMainComponentInj } from '../../components';
import { GeneralObjectContext } from '../../contexts';
import { useStore } from '../../hooks';
import { RenderCreateDescendantModal, RenderEditModal } from '../../types';
import {
    EditPortfolioManager as EditPortfolioManagerInj,
    EditPortfolioNumber as EditPortfolioNumberInj,
    EditPortfolioViewers as EditPortfolioViewersInj,
    PortfolioDescendantProjectModal as PortfolioDescendantProjectModalInj,
} from './components';

export const PortfolioPage = observer((): JSX.Element => {
    const [PortfolioDescendantProjectModal] = di([PortfolioDescendantProjectModalInj], PortfolioPage);
    const [GeneralObjectMainComponent] = di([GeneralObjectMainComponentInj], PortfolioPage);
    const [EditPortfolioNumber] = di([EditPortfolioNumberInj], PortfolioPage);
    const [EditPortfolioManager] = di([EditPortfolioManagerInj], PortfolioPage);
    const [EditPortfolioViewers] = di([EditPortfolioViewersInj], PortfolioPage);

    const { portfolioStore } = useStore();

    const renderEditModals: RenderEditModal = {
        number: EditPortfolioNumber,
        portfolioManager: EditPortfolioManager,
        portfolioViewer: EditPortfolioViewers,
    };

    const renderRegistryModals: RenderCreateDescendantModal = {
        project: PortfolioDescendantProjectModal,
    };

    const routes = clientRoute[portfolioStore.objectType];

    return (
        <GeneralObjectContext.Provider
            value={{
                objectStore: portfolioStore,
                renderRegistryModals,
                renderEditModals,
                routes,
            }}
        >
            <GeneralObjectMainComponent />
        </GeneralObjectContext.Provider>
    );
});
