import { Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useFeature } from 'feature-toggle-jsx';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { CustomLogoImg as CustomLogoImgInj } from '../../../components';
import { AppTheme, CustomLogoFeature } from '../../../types';

const TotLogo = styled('span')(
    ({ theme }) => `
        color: ${(theme as AppTheme).palette.primary.contrastText};
        font-size: 30px;
        font-weight: bold;
        line-height: 1.4;
        letter-spacing: 0.6px;
    `,
);

export const HomePageLogo = (): JSX.Element => {
    const [CustomLogoImg] = di([CustomLogoImgInj], HomePageLogo);

    const { startPageLogoImg } = CustomLogoFeature;
    const [isWithStartPageLogoText] = useFeature('startPageLogoText');

    return (
        <Grid container alignItems="center" wrap="nowrap">
            <Grid item>
                <Box pr={3}>
                    <CustomLogoImg logoFeatureName={startPageLogoImg} />
                </Box>
            </Grid>
            {isWithStartPageLogoText && (
                <Grid item>
                    <TotLogo>
                        <FormattedMessage id="feature.phrases.productTitle" />
                    </TotLogo>
                </Grid>
            )}
        </Grid>
    );
};
