import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { mergeSxStyles, SxStyles } from '@platform/front-ui';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { highlight, languages } from 'prismjs';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-scala';
import 'prismjs/themes/prism.css';
import React, { ReactNode, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import Editor from 'react-simple-code-editor';
import { CommonTypography as CommonTypographyInj } from '../../components';
import { consoleEditorClass, consoleErrorClass, tabSize } from '../../constants';
import { useAntiDoubleClick, useServerTitleBreadcrumbs, useStore } from '../../hooks';
import { ConsoleModel } from '../../models';
import { BreadcrumbsLocation } from '../../types';
import { ResultConsoleControls as ResultConsoleControlsInj } from './components';
import './error.scss';
import { consoleEditorStyles, consoleSxStyles as sx } from './useConsoleStyles';

const handleHighlight = (code: string): ReactNode => {
    return highlight(code, languages.scala, 'scala');
};

export const ConsolePage = observer((): JSX.Element => {
    const [CommonTypography] = di([CommonTypographyInj], ConsolePage);
    const [ResultConsoleControls] = di([ResultConsoleControlsInj], ConsolePage);

    const rootStore = useStore();
    const model = useMemo(() => new ConsoleModel(rootStore), [rootStore]);
    const { code, result, error, setCode, runScript } = model;
    const [isSending, endIcon, actionHandler] = useAntiDoubleClick(runScript);
    useServerTitleBreadcrumbs({ location: BreadcrumbsLocation.console });

    const generalEditorProps = {
        onValueChange: setCode,
        highlight: handleHighlight,
        style: consoleEditorStyles,
        padding: 10,
        tabSize,
    };

    const resultClasses = cn(consoleEditorClass, { [consoleErrorClass]: !!error });
    const resultValue = error || result;

    const isRunBtnDisabled = isSending || !code.replace(/\s/g, '');

    const editorWithResultWrapperSx = mergeSxStyles(sx.editorWrapper, sx.resultWrapper);

    return (
        <Container maxWidth="xl" sx={sx.wrapper}>
            <Grid container direction="column" sx={sx.inner}>
                <Grid item sx={sx.mainBlock}>
                    <Typography variant="h1" component="h1">
                        <FormattedMessage id="console.single.nominative" />
                    </Typography>
                </Grid>
                <Grid container item flexGrow={1} wrap="nowrap" justifyContent="space-between" sx={sx.mainBlock}>
                    <Grid container item direction="column" sx={sx.codeWrapperLeft} xs={6}>
                        <Grid item sx={sx.columnTitle}>
                            <CommonTypography>
                                <FormattedMessage id="console.script" />:
                            </CommonTypography>
                        </Grid>
                        <Grid item sx={sx.editorWrapper} flexGrow={1}>
                            <Editor
                                {...generalEditorProps}
                                textareaClassName={consoleEditorClass}
                                preClassName={consoleEditorClass}
                                value={code}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item direction="column" sx={sx.codeWrapperRight} xs={6}>
                        <Grid item sx={sx.columnTitle}>
                            <CommonTypography>
                                <FormattedMessage id="common.result" />:
                            </CommonTypography>
                        </Grid>
                        <Grid item sx={editorWithResultWrapperSx} flexGrow={1}>
                            <Box sx={sx.resultControlsWrapper}>
                                <ResultConsoleControls model={model} />
                            </Box>

                            {!!resultValue && (
                                <Editor
                                    {...generalEditorProps}
                                    textareaClassName={resultClasses}
                                    preClassName={resultClasses}
                                    value={resultValue}
                                    readOnly={true}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item justifyContent="flex-end">
                    <Grid item>
                        <Button
                            onClick={actionHandler}
                            variant="contained"
                            disabled={isRunBtnDisabled}
                            endIcon={endIcon}
                        >
                            <FormattedMessage id="console.runScript" />
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
});
