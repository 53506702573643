import { IconButton, SvgIcon } from '@mui/material';
import { observer } from 'mobx-react';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath } from 'react-router-dom';
import { permissionsConfig } from '../../../authSchemeConfig';
import { clientRoute } from '../../../clientRoute';
import {
    ActionMenuItem as ActionMenuItemInj,
    CreateCategoryCopyBtn as CreateCategoryCopyBtnInj,
    GeneralObjectDeleteBtn as GeneralObjectDeleteBtnInj,
    MenuButton as MenuButtonInj,
} from '../../../components';
import { useStore } from '../../../hooks';
import { DotMenu } from '../../../resources';
import { AuthorizationCheckQuery, CategoryTableRow, GeneralObject } from '../../../types';

export type CampaignRequestActionsBtnsProps = {
    categoryRow: CategoryTableRow;
    reloadData: () => void;
    isConfirm?: boolean;
    setIsConfirm?: React.Dispatch<React.SetStateAction<boolean>>;
    toggleIsRowBlocked: () => void;
    rowErrorHandler: () => void;
};

const category = GeneralObject.category;

export const CategoryRegistryActionsBtns = observer((props: CampaignRequestActionsBtnsProps): JSX.Element => {
    const [ActionMenuItem] = di([ActionMenuItemInj], CategoryRegistryActionsBtns);
    const [GeneralObjectDeleteBtn] = di([GeneralObjectDeleteBtnInj], CategoryRegistryActionsBtns);
    const [MenuButton] = di([MenuButtonInj], CategoryRegistryActionsBtns);
    const [CreateCategoryCopyBtn] = di([CreateCategoryCopyBtnInj], CategoryRegistryActionsBtns);

    const { categoryRow, reloadData, isConfirm, setIsConfirm, toggleIsRowBlocked, rowErrorHandler } = props;
    const { id } = categoryRow;

    const [permissionsData, setPermissionsData] = useState<boolean[]>([]);
    const [editCategory, deleteCategory, createCategoryCopy] = permissionsData;

    const { categoryStore, authorizationStore, notificationStore } = useStore();
    const intl = useIntl();

    useEffect(() => {
        if (setIsConfirm && isConfirm) {
            setIsConfirm(false);
            reloadData();
        }
    }, [isConfirm, setIsConfirm]);

    const handleDeleteCategory = useCallback((): Promise<void> => {
        return categoryStore.deleteObject(id, true).then(reloadData).catch(rowErrorHandler);
    }, [reloadData, rowErrorHandler, id, categoryStore, notificationStore]);

    const allPermissionsQueries = useMemo<AuthorizationCheckQuery[]>(() => {
        return [
            permissionsConfig.updateGeneralObject(category, id),
            permissionsConfig.deleteGeneralObject(category, id),
            permissionsConfig.createCategoryCopy,
        ];
    }, [id]);

    useEffect(() => {
        authorizationStore.checkAll(allPermissionsQueries).then(setPermissionsData);
    }, [allPermissionsQueries]);

    const renderActionItems = useCallback((): ((hideMenu?: () => void) => ReactNode[]) => {
        const { id } = categoryRow;
        const renderItems = (hideMenu?: () => void): ReactNode[] => {
            const onDeleteClick = (): Promise<void> => {
                hideMenu && hideMenu();
                toggleIsRowBlocked();
                return handleDeleteCategory();
            };

            return [
                <React.Fragment key="actionBtns">
                    {editCategory && (
                        <ActionMenuItem
                            messageId="common.edit"
                            path={generatePath(clientRoute.category.card, { id })}
                        />
                    )}
                    {createCategoryCopy && <CreateCategoryCopyBtn prototypeCategoryId={id} />}
                    {deleteCategory && (
                        <GeneralObjectDeleteBtn
                            object={intl.formatMessage({ id: 'category.single.accusative' })}
                            title={categoryRow.customData.title.title}
                            onDeleteClick={onDeleteClick}
                        />
                    )}
                </React.Fragment>,
            ];
        };
        return renderItems;
    }, [permissionsData, categoryRow, toggleIsRowBlocked]);

    const renderActionsButton = (onClick: (event: React.MouseEvent<HTMLButtonElement>) => void): JSX.Element => {
        const atLeastOneAllowed = permissionsData.some((isPermissionAllowed) => isPermissionAllowed);
        return atLeastOneAllowed ? (
            <IconButton className="t-menu-button" onClick={onClick}>
                <SvgIcon>
                    <DotMenu />
                </SvgIcon>
            </IconButton>
        ) : (
            <React.Fragment />
        );
    };

    return (
        <React.Fragment>
            <MenuButton renderButton={renderActionsButton} renderMenuItems={renderActionItems()} />
        </React.Fragment>
    );
});
