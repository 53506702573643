import { History } from 'history';
import { clientRoute } from '../clientRoute';
import { ApiStore } from '../stores';
import { AxiosErrorHandler, ErrorCode, ShouldUseCustomErrorHandlerFunctionType } from '../types';

export const createMainInfoErrorHandler =
    (
        history: History,
        customErrorHandler: ApiStore['userActionClientCatchHandler'],
    ): ShouldUseCustomErrorHandlerFunctionType =>
    (isShouldUseCustomHandler): AxiosErrorHandler =>
    (error) => {
        const status = error.response?.status;
        switch (status) {
            case ErrorCode.notFound: {
                history.replace(clientRoute.notFound);
                break;
            }
            case ErrorCode.notAllowed: {
                history.replace(clientRoute.notAllowed);
                break;
            }
            default: {
                isShouldUseCustomHandler && customErrorHandler(error);
                break;
            }
        }

        return Promise.reject(error);
    };
