import {
    ChartSettingField,
    GeneralObjectPermissionTrigger,
    GeneralObjectPFSettingSections,
    GeneralObjectSettingSections,
    LocalesTree,
    SettingsContentType,
} from '../../../types';

const settingsSections: Record<GeneralObjectSettingSections, string> = {
    access: 'Access',
    content: 'Content',
};

const pfSettingsSections: Record<GeneralObjectPFSettingSections, string> = {
    settings: 'Settings',
    access: 'Access',
};

const permissionTrigger: Record<GeneralObjectPermissionTrigger, string> = {
    role: 'Role',
    condition: 'Condition',
};

const contentTypes: Record<SettingsContentType, string> = {
    FORM: 'Form',
    LIST: 'List',
    PF: 'Print form',
    CHART: 'Visualization',
};

const visualizationSettingsFields: Record<ChartSettingField, string> = {
    chartSettings: 'Visualisation options',
    typeChart: 'Chart type',
    objectTab: 'Tab',
    objectRegistry: 'Object',
    valuesFieldCode: 'Values field code',
    dataLabelsFieldCode: 'Data labels field code',
    element: 'Element',
    source: 'Data source',
    registryQuery: 'Query',
};

const objectSettings: LocalesTree = {
    title: 'Tab settings',
    addTab: 'Add tab',
    hideTab: 'Hide tab',
    showTab: 'Show tab',
    hideTabConfirmation: 'The tab will be hidden on object pages, and its attributes will be unavailable in registers',
    showTabConfirmation: 'The tab will be shown on object pages, and its attributes will be available in registers',
    tabFields: {
        title: 'Name',
        code: 'Code',
        type: 'Content type',
    },
    tabModalTitle: 'New tab',
    addRule: 'Add rule',
    addPermissionModalTitle: 'New rule',
    editPermissionModalTitle: 'Access rule',
    codeHelperText: 'Latin letters and numbers, no spaces',
    accessFields: {
        roleCondition: 'Role/ condition',
        lifecycle: 'Lifecycle states',
        access: 'Access',
        editPermission: 'Edit',
        generation: 'Generation',
        filesDownload: 'Files download',
        edit: 'Edit',
        editForm: 'Form edit',
        filesManagement: 'Files management',
        filesUpload: 'Files upload',
        filesEdit: 'Files deletion and changing categories of files',
    },
    contentFields: {
        form: 'Form',
        showDownloadAllFilesButton: 'Show the "Download all files" button',
        list: 'List',
        entity: 'Object',
        preset: 'Preset',
    },
    printForms: 'Print forms',
    addPrintForm: 'Add print form template',
    newPrintForm: 'New print form template',
    editPrintForm: 'Edit the print form template',
    printFormFields: {
        titles: 'Name',
        code: 'Code',
        description: 'Description',
        docType: 'Document category',
        file: 'Template file',
        fileFormat: 'Generated file format',
        required: 'Is required for generation',
    },
    printFormList: {
        fileFormat: 'File format',
        required: 'Is required',
    },
    printFormDeleteText: 'The print form template will be deleted on the object’s tabs',
    visualizations: 'Data visualization',
    addVisualization: 'Add visualization',
    editVisualization: 'Edit visualization',
    newVisualization: 'New visualization',
    previewVisualization: 'Preview visualization',
    notValidSettings: 'Visualization settings is not valid',
    visualizationIsNotSet: 'Set up visualization options on the left',
    visualizationSetWithErrors: 'Visualization was configured with errors',
    visualizationPreview: 'Data',
    visualizationFields: {
        titles: 'Title',
        type: 'Type',
        source: 'Data source',
    },
    noVisualizations: 'No visualization',
    view: 'View',
    allStatuses: 'All statuses',
    contentTypes,
    settingsSections,
    pfSettingsSections,
    permissionTrigger,
    visualizationSettingsFields,
    notValidQuery: 'Query is not valid',
};

export default objectSettings;
