import { Box, Grid, Link, Typography } from '@mui/material';
import { makeSxStyles } from '@platform/front-ui';
import { useFeature } from 'feature-toggle-jsx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { Link as RouterLink } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import {
    FooterButtonStartPage as FooterButtonStartPageInj,
    HelpButton as HelpButtonInj,
    LanguageButton as LanguageButtonInj,
} from '../../../components';
import { contactSx } from '../../../constants';
import { useStore } from '../../../hooks';
import { HomePageLogo as HomePageLogoInj } from './HomePageLogo';

export const homePageLeftSideSxStyles = makeSxStyles({
    mainContainer: {
        height: '100%',
    },
    email: {
        paddingLeft: '1px',
        paddingRight: '1px',
    },
});

export const HomePageLeftSide = observer((): JSX.Element => {
    const [FooterButtonStartPage] = di([FooterButtonStartPageInj], HomePageLeftSide);
    const [LanguageButton] = di([LanguageButtonInj], HomePageLeftSide);
    const [HomePageLogo] = di([HomePageLogoInj], HomePageLeftSide);
    const [HelpButton] = di([HelpButtonInj], HomePageLeftSide);

    const { env } = useStore();
    const { totMail } = env;
    const [logo, logoConfig] = useFeature('startPageLogoImg');

    return (
        <Grid
            container
            direction="column"
            justifyContent="space-between"
            wrap="nowrap"
            sx={homePageLeftSideSxStyles.mainContainer}
        >
            <Grid item>
                {logoConfig.isEnabled && (
                    <Link component={RouterLink} to={clientRoute.root} underline="none">
                        <Grid container direction="row" alignItems="center">
                            <Grid container direction="row" alignItems="center">
                                <HomePageLogo />
                            </Grid>
                        </Grid>
                    </Link>
                )}
            </Grid>
            <Grid item>
                <Typography variant="h1" component="p" gutterBottom>
                    <Box fontWeight={700} component="span">
                        <FormattedMessage id="feature.phrases.productName" />
                    </Box>
                </Typography>
                <Typography variant="h1" component="p">
                    <Box fontSize="16px" fontWeight={500} component="span">
                        <FormattedMessage id="feature.phrases.productDescription" />
                    </Box>
                </Typography>
            </Grid>
            <Grid container item alignItems="center">
                <Grid item xs={6}>
                    <FooterButtonStartPage
                        // @ts-ignore
                        component="a"
                        sx={homePageLeftSideSxStyles.email}
                        href={`mailto:${totMail}`}
                        variant="text"
                        color="secondary"
                    >
                        {/* @ts-ignore */}
                        <Typography component="span" sx={contactSx}>
                            {totMail}
                        </Typography>
                    </FooterButtonStartPage>
                </Grid>
                <Grid container item direction="row" justifyContent="flex-end" spacing={3} xs={6}>
                    <HelpButton isDarkBackground={true} />
                    <Grid item>
                        <LanguageButton isDarkBackground={true} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
});
