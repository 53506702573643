import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { LoaderBox } from '../styled/LoaderBox';
import { FormioForm } from './FormioForm';
import { observer } from 'mobx-react-lite';
export var FormRaw = observer(function (props) {
    var formApi = props.formApi, onFormReady = props.onFormReady, onFormChange = props.onFormChange, className = props.className, setFormIsChanged = props.setFormIsChanged, locale = props.locale;
    var formModel = formApi.formModel, multiLang = formApi.multiLang, lang = formApi.lang, readOnly = formApi.readOnly;
    useEffect(function () {
        if (readOnly) {
            formApi.setLang(locale);
        }
    }, [locale]);
    var _a = useState(), submission = _a[0], setSubmission = _a[1];
    useEffect(function () {
        setSubmission(multiLang ? formModel.getLangSubmission(lang) : formModel.getSubmission());
    }, [lang, formModel.submission]);
    var onFormReadyCallback = function (form) {
        formApi.setFormioFormApi(form);
        form.form.formReady.then(function () {
            onFormReady && onFormReady(formApi);
        });
    };
    return (React.createElement(React.Fragment, null, formModel.loaded && submission ? (React.createElement(FormioForm, { form: formModel.form, submission: submission, i18n: formModel.i18n, locale: lang, onFormReady: onFormReadyCallback, onFormChange: onFormChange, readOnly: readOnly, className: className, setFormIsChanged: setFormIsChanged })) : (React.createElement(LoaderBox, null,
        React.createElement(CircularProgress, null)))));
});
