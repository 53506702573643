import { Backdrop, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeSxStyles } from '@platform/front-ui';
import { useFeature } from 'feature-toggle-jsx';
import React, { PropsWithChildren } from 'react';
import { di } from 'react-magnetic-di';

export type CustomBackgroundProps = {
    backgroundImage: string;
};

export const CustomBackground = styled('div')<CustomBackgroundProps>(
    ({ theme, backgroundImage }) => `
        height: 100%;
        background: url(${backgroundImage} center;
        background-size: cover;
        background-blend-mode: soft-light, overlay, normal;
        display: flex;
    `,
);

export const totBackgroundSxStyles = makeSxStyles({
    contentWrapper: {
        height: '100%',
        position: 'relative',
        zIndex: 2,
    },
    defaultBackgroundChildrenWrapper: {
        height: '100%',
        width: '100%',
        display: 'flex',
        zIndex: 2,
    },
});

export type TotBackgroundProps = PropsWithChildren<{ withBackdrop?: boolean }>;

export const TotBackground = (props: TotBackgroundProps): JSX.Element => {
    const { withBackdrop = false, children } = props;
    const [customBackground, config] = useFeature('customBackground');

    return (
        <CustomBackground backgroundImage={config.src}>
            <Grid container sx={totBackgroundSxStyles.contentWrapper}>
                {children}
            </Grid>
            <Backdrop style={{ zIndex: 1 }} open={withBackdrop} />
        </CustomBackground>
    );
};
