import React from 'react';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { GeneralObjectChildObjectModal as GeneralObjectChildObjectModalInj } from '../../../../components';
import { useStore } from '../../../../hooks';
import { CreateDescendantModalProps, GeneralObjectRouteParams } from '../../../../types';

export const ChildObjectDescendantChildObjectModal = (props: CreateDescendantModalProps): JSX.Element => {
    const [GeneralObjectChildObjectModal] = di(
        [GeneralObjectChildObjectModalInj],
        ChildObjectDescendantChildObjectModal,
    );
    const { id } = useParams<GeneralObjectRouteParams>();
    const { childObjectStore } = useStore();
    const { projectId } = childObjectStore;

    return <GeneralObjectChildObjectModal {...props} childObjectId={id} projectId={projectId} />;
};
