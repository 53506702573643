import { ObjectLocales } from '../../../types';

const visualization: ObjectLocales = {
    single: {
        nominative: 'визуализация',
        accusative: 'визуализацию',
    },
};

export default visualization;
