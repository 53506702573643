import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { di } from 'react-magnetic-di';
import { generatePath, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../clientRoute';
import { useStore } from '../../../../hooks';
import {
    ChildObjectCreateDTO,
    CreateDescendantModalProps,
    DescendantObjectCreateDTO,
    GeneralObject,
    GeneralObjectRouteParams,
} from '../../../../types';
import { GeneralObjectDescendantModal as GeneralObjectDescendantModalInj } from './GeneralObjectDescendantModal';

export type GeneralObjectChildObjectModalProps = CreateDescendantModalProps & {
    projectId: string;
    childObjectId?: string;
};

export const GeneralObjectChildObjectModal = observer((props: GeneralObjectChildObjectModalProps): JSX.Element => {
    const [GeneralObjectDescendantModal] = di([GeneralObjectDescendantModalInj], GeneralObjectChildObjectModal);

    const { projectId, childObjectId, creationInfoModel, ...CreateDescendantModalProps } = props;
    const { tabId } = useParams<GeneralObjectRouteParams>();
    const { childObjectStore, history } = useStore();
    const { creationInfo, isCreationInfoSet, setCreationInfo } = creationInfoModel;
    const { identifier, categories } = creationInfo;

    useEffect(() => {
        !isCreationInfoSet && childObjectStore.getDescendantCreateInfo(projectId, tabId).then(setCreationInfo);
    }, [projectId, tabId]);

    const onSubmit = (descendantValues: DescendantObjectCreateDTO): Promise<void> => {
        const dto: ChildObjectCreateDTO = {
            ...descendantValues,
            projectId: projectId,
        };

        if (childObjectId) {
            dto.childObjectId = childObjectId;
        }

        return childObjectStore.createObject(dto, tabId).then((id) => {
            history.push(
                generatePath(clientRoute.childObject.card, {
                    id,
                }),
            );
        });
    };

    return (
        <GeneralObjectDescendantModal
            {...CreateDescendantModalProps}
            descendantObject={GeneralObject.childObject}
            identifier={identifier}
            categories={categories}
            onSubmit={onSubmit}
        />
    );
});
