import { Box, Breadcrumbs, Skeleton, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { di } from 'react-magnetic-di';
import { useServerTitleBreadcrumbs, UseServerTitleBreadcrumbsProps } from '../../hooks';
import { BreadcrumbsLink as BreadcrumbsLinkInj } from './BreadcrumbsLink';

const maxItemWidth = 205;

export type ServerBreadcrumbsProps = UseServerTitleBreadcrumbsProps & {
    itemWidth?: number;
    isVisible?: boolean;
};

export const ServerBreadcrumbs = observer((props: ServerBreadcrumbsProps): JSX.Element => {
    const [BreadcrumbsLink] = di([BreadcrumbsLinkInj], ServerBreadcrumbs);
    const { itemWidth = maxItemWidth, isVisible = true, ...useServerTitleBreadcrumbsProps } = props;
    const [maxItems, setMaxItems] = useState<number>(0);
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
    const containerRef = useRef<HTMLDivElement>(null);
    const [routes, isLoading] = useServerTitleBreadcrumbs(useServerTitleBreadcrumbsProps);

    const onWindowResize = (): void => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', onWindowResize);
        return () => {
            window.removeEventListener('resize', onWindowResize);
        };
    }, []);

    useEffect(() => {
        const containerWidth = containerRef.current?.clientWidth || 0;
        const currentMaxItems = Math.floor(containerWidth / itemWidth);
        setMaxItems(currentMaxItems);
    }, [windowWidth]);

    const lastLinkIndex = routes.length - 1;

    return (
        <React.Fragment>
            {isVisible && (
                <Box ref={containerRef}>
                    {isLoading ? (
                        <Typography>
                            <Skeleton animation="wave" width="30%" />
                        </Typography>
                    ) : (
                        <Breadcrumbs maxItems={maxItems}>
                            {routes.map((link, index) => {
                                const { url, label } = link;
                                const isWithRedirect = lastLinkIndex === index;

                                return <BreadcrumbsLink to={url} isLast={isWithRedirect} linkText={label} />;
                            })}
                        </Breadcrumbs>
                    )}
                </Box>
            )}
        </React.Fragment>
    );
});
