import DataLoader from 'dataloader';
import { action, makeObservable, observable } from 'mobx';
import { di } from 'react-magnetic-di';
import { apiConfigs } from '../apiConfigs';
import { AuthorizationCheckQuery, AuthorizationCheckResult } from '../types';
import { stringifyObjectOrderedByKey } from '../utils';
import { ApiStore } from './ApiStore';
import { RootStore } from './RootStore';

export const AuthorizationStoreProps = {
    api: observable,
    dataLoader: observable,
    check: action.bound,
    checkAll: action.bound,
    loadGrants: action.bound,
};

export class AuthorizationStore {
    private api: ApiStore;
    private dataLoader: DataLoader<AuthorizationCheckQuery, boolean>;

    constructor(rootStore: RootStore) {
        makeObservable(this, AuthorizationStoreProps);
        this.api = rootStore.api;
        this.dataLoader = new DataLoader(this.loadGrants, { cache: false });
    }

    check(query: AuthorizationCheckQuery): Promise<boolean> {
        return this.dataLoader.load(query);
    }

    checkAll(queries: AuthorizationCheckQuery[]): Promise<boolean[]> {
        return Promise.all(queries.map((query) => this.dataLoader.load(query)));
    }

    private loadGrants(queries: Readonly<AuthorizationCheckQuery[]>): Promise<boolean[]> {
        // достаем уникальные query из queries, сортировка полей необходима так как JSON.stringify не гарантирует алфавитный порядок полей
        const uniqueQueriesSet = new Set(queries.map((query) => stringifyObjectOrderedByKey(query))).keys();
        const uniqueQueries = Array.from(uniqueQueriesSet).map((query) => JSON.parse(query));
        // отправляем их на бэк
        return this.api
            .client(apiConfigs.authorizationCheck(uniqueQueries))
            .then((r) => r.data)
            .then((checkResults: AuthorizationCheckResult[]) => {
                // создаем коллекцию ключ/значение, в которой ключ: stringfied query без allowed (т.е оригинальная stringfied query), значение: query + {allowed: boolean}
                const checkResultsMap = new Map(
                    checkResults.map((checkResult) => {
                        const originalQuery = { ...checkResult } as Partial<typeof checkResult>;
                        delete originalQuery.allowed;
                        return [stringifyObjectOrderedByKey(originalQuery), checkResult];
                    }),
                );
                // Проходимся по queries (query внутри могут повторяться), достаем из checkResultsMap ответ бэка для текущей query, используя stringified query как ключ
                return queries.map((query) => {
                    const queryWithCheckResult = checkResultsMap.get(stringifyObjectOrderedByKey(query));
                    return queryWithCheckResult ? queryWithCheckResult.allowed : false;
                });
            });
    }
}

export const getAuthorizationStore = (): any => {
    const [_AuthorizationStore] = di([AuthorizationStore], getAuthorizationStore);
    return _AuthorizationStore;
};
